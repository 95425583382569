import { Observable } from 'rxjs/Observable';
import { ajax } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';

/** *************************
 *          CONSTANTS
 **************************** */

const SEARCH_VALUE_CHANGED = 'SEARCH_VALUE_CHANGED';
const SEARCH_RESULTS_FULFILLED = 'SEARCH_RESULTS_FULFILLED';

const SEARCH_RESULTS_PENDING = 'SEARCH_RESULTS_PENDING';

const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';

export const RESET_SEARCH = 'RESET_SEARCH';

const RESET_SEARCH_IS_FETCHING = 'RESET_IS_FETCHING';

const RESET_LIVE_JOBS_SEARCH = 'RESET_LIVE_JOBS_SEARCH';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const searchValueChanged = (value, territoryId, excludeTruck, isLiveJobsPage) => ({
  type: SEARCH_VALUE_CHANGED,
  territoryId,
  value,
  excludeTruck,
  isLiveJobsPage,
});

export const resetSearch = () => ({
  type: RESET_SEARCH,
});

export const resetLiveJobsSearch = () => ({
  type: RESET_LIVE_JOBS_SEARCH
})

export const resetIsFetching = () => ({
  type: RESET_SEARCH_IS_FETCHING,
});

const searchFulfilled = (payload) => ({
  type: SEARCH_RESULTS_FULFILLED,
  payload,
});

const searchPending = () => ({
  type: SEARCH_RESULTS_PENDING,
});

export const setSearchValue = (payload, isLiveJobsPage) => ({
  type: SET_SEARCH_VALUE,
  payload,
  isLiveJobsPage,
});

/** *************************
 *           EPICS
 **************************** */

// query address search
const searchString = (query) => {
  const { value, territoryId, excludeTruck } = query;
  return `/search?address=${value}&territoryId=${territoryId}&excludeTrucks=${excludeTruck ? true : false}`;
};
const searchAddressEpic = (actions, { getState }) =>
  Observable.combineLatest(
    actions
      .ofType(SEARCH_VALUE_CHANGED)
      .debounceTime(250)
      .map((action) => action)
      .startWith(getState().search.value)
      .filter(
        (action) =>
          !!action.value && action.value.replace(/\s/g, '').length >= 3,
      ),
    (q) => searchString(q),
  ).switchMap((url) =>
    ajax
      .get(url, null, (payload) =>
        Observable.of(searchFulfilled(payload.response)),
      )
      .startWith(searchPending()),
  );

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  value: '',
  liveJobsValue: '',
  searchedValue: '',
  items: {
    addresses: [],
    trucks: [],
  },
  liveJobsItems: {
    addresses: [],
    trucks: [],
  },
  result: {
    id: null,
    streetNumber: null,
    streetName: '',
    suburb: '',
    postCode: null,
  },
  liveJobsResult: {
    id: null,
    streetNumber: null,
    streetName: '',
    suburb: '',
    postCode: null,
  },
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case SEARCH_VALUE_CHANGED:
      return {
        ...state,
        isFetching: true,
        value: action.isLiveJobsPage ? state.value : action.value,
        liveJobsValue: action.isLiveJobsPage ? action.value : state.liveJobsValue,
      };    
    case SEARCH_RESULTS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
        liveJobsItems: action.payload,
      };
    case RESET_SEARCH_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_SEARCH:
      return {
        ...initialState,
        liveJobsValue: state.liveJobsValue,
        liveJobsItems: state.liveJobsItems,
        liveJobsResult: state.liveJobsResult,
      };
    case SET_SEARCH_VALUE: // eslint-disable-line no-case-declarations
      const { streetNumber, streetName, suburb, postCode } = action.payload;
      const value = `${streetNumber || ''} ${
        streetName ? `${streetName},` : ''
      } ${suburb || ''} ${postCode || ''}`;

      return {
        ...state,
        value: action.isLiveJobsPage ? state.value : value,
        searchedValue: value,
        liveJobsValue: action.isLiveJobsPage ? value : state.liveJobsValue,
        result: action.payload,
        liveJobsResult: action.isLiveJobsPage ? action.payload : state.liveJobsResult,
      };
    case RESET_LIVE_JOBS_SEARCH:
      return {
        ...state,
        liveJobsValue: '',
        liveJobsItems: {
          addresses: [],
          trucks: [],
        },
        liveJobsResult: {
          id: null,
          streetNumber: null,
          streetName: '',
          suburb: '',
          postCode: null,
        },
      }
    default:
      return state;
  }
}

export const searchEpics = {
  searchAddressEpic,
};
