/** *************************
 *   ADMIN RESTRICTED MODULE
 **************************** */

import { Observable } from 'rxjs/Observable';
import { ajax } from '../../common/AjaxClient';
import { jwt } from '../../common/JwtManager';
import { showMessage, SHOW_MESSAGE } from './messages';
import { HAS_UNAUTHENTICATED } from './account';
import {
  POST_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from './users';
import { accountModules } from '../../common/options';
import { triggerDownload } from '../../common/helpers';
import azureSession from '../../azure/authentication/session';

/** *************************
 *          CONSTANTS
 **************************** */

const REQUEST_ACCOUNTS = 'REQUEST_ACCOUNTS';
const RECEIVE_ACCOUNTS = 'RECEIVE_ACCOUNTS';

const ACCOUNT_POST = 'ACCOUNT_POST';
const ACCOUNT_POST_SUCCESS = 'ACCOUNT_POST_SUCCESS';

const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
const UPDATE_ACCOUNTS_SUCCESS = 'UPDATE_ACCOUNTS_SUCCESS';

const ADD_BUFFERED_FILES = 'ADD_BUFFERED_FILES';
const DELETE_BUFFERED_FILES = 'DELETE_BUFFERED_FILES';
const TOGGLE_INVOICE_MODAL_OPEN = 'TOGGLE_INVOICE_MODAL_OPEN';
const CHANGE_DROPDOWN_VALUES = 'CHANGE_DROPDOWN_VALUES';

const UPLOAD_INVOICE = 'UPLOAD_INVOICE';
const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS';

const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
const RECEIVE_INVOICE_DOWNLOAD = 'RECEIVE_INVOICE_DOWNLOAD';

const REQUEST_DELETE_INVOICE = 'REQUEST_DELETE_INVOICE';
const RECEIVE_DELETE_INVOICE = 'RECEIVE_DELETE_INVOICE';

const REQUEST_EXPORT_USER_LOGS = 'REQUEST_EXPORT_USER_LOGS';
const RECEIVE_EXPORT_USER_LOGS = 'RECEIVE_EXPORT_USER_LOGS';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const requestAccounts = () => ({
  type: REQUEST_ACCOUNTS,
});

const receiveAccounts = (payload) => ({
  type: RECEIVE_ACCOUNTS,
  payload,
});

export const accountPost = (payload) => ({
  type: ACCOUNT_POST,
  payload,
});

const accountPostSuccess = (payload) => ({
  type: ACCOUNT_POST_SUCCESS,
  payload,
});

export const updateAccounts = (payload) => ({
  type: UPDATE_ACCOUNTS,
  payload,
});

const updateAccountsSuccess = (payload) => ({
  type: UPDATE_ACCOUNTS_SUCCESS,
  payload,
});

export const addBufferedFiles = (o) => ({
  type: ADD_BUFFERED_FILES,
  o,
});

export const deleteBufferedFiles = (o) => ({
  type: DELETE_BUFFERED_FILES,
  o,
});

export const toggleInvoiceModalOpen = () => ({
  type: TOGGLE_INVOICE_MODAL_OPEN,
});

export const changeDropdownValues = (name, value) => ({
  type: CHANGE_DROPDOWN_VALUES,
  name,
  value,
});

export const uploadInvoice = (payload) => ({
  type: UPLOAD_INVOICE,
  payload,
});

const uploadInvoiceSuccess = (payload) => ({
  type: UPLOAD_INVOICE_SUCCESS,
  payload,
});

export const downloadInvoice = (id, name) => ({
  type: DOWNLOAD_INVOICE,
  id,
  name,
});

const receiveInvoiceDownload = (payload, fileName) => ({
  type: RECEIVE_INVOICE_DOWNLOAD,
  payload,
  fileName,
});

export const requestDeleteInvoice = (id) => ({
  type: REQUEST_DELETE_INVOICE,
  id,
});

export const receiveDeleteInvoice = (id) => ({
  type: RECEIVE_DELETE_INVOICE,
  id,
});

export const requestExportUserLogs = (id) => ({
  type: REQUEST_EXPORT_USER_LOGS,
  id,
});

export const receiveExportUserLogs = (payload, filename) => ({
  type: RECEIVE_EXPORT_USER_LOGS,
  payload,
  filename,
});

/** *************************
 *           EPICS
 **************************** */

// add account
const accountPostEpic = (action$) =>
  action$.ofType(ACCOUNT_POST).switchMap((action) => {
    const modules = [];
    accountModules.forEach((module) => {
      if (!!action.payload.modules && action.payload.modules[module.name]) {
        modules.push(module);
      }
    });
    return ajax.post(
      '/admin/account',
      {
        account: {
          ...action.payload,
          type: 'MUNI',
          modules,
        },
      },
      null,
      (payload) =>
        Observable.of(
          accountPostSuccess(payload.response.account),
          showMessage('Success', 'Account has been added', 'success'),
        ),
    );
  });

// update account
const updateAccountsEpic = (action$) =>
  action$.ofType(UPDATE_ACCOUNTS).switchMap((action) => {
    const modules = [];
    accountModules.forEach((module) => {
      if (!!action.payload.modules && action.payload.modules[module.name]) {
        modules.push(module);
      }
    });
    return ajax.post(
      `/admin/account/${action.payload.id}`,
      {
        account: {
          ...action.payload,
          type: 'MUNI',
          modules,
        },
      },
      null,
      (payload) =>
        Observable.of(
          updateAccountsSuccess(payload.response.account),
          showMessage('Success', 'Account has been updated', 'success'),
        ),
    );
  });

// request accounts
const requestAccountsEpic = (action$) =>
  action$
    .ofType(REQUEST_ACCOUNTS)
    .switchMap(() =>
      ajax.get('/admin/account', null, (payload) =>
        Observable.of(receiveAccounts(payload.response.accounts)),
      ),
    );

// Upload new invoice and update the view
export const uploadInvoiceEpic = (action$) =>
  action$.ofType(UPLOAD_INVOICE).switchMap((action) =>
    ajax.post(
      '/admin/invoice',
      {
        ...action.payload,
      },
      null,
      (payload) =>
        Observable.of(
          uploadInvoiceSuccess(payload.response.invoice),
          showMessage('Success', 'Invoice has been added', 'success'),
        ),
    ),
  );

export const downloadInvoiceEpic = (action$) =>
  action$.ofType(DOWNLOAD_INVOICE).switchMap((action) => {
    const h = {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    };
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    // Construct a useful filename
    const filename = action.name;
    const request = {
      headers: h,
      responseType: 'arraybuffer',
      url: `/portal/invoice/file/${action.id}`,
      method: 'GET',
    };

    return Observable.ajax(request)
      .flatMap((payload) =>
        Observable.of(receiveInvoiceDownload(payload, filename)),
      )
      .catch(() => {
        // Fail silently
      });
  });

// Delete an invoice file by id, removes single invoice file
export const requestDeleteInvoiceEpic = (action$) =>
  action$
    .ofType(REQUEST_DELETE_INVOICE)
    .switchMap((action) =>
      ajax.delete(`/admin/invoice/file/${action.id}`, null, () =>
        Observable.of(receiveDeleteInvoice(action.id)),
      ),
    );

// export report
const requestExportUserLogsEpic = (action$) =>
  action$.ofType(REQUEST_EXPORT_USER_LOGS).switchMap(() => {
    if (!azureSession.getToken()) {
      return Observable.of(showMessage('Error', 'Access Denied', 'error'));
    }
    const request = {
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${azureSession.getToken()}`,
      },
      responseType: 'arraybuffer',
      url: '/portal/admin/user_logs/export',
      method: 'GET',
    };
    return Observable.ajax(request)
      .flatMap((payload) =>
        Observable.of(receiveExportUserLogs(payload, 'user_logs.csv')),
      )
      .catch(() => {
        // Fail silently
      });
  });

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  isPosting: false,
  isUpdating: false,
  isDeleting: false,
  items: [],
  isUploadingInvoice: false,
  isDownloadingInvoice: null,
  isDeletingInvoice: null,
  invoiceModalOpen: false,
  bufferedFiles: [],
  month: null,
  year: null,
  isExportingUserLogs: false,
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case REQUEST_ACCOUNTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ACCOUNTS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case ACCOUNT_POST:
      return {
        ...state,
        isPosting: true,
      };
    case ACCOUNT_POST_SUCCESS: {
      const prevNewItems = state.items;
      // Todo: Return object should not be defined here
      prevNewItems.push({
        account: action.payload,
        users: [],
        invoices: [],
      });
      return {
        ...state,
        items: prevNewItems,
        isPosting: false,
      };
    }
    case SHOW_MESSAGE:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        isPosting: false,
        // Reset modal and close it either on success or failure
        isUploadingInvoice: false,
        invoiceModalOpen: false,
        bufferedFiles: [],
        month: null,
        year: null,
        isExportingUserLogs: false,
      };
    case UPDATE_ACCOUNTS:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_ACCOUNTS_SUCCESS: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < prevUpdateItems.length; i += 1) {
        if (
          prevUpdateItems[i].account &&
          prevUpdateItems[i].account.id === action.payload.id
        ) {
          prevUpdateItems[i].account = action.payload;
        }
      }
      return {
        ...state,
        isUpdating: false,
        items: prevUpdateItems,
      };
    }
    case POST_USER_SUCCESS:
      for (let i = 0; i < state.items.length; i += 1) {
        if (
          state.items[i].account &&
          state.items[i].account.id === action.accountId
        ) {
          state.items[i].users.push(action.payload);
        }
      }
      return {
        ...state,
        items: state.items,
        isPosting: false,
      };
    case UPDATE_USER_SUCCESS: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < prevUpdateItems.length; i += 1) {
        if (
          prevUpdateItems[i].account &&
          prevUpdateItems[i].account.id === action.accountId
        ) {
          for (let j = 0; j < prevUpdateItems[i].users.length; j += 1) {
            if (prevUpdateItems[i].users[j].id === action.payload.id) {
              prevUpdateItems[i].users[j] = action.payload;
            }
          }
        }
      }
      return {
        ...state,
        items: prevUpdateItems,
        isUpdating: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      const items = state.items;
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items[i].users.length; j += 1) {
          if (items[i].users[j].id === action.id) {
            items[i].users.splice(j, 1);
          }
        }
      }
      return {
        ...state,
        items,
        isUpdating: false,
      };
    }
    case ADD_BUFFERED_FILES: {
      const newState = state.bufferedFiles.concat(action.o);
      for (let i = 0; i < newState.length; i += 1) {
        newState[i].id = i + 1;
      }
      return {
        ...state,
        bufferedFiles: newState,
      };
    }
    case DELETE_BUFFERED_FILES: {
      return {
        ...state,
        bufferedFiles: [
          ...state.bufferedFiles.slice(
            0,
            state.bufferedFiles.indexOf(action.o),
          ),
          ...state.bufferedFiles.slice(
            state.bufferedFiles.indexOf(action.o) + 1,
          ),
        ],
      };
    }
    case TOGGLE_INVOICE_MODAL_OPEN:
      return {
        ...state,
        invoiceModalOpen: !state.invoiceModalOpen,
      };
    case CHANGE_DROPDOWN_VALUES:
      return {
        ...state,
        [action.name]: action.value,
      };
    case UPLOAD_INVOICE:
      return {
        ...state,
        isUploadingInvoice: true,
      };
    case UPLOAD_INVOICE_SUCCESS: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < prevUpdateItems.length; i += 1) {
        if (
          prevUpdateItems[i].account &&
          prevUpdateItems[i].account.id === action.payload.accountId
        ) {
          // Do you need to update an existing invoice ?
          let found = false;
          for (let j = 0; j < prevUpdateItems[i].invoices.length; j += 1) {
            if (prevUpdateItems[i].invoices[j].id === action.payload.id) {
              prevUpdateItems[i].invoices[j] = action.payload;
              found = true;
            }
          }
          // If no match could be found, add the invoice to the list
          if (!found) {
            prevUpdateItems[i].invoices.push(action.payload);
          }
        }
      }
      return {
        ...state,
        items: prevUpdateItems,
      };
    }
    case DOWNLOAD_INVOICE: {
      return {
        ...state,
        isDownloadingInvoice: action.id,
      };
    }
    case RECEIVE_INVOICE_DOWNLOAD:
      triggerDownload(
        action.payload.response,
        action.fileName,
        'application/octet-stream',
      );
      return {
        ...state,
        isDownloadingInvoice: null,
      };
    case REQUEST_DELETE_INVOICE:
      return {
        ...state,
        isDeletingInvoice: action.id,
      };
    case RECEIVE_DELETE_INVOICE: {
      const prevUpdateItems = state.items;
      for (let it = 0; it < prevUpdateItems.length; it += 1) {
        for (let inv = 0; inv < prevUpdateItems[it].invoices.length; inv += 1) {
          for (
            let f = 0;
            f < prevUpdateItems[it].invoices[inv].files.length;
            f += 1
          ) {
            if (
              prevUpdateItems[it].invoices[inv].files[f].fileId === action.id
            ) {
              // delete file so the view updates immediately
              prevUpdateItems[it].invoices[inv].files.splice(f, 1);
            }
          }
        }
      }
      return {
        ...state,
        items: prevUpdateItems,
        isDeletingInvoice: null,
      };
    }
    case REQUEST_EXPORT_USER_LOGS:
      return {
        ...state,
        isExportingUserLogs: true,
      };
    case RECEIVE_EXPORT_USER_LOGS: {
      triggerDownload(action.payload.response, action.filename, 'text/csv');
      return {
        ...state,
        isExportingUserLogs: false,
        export: action.payload,
      };
    }
    default:
      return state;
  }
}

export const accountsEpics = {
  accountPostEpic,
  updateAccountsEpic,
  requestAccountsEpic,
  uploadInvoiceEpic,
  downloadInvoiceEpic,
  requestDeleteInvoiceEpic,
  requestExportUserLogsEpic,
};
