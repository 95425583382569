 /* eslint-disable */
import { createLiftBubble } from '../../helpers/LiftBubble';
import { getLiftIcon, getClusterColor } from './icons';
import { getClusteringTheme } from './theme';

const HMapWastestreamCluster = {
  generateCluster(liftsByWastestream, ui, setActiveEvent, setClickedTruck) {
    const lifts = [];
    const wasteStreams = [];

    liftsByWastestream.forEach((truck) => {
      wasteStreams.push(truck.wasteStream);
      truck.lifts.forEach((lift) =>
        lifts.push(
          // eslint-disable-next-line no -undef
          new H.clustering.DataPoint(lift.position[0], lift.position[1], 1, { ...lift, ...{ truckId: truck.truckId } }),
        ),
      );
    });

    // Create lift icon once, cluster icon instance needed for each 'size'
    const liftIcon = getLiftIcon(50).replace('{colour}', getClusterColor(wasteStreams));
    const liftHIcon = new H.map.Icon(liftIcon, {
      size: { w: 25, h: 25 },
      anchor: { x: 50, y: 50 },
    });

    const clusterProvider = new H.clustering.Provider(lifts, {
      clusteringOptions: {
        minWeight: 3,
        eps: 20,
      },
      theme: getClusteringTheme(getClusterColor(wasteStreams), liftHIcon),
    });

    clusterProvider.addEventListener('tap', (event) => {
      const data = event.target.getData();
      createLiftBubble(data, ui, setActiveEvent, setClickedTruck);
    });

    return new H.map.layer.ObjectLayer(clusterProvider);
  },
};

export default HMapWastestreamCluster;
