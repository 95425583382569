import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as accountActionCreators from '../../redux/modules/account';
import { Grid, Loader, Image } from 'semantic-ui-react';
import Logo from '../../common/images/general/cleanaway.svg';

class LoginContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }

  props: {
    isFetching: boolean,
    account: Object,
  };

  renderPageState() {
    if (this.props.account.authenticateFailedStatusCode === 401) {
      return (
        <p>
          This user has not been added to the Cleanaview system, please contact
          your local Cleanaway branch
        </p>
      );
    }
    if (this.props.account.authenticateFailedStatusCode === 403) {
      return (
        <p>
          Welcome to the Cleanaview Council Portal. If you have recently
          accepted the invite we have sent to you , please wait for a
          confirmation email that your user profile has been set up. This should
          be received within 2 business days. If you have not yet requested
          access, please contact your local Cleanaway branch.
        </p>
      );
    }

    return (
      <p>
        A popup will appear to input your account details. Please always allow
        pop-ups from this domain.
      </p>
    );
  }

  render() {
    return (
      <div style={{ height: '100%' }} className="comp">
        <Grid centered verticalAlign="middle" className="not-found">
          <Grid.Column textAlign="center" style={{ maxWidth: '620px' }}>
            <Helmet
              title="Home"
              meta={[{ name: 'Home', content: 'Customer Portal' }]}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}>
              <Image src={Logo} alt="Cleanaway" width={400} />
            </div>

            {this.renderPageState()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isFetching: state.account.isFetching,
    account: state.account,
  }),
  (dispatch) => bindActionCreators(accountActionCreators, dispatch),
)(LoginContainer);
