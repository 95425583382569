import React from 'react';
import { getDotColor } from '../../../../../common/wasteStreamColour';


export const getClusterIcon = () =>
  '<svg xmlns="http://www.w3.org/2000/svg" height="50px" width="50px"><circle cx="30px" cy="30px" r="20" fill="{colour}" stroke-opacity="0.5" />' +
  '<text x="30" y="38" font-size="17pt" font-family="arial" font-weight="bold" text-anchor="middle" fill="white">{text}</text>' +
  '</svg>';

export const getLiftIcon = (size = 28) =>
  `<svg width="${size}" height="${size}" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">` +
  '<g filter="url(#filter0_d)">' +
  '<circle cx="14" cy="12" r="11" fill="{colour}" stroke="white" stroke-width="2"/>' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M9 9.27273C9 9.02169 9.22386 8.81818 9.5 8.81818H18.5C18.7761 8.81818 19 9.02169 19 9.27273C19 9.52377 18.7761 9.72727 18.5 9.72727H9.5C9.22386 9.72727 9 9.52377 9 9.27273Z" fill="white"/>' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M13 7.90909C12.8674 7.90909 12.7402 7.95698 12.6464 8.04222C12.5527 8.12747 12.5 8.24308 12.5 8.36364V8.81818H15.5V8.36364C15.5 8.24308 15.4473 8.12747 15.3536 8.04222C15.2598 7.95698 15.1326 7.90909 15 7.90909H13ZM16.5 8.81818V8.36364C16.5 8.00198 16.342 7.65513 16.0607 7.3994C15.7794 7.14367 15.3978 7 15 7H13C12.6022 7 12.2206 7.14367 11.9393 7.3994C11.658 7.65513 11.5 8.00198 11.5 8.36364V8.81818H10.5C10.2239 8.81818 10 9.02169 10 9.27273V15.6364C10 15.998 10.158 16.3449 10.4393 16.6006C10.7206 16.8563 11.1022 17 11.5 17H16.5C16.8978 17 17.2794 16.8563 17.5607 16.6006C17.842 16.3449 18 15.998 18 15.6364V9.27273C18 9.02169 17.7761 8.81818 17.5 8.81818H16.5ZM11 9.72727V15.6364C11 15.7569 11.0527 15.8725 11.1464 15.9578C11.2402 16.043 11.3674 16.0909 11.5 16.0909H16.5C16.6326 16.0909 16.7598 16.043 16.8536 15.9578C16.9473 15.8725 17 15.7569 17 15.6364V9.72727H11Z" fill="white"/>' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M13 11.0909C13.2761 11.0909 13.5 11.2944 13.5 11.5455V14.2727C13.5 14.5238 13.2761 14.7273 13 14.7273C12.7239 14.7273 12.5 14.5238 12.5 14.2727V11.5455C12.5 11.2944 12.7239 11.0909 13 11.0909Z" fill="white"/>' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M15 11.0909C15.2761 11.0909 15.5 11.2944 15.5 11.5455V14.2727C15.5 14.5238 15.2761 14.7273 15 14.7273C14.7239 14.7273 14.5 14.5238 14.5 14.2727V11.5455C14.5 11.2944 14.7239 11.0909 15 11.0909Z" fill="white"/>' +
  '</g>' +
  '<defs>' +
  '<filter id="filter0_d" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
  '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
  '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>' +
  '<feOffset dy="2"/>' +
  '<feGaussianBlur stdDeviation="1"/>' +
  '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>' +
  '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>' +
  '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>' +
  '</filter>' +
  '</defs>' +
  '</svg>';

export const getLiftIconAsSvgElement = (size = 28, colour = '#535353') => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      '
      <circle
        cx="14"
        cy="12"
        r="11"
        fill={colour}
        stroke="white"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9.27273C9 9.02169 9.22386 8.81818 9.5 8.81818H18.5C18.7761 8.81818 19 9.02169 19 9.27273C19 9.52377 18.7761 9.72727 18.5 9.72727H9.5C9.22386 9.72727 9 9.52377 9 9.27273Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.90909C12.8674 7.90909 12.7402 7.95698 12.6464 8.04222C12.5527 8.12747 12.5 8.24308 12.5 8.36364V8.81818H15.5V8.36364C15.5 8.24308 15.4473 8.12747 15.3536 8.04222C15.2598 7.95698 15.1326 7.90909 15 7.90909H13ZM16.5 8.81818V8.36364C16.5 8.00198 16.342 7.65513 16.0607 7.3994C15.7794 7.14367 15.3978 7 15 7H13C12.6022 7 12.2206 7.14367 11.9393 7.3994C11.658 7.65513 11.5 8.00198 11.5 8.36364V8.81818H10.5C10.2239 8.81818 10 9.02169 10 9.27273V15.6364C10 15.998 10.158 16.3449 10.4393 16.6006C10.7206 16.8563 11.1022 17 11.5 17H16.5C16.8978 17 17.2794 16.8563 17.5607 16.6006C17.842 16.3449 18 15.998 18 15.6364V9.27273C18 9.02169 17.7761 8.81818 17.5 8.81818H16.5ZM11 9.72727V15.6364C11 15.7569 11.0527 15.8725 11.1464 15.9578C11.2402 16.043 11.3674 16.0909 11.5 16.0909H16.5C16.6326 16.0909 16.7598 16.043 16.8536 15.9578C16.9473 15.8725 17 15.7569 17 15.6364V9.72727H11Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.0909C13.2761 11.0909 13.5 11.2944 13.5 11.5455V14.2727C13.5 14.5238 13.2761 14.7273 13 14.7273C12.7239 14.7273 12.5 14.5238 12.5 14.2727V11.5455C12.5 11.2944 12.7239 11.0909 13 11.0909Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11.0909C15.2761 11.0909 15.5 11.2944 15.5 11.5455V14.2727C15.5 14.5238 15.2761 14.7273 15 14.7273C14.7239 14.7273 14.5 14.5238 14.5 14.2727V11.5455C14.5 11.2944 14.7239 11.0909 15 11.0909Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const getClusterColor = (wasteStreams: string[]): string => {
  const uniqueWasteStreams = wasteStreams.filter(
    (a, b) => wasteStreams.indexOf(a) === b,
  );
  return getDotColor(
    uniqueWasteStreams.length === 1 ? uniqueWasteStreams[0] : 'other',
  );
};
