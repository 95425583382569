/** *************************
 *          CONSTANTS
 **************************** */

const SELECT_COUNCIL = 'SELECT_COUNCIL';
const RECIEVE_COUNCILS = 'RECIEVE_COUNCILS';
const DEACTIVATE_COUNCIL = 'DEACTIVATE_COUNCIL';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const selectCouncil = (payload) => ({
  type: SELECT_COUNCIL,
  payload,
});

export const recieveCouncils = (payload) => ({
  type: RECIEVE_COUNCILS,
  payload,
});

export const deactivateCouncil = () => ({
  type: DEACTIVATE_COUNCIL,
});

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  hasCouncilSelected: false,
  available: [],
  active: {},
  accountModuleAccess: ['COUNCIL_SELECT'], // Add the default available module
};

export default function councils(state = initialState, action) {
  switch (action.type) {
    case SELECT_COUNCIL:
      sessionStorage.setItem('activeCouncil', JSON.stringify(action.payload));
      return {
        ...state,
        hasCouncilSelected: true,
        active: action.payload,
        accountModuleAccess: action.payload.accountModuleAccess,
      };
    case RECIEVE_COUNCILS:
      if (sessionStorage.hasOwnProperty('activeCouncil')) {
        const activeCouncil = JSON.parse(
          sessionStorage.getItem('activeCouncil'),
        );
        return {
          ...state,
          available: action.payload,
          hasCouncilSelected: true,
          active: activeCouncil,
          accountModuleAccess: activeCouncil.accountModuleAccess,
        };
      } else {
        return {
          ...state,
          available: action.payload,
        };
      }
    case DEACTIVATE_COUNCIL:
      sessionStorage.removeItem('activeCouncil');
      return {
        ...state,
        hasCouncilSelected: true,
        active: {},
        accountModuleAccess: ['COUNCIL_SELECT'],
      };
    default:
      return state;
  }
}
