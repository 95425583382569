import { PublicClientApplication } from '@azure/msal-browser';
import authConfig, { scopes, loginRequest } from './config';
import azureSession from './session';
import { store } from '../../redux/configureStore';
import {
  requestAccount,
  checkAuthenticated,
} from '../../redux/modules/account';

let accountId = '';

export const msal = new PublicClientApplication(authConfig);

const currentAccounts = msal.getAllAccounts();
const activeAccount = currentAccounts[0];

const silentRequest = {
  scopes: scopes,
  account: activeAccount,
  forceRefresh: false,
};

export const handleResponse = (response) => {
  const { accessToken, expiresOn } = response;
  azureSession.setToken(accessToken);
  azureSession.setExpires(expiresOn);

  if (sessionStorage.getItem('userAccount') === null) {
    store.dispatch(requestAccount(accessToken));
  }
};

export const acquireToken = () => {
  msal
    .acquireTokenSilent(silentRequest)
    .then((response) => {
      console.log('=== Silently updating token ===');
      handleResponse(response);
    })
    .catch(() => {
      console.log('=== No token found, login popup ===');
      msal
        .acquireTokenPopup(loginRequest)
        .then((response) => {
          msal.setActiveAccount(response.account);
          handleResponse(response);
        })
        .catch((error) => {
          console.log('auth error: ', error);
        });
    });
};

export const signOut = (interactionType) => {
  const logoutRequest = {
    account: msal.getAccountByHomeId(accountId),
  };

  if (interactionType === 'popup') {
    msal.logoutPopup(logoutRequest).then(() => {
      window.location.reload();
    });
  } else {
    msal.logoutRedirect(logoutRequest);
  }
};

export * from './config';
