import { Observable } from 'rxjs/Observable';
import { jwt } from '../../common/JwtManager';
import { ajax, handleError, maxTimeout } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { RESET_SEARCH } from './search';
import API_URL from '../../common/api';
import azureSession from '../../azure/authentication/session';

/** *************************
 *          CONSTANTS
 **************************** */

const MAP_REQUEST_TRUCKS = 'MAP_REQUEST_TRUCKS';
const MAP_RECEIVE_TRUCKS = 'MAP_RECEIVE_TRUCKS';

const MAP_REQUEST_ALERTS = 'MAP_REQUEST_ALERTS';
const MAP_RECEIVE_ALERTS = 'MAP_RECEIVE_ALERTS';

const MAP_REQUEST_ACTIVITY_AREA = 'MAP_REQUEST_ACTIVITY_AREA';
const MAP_RECEIVE_ACTIVITY_AREA = 'MAP_RECEIVE_ACTIVITY_AREA';
const MAP_RESET_ACTIVITY_AREA = 'MAP_RESET_ACTIVITY_AREA';

const MAP_SET_ALERT_FILTER = 'MAP_SET_ALERT_FILTER';
const MAP_SET_TRUCK_FILTER = 'MAP_SET_TRUCK_FILTER';
const MAP_SET_LIFTS_FILTER = 'MAP_SET_LIFTS_FILTER';

const MAP_REQUEST_MAP_LAYER = 'MAP_REQUEST_MAP_LAYER';
const MAP_RECEIVE_MAP_LAYER = 'MAP_RECEIVE_MAP_LAYER';

const MAP_SET_CENTER_ADDRESS = 'MAP_SET_CENTER_ADDRESS';
const MAP_RESET_CENTER_ADDRESS = 'MAP_RESET_CENTER_ADDRESS';

const MAP_REQUEST_ADDRESS = 'MAP_REQUEST_ADDRESS';
const MAP_RECEIVE_ADDRESS = 'MAP_RECEIVE_ADDRESS';

const MAP_REQUEST_BIN_IMAGE_PREVIEW = 'MAP_REQUEST_BIN_IMAGE_PREVIEW';
const MAP_RECEIVE_BIN_IMAGE_PREVIEW = 'MAP_RECEIVE_BIN_IMAGE_PREVIEW';

const MAP_REQUEST_ALL_BIN_IMAGES = 'MAP_REQUEST_ALL_BIN_IMAGES';
const MAP_RECEIVE_ALL_BIN_IMAGES = 'MAP_RECEIVE_ALL_BIN_IMAGES';

const MAP_RECEIVE_CLICKED_TRUCK = 'MAP_RECEIVE_CLICKED_TRUCK';

const MAP_REQUEST_LIFTS_FOR_WASTESTREAM = 'MAP_REQUEST_LIFTS_FOR_WASTESTREAM';
const MAP_RECEIVE_LIFTS_FOR_WASTESTREAM = 'MAP_RECEIVE_LIFTS_FOR_WASTESTREAM';
const MAP_RESET_LIFTS_FOR_WASTESTREAM = 'MAP_RESET_LIFTS_FOR_WASTESTREAM';

const RESET_LIVE_COLLECTIONS_FILTERS = 'RESET_LIVE_COLLECTIONS_FILTERS';

const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const requestTrucksMap = (payload) => ({
  type: MAP_REQUEST_TRUCKS,
  payload,
});

export const resetLiveCollectionsFilters = () => ({
  type: RESET_LIVE_COLLECTIONS_FILTERS
})

const receiveTrucks = (payload) => ({
  type: MAP_RECEIVE_TRUCKS,
  payload,
});

export const requestAlertsMap = (payload, territoryId) => ({
  type: MAP_REQUEST_ALERTS,
  date: payload,
  territoryId,
});

const receiveAlerts = (payload) => ({
  type: MAP_RECEIVE_ALERTS,
  payload,
});

export const requestActivityAreaMap = (payload, territoryId) => ({
  type: MAP_REQUEST_ACTIVITY_AREA,
  date: payload,
  territoryId: territoryId,
});

export const resetActivityAreaMap = () => ({
  type: MAP_RESET_ACTIVITY_AREA,
});

const receiveActivityAreaMap = (payload) => ({
  type: MAP_RECEIVE_ACTIVITY_AREA,
  payload,
});

export const requestLiftsForWastestream = ({
  date,
  wasteStream,
  territoryId,
}) => ({
  type: MAP_REQUEST_LIFTS_FOR_WASTESTREAM,
  date,
  wasteStream,
  territoryId,
});

export const resetLiftsForWastestream = () => ({
  type: MAP_RESET_LIFTS_FOR_WASTESTREAM,
});

const receiveLiftsForWastestream = (payload) => ({
  type: MAP_RECEIVE_LIFTS_FOR_WASTESTREAM,
  payload,
});

export const requestMapLayer = (payload) => ({
  type: MAP_REQUEST_MAP_LAYER,
  payload,
});

export const receiveMapLayer = (payload) => ({
  type: MAP_RECEIVE_MAP_LAYER,
  payload,
});

export const setAlertFilter = (payload) => ({
  type: MAP_SET_ALERT_FILTER,
  payload,
});

export const setTruckFilter = (payload) => ({
  type: MAP_SET_TRUCK_FILTER,
  payload,
});

export const setLiftsFilter = (payload) => ({
  type: MAP_SET_LIFTS_FILTER,
  payload,
});

export const setCenterAddress = (payload) => ({
  type: MAP_SET_CENTER_ADDRESS,
  payload,
});

export const resetCenterAddress = () => ({
  type: MAP_RESET_CENTER_ADDRESS,
});

export const requestAddress = (payload) => ({
  type: MAP_REQUEST_ADDRESS,
  payload,
});

const receiveAddress = (payload) => ({
  type: MAP_RECEIVE_ADDRESS,
  payload,
});

export const requestBinImagePrevie = (binEvents, index) => ({
  type: MAP_REQUEST_BIN_IMAGE_PREVIEW,
  binEvents,
  index,
});

export const receiveBinImagePreview = (image, eventId) => ({
  type: MAP_RECEIVE_BIN_IMAGE_PREVIEW,
  image,
  eventId,
});

export const requestAllBinImages = (imageResources, id) => ({
  type: MAP_REQUEST_ALL_BIN_IMAGES,
  imageResources,
  id,
});

const receiveAllBinImages = (payload) => ({
  type: MAP_RECEIVE_ALL_BIN_IMAGES,
  payload,
});

export const receiveClickedTruck = (payload) => ({
  type: MAP_RECEIVE_CLICKED_TRUCK,
  payload,
});

export const resetLiveCollectionIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

/** *************************
 *           EPICS
 **************************** */

// request trucks - fetch once, then poll every 2 mins
const requestTrucksMapEpic = (action$) =>
  action$.ofType(MAP_REQUEST_TRUCKS).switchMap((action) => {
    const territoryId = action.payload;
    return ajax.get(`/truck?territoryId=${territoryId}`, null, (payload) => {
      return Observable.of(receiveTrucks(payload.response));
    });
  });

// request alerts - fetch once, then poll every 5 mins

const BIN_EVENTS_REQUEST_CONFIG = {
  from: '0',
  limit: '1000',
};

const binEventEndpoint = (date, territoryId) => {
  const { from, limit } = BIN_EVENTS_REQUEST_CONFIG;
  const { dateTo, dateFrom, offset } = date;
  return `/event/binevents?territoryId=${territoryId}&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
    offset,
  )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(
    offset,
  )}&from=${from}&limit=${limit}`;
};

const requestAlertsMapEpic = (action$) =>
  action$.ofType(MAP_REQUEST_ALERTS).switchMap((action) =>
    ajax.get(
      binEventEndpoint(action.date, action.territoryId),
      null,
      (payload) => Observable.of(receiveAlerts(payload.response.binEvents)),
    ),
  );

const requestActivityAreaEpic = (action$) =>
  action$.ofType(MAP_REQUEST_ACTIVITY_AREA).switchMap((action) => {
    const { dateFrom, dateTo, offset } = action.date;

    const boundaryUrl = `/boundary?eventType=binLift&territoryId=${
      action.territoryId
    }&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
      offset,
    )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(offset)}`;
    return ajax.get(boundaryUrl, null, (payload) =>
      Observable.of(receiveActivityAreaMap(payload.response)),
    );
  });

const requestLiftsEpic = (action$) =>
  action$.ofType(MAP_REQUEST_LIFTS_FOR_WASTESTREAM).switchMap((action) => {
    const { wasteStream, territoryId, date } = action;
    const { dateFrom, dateTo, offset } = action.date;
    const wasteStreamUrl = `/waste-stream?name=${wasteStream}&territoryId=${territoryId}&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
      offset,
    )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(offset)}`;
    return ajax.get(wasteStreamUrl, null, (payload) =>
      Observable.of(receiveLiftsForWastestream(payload.response)),
    );
  });

// get map layer for given account
const formatMapLayer = (councilName) =>
  councilName.replace(' ', '_').replace('/', '%2F');

const requestMapLayerEpic = (action$) =>
  action$.ofType(MAP_REQUEST_MAP_LAYER).switchMap((action) => {
    const url = `https://cleanaviewstaticprd.blob.core.windows.net/boundaries/LGA-${formatMapLayer(
      action.payload,
    )}.json`;
    const h = { Accept: 'application/json' };
    h['Content-Type'] = 'application/json';
    const request = {
      url,
      headers: h,
    };
    return Observable.ajax(request).timeout(maxTimeout).flatMap((payload) =>
      Observable.of(receiveMapLayer(payload.response)),
    ).catch((e) => handleError(e));
  });

// get address
const createAddressUrl = (payload) => {
  const { id, activeDate, dayRange, range, lat, lng, territoryId } = payload;

  let base = `/address?addressId=${id}&territoryId=${territoryId}&lat=${lat}&lng=${lng}`;
  if (dayRange) {
    const { dateFrom, dateTo, offset } = dayRange;
    base =
      base +
      `&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
        offset,
      )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(offset)}`;
  }
  if (range) {
    base = base + `&range=${range}`;
  }
  return base;
};

const requestAddressEpic = (actions$) =>
  actions$.ofType(MAP_REQUEST_ADDRESS).mergeMap((action) => {
    return Observable.zip(
      // get work orders and bin events
      ajax.get(createAddressUrl(action.payload)),
      // get truck lifts
      (address) => ({
        ...address.response,
        position: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
        truckLifts: formatTruckLiftsFromAddressResponse(address.response),
      }),
    ).flatMap((payload) => {
      return Observable.of(
        setCenterAddress({
          ...action.payload,
          ...{ dataRadius: payload.dataRadius },
        }),
        receiveAddress(payload),
      );
    });
  });

const formatTruckLiftsFromAddressResponse = (response) => {
  if (!response || !response.trucks) {
    return [];
  }
  return response.trucks
    .map((truck) =>
      truck.lifts.map((lift) => ({
        created: lift.created,
        position: lift.position,
        wasteStream: truck.wasteStream,
        truckId: truck.truckId,
        uuid: lift.uuid,
      })),
    )
    .reduce((acc, val) => acc.concat(val), []);
};

export const formatTruckLiftsResponse = (trucks) =>
  trucks
    .map((truck) =>
      truck.lifts.map((lift) => ({
        created: lift.created,
        position: lift.position,
        wasteStream: truck.wasteStream,
        truckId: truck.truckId,
        uuid: lift.uuid,
      })),
    )
    .reduce((acc, val) => acc.concat(val), []);

const receiveAddressEpic = (actions$) =>
  actions$.ofType(MAP_RECEIVE_ADDRESS).switchMap((action) => {
    if (action.payload.binEvents) {
      return Observable.of(requestBinImagePreview(action.payload.binEvents, 0));
    }
    return Observable.empty();
  });

// just change to that particular image
export const requestBinPreviewImageEpic = (action$) =>
  action$.ofType(MAP_REQUEST_BIN_IMAGE_PREVIEW).switchMap((action) => {
    const actionIndex = action.index === null ? 0 : action.index;
    const imageIndex = 0;
    let imageHash = '';
    let url = '';

    // Request setup
    // Set Headers
    const h = {
      Accept: 'image/png',
      'Content-Type': 'image/png',
    };
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    const request = {
      headers: h,
      responseType: 'arraybuffer',
    };

    if (
      action.binEvents[action.index] !== undefined &&
      action.binEvents[action.index].imageResources &&
      action.binEvents[action.index].imageResources.length
    ) {
      // Use the first image as a thumbnail
      imageHash = encodeURIComponent(
        action.binEvents[action.index].imageResources[imageIndex],
      );
      url = `/event/binevents/${
        action.binEvents[action.index].$id
      }/image/${imageHash}?size=preview`;

      if (action.index < action.binEvents.length) {
        request.url = url;
        return Observable.ajax(request)
          .flatMap((payload) =>
            Observable.of(
              receiveBinImagePreview(
                payload.response,
                action.binEvents[action.index].$id,
              ),
              requestBinImagePreview(action.binEvents, actionIndex + 1),
            ),
          )
          .catch(() =>
            // Fail silently
            Observable.empty(),
          );
      }
      // Last call
      request.url = url;
      return Observable.ajax(request).flatMap((payload) =>
        Observable.of(
          receiveBinImagePreview(
            payload.response,
            action.binEvents[action.index].$id,
          ),
        ),
      );
    }
    // No images and last call
    return Observable.empty();
  });

export const requestAllBinImagesEpic = (action$) =>
  action$.ofType(MAP_REQUEST_ALL_BIN_IMAGES).mergeMap((action) => {
    const request = {
      responseType: 'arraybuffer',
    };

    return Observable.zip(
      ...action.imageResources.map((img) =>
        Observable.ajax({
          ...request,
          url: `${API_URL}/event/${action.id}/image/${encodeURIComponent(
            img,
          )}?size=main`,
        }),
      ),
      (...args) => [...args.map((i) => i.response)],
    ).flatMap((payload) => Observable.of(receiveAllBinImages(payload)));
  });

/* **************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  isFetchingTrucks: false,
  isFetchingWastestream: false,
  isFetchingActivityArea: false,
  fetchingImages: false,
  providedTimeLocalCouncil: null,
  centerOnAddress: {
    dataRadius: 0,
    id: 0,
    infirmAssistance: false,
    streetName: '',
    streetNumber: 0,
    streetType: '',
    suburb: '',
    postCode: 0,
  },
  allLiftsForWastestream: [],
  truckMarkers: [],
  clickedTruck: null,
  alertMarkers: [],
  activityArea: [],
  alertFilter: 'all',
  truckFilter: 'all',
  liftsFilter: 'all',
  mapLayer: null,
  sidebar: false,
  addressInfo: {
    dataRadius: null,
    workOrders: [],
    binEvents: [],
    truckLifts: [],
    outcome: { error: false },
  },
  images: [],
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case RESET_LIVE_COLLECTIONS_FILTERS:
      return {
        ...state,
        alertFilter: 'all',
        truckFilter: 'all',
        liftsFilter: 'all',
      }
    case MAP_REQUEST_ALERTS:
      return {
        ...state,
        isFetching: true,
      };
    case MAP_RECEIVE_ALERTS:
      return {
        ...state,
        isFetching: false,
        alertMarkers: action.payload,
      };
    case MAP_REQUEST_ACTIVITY_AREA:
      return {
        ...state,
        activityArea: [],
        isFetchingActivityArea: true,
        isFetching: true,
      };
    case MAP_RESET_ACTIVITY_AREA:
      return {
        ...state,
        activityArea: [],
        isFetchingActivityArea: false,
        isFetching: false,
      };
    case MAP_RECEIVE_ACTIVITY_AREA:
      return {
        ...state,
        isFetching: false,
        isFetchingActivityArea: false,
        activityArea: action.payload,
      };
    case MAP_RECEIVE_LIFTS_FOR_WASTESTREAM:
      return {
        ...state,
        isFetchingWastestream: false,
        allLiftsForWastestream: action.payload.trucks,
      };
    case MAP_REQUEST_LIFTS_FOR_WASTESTREAM:
      return {
        ...state,
        isFetchingWastestream: true,
      };
    case MAP_RESET_LIFTS_FOR_WASTESTREAM:
      return {
        ...state,
        isFetchingWastestream: false,
        allLiftsForWastestream: [],
      };
    case MAP_REQUEST_TRUCKS:
      return {
        ...state,
        isFetching: true,
        isFetchingTrucks: true,
      };
    case MAP_RECEIVE_TRUCKS:
      return {
        ...state,
        isFetching: false,
        isFetchingTrucks: false,
        providedTimeLocalCouncil: action.payload.accountTime,
        truckMarkers: action.payload.truck,
      };
    case MAP_SET_ALERT_FILTER:
      return {
        ...state,
        alertFilter: action.payload,
      };
    case MAP_SET_TRUCK_FILTER:
      return {
        ...state,
        truckFilter: action.payload,
      };
    case MAP_SET_LIFTS_FILTER:
      return {
        ...state,
        liftsFilter: action.payload,
      };
    case MAP_RECEIVE_MAP_LAYER:
      return {
        ...state,
        mapLayer: action.payload,
      };
    case MAP_SET_CENTER_ADDRESS:
      return {
        ...state,
        centerOnAddress: action.payload,
      };
    case MAP_RESET_CENTER_ADDRESS:
      return {
        ...state,
        centerOnAddress: initialState.centerOnAddress,
      };
    case MAP_REQUEST_ADDRESS:
      return {
        ...state,
        isFetching: true,
        sidebar: true,
      };
    case MAP_RECEIVE_ADDRESS:
      return {
        ...state,
        addressInfo: action.payload,
        isFetching: false,
      };
    case MAP_REQUEST_BIN_IMAGE_PREVIEW:
      return {
        ...state,
      };
    case MAP_RECEIVE_BIN_IMAGE_PREVIEW: {
      const addressInfo = state.addressInfo;
      if (action.image) {
        const match = addressInfo.binEvents.find(
          (binEvent) => binEvent.$id === action.eventId,
        );
        match.previewImage = action.image;
      }
      return {
        ...state,
        addressInfo,
      };
    }
    case MAP_REQUEST_ALL_BIN_IMAGES:
      return {
        ...state,
        fetchingImages: true,
      };
    case MAP_RECEIVE_ALL_BIN_IMAGES:
      return {
        ...state,
        fetchingImages: false,
        images: action.payload,
      };
    case RESET_SEARCH:
      return {
        ...state,
        sidebar: false,
        addressInfo: initialState.addressInfo,
      };
    case MAP_RECEIVE_CLICKED_TRUCK:
      return {
        ...state,
        clickedTruck: action.payload,
      };
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetchingTrucks: false,
        isFetching: false,
        isFetchingActivityArea: false,
        fetchingImages: false,
      }
    default:
      return state;
  }
}

export const mapEpics = {
  requestTrucksMapEpic,
  requestAlertsMapEpic,
  requestActivityAreaEpic,
  requestLiftsEpic,
  requestMapLayerEpic,
  requestAddressEpic,
  receiveAddressEpic,
  requestBinPreviewImageEpic,
  requestAllBinImagesEpic,
};
