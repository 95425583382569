// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Table, Loader, Menu, Icon } from 'semantic-ui-react';
import * as reportActionCreators from '../../redux/modules/reports';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';

class ReportPreviewContainer extends React.Component {
  componentDidMount() {
    if (this.props.reports) {
      const request = this.props.reports.preparedRequest;
      if (!!request && !!request.metrics && !!request.dataGroup) {
        this.props.requestReportPreview(request, this.props.territoryId);
      }
    }
  }

  props: {
    history: Object,
    reports: Object,
    requestReportPreview: Function,
  };

  render() {
    const reports = this.props.reports;

    return (
      <div>
        <Helmet
          title="Report Preview"
          meta={[{ name: 'Report Preview', content: 'Report Preview' }]}
        />
        <Menu secondary stackable>
          <span
            role="button"
            tabIndex={0}
            className="link item"
            onClick={this.props.history.goBack}>
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            Edit Report
          </span>
        </Menu>
        <div className="custom-reports">
          <FluidContainer fluid>
            <PageHeader title="Report Preview" />
            {reports.fetchingPreview ? (
              <Loader active size="big" inline="centered" />
            ) : (
              <Table
                basic
                padded
                celled
                unstackable
                size="large"
                className="standard report-preview">
                <Table.Header>
                  <Table.Row>
                    {reports.preview.headers.map((header) => (
                      <Table.HeaderCell>{header.alias}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {reports.preview.rows.map((row) => (
                    <Table.Row>
                      {row.map((cell) => (
                        <Table.Cell>{cell}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </FluidContainer>
        </div>
      </div>
    );
  }
}

// decorate in connect and reduxForm
export default connect(
  (state) => ({
    reports: state.reports,
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) => ({
    ...bindActionCreators(reportActionCreators, dispatch),
    dispatch,
  }),
)(ReportPreviewContainer);
