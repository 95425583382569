/* eslint-disable */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { keyframes, css } from 'styled-components';
import api from '../../common/api';
import { getEventColourFromWasteStream } from '../../common/wasteStreamColour';
import './styles/pulse.css';

const growLift = keyframes`
    from {
        width: 0;
        height: 0;
    }
    to {
        width: 24px;
        height: 24px;
        top: -6px;
        left: -6px;
    }
`

const LiftIcon = styled.div`
  width: ${(props) => (props.markerIsActive ? '24px' : '12px')};
  height: ${(props) => (props.markerIsActive ? '24px' : '12px')};
  box-shadow: 0 ${(props) => (props.markerIsActive ? '4px' : '2px')}
    ${(props) => (props.markerIsActive ? '8px' : '4px')} 0
    rgba(0, 0, 0, 0.5);
  border: solid ${(props) => (props.markerIsActive ? '6px' : '3px')} #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${(props) =>
    getEventColourFromWasteStream(props.wasteStream)};
  opacity: ${(props) => (props.isFocused ? `1` : `.2`)};
  border-radius: 100%;
  animation: ${props => props.markerIsActive ? `${growLift} 300ms normal forwards ease-in-out`: ''};
`;

export default class HEREMarker {
  constructor(ui, props, type, handleThumb) {
    const {
      children,
      lat,
      lng,
      data,
      icon,
      zindex,
      lift,
      min,
      max,
      address,
      shouldOpenNotification,
      wasteStream,
      customEventHandler,
      active,
      isFocused,
      markerIsActive,
      iconType,
    } = props;
    // Map ui
    this.ui = ui;
    let marker: H.map.DomMarker | H.map.Marker;

    if (lift) {
      const html = ReactDOMServer.renderToStaticMarkup(
        <LiftIcon
          wasteStream={wasteStream}
          isFocused={isFocused}
          markerIsActive={markerIsActive}
        />,
      );

      const HmapIcon = new H.map.DomIcon(html);

      // then create a normal marker instance and attach it to the map
      marker = new H.map.DomMarker(
        { lat, lng },
        { icon: HmapIcon, zindex, data },
      );
    } else if (icon) {
      const HmapIcon = icon.image
        ? this.createImageIcon(icon, active)
        : this.createCircleIcon(icon, active);

      // then create a normal marker instance and attach it to the map
      marker = new H.map.DomMarker(
        { lat, lng },
        { icon: HmapIcon, zindex, data, min, max },
      );
    } else {
      // create a default marker at the provided location
      marker = new H.map.Marker({ lat, lng }, { zindex });
    }
    marker.markerType = type || 'marker';
    return marker;
  }

  createElementIcon(element) {
    return new H.map.DomIcon(element);
  }

  /**
   * Create a H.map.Icon based on public image url
   *
   * @param image
   * @param width
   * @param height
   * @param heading
   * @param zIndex
   * @param active
   */
  createImageIcon(
    { image, width, height, rotation, opacity },
    active: boolean,
  ) {
    // image is a rectangle that can be rotated, get the largest line (corner to corner), then draw a square around that
    const maxDimension = Math.ceil(Math.hypot(width, height));
    const div = document.createElement('DIV');
    const img = document.createElement('IMG');

    img.src = image;
    img.width = width;
    img.height = height;

    const lagX = (maxDimension - width) / 2;
    const lagY = (maxDimension - height) / 2;
    img.setAttribute(
      'style',
      `transform: translate(${lagX}px, ${lagY}px) scale(0.6) rotate(${
        rotation > 360 ? 0 : rotation
      }deg);`,
    );

    div.setAttribute(
      'style',
      `width: ${maxDimension}px; 
      height: ${maxDimension}px; 
      top: -${maxDimension / 2}px; 
      left: -${maxDimension / 2}px; 
      opacity: ${opacity ? opacity : '1'};
      `,
    );

    div.className = active ? 'img-marker active-marker' : 'img-marker';

    if (active) {
      const pulse = this.createActivePulse();
      div.appendChild(pulse);
    }

    div.appendChild(img);

    return new H.map.DomIcon(div);
  }

  createActivePulse() {
    const pulseWrapper = document.createElement('DIV');

    pulseWrapper.className = 'pulse_holder';

    for (let i = 1; i < 5; i++) {
      const pulse = document.createElement('DIV');

      pulse.className = 'pulse pulse-' + i;

      pulseWrapper.appendChild(pulse);
    }

    return pulseWrapper;
  }

  /**
   *  Create a 'cluster' circle icon for when markers are too close together
   * @param count
   * @param countBreakdown
   * @returns {H.map.DomIcon}
   */
  createCircleIcon({ count, countBreakdown }, active) {
    // image is a rectangle that can be rotated,
    // get the largest line (corner to corner), then draw a square around that
    const wasteStreamColours = {
      Refuse: '#DC0028', // Red
      Greenwaste: '#42C332', // Green
      Recycle: '#E8AA0C', // Yellow
      Other: '#494949', // Black
      Hardwaste: '#FF5C00', // Orange
      Glass: '#8D43FF', // Purple
      Organics: '#42C332',
    };

    const diameter = 29;
    const radius = diameter / 2;

    const div = document.createElement('DIV');
    div.setAttribute(
      'style',
      `top:-${radius}px; left:-${radius}px; cursor: zoom-in; z-index: 99999`,
    );

    div.className = active ? 'circle-marker active-marker' : 'circle-marker';

    if (active) {
      const pulse = this.createActivePulse();
      div.appendChild(pulse);
    }

    const canvas = document.createElement('CANVAS');
    canvas.width = diameter;
    canvas.height = diameter;
    const x = canvas.width / 2;
    const y = canvas.height / 2;
    const context = canvas.getContext('2d');
    const counterClockwise = false;

    const angle = 360 / countBreakdown.length;

    for (let i = 0; i < countBreakdown.length; i++) {
      let startAngle = (angle * i * Math.PI) / 180;
      let endAngle = (angle * (i + 1) * Math.PI) / 180;
      context.beginPath();
      context.moveTo(x, y);
      context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
      context.closePath();
      context.fillStyle = countBreakdown[i]
        ? wasteStreamColours[countBreakdown[i]]
        : wasteStreamColours.Other;
      context.fill();
    }

    const divCircle = document.createElement('DIV');
    divCircle.className = 'circle-content';

    const spanCircleText = document.createElement('SPAN');
    spanCircleText.innerHTML = count;

    divCircle.appendChild(spanCircleText);
    div.appendChild(divCircle);
    div.appendChild(canvas);

    return new H.map.DomIcon(div);
  }

  /**
   * Create a H.map.Icon based on public image url
   *
   * @param image
   * @param width
   * @param height
   * @param heading
   * @param zIndex
   * @param anchor
   */
  createIconCanvas({ image, width, height, rotation, anchor }) {
    // image is a rectangle that can be rotated, get the largest line (corner to corner), then draw a square around that
    const maxDimension = Math.ceil(Math.hypot(width, height));

    if (!anchor) {
      // Anchor HEREMap default is bottom center (good for pin, not for truck)
      anchor = {
        x: maxDimension / 2,
        y: maxDimension / 2,
      };
    }

    const size = {
      w: maxDimension,
      h: maxDimension,
    };

    const canvas = document.createElement('CANVAS');
    canvas.width = maxDimension;
    canvas.height = maxDimension;
    const context = canvas.getContext('2d');

    // Fill up space
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    context.save();

    const imgElement = new Image();
    imgElement.src = image;
    // rotate from center
    context.translate(anchor.x, anchor.y);

    context.rotate((rotation * Math.PI) / 180);

    context.translate(-anchor.x, -anchor.y);

    context.drawImage(
      imgElement,
      anchor.x - width / 2,
      anchor.y - height / 2,
      width,
      height,
    );

    context.restore();

    return new H.map.Icon(canvas, {
      size,
      anchor,
    });
  }
}
