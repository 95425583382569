
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import {
  Accordion,
  Button,
  Container,
  Header,
  Icon,
  Loader,
  Table,
} from 'semantic-ui-react';
import * as invoicesActionCreators from '../../redux/modules/invoices';
import { getShortMonth } from '../../common/helpers';

class InvoicesContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleAccordionClick = this.handleAccordionClick.bind(this);
    this.isAccordionTabActive = this.isAccordionTabActive.bind(this);
  }

  state = {
    activeAccordionTab: [],
  };

  componentDidMount() {
    const { items } = this.props.invoices;
    if (!items.length) {
      this.props.requestUserInvoices();
    }
  }

  handleAccordionClick(e, titleProps) {
    const { index } = titleProps;
    const { activeAccordionTab } = this.state;
    const newArray = activeAccordionTab.slice();
    for (let i = 0; i < newArray.length; i += 1) {
      if (newArray[i] === index) {
        newArray.splice(i, 1);
        this.setState({ activeAccordionTab: newArray });
        return;
      }
    }
    newArray.push(index);
    this.setState({ activeAccordionTab: newArray });
  }

  isAccordionTabActive(id) {
    const { activeAccordionTab } = this.state;
    for (let i = 0; i < activeAccordionTab.length; i += 1) {
      if (activeAccordionTab[i] === id) {
        return true;
      }
    }
    return false;
  }

  props: {
    items: Array,
    invoices: Object,
    requestUserInvoices: Function,
    requestUserInvoiceDownload: Function,
  }

  render() {
    const { items, isFetchingUserInvoices, isDownloadingUserInvoice } = this.props.invoices;

    const tableContents = items
      // Only invoices that have files should be displayed
      .filter((invoice) => invoice.files.length > 0)
      .map((invoiceWithFiles) => {
        const included = this.state.activeAccordionTab.includes(invoiceWithFiles.id);
        return (<Accordion className="invoice-accordion">
          <Accordion.Title active={this.isAccordionTabActive(invoiceWithFiles.id)} index={invoiceWithFiles.id} onClick={this.handleAccordionClick}>
            <Table basic padded selectable unstackable celled size="large" className="standard">
              <Table.Body>
                <Table.Row key={invoiceWithFiles.id} className="user-invoices-table">
                  <Table.Cell className="time-period__column">
                    {getShortMonth(parseInt((invoiceWithFiles.month), 10))} {invoiceWithFiles.year}
                  </Table.Cell>
                  <Table.Cell className="invoice-number__column">
                    {invoiceWithFiles.files.length} <span className="mobile-only">file{invoiceWithFiles.files.length >= 2 ? 's' : ''}</span>
                  </Table.Cell>
                  <Table.Cell className="chevron__column">
                    <Icon name={'chevron down'} style={{ display: included ? 'none' : 'inline-block', color: '#0c5374', position: 'relative', top: '-3px' }} />
                    <Icon name={'chevron up'} style={{ display: !included ? 'none' : 'inline-block', color: '#0c5374', position: 'relative', top: '-3px' }} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Title>
          <Accordion.Content active={this.isAccordionTabActive(invoiceWithFiles.id)} index={invoiceWithFiles.id}>
            <Table basic padded selectable celled size="large" className="standard invoice-file-list">
              <Table.Body>
                {
                  invoiceWithFiles.files.map((file) => (
                    <Table.Row key={file.fileId} className="invoice-file">
                      <Table.Cell>{file.fileName}</Table.Cell>
                      <Table.Cell textAlign="center" className="actions-cell">
                        <Button
                          color="green"
                          onClick={() => this.props.requestUserInvoiceDownload(file.fileId, file.fileName)}
                          loading={isDownloadingUserInvoice === file.fileId}
                        >
                        Download
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>);
      });

    const table = (
      <div>
        <Table basic padded selectable celled size="large" className="standard invoice-table__outer">
          <Table.Header className="invoice-table__header">
            <Table.Row>
              <Table.Cell className="headings time-period__column">
                <div>Time Period</div>
              </Table.Cell>
              <Table.Cell className="headings invoice-number__column">
                <div>Number of Files</div>
              </Table.Cell>
            </Table.Row>
          </Table.Header>
        </Table>
        {tableContents}
      </div>
    );


    return (
      <div className="comp">
        <Container>
          <Helmet
            title="Documents"
            meta={[{ name: 'Documents', content: 'Customer Portal' }]}
          />
          <Header
            as="h1"
            style={{
              marginTop: '50px',
              marginBottom: '50px',
            }}>
            Documents
          </Header>

          {/* show table or loader */}
          {items.length && !isFetchingUserInvoices ? (
            table
          ) : (
            <Loader
              active={isFetchingUserInvoices}
              size="massive"
              inline="centered"
              style={{ marginTop: '50px' }}
            />
          )}

          {/* no results */}
          {!items.length && !isFetchingUserInvoices ? (
            <p
              style={{
                color: '#424852',
                textAlign: 'center',
                fontSize: '16px',
              }}>
              No results
            </p>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default connect(
  // () => ({
  (state) => ({
    invoices: state.invoices,
  }),
  (dispatch) => bindActionCreators(invoicesActionCreators, dispatch),
)(InvoicesContainer);
