
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import {
  Accordion,
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Modal,
  Tab,
  Table,
} from 'semantic-ui-react';
import FluidContainer from '../../components/FluidContainer';
import EditAccount from '../EditAccount';
import EditUser from '../EditUser';
import AddUser from '../AddUser';
import * as accountsActionCreators from '../../redux/modules/accounts';
import { INVOICE_START_YEAR } from '../../common/options';
import { getShortMonth } from '../../common/helpers';

class ViewAccountContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.editUser = this.editUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleAccordionClick = this.handleAccordionClick.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.deleteInvoiceOnBuffer = this.deleteInvoiceOnBuffer.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleSubmitUpload = this.handleSubmitUpload.bind(this);
  }

  state = {
    activeAccordionTab: [],
  };

  // Invoke the upload library, convert file to base64 and trigger the action creator
  onDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles && rejectedFiles.length) {
      // console.log('These files were rejected: ' + rejectedFiles);
    }
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      const o = {};
      const reader = new FileReader();
      // the base 64 conversion follows
      reader.onload = (event) => {
        o.name = acceptedFiles[i].name;
        o.base64 = event.target.result.split(',')[1];
        this.props.addBufferedFiles(o);
      };
      reader.readAsDataURL(acceptedFiles[i]);
    }
  }

  handleAccordionClick(e, titleProps) {
    const { index } = titleProps;
    const { activeAccordionTab } = this.state;
    const newArray = activeAccordionTab.slice();
    for (let i = 0; i < newArray.length; i += 1) {
      if (newArray[i] === index) {
        newArray.splice(i, 1);
        this.setState({ activeAccordionTab: newArray });
        return;
      }
    }
    newArray.push(index);
    this.setState({ activeAccordionTab: newArray });
  }

  isAccordionTabActive(id) {
    const { activeAccordionTab } = this.state;
    for (let i = 0; i < activeAccordionTab.length; i += 1) {
      if (activeAccordionTab[i] === id) {
        return true;
      }
    }
    return false;
  }

  handleSubmitUpload() {
    const { match, month, year, bufferedFiles } = this.props;
    const payload = {
      accountId: parseInt(match.params.id, 10),
      month: parseInt(month, 10),
      year: parseInt(year, 10),
      files: [],
    };
    for (let i = 0; i < bufferedFiles.length; i += 1) {
      const o = {
        fileName: bufferedFiles[i].name,
        file: bufferedFiles[i].base64,
      };
      payload.files.push(o);
    }
    this.props.uploadInvoice(payload);
  }

  deleteInvoiceOnBuffer(o) {
    if (this.props.bufferedFiles.indexOf(o) > -1) {
      this.props.deleteBufferedFiles(o);
    }
  }

  handleDropdownChange = (e, { name, value }) => {
    this.props.changeDropdownValues(name, value);
  }

  toggleModal() {
    this.props.toggleInvoiceModalOpen();
  }

  editUser(user) {
    this.props.history.push(`${this.props.match.url}/user/${user.id}/edit`, {
      user,
      items: this.props.location.state.item.users,
      item: this.props.location.state.item,
    });
  }

  handleDownloadInvoice(fileId, fileName) {
    this.props.downloadInvoice(fileId, fileName);
  }

  handleDeleteInvoice(fileId) {
    const { isDeletingInvoice, isDownloadingInvoice } = this.props;
    // User can't remove a file until previous one has finished
    if (isDeletingInvoice === null && isDownloadingInvoice === null) {
      this.props.requestDeleteInvoice(fileId);
    }
  }

  props: {
    location: Object,
    match: Object,
    history: Object,
    item: Object,
    items: Array,
    invoiceModalOpen: boolean,
    bufferedFiles: Array,
    month: number,
    year: number,
    isUploadingInvoice: boolean,
    isDownloadingInvoice: number,
    isDeletingInvoice: number,
    uploadInvoice: Function,
    downloadInvoice: Function,
    addBufferedFiles: Function,
    toggleInvoiceModalOpen: Function,
    changeDropdownValues: Function,
    deleteBufferedFiles: Function,
    requestDeleteInvoice: Function,
  };

  render() {
    const {
      match,
      invoiceModalOpen,
      bufferedFiles,
      month,
      year,
      isUploadingInvoice,
      isDownloadingInvoice,
      isDeletingInvoice,
    } = this.props;

    let item = this.props.location.state.item;
    Object.values(this.props.items).forEach((thisItem) => {
      if (thisItem.account.id === item.account.id) {
        item = thisItem;
      }
    });

    const { account, users, invoices } = item;

    const mappableUsers = users || [];

    const userItems = mappableUsers.map((user) => (
      <Table.Row key={user.id} onClick={() => this.editUser(user)}>
        <Table.Cell className="first-name">{user.firstName}</Table.Cell>
        <Table.Cell className="last-name">{user.lastName}</Table.Cell>
        <Table.Cell className="email">{user.email}</Table.Cell>
        <Table.Cell className="type">{user.role}</Table.Cell>
        <Table.Cell className="status">
          {user.suspended ? 'Suspended' : 'Active'}
          <Icon className="icon-right" style={{ float: 'right' }} />
        </Table.Cell>
      </Table.Row>
    ));

    const table = invoices
      // Only invoices that have files should be displayed
      .filter((invoice) => invoice.files.length > 0)
      .map((invoiceWithFiles) => {
        const included = this.state.activeAccordionTab.includes(invoiceWithFiles.id);

        return (<div key={invoiceWithFiles.id} className="invoices-table">
          <Accordion className="invoice-accordion">
            <Accordion.Title active={this.isAccordionTabActive(invoiceWithFiles.id)} index={invoiceWithFiles.id} onClick={this.handleAccordionClick}>
              <Table basic padded selectable unstackable celled size="large" className="standard">
                <Table.Body>
                  <Table.Row key={invoiceWithFiles.id} className="user-invoices-table">
                    <Table.Cell className="time-period__column">
                      {getShortMonth(parseInt((invoiceWithFiles.month), 10))} {invoiceWithFiles.year}
                    </Table.Cell>
                    <Table.Cell className="invoice-number__column">
                      {invoiceWithFiles.files.length} <span className="mobile-only">file{invoiceWithFiles.files.length >= 2 ? 's' : ''}</span>
                    </Table.Cell>
                    <Table.Cell className="chevron__column">
                      <Icon name={'chevron down'} style={{ display: included ? 'none' : 'inline-block', color: '#0c5374', position: 'relative', top: '-3px' }} />
                      <Icon name={'chevron up'} style={{ display: !included ? 'none' : 'inline-block', color: '#0c5374', position: 'relative', top: '-3px' }} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Accordion.Title>
            <Accordion.Content active={this.isAccordionTabActive(invoiceWithFiles.id)} index={invoiceWithFiles.id}>
              <Table basic padded selectable celled size="large" className="standard invoice-file-list">
                <Table.Body>
                  {
                    invoiceWithFiles.files.map((file) => (
                      <Table.Row key={file.fileId} className="invoice-file">
                        <Table.Cell>{file.fileName}</Table.Cell>
                        <Table.Cell textAlign="center" className="actions-cell">
                          <Button
                            color="green"
                            onClick={() => this.handleDownloadInvoice(file.fileId, file.fileName)}
                            loading={isDownloadingInvoice === file.fileId}
                          >
                            Download
                          </Button>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <div className="delete-invoice-wrapper">
                            {
                              isDeletingInvoice === file.fileId
                                ? <Loader active size="tiny" inline="centered" />
                                : (<a
                                    role="button"
                                    tabIndex={0}
                                    className="remove"
                                    style={{ borderLeft: 'none', paddingLeft: '0' }}
                                    onClick={() => this.handleDeleteInvoice(file.fileId)}
                                >
                                  Remove
                                </a>)
                            }
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        </div>);
      });

    const panes = [
      { menuItem: 'Users',
        render: () => (
          <Tab.Pane className="plain-tab">
            <Grid className="tab-subheader computer-only">
              <Grid.Row>
                <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
                  <Header as="h3" floated="left">Users</Header>
                </Grid.Column>
                <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
                  <Link
                    to={{
                      pathname: `${match.url}/add-user/`,
                      state: { item: this.props.location.state.item },
                    }}
                    className="ui green massive button right floated"
                  >
                    <Icon name="plus" />Add User
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Table basic padded celled size="large" selectable className="user-management standard">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{userItems}</Table.Body>
            </Table>
            <div className="mobile-only" style={{ textAlign: 'center' }}>
              <Divider />
              <Link
                to={{
                  pathname: `${match.url}/add-user/`,
                  state: { item: this.props.location.state.item },
                }}
                className="ui green massive button"
              >
                <Icon name="plus" />Add User
              </Link>
            </div>
          </Tab.Pane>),
      },
      { menuItem: 'Invoices',
        render: () => (
          <Tab.Pane className="plain-tab">
            <Grid className="tab-subheader computer-only">
              <Grid.Row>
                <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
                  <Header as="h3" floated="left">Invoices</Header>
                </Grid.Column>
                <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
                  <Button
                    primary
                    basic
                    size="massive"
                    floated="right"
                    onClick={this.toggleModal}
                  ><Icon name="cloud upload" />Upload Invoice</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              invoices.length
                ? table
                : <p style={{ color: '#424852', textAlign: 'center', fontSize: '16px' }}>No results</p>
            }
            <div className="mobile-only" style={{ textAlign: 'center' }}>
              <Divider />
              <Button
                primary
                basic
                size="massive"
                onClick={this.toggleModal}
              ><Icon name="cloud upload" />Upload Invoice</Button>
            </div>
          </Tab.Pane>),
      },
    ];

    const months = [
      { value: '1', text: 'January' },
      { value: '2', text: 'February' },
      { value: '3', text: 'March' },
      { value: '4', text: 'April' },
      { value: '5', text: 'May' },
      { value: '6', text: 'June' },
      { value: '7', text: 'July' },
      { value: '8', text: 'August' },
      { value: '9', text: 'September' },
      { value: '10', text: 'October' },
      { value: '11', text: 'November' },
      { value: '12', text: 'December' },
    ];

    const years = [];
    const now = new Date();
    for (let y = now.getFullYear(); y >= INVOICE_START_YEAR; y -= 1) {
      years.push({ value: y, text: y });
    }

    const bufferedFilesComponent = bufferedFiles.map((o) => (
      <div key={o.id} className={`invoice-buffer ${o.id === 1 ? 'first-element' : ''} ${o.id === bufferedFiles.length ? 'last-element' : ''} `}>
        <span className="name-text">{o.name}</span>
        <span className="remove-link" role="link" tabIndex="-1" onClick={() => this.deleteInvoiceOnBuffer(o)}>Remove</span>
      </div>
    ));

    return (
      <div>
        <Route path={`${match.url}/edit`} component={EditAccount} />
        <Route path={`${match.url}/user/:id/edit`} component={EditUser} />
        <Route path={`${match.url}/add-user`} component={AddUser} />
        <Route
          exact
          path={match.url}
          render={() => (
            <div className="comp secondary-comp">
              <Helmet
                title="View Account"
                meta={[
                  { name: 'View Account', content: 'Customer Portal' },
                ]}
              />
              <Menu secondary stackable>
                <Link to="/account-management" className="link item">
                  <Icon className="icon-left" style={{ marginRight: '15px' }} />
                  All Accounts
                </Link>
              </Menu>
              <FluidContainer fluid>
                <Grid className="multi-header account-management">
                  <Grid.Row>
                    <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
                      <Header as="h1">{account.name}</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
                      <Link to={{ pathname: `${match.url}/edit`, state: { item: this.props.location.state.item } }} className="remove">Edit Account</Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Tab menu={{ secondary: true, pointing: true, className: 'tab-menu' }} panes={panes} />
              </FluidContainer>
              <Modal className="invoice-upload-modal" size="tiny" open={invoiceModalOpen} closeIcon={<Icon className="icon-cancel" onClick={this.toggleModal} />}>
                <Modal.Header>Upload Invoice</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Form noValidate autoComplete="off" size="mini">
                      <Form.Field required>
                        <label htmlFor="invoice-period">Invoice Period</label>
                        <Form.Group widths="equal" id="invoice-period">
                          <Form.Select onChange={this.handleDropdownChange} name="month" value={month} options={months} placeholder="Month" icon="angle down" />
                          <Form.Select onChange={this.handleDropdownChange} name="year" value={year} options={years} placeholder="Year" icon="angle down" />
                        </Form.Group>
                      </Form.Field>
                      <Form.Field required>
                        <label htmlFor="select-file">Select File</label>
                        <Form.Group widths="equal" id="select-file">
                          <Dropzone className="dropzone" onDrop={this.onDrop} activeStyle={{ background: '#fff' }}>
                            <p className="first-p">Drop files here to upload</p>
                            <p className="second-p">or</p>
                            <p className="third-p">Browse Files</p>
                          </Dropzone>
                        </Form.Group>
                      </Form.Field>
                      <Form.Field>
                        {bufferedFilesComponent}
                      </Form.Field>
                    </Form>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <button className="cancel" onClick={this.toggleModal}>Cancel</button>
                  <Button
                    color="green"
                    type="submit"
                    size="massive"
                    loading={isUploadingInvoice}
                    disabled={!month || !year || !bufferedFiles.length}
                    onClick={this.handleSubmitUpload}
                  >
                    Upload
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    items: state.accounts.items,
    isUploadingInvoice: state.accounts.isUploadingInvoice,
    invoiceModalOpen: state.accounts.invoiceModalOpen,
    bufferedFiles: state.accounts.bufferedFiles,
    month: state.accounts.month,
    year: state.accounts.year,
    isDeletingInvoice: state.accounts.isDeletingInvoice,
    isDownloadingInvoice: state.accounts.isDownloadingInvoice,
  }),
  (dispatch) => bindActionCreators(accountsActionCreators, dispatch),
)(ViewAccountContainer);
