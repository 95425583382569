import styled, { css } from 'styled-components';

export const STruckLiftEvent = styled.div`
  border-bottom: 1px solid rgba(216, 216, 216, 0.3);
  float: left;
  width: 100%;
  height: 60px;
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  background-color: ${(props) => (props.isFocused ? 'transparent' : '#f2f2f2')};
  opacity: ${(props) => (props.isFocused ? '1' : '.2')};

  ${(props) =>
    props.centerContent &&
    css`
      display: flex;
      align-items: center;
    `}

  :first-of-type {
    .icon-wrapper {
      .lift-event-connector {
        height: 34px;
        top: 23px;
      }
    }
  }

  :last-of-type {
    .icon-wrapper {
      .lift-event-connector {
        height: 55px;
      }
    }
  }
`;

export const SLiftEventAlertIcon = styled.img`
  position: absolute;
  width: 16px;
  z-index: 2;
  top: 20px;
`;

export const SLiftIcon = styled.div`
  width: 16px;
  height: 16px;
  top: 20px;
  position: ${(props) => (props.unsetPosition ? '' : 'absolute')};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  border: solid 3px #ffffff;
  background-color: ${(props) => props.themeColor};
  border-radius: 16px;
  z-index: 2;
`;

export const SIconWrapper = styled.div`
  float: left;
  position: relative;
  width: 40px;
  height: 60px;
  padding: 0 13.5px;
`;

export const SLiftEventInfoWrapper = styled.div`
  float: left;
  padding: 10px 0 0 20px;
  ${(props) =>
    props.noPaddingTop &&
    css`
      padding-top: 0;
    `}
`;

export const SLiftEventConnector = styled.span`
  position: absolute;
  border-left: 3px dotted ${(props) => props.themeColor};
  height: 57px;
  left: 50%;
  width: 3px;
`;

export const SLiftCreatedHeader = styled.p`
  font-size: 10px;
  color: #082441;
  margin: 0;
`;

export const SLiftCreated = styled.p`
  font-size: 14px;
  color: #004b6d;
`;
