// @Flow

import React from 'react';
import styled from 'styled-components';
import { getLiftIconAsSvgElement } from '../../../HereMap/helpers/HMapWastestreamCluster/icons';

export const SLiftIcon = styled.div`
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  margin-top: -2px;
`;

type Props = {
  isActive: string,
  colour: string,
};

const LiftIcon = ({ isActive, colour }: Props) => (
  <SLiftIcon isActive={isActive}>
    {getLiftIconAsSvgElement(28, colour)}
  </SLiftIcon>
);

export default LiftIcon;
