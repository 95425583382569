
// @flow

import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

export default styled(Label)`
  width: 2em;
  height: 2em;
`;
