/* eslint-disable */
import React from 'react';
import HEREMarker from '../HEREMarker';

const makerShouldBeFocused = (activeEvent, uuid): boolean => {
  if (activeEvent.type === null) return true;
  return activeEvent.type === 'binLift' && uuid === activeEvent.uuid;
};
const makerIsActive = (activeEvent, uuid): boolean =>
  activeEvent.type === 'binLift' && uuid === activeEvent.uuid;

class LiftMarker extends HEREMarker {
  /**
   * Get minimum vars for a marker and add lift specific attributes
   *
   * @param liftProps
   */
  constructor(ui, liftProps, activeEvent, setActiveEvent) {
    const { position, uuid, wasteStream } = liftProps;

    const markerOptions = {
      wasteStream,
      lat: position.lat || position[0],
      lng: position.lng || position[1],
      isFocused: makerShouldBeFocused(activeEvent, uuid),
      markerIsActive: makerIsActive(activeEvent, uuid),
      lift: true,
      shouldOpenNotification: false,
      customEventHandler: () => {
        setActiveEvent(uuid, 'binLift');
        return;
      },
      data: liftProps || {},
    };
    return super(ui, markerOptions);
  }
}

export default LiftMarker;
