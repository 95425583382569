
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import { Button, Table, Icon, Divider, Loader } from 'semantic-ui-react';
import AddAccount from '../../containers/AddAccount';
import ViewAccount from '../../containers/ViewAccount';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';
import * as accountsActionCreators from '../../redux/modules/accounts';

class AccountManagementContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.view = this.view.bind(this);
    this.exportLogs = this.exportLogs.bind(this);
  }

  componentDidMount() {
    if (!this.props.items.length) {
      this.props.requestAccounts();
    }
  }

  view(item) {
    this.props.history.push(`/account-management/${item.account.id}/view`, { item });
  }

  exportLogs() {
    this.props.requestExportUserLogs();
  }

  render() {
    const { match, items } = this.props;
    const accountItems = items.map((item) =>
      (<Table.Row key={item.account.id} onClick={() => this.view(item)}>
        <Table.Cell>{item.account.name}</Table.Cell>
        <Table.Cell>
          {item.users ? item.users.length : 0}
          <Icon className="icon-right" style={{ float: 'right' }} />
        </Table.Cell>
      </Table.Row>)
    );

    const table = (
      <div>
        <Table basic padded celled unstackable size="large" selectable className="account-management standard">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Account Name</Table.HeaderCell>
              <Table.HeaderCell>Users</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{accountItems}</Table.Body>
        </Table>
      </div>
    );

    return (
      <div>
        <Route path={`${match.url}/add-account`} component={AddAccount} />
        <Route path={`${match.url}/:id/view`} component={ViewAccount} />
        <Route
          exact
          path={match.url}
          render={() => (
            <div className="comp">
              <Helmet
                title="Account Management"
                meta={[
                  { name: 'Account Management', content: 'Customer Portal' },
                ]}
              />
              <FluidContainer fluid>
                <PageHeader
                  title="Account Management"
                  action={
                    <div >
                      <Button basic size="massive" primary type="submit" loading={this.props.isExportingUserLogs} onClick={this.exportLogs}><Icon name="cloud upload" /> User Logs</Button>
                      <Link
                        to={`${match.url}/add-account`}
                        className="ui green massive button right floated"
                      >
                        <Icon name="plus" />Add Account
                      </Link>
                    </div>
                  }
                />
                {this.props.items.length
                  ? table
                  : <Loader active={this.props.isFetching} size="massive" inline="centered" />
                }

                <div className="mobile-only" style={{ textAlign: 'center' }}>
                  <Divider />
                  <Link
                    to={`${match.url}/add-account`}
                    className="ui green massive button"
                    style={{ margin: '15px 0 0 0' }}
                  >
                    <Icon name="plus" />Add Account
                  </Link>
                </div>
              </FluidContainer>
            </div>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    items: state.accounts.items,
    isFetching: state.accounts.isFetching,
  }),
  (dispatch) => bindActionCreators(accountsActionCreators, dispatch)
)(AccountManagementContainer);
