// @flow

import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Form, Button, Loader, Grid, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import FluidContainer from '../../components/FluidContainer';
import Check from '../../components/Check';
import Drop from '../../components/DropDown';
import Input from '../../components/Input';
import SideBarMenu from '../../components/SidebarMenu';
import { MODAL_DOWNLOAD, MODAL_DETAILS } from '../CustomReport';
import {
  unSnake,
  toSnake,
  isAggreg,
  isDateField,
  isAddressField,
} from '../../common/helpers';

type Props = {
  handleChange: Function,
  toggleModal: Function,
  resetForm: Function,
  handleGroupDataChange: Function,
  reports: Object,
  formValues: Object,
  menuItems: Array,
  mode: String,
  save: Function,
  remove: Function,
  id: Number,
  preview: Function,
};

function configReportForm({
  toggleModal,
  handleChange,
  reports,
  formValues,
  handleGroupDataChange,
  menuItems,
  resetForm,
  remove,
  mode,
  save,
  id,
  preview,
}: Props) {
  /**
   * Define action on saving (open modal for report name on creation, directly save on edit)
   * @returns {Boolean}
   */
  const onSaveClick = () => {
    if (mode === 'add') {
      toggleModal(MODAL_DETAILS);
    } else {
      save();
    }
  };

  /**
   * Display a field
   * @param   {Object} module
   * @param   {Object} field
   * @param   {Number} i
   *
   * @returns {Object}
   */
  const processField = (module, field, i) => {
    const group = module.dataGroup;
    const name = field.name;

    let index = group;
    if (isDateField(field.name)) {
      index = 'dates';
    }
    if (isAddressField(field.name)) {
      index = 'address';
    }

    let tmpFieldLabel = unSnake(name);
    tmpFieldLabel = tmpFieldLabel.replace(/([a-z])([A-Z])/g, '$1 $2');

    if (field.filter) {
      let filter = null;

      if (!field.filterEnum) {
        filter = (
          <Field
            key={i}
            component={Input}
            name="filters"
            icon="tag"
            type="text"
            className="filter filter__input"
            size={50}
          />
        );
      } else {
        const options = field.filterEnum.map((e) => ({
          text: e,
          value: field.name === 'Service' ? e : toSnake(e),
        }));
        filter = (
          <Field
            key={i}
            multiple
            component={Drop}
            name="filters"
            placeholder={'Choose'}
            search
            selection
            options={options}
            icon="angle down"
            className="filter"
          />
        );
      }
      const display =
        !!formValues.metrics &&
        formValues.metrics[index] &&
        formValues.metrics[index][name] &&
        formValues.metrics[index][name].open
          ? 'block'
          : 'none';

      return (
        <FormSection className="input-wrap" name={name}>
          <span>
            <Field
              key={i}
              component={Check}
              type="checkbox"
              label={tmpFieldLabel}
              name="open"
            />
          </span>
          <div style={{ display }} className="filter-super-wrap">
            <div className="filter-wrap">
              Filter by {tmpFieldLabel} (Optional)
              {filter}
            </div>
            <hr
              style={{
                background: '#efefef',
                height: '1px',
                border: 'none',
                margin: '1em 0em 2em 0.8em',
              }}
            />
          </div>
        </FormSection>
      );
    }
    return (
      <div className="input-wrap">
        <Field
          key={i}
          component={Check}
          type="checkbox"
          label={tmpFieldLabel}
          name={name}
        />
      </div>
    );
  };

  // break up form by dynamic module/type
  let moduleSpecificTimePeriodOptions = [];
  let moduleSpecificAddressOptions = [];

  let selModule = null;

  const reportModules = reports.reportTypes
    .filter((module) => {
      if (!reports.dataGroup) {
        return false;
      }
      return reports.dataGroup === module.dataGroup;
    })
    .map((module) => {
      selModule = module;
      moduleSpecificTimePeriodOptions = [];
      moduleSpecificAddressOptions = [];
      const mainFields = module.fields
        // Filter out Time Periods
        .filter((field) => {
          if (isDateField(field.name)) {
            moduleSpecificTimePeriodOptions.push(field);
            return false;
          }
          if (isAddressField(field.name)) {
            moduleSpecificAddressOptions.push(field);
            return false;
          }
          return true;
        });

      return (
        <FormSection name={module.dataGroup}>
          {
            /* Non-aggregator fields */
            mainFields
              .filter((field) => !isAggreg(field.name))
              .map((field, index) => processField(selModule, field, index))
          }

          <hr
            style={{ background: '#e3e3e3', height: '1px', border: 'none' }}
          />

          {
            /* Aggregator fields */
            mainFields
              .filter((field) => isAggreg(field.name))
              .map((field, index) => processField(selModule, field, index))
          }
        </FormSection>
      );
    });

  const datesCol = (
    <FormSection name="dates">
      {moduleSpecificTimePeriodOptions.map((field, index) =>
        processField(selModule, field, index),
      )}
    </FormSection>
  );

  const addressCol = (
    <FormSection name="address">
      {moduleSpecificAddressOptions.map((field, index) =>
        processField(selModule, field, index),
      )}
    </FormSection>
  );

  let linkClassName = 'link item view-preview ui massive fluid primary button';
  if (!reports.canSubmit) {
    linkClassName += ' disabled';
  }

  return (
    <Form onChange={handleChange}>
      <FluidContainer className="header-container" fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              floated="left"
              mobile={16}
              tablet={16}
              computer={8}
              largeScreen={8}>
              <Header as="h1" floated="left">
                Custom Reports
              </Header>
            </Grid.Column>
            <Grid.Column
              floated="right"
              mobile={16}
              tablet={16}
              computer={8}
              largeScreen={8}
              className="action-bar">
              {reports.fetchingTypes ? null : (
                <div>
                  <div className="inner-actions">
                    {mode !== 'edit' ? (
                      <span className="reset-fields">
                        <Icon name="repeat" flipped="horizontally" />
                        <span onClick={resetForm} role="presentation">
                          Reset
                        </span>
                      </span>
                    ) : (
                      <button
                        className="remove"
                        onClick={() => {
                          remove(id);
                        }}>
                        <Icon name="remove" flipped="horizontally" />
                        Remove Report
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FluidContainer>
      {reports.fetchingTypes ? (
        <Loader
          active={reports.fetchingTypes}
          size="massive"
          inline="centered"
        />
      ) : (
        <div>
          <FluidContainer fluid>
            {mode === 'edit' ? (
              <div>
                <div>
                  <Field
                    name="reportName"
                    component={Input}
                    type="text"
                    label="Report Name"
                  />
                </div>
                <div className="reset-inter">
                  <span className="reset-fields">
                    <Icon name="repeat" flipped="horizontally" />
                    <span onClick={resetForm} role="presentation">
                      Reset
                    </span>
                  </span>
                </div>
              </div>
            ) : null}
            <FormSection name="metrics">
              <Grid
                celled
                columns={2}
                className="custom-reports-container custom-reports-container__desktop"
                padded={false}>
                <Grid.Row only="computer">
                  <Grid.Column computer={4}>
                    <h4>Reporting Datasets</h4>
                  </Grid.Column>
                  <Grid.Column computer={12}>
                    <Grid>
                      <Grid.Column computer={7}>
                        <h5>Fields</h5>
                      </Grid.Column>
                      <Grid.Column computer={9}>
                        <h5>Data Segmentation</h5>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row only="computer">
                  <Grid.Column computer={4} className="column-menu-container">
                    <SideBarMenu
                      handleClick={handleGroupDataChange}
                      menuItems={menuItems}
                      activeElement={reports.dataGroup}
                      elementsSelected={reports.elementsSelected}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={12}
                    className="custom-reports-form-container custom-reports-form-container__desktop">
                    <Grid>
                      <Grid.Column
                        computer={7}
                        className="module"
                        key={module.dataGroup}>
                        {reportModules}
                      </Grid.Column>
                      <Grid.Column
                        className="module"
                        computer={3}
                        key={'dates'}>
                        {datesCol}
                      </Grid.Column>
                      <Grid.Column
                        className="module"
                        computer={6}
                        key={'address'}>
                        {addressCol}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid className="actions">
                <Grid.Row only="computer">
                  <Grid.Column
                    className="actions-button-wrapper"
                    floated="right"
                    width={14}>
                    <Link to="/reports" className="link item cancel">
                      Cancel
                    </Link>

                    <Button onClick={preview} className={linkClassName}>
                      <Icon name="eye" style={{ marginRight: '15px' }} />
                      Preview
                    </Button>
                    <Button
                      fluid
                      size="massive"
                      disabled={!reports.canSubmit}
                      primary
                      type="button"
                      onClick={() => toggleModal(MODAL_DOWNLOAD)}>
                      <Icon name="download" /> Download
                    </Button>
                    <Button
                      color="green"
                      loading={reports.postingReport}
                      disabled={!reports.canSubmit}
                      type="button"
                      fluid
                      size="massive"
                      onClick={onSaveClick}>
                      <Icon name="save" /> Save
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {/* Mobile Grid */}
              <Grid columns={1} className="custom-reports-container">
                <Grid.Row only="tablet mobile">
                  <Grid.Column mobile={16} tablet={16}>
                    <SideBarMenu
                      handleClick={handleGroupDataChange}
                      menuItems={menuItems}
                      activeElement={reports.dataGroup}
                      elementsSelected={reports.elementsSelected}
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    className="custom-reports-form-container">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          className="module"
                          mobile={16}
                          tablet={16}
                          key={module.dataGroup}>
                          <h5>Fields</h5>
                          {reportModules}
                        </Grid.Column>
                        <Grid.Column
                          className="module"
                          mobile={16}
                          tablet={16}
                          key={'dates'}>
                          <h5>Data Segmentation</h5>
                          {datesCol}
                        </Grid.Column>
                        <Grid.Column
                          className="module"
                          mobile={16}
                          tablet={16}
                          key={'address'}>
                          {addressCol}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row only="tablet mobile">
                  <Grid.Column>
                    <Grid columns={4} className="actions" padded={false}>
                      <Grid.Row
                        only="tablet mobile"
                        className="actions-button-wrapper">
                        <Button onClick={preview} className={linkClassName}>
                          <Icon name="eye" style={{ marginRight: '15px' }} />
                          Preview
                        </Button>
                      </Grid.Row>
                      <Grid.Row
                        only="tablet mobile"
                        className="actions-button-wrapper">
                        <Button
                          fluid
                          size="massive"
                          disabled={!reports.canSubmit}
                          primary
                          type="button"
                          onClick={() => toggleModal(MODAL_DOWNLOAD)}>
                          <Icon name="download" /> Download
                        </Button>
                      </Grid.Row>
                      <Grid.Row
                        only="tablet mobile"
                        className="actions-button-wrapper">
                        <Button
                          color="green"
                          loading={reports.postingReport}
                          disabled={!reports.canSubmit}
                          type="button"
                          fluid
                          size="massive"
                          onClick={onSaveClick}>
                          <Icon name="save" /> Save
                        </Button>
                      </Grid.Row>
                      <Grid.Row
                        only="tablet mobile"
                        className="actions-button-wrapper">
                        <Link to="/reports" className="link item cancel">
                          Cancel
                        </Link>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </FormSection>
          </FluidContainer>
        </div>
      )}
    </Form>
  );
}

// decorate
configReportForm = reduxForm({
  // eslint-disable-line no-func-assign
  form: 'configReport',
  enableReinitialize: true,
})(configReportForm);

export default configReportForm;
