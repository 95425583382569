// @flow

import React from 'react';
import Moment from 'react-moment';
import { WorkOrders } from '../../index.d';
import { unSnake } from '../../../../common/helpers';

type Props = {
  workOrders: Array<WorkOrders>,
};

const TabContentListTickets = ({ workOrders }: Props): React.Element => (
  <div className={'active-tickets'}>
    {workOrders.map((order) => (
      <div key={order.id} className="ticket">
        <span className="number">#{order.id}</span>
        <span className="title">{unSnake(order.workOrderType)}</span>
        <span className="pick-up">
          Scheduled{' '}
          <Moment format="MMM Do, h:mm a">
            {order.scheduledStartDate
              ? order.scheduledStartDate.replace(/\+0$/, '+00:00')
              : null}
          </Moment>
        </span>
        <span className="pick-up">Status {order.status}</span>
        <div className="pulse" />
      </div>
    ))}
  </div>
);
export default TabContentListTickets;
