import { getClusterIcon } from '../icons';

export const getClusteringTheme = (colour,liftHIcon) => {
  const CUSTOM_THEME = {
    getClusterPresentation: (cluster) => {
      const icon = getClusterIcon()
        .replace('{text}', cluster.getWeight())
        .replace('{colour}', colour);

      const truckClusterMarker = new H.map.Marker(cluster.getPosition(), {
        icon: new H.map.Icon(icon, {
          size: { w: 25, h: 25 },
          anchor: { x: 50, y: 50 },
        }),
        min: cluster.getMinZoom(),
        // Ensure it doesn't cluser beyond 18, max must not exceed 19.
        max: cluster.getMaxZoom() > 18 ? 18 : cluster.getMaxZoom(),
      });

      truckClusterMarker.setData(cluster);

      return truckClusterMarker;
    },
    getNoisePresentation: (noisePoint) => {
      const data = noisePoint.getData();
      

      const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
        // Ensure it doesn't cluser beyond 18, min must not exceed 19.
        min: noisePoint.getMinZoom() < 19 ? noisePoint.getMinZoom() : 19,
        icon: liftHIcon,
      });

      noiseMarker.setData(data);

      return noiseMarker;
    },
  };
  return CUSTOM_THEME;
};
