
// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Form, Image } from 'semantic-ui-react';
import BoxForm from '../BoxForm';
import Branding from '../Branding';
import Input from '../Input';
import Check from '../../common/images/general/check_green.svg';
import validate from './validate';

type Props = {
  handleSubmit: Function,
  isSettingPassword: boolean,
  passwordSetSuccess: boolean,
  pristine: boolean,
};

function SetPasswordForm({ handleSubmit, isSettingPassword, passwordSetSuccess, pristine }: Props) {
  const success = (
    <div>
      <Image src={Check} centered alt="check" />
      <p style={{ textAlign: 'center', fontSize: '20px', margin: '40px 0' }}>Your password has been successfully set</p>
      <Link to="/login" className="ui green massive fluid button">
        Login
      </Link>
    </div>
  );

  const reset = (
    <div>
      <Branding />
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <p style={{ fontSize: '20px', marginBottom: '40px', textAlign: 'center', padding: '0 25px' }}>Set password</p>
        <Field name="newPassword" placeholder="New Password" component={Input} type="password" />
        <Field name="confirmPassword" placeholder="Confirm New Password" component={Input} type="password" />
        <Button
          color="green"
          fluid
          size="massive"
          type="submit"
          loading={isSettingPassword}
          disabled={pristine || isSettingPassword}
        >
          Done
        </Button>
      </Form>
      <Link to="/login" style={{ textAlign: 'center', marginTop: '40px', display: 'block' }}>Login</Link>
    </div>
  );

  return (
    <BoxForm>
      {passwordSetSuccess ? success : reset}
    </BoxForm>
  );
}

// decorate
SetPasswordForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'setPassword',
  validate,
})(SetPasswordForm);

export default SetPasswordForm;
