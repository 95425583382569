// @flow

import React from 'react';
import { Image } from 'semantic-ui-react';
import success from '../../common/images/messages/success.svg';
import error from '../../common/images/messages/error.svg';
import info from '../../common/images/messages/info.svg';

type Props = {
  type: string,
  title?: string,
  content: string,
};

Message.defaultProps = {
  title: null,
};

export default function Message({ type, title, content }: Props) {
  let icon = success;
  if (type === 'error') {
    icon = error;
  } else if (type === 'info') {
    icon = info;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <Image
        src={icon}
        className="msg-icon"
        style={{ marginRight: 10 }}
        alt="message"
      />
      <span className="inner-content">
        <span>{title}</span>
        <span>{content}</span>
      </span>
    </div>
  );
}
