import styled from 'styled-components';

export const SLiveHistoricIndicator = styled.div`
  background: #000000;
  border-radius: 12.5px;
  color: #fff;
  display: inline-block;
  float: right;
  font-size: 11px;
  padding: 3px 12px;
  margin: 10px 0;
`;

export const SLiveHistoricIndicatorDot = styled.span`
  width: 11px;
  height: 11px;
  border-radius: 12px;
  background:  ${(props) => (props.isLive ? '#ff6363' : '#177cb0')};
  margin: 4px 5px 0 0;
  float: left;
`;
