export default (values) => {
  const errors = {};

  // account name
  if (!values.name) {
    errors.name = 'Required.';
  }

  // account id
  if (!values.id) {
    errors.id = 'Required.';
  } else if (isNaN(Number(values.id))) {
    errors.id = 'Must be a number.';
  }

  // latitude
  if (!values.lat) {
    errors.lat = 'Required.';
  } else if (isNaN(Number(values.lat))) {
    errors.lat = 'Must be a number.';
  }

  // longitude
  if (!values.lng) {
    errors.lng = 'Required.';
  } else if (isNaN(Number(values.lng))) {
    errors.lng = 'Must be a number.';
  }

  // territory id - may contain chars
  if (!values.territoryID) {
    errors.territoryID = 'Required.';
  }

  // Needs timezone
  if (!values.timezone) {
    errors.timezone = 'Required.';
  }

  return errors;
};
