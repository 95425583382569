
// @flow

import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import mediaQueryBreakPoints from '../../common/mediaQueryBreakPoints';

export default styled(Container)`
  @media (min-width: ${mediaQueryBreakPoints.tabletBreakpoint}) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
