// @flow

import React from 'react';
import { DateRangePicker } from 'react-dates';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Icon, Modal } from 'semantic-ui-react';
import Drop from '../../components/DropDown';
import { reportDownloadOptions } from '../../common/options';

type Props = {
  handleSubmit: Function,
  onSubmit: Function,
  modalOpen: boolean,
  toggleModal: Function,
  fetchingExport: boolean,
  focusedInput: Array | Object,
  initStartDate: Object,
  initEndDate: Object,
  dateRangeChoice: String,
  handleDateChange: Function,
  handleFocusInput: Function,
  handleDateRangeChange: Function,
};

function configReportDownloadForm({
  handleSubmit,
  modalOpen,
  toggleModal,
  onSubmit,
  fetchingExport,
  initStartDate,
  initEndDate,
  dateRangeChoice,
  focusedInput,
  handleDateChange,
  handleFocusInput,
  handleDateRangeChange,
}: Props) {
  const reportDropdownDateRangeSelection = (
    <Field
      component={Drop}
      name="dateRange"
      placeholder={'Choose a date range'}
      selection
      options={reportDownloadOptions}
      icon="angle down"
      onChange={(e, value) => handleDateRangeChange(value)}
      value={dateRangeChoice}
      label="Date Range*"
    />
  );

  const custDateRangePicker = (
    <div className="field datepicker">
      <label htmlFor="dateRange">Select Dates*</label>
      <div>
        <DateRangePicker
          startDate={initStartDate} // momentPropTypes.momentObj or null,
          endDate={initEndDate} // momentPropTypes.momentObj or null,
          onDatesChange={({ startDate, endDate }) =>
            handleDateChange({ startDate, endDate })
          } // PropTypes.func.isRequired,
          onFocusChange={(newFocusedInput) => handleFocusInput(newFocusedInput)} // PropTypes.func.isRequired,
          startDateId={'startDate'}
          endDateId={'endDate'}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={() => {}} /* as strange as it looks, this allows past dates */
        />
      </div>
    </div>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Modal
        size="tiny"
        open={modalOpen}
        closeIcon={
          <Icon className="icon-cancel" onClick={() => toggleModal('')} />
        }>
        <Modal.Header>Download Report</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="custom-report-download">
              {reportDropdownDateRangeSelection}
              {dateRangeChoice === 'CUSTOM' ? custDateRangePicker : null}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <button className="cancel" onClick={() => toggleModal('')}>
            Cancel
          </button>
          <Button
            color="green"
            onClick={() => onSubmit()}
            loading={fetchingExport}>
            <Icon name="save" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    </Form>
  );
}

// decorate
configReportDownloadForm = reduxForm({
  // eslint-disable-line no-func-assign
  form: 'configReportDownload',
})(configReportDownloadForm);

export default configReportDownloadForm;
