// @flow

import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';

class NotFound extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  props: { isAuthed: boolean };

  render() {
    return (
      <div className="comp">
        <Grid centered verticalAlign="middle" className="not-found">
          <Grid.Column textAlign="center" style={{ maxWidth: '320px' }}>
            <Helmet
              title="404"
              meta={[{ name: 'Page Not Found', content: 'Customer Portal' }]}
            />
            <Header as="h1">Oops!</Header>
            <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
            {this.props.isAuthed ? (
              <Link to="/live-collections" className="ui green large button">
                Home
              </Link>
            ) : (
              <Link to="/login" className="ui green large button">
                Login
              </Link>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect((state) => ({ isAuthed: state.account.isAuthed }))(
  NotFound,
);
