import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';


const formatActiveDate = (activeDate: string | null): string =>
  activeDate !== null ? activeDate.split('-').join('/') : '';

const SMapDataToggleWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #d8d8d8;
  height: 53px;

  :first-of-type {
    border-top: 1px solid #d8d8d8;
  }
`;

const SMapDataToggleCheckboxCol = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ui.fitted.toggle.checkbox, .ui.toggle.checkbox input {
    width: 34px;
  }

  .ui.toggle.checkbox {
    .box:after, label:after {
      width: 14px;
      height: 14px;
      margin-top: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
    }
    input~.box:after, input~label:after {
      left: 0.25rem;
    }

    input:checked~.box:after, input:checked~label:after {
      left: 15px;
    }

    .box:before, label:before {
      width: 34px;
    }
  }

}
`;

const SMapDataToggleCol = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const SMapDataToggleDescription = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 3.5;
  display: flex;
  color: #082441;

  .icon-wrapper {
    display: flex;
    align-items: center;
    width: 36px;
  }

  .label-wrapper {
    text-transform: capitalize;
    white-space: nowrap;

    .label-lift-count {
      margin-left: 5px;
      color: #082441;
      opacity: 0.7;
    }
  }
`;

type Props = {
  label: string,
  type: string,
  icon: string,
  checked: boolean,
  shouldShowDynamicData: Object,
  liftCount: number,
  isToday: boolean,
  activeDate: string | null,
  onChange: Function,
};

const MapDataToggle = ({
  type,
  icon,
  label,
  checked,
  shouldShowDynamicData,
  liftCount,
  isToday,
  activeDate,
  onChange,
}: Props): React.Element => (
  <SMapDataToggleWrapper>
    <SMapDataToggleCol>
      <SMapDataToggleDescription>
        <div className="icon-wrapper">
          <img src={icon} alt={label} />
        </div>
        <span className="label-wrapper">
          {`Show ${label}`}
          <span className="label-lift-count">
            {shouldShowDynamicData.liftCount && liftCount !== 0
              ? `[${liftCount} lifts ${isToday ? 'today' : formatActiveDate(activeDate)}]`
              : ''}
          </span>
        </span>
      </SMapDataToggleDescription>
    </SMapDataToggleCol>
    <SMapDataToggleCheckboxCol>
      <Checkbox toggle checked={checked} onChange={() => onChange(type)} />
    </SMapDataToggleCheckboxCol>
  </SMapDataToggleWrapper>
);

export default MapDataToggle;
