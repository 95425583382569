/* eslint-disable */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { reportedMinutes, toSnake } from '../../../common/helpers';
import API_URL from '../../../common/api';
import { IconTruck } from '../../Icons/Truck';
import azureSession from '../../../azure/authentication/session';
import { Icon, Image } from 'semantic-ui-react';
import noImage from '../../../common/images/general/no_image.png';

const getAlertImage = (id, imageResources) => {
  const filterOutEmpty = imageResources.filter((item) => item.length > 0);

  if (filterOutEmpty.length === 0) {
    return noImage;
  }
  const url = `${API_URL}/event/${id}/image/${encodeURIComponent(
    filterOutEmpty[0],
  )}?size=thumb`;

  return url;
};

export const AlertIcon = (
  color,
  opacity,
) => `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31" height="28" viewBox="0 0 31 28" style="opacity: ${opacity};">
    <defs>
        <path id="a" d="M24.882 20.522a.957.957 0 0 1 .118.413c0 .276-.108.523-.324.74-.215.217-.48.325-.794.325H1.06c-.314 0-.569-.099-.765-.296-.196-.197-.294-.453-.294-.769 0-.315.078-.551.235-.71L11.471.652h.058c.236-.434.55-.651.942-.651.47 0 .784.177.94.532l11.354 19.753c0 .04.01.06.03.06.019 0 .029.019.029.058l.058.119zM14.06 6.21c0-.395-.216-.592-.647-.592h-1.883c-.392 0-.588.197-.588.592v8.575c0 .434.216.65.647.65h1.824c.431 0 .647-.216.647-.65V6.21z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(3 3)">
        <use fill="${color}" xlink:href="#a"/>
        <path stroke="#FFF" stroke-width="3" d="M12.067 1.192l-.027-.053a.571.571 0 0 0 .027.053zm11.472 19.997zm2.737-1.23c.142.316.224.637.224.976 0 .685-.272 1.307-.76 1.798-.5.501-1.148.767-1.858.767H1.06c-.7 0-1.34-.248-1.828-.738-.486-.489-.731-1.128-.731-1.827 0-.594.156-1.139.506-1.58L10.602-.85h.21A2.395 2.395 0 0 1 12.47-1.5c.999 0 1.845.46 2.272 1.339l11.522 20.046v.4a1.315 1.315 0 0 0-.15-.624 1.474 1.474 0 0 0-1.32-.817c.86 0 1.295.46 1.455 1.015a1.745 1.745 0 0 1 .026.1c.032.146.048.296.048.444h-1.5l1.343-.668.058.119a3.253 3.253 0 0 1 .05.105zm-13.717-6.024v.85c0-.393.454-.85.853-.85h-.853zm-.118 0h-.853c.4 0 .853.457.853.85v-.85zm0-6.817V6.21c0 .432-.474.908-.912.908h.912zm.118 0h.853c-.369 0-.853-.443-.853-.908v.908zm0 0v6.817h-.118V7.118h.118z"/>
        <ellipse cx="12.5" cy="18.508" fill="#FFF" rx="1.5" ry="1.508"/>
    </g>
</svg>`;

function AlertData({
  reason,
  created,
  address,
  wasteStream,
  imageResources,
  id,
  secondaryReason,
  truckId,
}: Props) {
  const { streetName, streetNumber, suburb, streetNumberDriverVerification } = address;
  return (
    <div className={`marker-container ${toSnake(reason)}`}>
      <span className="address">
          <span>
            <i className="icon-location icon" />
            {streetNumber} {streetName}, {suburb}</span>
      </span>
      { streetNumberDriverVerification ? 
        <span className="address address_house_section">
          <span> <i className="icon-location icon" /> Confirmed House #: {streetNumberDriverVerification}</span>
      </span> : null
      }
      <span className="clearfix content-container">
        <span className="img-container" role="presentation" ref="testing">
          <div
            style={{ position: 'relative', width: '80px' }}
            role="presentation">
            <Image
              className="thumb"
              src={getAlertImage(id, imageResources)}
              size="tiny"
              alt="Bin"
            />
          </div>
        </span>
        <span>
          <span className="reason">
            <img
              src={
                process.env.PUBLIC_URL + `/assets/alerts/${toSnake(reason)}.svg`
              }
              alt="reason"
            />{' '}
            {reason}
          </span>
          {secondaryReason ? <p>{secondaryReason}</p> : ''}

          <span className="time">{reportedMinutes(created)}</span>
          {truckId ? (
            <a className="truckId" data-truck-id={`${truckId.trim()}`}>
              <IconTruck
                wasteStream={wasteStream}
                size="20px"
                rotate="45"
                shadow
              />
              <span data-truck-id={`${truckId.trim()}`}>#{truckId.trim()}</span>
            </a>
          ) : (
            ''
          )}
        </span>
      </span>
    </div>
  );
}

export const makerShouldBeFocused = (activeEvent, uuid): boolean => {
  if (activeEvent.type === null) return true;
  return activeEvent.type === 'alerts' && uuid === activeEvent.uuid;
};
type Props = {
  reason: string,
  created: string,
  address: Object,
  secondaryReason: String,
  wasteStream: string,
  clickHandler: Function,
};

export default class AlertMarker {
  constructor(alertProps: Props) {
    this.alertProps = alertProps;
  }

  getMarkup = () =>
    ReactDOMServer.renderToStaticMarkup(AlertData(this.alertProps));
}
