import React from 'react';

export const IconClear = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon-library" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-68.000000, -200.000000)" fill="#FFFFFF">
        <path d="M76,200 C77.0991465,200 78.1358254,200.209453 79.1100691,200.628369 C80.0843151,201.047284 80.9336428,201.619377 81.6580784,202.344665 C82.3825165,203.069952 82.9539396,203.91715 83.3723647,204.886283 C83.7907898,205.855417 84,206.890186 84,207.99062 C84,209.10356 83.7907898,210.144583 83.3723647,211.113717 C82.9539396,212.08285 82.3825165,212.930048 81.6580784,213.655334 C80.9336428,214.380621 80.0843151,214.952714 79.1100691,215.371629 C78.1358254,215.790545 77.0991465,216 76,216 C74.9008535,216 73.8641746,215.790545 72.8899285,215.371629 C71.9156849,214.952714 71.0663572,214.380621 70.3419204,213.655334 C69.6174828,212.930048 69.0460599,212.08285 68.6276348,211.113717 C68.2092095,210.144583 68,209.10356 68,207.99062 C68,206.890186 68.2092095,205.855417 68.6276348,204.886283 C69.0460599,203.91715 69.6174828,203.069952 70.3419204,202.344665 C71.0663572,201.619377 71.9156849,201.047284 72.8899285,200.628369 C73.8641746,200.209453 74.9008535,200 76,200 Z M79.7283372,210.485345 L77.2552693,207.99062 L79.7283372,205.514652 L78.4730691,204.257912 L76.0000012,206.73388 L73.5269333,204.257912 L72.2716628,205.514652 L74.7447307,207.99062 L72.2716628,210.485345 L73.5269333,211.742085 L76.0000012,209.247362 L78.4730691,211.742085 L79.7283372,210.485345 Z" id="clear"></path>
      </g>
    </g>
  </svg>
);
