import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import SetPasswordForm from '../../components/SetPasswordForm';
import * as accountActionCreators from '../../redux/modules/account';

class SetPasswordContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleSetPassword = this.handleSetPassword.bind(this);
  }

  props: {
    location: Object,
    isSettingPassword: boolean,
    passwordSetSuccess: boolean,
    setPassword: Function,
  };

  handleSetPassword(values) {
    const params = queryString.parse(this.props.location.search);
    const token = params.token;
    const email = params.email;
    this.props.setPassword(email, values.newPassword, token);
  }

  render() {
    return (
      <div style={{ height: '100%' }} className="comp">
        <Helmet
          title="Set Password"
          meta={[
            { name: 'Set Password', content: 'Customer Portal' },
          ]}
        />
        <SetPasswordForm
          onSubmit={this.handleSetPassword}
          isSettingPassword={this.props.isSettingPassword}
          passwordSetSuccess={this.props.passwordSetSuccess}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isSettingPassword: state.account.isSettingPassword,
    passwordSetSuccess: state.account.passwordSetSuccess,
  }),
  (dispatch) => bindActionCreators(accountActionCreators, dispatch)
)(SetPasswordContainer);
