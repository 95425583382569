
// @flow

import React from 'react';
import { Header, Grid } from 'semantic-ui-react';

type Props = {
  title: string,
  action?: Object,
  className?: string,
};

PageHeader.defaultProps = {
  action: null,
  className: null,
};

export default function PageHeader({ title, action, className }: Props) {
  return (
    <Grid className={`page-header ${className}`}>
      <Grid.Row>
        <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
          <Header as="h1" floated="left">{title}</Header>
        </Grid.Column>
        <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
          {action}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
