// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dropdown } from 'semantic-ui-react';
import SideBarMenuItem from './SidebarMenuItem';


type Props = {
  menuItems: PropTypes.array,
  handleClick: PropTypes.func,
  activeElement: string,
  elementsSelected: boolean,

};
export default function SidebarMenu({ menuItems, handleClick, activeElement, elementsSelected }: Props) {
  const populatedDesktopMenuItems = menuItems.map((item) => (<SideBarMenuItem key={item.name} handleClick={handleClick} item={item} activeElement={activeElement} elementsSelected={elementsSelected} />));

  const newMenuItems = menuItems.map((item) => {
    const selectedItem = elementsSelected && (item.key !== activeElement);
    return {
      ...item,
      elementsSelected: selectedItem,
      disabled: selectedItem,
    };
  });

  const reportDropdownSelection = (
    <Dropdown
      placeholder="Select Report"
      fluid
      selection
      options={newMenuItems}
      onChange={(e, { value }) => (handleClick(value, elementsSelected))}
      value={activeElement}
    />);

  return (
    <aside className="sidebar-menu">
      <Grid>
        <Grid.Row only="mobile tablet">
          <Grid.Column>
            <h4>Reporting Datasets</h4>
            {reportDropdownSelection}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="computer">
          <Grid.Column className="column-menu-container">
            <Grid>
              {populatedDesktopMenuItems}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </aside>
  );
}
