import React from 'react';

export const IconMap = () => (
  <svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon-library" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-480.000000, -112.000000)" fill="#004B6D">
        <path d="M486.51952,112 C488.315325,112 489.844338,112.646457 491.106607,113.939394 C492.368875,115.232331 493,116.794604 493,118.626263 C493,120.457922 492.368875,122.020194 491.106607,123.313131 L486.5,128 L481.912913,123.313131 C480.637631,122.020194 480,120.457922 480,118.626263 C480,116.794604 480.637631,115.232331 481.912913,113.939394 C483.188194,112.646457 484.723714,112 486.51952,112 Z M486.5,120.828283 C487.072576,120.828283 487.554052,120.626265 487.944444,120.222222 C488.347849,119.804712 488.54955,119.309768 488.54955,118.737374 C488.54955,118.16498 488.347849,117.670035 487.944444,117.252525 C487.541039,116.835015 487.059561,116.626263 486.5,116.626263 C485.953451,116.626263 485.47848,116.835015 485.075075,117.252525 C484.67167,117.670035 484.46997,118.16498 484.46997,118.737374 C484.46997,119.309768 484.67167,119.804712 485.075075,120.222222 C485.465468,120.626265 485.940439,120.828283 486.5,120.828283 Z" id="map"></path>
      </g>
    </g>
  </svg>
);

