
// @flow

import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import mediaQueryBreakPoints from '../../common/mediaQueryBreakPoints';

export default styled(Grid)`
  @media (min-width: ${mediaQueryBreakPoints.tabletBreakpoint}) {
    height: 100%;
    margin: 0;
  }
`;
