export const UnAssignedIcon = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="8" height="8" rx="4" fill="#D9D9D9" />
  </svg>
);

export const MagnifyingIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="4" fill="#0D2B3A" />
      <path
        d="M15.1667 21.8333C18.8486 21.8333 21.8333 18.8486 21.8333 15.1667C21.8333 11.4848 18.8486 8.5 15.1667 8.5C11.4848 8.5 8.5 11.4848 8.5 15.1667C8.5 18.8486 11.4848 21.8333 15.1667 21.8333Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.5 23.5L19.875 19.875"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.168 12.668V17.668"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.668 15.168H17.668"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CloseModelIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 10L10 30"
      stroke="white"
      stroke-width="3.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 10L30 30"
      stroke="white"
      stroke-width="3.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ArrowRight = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    width="60"
    className={className}
    height="62"
    viewBox="0 0 35 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 57.5L30.75 30.75L4 4"
      stroke="white"
      stroke-width="7.13333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ArrowLeft = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    width="60"
    className={className}
    height="62"
    viewBox="0 0 35 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.75 57.5L4 30.75L30.75 4"
      stroke="white"
      stroke-width="7.13333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const LeftArrowDisabled = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="35"
    height="62"
    viewBox="0 0 35 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M30.75 57.5L4 30.75L30.75 4"
      stroke="white"
      stroke-width="7.13333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RightArrowDisabled = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="35"
    height="62"
    viewBox="0 0 35 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M30.75 57.5L4 30.75L30.75 4"
      stroke="red"
      stroke-width="7.13333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RightArrowIcon = () => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.25 16.5L8.75 9L1.25 1.5"
      stroke="#0D2B3A"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const UnAssignedIndicator = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="8" height="8" rx="4" fill="#D9D9D9" />
  </svg>
);

export const CompletedIndicator = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="8" height="8" rx="4" fill="#72C955" />
  </svg>
);

export const ExceptionIndicator = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="8" height="8" rx="4" fill="#FF2727" />
  </svg>
);

export const DownArrowIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.25L9 8.75L16.5 1.25"
      stroke="#0D2B3A"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const JobIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.38512 0H16.6266C20.4014 0 22.5032 2.047 22.5032 5.5545V17.434C22.5032 20.999 20.4014 23 16.6266 23H6.38512C2.67025 23 0.507324 20.999 0.507324 17.434V5.5545C0.507324 2.047 2.67025 0 6.38512 0ZM6.71505 5.359V5.3475H10.3676C10.8943 5.3475 11.322 5.75 11.322 6.24335C11.322 6.7505 10.8943 7.153 10.3676 7.153H6.71505C6.18837 7.153 5.7619 6.7505 5.7619 6.256C5.7619 5.7615 6.18837 5.359 6.71505 5.359ZM6.71505 12.351H16.2955C16.8209 12.351 17.2486 11.9485 17.2486 11.454C17.2486 10.9595 16.8209 10.5559 16.2955 10.5559H6.71505C6.18837 10.5559 5.7619 10.9595 5.7619 11.454C5.7619 11.9485 6.18837 12.351 6.71505 12.351ZM6.71505 17.6065H16.2955C16.7831 17.5605 17.1509 17.1684 17.1509 16.7095C17.1509 16.238 16.7831 15.847 16.2955 15.801H6.71505C6.34845 15.7665 5.99408 15.9275 5.79856 16.2265C5.60304 16.514 5.60304 16.8935 5.79856 17.1925C5.99408 17.48 6.34845 17.6525 6.71505 17.6065Z"
      fill="#1B97E0"
    />
  </svg>
);

export const UpArrowIcon = () => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 8.75L9 1.25L1.5 8.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.7244 9.64709L4.54944 44.9971C4.11286 45.7532 3.88186 46.6103 3.87941 47.4834C3.87697 48.3564 4.10317 49.2149 4.53551 49.9734C4.96785 50.7319 5.59126 51.364 6.34371 51.8067C7.09617 52.2495 7.95144 52.4875 8.82444 52.4971H51.1744C52.0474 52.4875 52.9027 52.2495 53.6552 51.8067C54.4076 51.364 55.031 50.7319 55.4634 49.9734C55.8957 49.2149 56.1219 48.3564 56.1195 47.4834C56.117 46.6103 55.886 45.7532 55.4494 44.9971L34.2744 9.64709C33.8288 8.91236 33.2012 8.30489 32.4524 7.8833C31.7036 7.46171 30.8588 7.24023 29.9994 7.24023C29.1401 7.24023 28.2953 7.46171 27.5465 7.8833C26.7976 8.30489 26.1701 8.91236 25.7244 9.64709Z"
      stroke="#F62E2E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 22.5V32.5"
      stroke="#F62E2E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 42.498H30.0237"
      stroke="#F62E2E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
