
import { passwordStrengthCheck } from '../../common/helpers';
import { emailRegex } from '../../common/options';

export default (values) => {
  const errors = {};

  // first name
  if (!values.firstName) {
    errors.firstName = 'Required.';
  }

  // last name
  if (!values.lastName) {
    errors.lastName = 'Required.';
  }

  // email
  if (!values.email) {
    errors.email = 'Required.';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  // role
  if (!values.role) {
    errors.role = 'Required.';
  }

  // new password
  if (values.password) {
    const check = passwordStrengthCheck(values.password);
    if (!check.valid) {
      errors.password = check.rule;
    }

    // confirm password
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required.';
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords do not match';
    }
  }

  return errors;
};
