import React, { Component } from 'react';
import styled from 'styled-components';

import { IconChevron } from '../../../../Icons/Chevron';
import LiftsFilterMenu from './LiftsFilterMenu';
import { wasteStreams } from '../../../../../common/filters';

const SLiftsFilter = styled.div`
    background: #eeeff0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 48px;
    font-size: 0.8em;
    .nearby-lifts {
      strong {
        text-transform: uppercase;
        color: #3e546b;
      }
      span {
        margin-left: 1em;
        font-weight: bold;
        color: #c1c8cf;
      }
    }
    .filter {
       color: #8391a0;
       position: relative;
       .toggle {
         cursor: pointer;
         display: flex;
         align-items: center;
        .filter-status {
          transition: opacity 250ms;
          opacity: 0;
          &.active {
            background-color: #b6ff6a;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: 1;
            margin-right: 0.5em;
          }
        }
      }
      .label {
        font-weight: bold;
        margin-right: 1em;
      }
    }
`;

type Props = {
  dataRadius: Number,
  setLiftsFilter: Function
}

type State = {
  filterOpen: Boolean,
  options: Array<Object>
}

class LiftsFilter extends Component<Props, State> {
  state = {
    filterOpen: false,
    options: wasteStreams,
  }

  setFilters = (options) => {
    this.setState({ options });

    const activeFilters = Object.values(options).filter((value) => !!value.isActive);

    const payload = activeFilters.map((filter) => filter.value);

    this.props.setLiftsFilter(payload);
  }

  toggleFilter = () => {
    const filterOpen = !this.state.filterOpen;
    this.setState({ filterOpen });
  }

  isFilterActive = () => {
    const { options } = this.state;

    const active = Object.values(options).filter((option) => option.isActive && option.value !== 'all');

    return !!(active.length || active.length);
  }

  render() {
    const { filterOpen, options } = this.state;
    const { dataRadius } = this.props;

    return (<SLiftsFilter>
      <div className="nearby-lifts">
        <strong>Nearby Lifts</strong>
        <span>Within {dataRadius} mtrs</span>
      </div>
      <div className="filter">
        <div className="toggle" onClick={this.toggleFilter}>
          <span className={this.isFilterActive() ? 'filter-status active' : 'filter-status inactive'}></span>
          <span className="label">{this.isFilterActive() ? 'FILTERED' : 'ALL TRUCK TYPES'}</span>
          <IconChevron open={filterOpen} color="#003f60"></IconChevron>
        </div>
        {filterOpen &&
          <LiftsFilterMenu
            toggleFilter={this.toggleFilter}
            setFilters={this.setFilters}
            options={options}
          >
          </LiftsFilterMenu>}
      </div>
    </SLiftsFilter>);
  }
}

export default LiftsFilter;
