import React from 'react';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Menu, Sidebar, Accordion } from 'semantic-ui-react';
import HEREMap from '../../components/HereMap/liveJob/LiveJob';
import * as searchActionCreators from '../../redux/modules/search';
import * as liveJobActionCreators from '../../redux/modules/liveJob';
import * as jobsActionCreators from '../../redux/modules/jobs';
import { hereMaps } from '../../common/options';
import LiveJobFilter from '../../components/liveJobFIlter';
import PickSingleDate from '../../components/PickSingleDate';
import SearchBar from '../../components/SearchBar';
import { dayFromGivenDate } from '../../common/timeOffsetHelper';
import LiveJobStatus from '../../components/HereMap/liveJob/jobStatus';
import TrucksLoadingOverlay from './TrucksLoadingOverlay';
import HMapMethods from '../../components/HereMap/instance/live-map-methods';
import LiveJobDetailPopup from '../../components/HereMap/liveJob/liveJobDetailPopup';
import { ZOOM_LEVEL_DETAILED_VIEW_BREAKPOINT } from '../../components/HereMap/instance/h-map-methods';
import pinMap from '../../common/images/general/pin-map.png';

class LiveJob extends React.Component {
  constructor(props) {
    super(props);

    this.setHMapState = this.setHMapState.bind(this);
    this.onCloseJobModal = this.onCloseJobModal.bind(this);

    this.handleFilter = this.handleFilter.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.onClickJobButton = this.onClickJobButton.bind(this);
    this.onCloseJobStatusModal = this.onCloseJobStatusModal.bind(this);
    this.handleReceiveClickedSearch =
      this.handleReceiveClickedSearch.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.showLiveJobPopup = this.showLiveJobPopup.bind(this);

    this.state = {
      currentZoom: 0,
      map: null,
      showLiveJobStatus: false,
      isToday: typeof this.props?.liveJob?.userInteraction?.isToday === 'undefined' ? true : this.props?.liveJob?.userInteraction?.isToday,
      activeDate: typeof this.props?.liveJob?.userInteraction?.activeDateString === 'undefined' ? null : this.props?.liveJob?.userInteraction?.activeDateString,
      filters: this.getLiveJobFilters(),
      workOrder: this.props?.liveJob?.userInteraction?.addressWithJobs,
      selectedMarkerAddress: this.props?.liveJob?.userInteraction?.addressWithJobs?.address,
      shouldSearchAddress: true,
    };
  }

  setHMapState(mapStates) {
    const { currentZoom } = this.state.currentZoom;
    this.setState(
      {
        ...mapStates,
      },
      () => {
        if (
          !this.state.councilBoundaryInitialized &&
          this.props.liveJob.mapLayer &&
          this.state.ui &&
          this.state.map
        ) {
          setTimeout(() => {
            this.drawCouncilBoundary();
          }, 1000);
        }

        if (mapStates.ui && !this.state.mapInstance) {
          this.setState({
            mapInstance: new HMapMethods({
              ...this.props,
              ui: this.state.ui,
            }),
          });
        }

        if (
          this.state.map &&
          this.state.ui &&
          this.state.mapInstance &&
          mapStates.currentZoom != currentZoom
        ) {
          this.zoomListener(mapStates.currentZoom);
        }
      },
    );
  }

  zoomListener(zoom) {
    this.state.mapInstance.changeZoomLevel(zoom);

    const selectedId =
      this.state.selectedMarkerAddress?.id || this.state.selectedMarkerAddress?.addressNo || null;
    this.state.mapInstance.selectPin(
      selectedId ? selectedId.toString() : null,
      true,
    );

    const center = this.state.map?.getCenter();
    if(this.state.selectedAddress && !(center.lat == this.state.selectedAddress.lat && center.lng == this.state.selectedAddress.lng)) {
      this.setState({
        hasUserMovedMap: true
      });
    }
    // we want to ignore the inital map loads before assigning the global zoom and center state
    if(this.state.map && this.state.loadCount > 2) {
      this.setState({
        shouldSearchAddress: false,
      }, () => {
        this.props.setUserInteraction( {
          mapZoom: zoom,
          mapCenter: this.state.map.getCenter()
        });
      });
    }
  }

  drawCouncilBoundary = () => {
    const mapLayer = this.props.liveJob.mapLayer;

    let shapes = new Array();
    if (mapLayer && mapLayer.shapes) {
      mapLayer.shapes.forEach((line) => {
        shapes.push(new H.geo.LineString(line));
      });
    }

    const geoPolygon = new H.geo.Polygon(
      new H.geo.LineString(mapLayer.container),
      shapes,
    );

    const polyline = new H.map.Polyline(shapes[0], {
      style: { lineWidth: 4 },
    });

    const viewModel = this.state.map.getViewModel();
    viewModel.setLookAtData({bounds: polyline.getBoundingBox()});

    // we need to create a listener that will capture when the map change was applied
    // fire this once so we don't keep zooming out
    viewModel.addEventListener('sync', function () {
      viewModel.setLookAtData({
        zoom: viewModel.getLookAtData().zoom - 0.5
      });
    }, { once: true });

    if (this.state.counsilBoundaryObject) {
      this.state.map.removeObject(this.state.counsilBoundaryObject);
    }

    const counsilBoundaryObject = new H.map.Polygon(geoPolygon, {
      style: {
        fillColor: 'rgba(55, 85, 170, 0.5)',
        strokeColor: '#0055AA',
        lineWidth: 3,
      },
    });

    this.state.map.addObject(counsilBoundaryObject);

    if (this.props.liveJob?.userInteraction?.mapZoom) {
      this.state.map.setZoom(this.props.liveJob?.userInteraction?.mapZoom);
    }

    if(this.props.liveJob?.userInteraction?.mapCenter) {
      this.state.map.setCenter(this.props.liveJob?.userInteraction?.mapCenter);
    }

    this.setState({
      counsilBoundaryObject,
      councilBoundaryInitialized: true,
      councilBounds: polyline.getBoundingBox(),
    });
  };

  drawMarkers = (props) => {
    const { map, ui } = this.state;
    const { liveJobMarkers } = props.liveJob;

    if (this.state.currentAlertMarkers) {
      this.state.currentAlertMarkers.removeAll();
    }

    if (map && ui && liveJobMarkers && liveJobMarkers.length > 0) {
      const generatedAlertMarkers =
        this.state.mapInstance.generateLiveJobMarkers(
          liveJobMarkers,
          this.showLiveJobPopup,
          this.state.currentZoom,
          map,
          this.state.selectedMarkerAddress,
        );

      this.setState({ currentAlertMarkers: generatedAlertMarkers }, () =>
        map.addObject(generatedAlertMarkers),
      );

      return generatedAlertMarkers.getBoundingBox();
    }
  };

  onCloseJobModal() {
    this.setState(
      {
        workOrder: null,
        selectedMarkerAddress: null,
        shouldSearchAddress: false,
      },
      () => {
        this.state.mapInstance.selectPin(null);
        this.props.setUserInteraction({ addressWithJobs: undefined, workOrderNumber: undefined });
      },
    );
  }

  onCloseJobStatusModal() {
    this.setState({
      showLiveJobStatus: false,
    });
  }

  onClickJobButton() {
    const { council, requestLiveJobStatus } = this.props;
    requestLiveJobStatus({
      territoryId: council.territoryId,
      date: dayFromGivenDate(
        this.state.activeDate,
        this.state.isToday,
        this.props.council.localTime,
      ),
      filters: this.state.filters,
    });
    this.setState({
      showLiveJobStatus: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.drawMarkers(nextProps);
    // when the live job markers are updated after the api call we will enter
    // here and can re-search the selected address
    if(nextProps?.search?.liveJobsResult?.id && this.props.search?.liveJobsResult?.id == nextProps.search?.liveJobsResult?.id &&
       nextProps?.search?.liveJobsValue &&
       nextProps?.liveJob?.userInteraction?.jobNumber === this.props?.liveJob?.userInteraction?.jobNumber) {
      if(this.state.shouldSearchAddress) {
        this.handleReceiveClickedSearch(nextProps.search.liveJobsResult, nextProps.liveJob?.liveJobMarkers, false);
      }

      this.setState({shouldSearchAddress: true});
    }

    if(nextProps?.liveJob?.userInteraction?.addressWithJobs !== this.props?.liveJob?.userInteraction?.addressWithJobs){
      this.setState({
        workOrder: nextProps?.liveJob?.userInteraction?.addressWithJobs,
        selectedMarkerAddress: nextProps?.liveJob?.userInteraction?.addressWithJobs?.address,
      });
    }
  }

  componentDidMount() {
    this.loadLiveJobsData();
    this.props.requestLiveJobServiceType({
      territoryId: this.props.council.territoryId,
    });
    this.timeoutId = setInterval(() => {
      if (this.state.isToday) {
        this.loadLiveJobsData();
      }
    }, 1000 * 60 * 5);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    if (this.props.liveJob.sidebar) {
      this.handleClearSearch();
    }
    this.setState({
      map: null,
      ui: null,
      mapInstance: null,
    });
    if (this.state.map && this.state.counsilBoundaryObject) {
      this.removeAMapObject(this.state.counsilBoundaryObject);
    }
    this.props.resetLiveJobMarkers();
  }

  removeAMapObject(object) {
    try {
      this.state.map.removeObject(object);
    } catch (e) {}
  }

  getActiveValues(options) {
    const activeFilters = Object.values(options).filter(
      (value) => !!value.isActive,
    );

    const payload = activeFilters.map((filter) => filter.value);
    return payload;
  }

  loadLiveJobsData() {
    const { council, requestMapLayer, resetSearch, requestLiveJobMap } =
      this.props;

    const statusOptions = this.props.liveJob?.filterMenus?.status?.options;
    const convertedStatusOptions = statusOptions.length ? statusOptions : this.getActiveValues(statusOptions);

    const serviceOptions = this.props.liveJob?.filterMenus?.serviceType?.options;
    const convertedServiceOptions = serviceOptions.length ? serviceOptions : this.getActiveValues(serviceOptions);

    const dateTerritoryPara = {
      territoryId: council.territoryId,
      date: dayFromGivenDate(
        this.state.activeDate,
        this.state.isToday,
        this.props.council.localTime,
      ),
      filters: {
        status: convertedStatusOptions,
        serviceType: convertedServiceOptions,
      }
    };

    if (council.hasMapLayer) {
      requestMapLayer(council.councilName);
    }

    requestLiveJobMap(dateTerritoryPara);
  }

  setActiveDate = (activeDate, isToday, rawActiveDate) => {
    const hasChangedDate = this.state.activeDate != activeDate;
    const newState = {
      activeDate,
      isToday,
      selectedMarkerAddress: null,
      workOrder: null,
      hasChangedDate
    };

    this.props.setUserInteraction({activeDate: rawActiveDate, isToday: isToday, activeDateString: activeDate});

    if (this.state.showLiveJobStatus) {
      newState.showLiveJobStatus = false;
    }

    this.removeCurrentPin();
    this.state.mapInstance.selectPin(null);
    this.setState(newState);

    this.props.requestLiveJobMap({
      territoryId: this.props.council.territoryId,
      date: dayFromGivenDate(activeDate, isToday, this.props.council.localTime),
      filters: this.state.filters,
    });
  };

  handleFilter(filterType, value) {
    const filters = this.getLiveJobFilters();

    filters[filterType] = value;
    const { requestLiveJobMap } = this.props;

    this.setState({
      filters,
    });

    this.state.mapInstance.selectPin(null);

    requestLiveJobMap({
      territoryId: this.props.council.territoryId,
      date: dayFromGivenDate(
        this.state.activeDate,
        this.state.isToday,
        this.props.council.localTime,
      ),
      filters: filters,
    });
  }

  getLiveJobFilters() {
    const statusOptions = this.props.liveJob?.filterMenus?.status?.options;
    const convertedStatusOptions = this.getActiveValues(statusOptions);

    const serviceOptions = this.props.liveJob?.filterMenus?.serviceType?.options;
    const convertedServiceOptions = this.getActiveValues(serviceOptions);

    const filters = {
      status: convertedStatusOptions,
      serviceType: convertedServiceOptions,
    };
    return filters;
  }

  resetFilters() {
    const { requestLiveJobMap } = this.props;
    const dateTerritoryPara = {
      territoryId: this.props.council.territoryId,
      date: dayFromGivenDate(
        this.state.activeDate,
        this.state.isToday,
        this.props.council.localTime,
      ),
      filters: {
        status: [],
        serviceType: [],
      },
    };

    this.setState({
      filters: {
        status: [],
        serviceType: [],
      },
    });

    requestLiveJobMap(dateTerritoryPara);
  }

  handleClearSearch() {
    this.props.resetLiveJobsSearch();
    const isSelectedAddressEqualSearchedAddress =
      this.props?.search?.result?.lat == this.props?.liveJob?.userInteraction?.addressWithJobs?.address?.latitude &&
      this.props?.search?.result?.lng == this.props?.liveJob?.userInteraction?.addressWithJobs?.address?.longitude;

    this.setState(
      {
        selectedMarkerAddress: isSelectedAddressEqualSearchedAddress ? null : this.state.selectedMarkerAddress,
        searchText: '',
        workOrder: isSelectedAddressEqualSearchedAddress ? null : this.state.workOrder,
      },
      () => {
        if(isSelectedAddressEqualSearchedAddress) {
          this.state.mapInstance.selectPin(null);
        }

        this.removeCurrentPin();
      },
    );
  }

  handleReceiveClickedSearch(address, liveJobMarkers = this.props.liveJob?.liveJobMarkers, hasAddressChanged = true) {
    const { lat, lng, id } = address;

    // we only want to perform a full address search when the date has changed
    // or the address has changed by the user
    // on other occassions like a page navigation just re-add the searched address
    // pin.
    const isSelectedAddressEqualSavedState =
    this.state.selectedMarkerAddress?.latitude == this.props.liveJob?.userInteraction?.addressWithJobs?.address?.latitude &&
    this.state.selectedMarkerAddress?.longitude == this.props.liveJob?.userInteraction?.addressWithJobs?.address?.longitude;

    const shouldPerformAddressSearch = this.state.hasChangedDate || hasAddressChanged ||
    !isSelectedAddressEqualSavedState;

    if(!shouldPerformAddressSearch) {
      this.setCurrentPin(lat, lng, 200);
      return;
    }

    const searchState = {
      searchText: address.fullAddress,
      workOrder: null,
      selectedAddress: address,
      selectedMarkerAddress: address,
      hasUserMovedMap: hasAddressChanged ? false : this.state.hasUserMovedMap
    };

    const workOrder = liveJobMarkers ? liveJobMarkers.find(
      (i) => i.address.addressNo.toString() === id.toString(),
    ) : undefined;

    if (workOrder) {
      searchState.workOrder = workOrder;
    }

    this.removeCurrentPin();
    this.setState(searchState, () => {
      if (this.state.mapInstance && this.state.map) {
        this.state.mapInstance.selectPin(id.toString());
        if (!this.state.hasUserMovedMap || this.state.hasChangedDate) {
          const nextZoomLevel = ZOOM_LEVEL_DETAILED_VIEW_BREAKPOINT + 3;
          this.state.map.setZoom(nextZoomLevel, this.state.animateZoom);
          this.state.mapInstance.changeZoomLevel(nextZoomLevel);
          this.state.map.setCenter({ lat, lng });
          if (this.state.hasChangedDate) {
            this.setState({
              hasChangedDate: false
            });
          }

          // we need to save back the selected address
          // to the global state so that when we navigate away from here
          // it will remember that it's either no address with jobs or is one.
          // try to only call this when we center on the searched address
          // so that we don't go into a loop that kills the react renderer
          this.props.setUserInteraction({ addressWithJobs: workOrder });
        }
        this.setCurrentPin(lat, lng, 200);
      }
    });
  }

  removeCurrentPin() {
    const { currentPin } = this.state;
    if (currentPin) {
      try {
        if (this.state.map.removeObject && currentPin.marker) {
          this.state.map.removeObject(currentPin.marker);
        }
      } catch (e) {}

      // the current pin may be added multiple times so need to remove it
      // this occurs when searching an address and navigating back to live jobs
      // and trying to remove the current address
      for(let i = 0; i < this.state?.currentPinHistory?.length; i++) {
        try {
          if(this.state.map.removeObject) {
            const pin = this.state.currentPinHistory[i];
            this.state.map.removeObject(pin.marker);
          }
        }
        catch(e) {}
      }

      this.setState({currentPinHistory: []});
    }
  }

  setCurrentPin(lat, lng) {
    this.setState(
      {
        currentPin: null,
      },
      () => {
        const icon = new H.map.Icon(pinMap, {size: { w: 40, h: 50}});
        const marker = new H.map.Marker({ lat, lng }, { icon });
        this.state.map.addObject(marker);

        const currentPinHistory = this.state.currentPinHistory ? this.state.currentPinHistory : [];

        this.setState({
          currentPin: {
            marker,
            location,
          },
          currentPinHistory: [...currentPinHistory, { marker, location}]
        });
      },
    );
  }

  sortServiceTypeFilters() {
    const { liveJob } = this.props;
    const { serviceType } = liveJob.filterMenus;

    if (
      this.state.filters.serviceType.length === 0 &&
      this.state.filters.status.length === 0
    ) {

      const sortedArray = [
        ...Object.values(serviceType.options).filter(
          (type) => type.value == 'all',
        ),
        ...Object.values(serviceType.options).filter(
          (type) => type.value !== 'all',
        ),
      ];

      serviceType.options = sortedArray.reduce((obj, item) => {
        obj[item.value] = item;
        return obj;
      }, {});
    }

    return liveJob.filterMenus;
  }

  showLiveJobPopup(workOrder) {
    this.setState({
      workOrder,
      selectedMarkerAddress: workOrder.address,
    });
  }

  render() {
    const { liveJob, council } = this.props;

    const center = {
      lat: council.lat || -25.560424,
      lng: council.lng || 134.917117,
    };

    return (
      <div className="compWithMap secondary-comp">
        <Helmet
          title="Live Jobs"
          meta={[{ name: 'Live Jobs', content: 'Cleanaview' }]}
        />
        <Menu secondary stackable>
          <SearchBar
            handleReceiveClickedSearch={this.handleReceiveClickedSearch}
            shown={liveJob.sidebar}
            handleClearSearch={this.handleClearSearch}
            activeDate={this.state.activeDate}
            defaultValue={this.state.searchText}
            isToday={this.state.isToday}
            excludeTruck={true}
            isLiveJobsPage={true}
          />
          <PickSingleDate
            setActiveDate={this.setActiveDate}
            providedTimeLocalCouncil={this.props?.liveJob?.userInteraction?.activeDate}
          />

          <Accordion className="menu-accordion computerBreakpoint menu right">
            <Accordion.Title></Accordion.Title>
            <Accordion.Content>
              <Menu.Menu position="right">
                <LiveJobFilter
                  buttonText={'Filters'}
                  containerStyle={{
                    maxHeight: 300,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                  handleFilter={this.handleFilter}
                  filterMenus={this.sortServiceTypeFilters()}
                  resetFilters={this.resetFilters}
                  activeDate={this.state.activeDate}
                />
              </Menu.Menu>
            </Accordion.Content>
          </Accordion>
        </Menu>

        <Sidebar.Pushable>
          <Sidebar
            animation="overlay"
            width="wide"
            className="live-job-status-model"
            visible={this.state.showLiveJobStatus}>
            <LiveJobStatus
              filters={this.sortServiceTypeFilters()}
              liveJobStatus={this.props.liveJob.liveJobStatus}
              onCloseModal={this.onCloseJobStatusModal}
              isFetching={this.props.liveJob.isFetching}
            />
          </Sidebar>
          <Sidebar.Pushable>
            {this.props.liveJob.isFetching ? <TrucksLoadingOverlay /> : null}
            <HEREMap
              apiKey={hereMaps.apiKey}
              interactive
              secure
              hidpi
              setHMapState={this.setHMapState}
              map={this.state.map}
              ui={this.state.ui}
              center={center}
              onClickJobButton={this.onClickJobButton}
              zoom={this.props.liveJob?.userInteraction?.mapZoom}
            />
            {this.state.workOrder ? (
              <LiveJobDetailPopup
                onCloseModal={this.onCloseJobModal}
                workOrder={this.state.workOrder}
                territoryId={this.state.territoryId}
                requestCRNWorkOrders={this.state.requestCRNWorkOrders}
              />
            ) : null}
          </Sidebar.Pushable>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    search: state.search,
    liveJob: state.liveJob,
    account: state.account.info.account,
    council: state.councils.active,
    jobs: state.jobs,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...searchActionCreators,
        ...liveJobActionCreators,
        ...jobsActionCreators,
      },
      dispatch,
    ),
)(LiveJob);
