import React, { Component } from 'react';
import { ArrowLeft, ArrowRight } from './svgComponents';
import { CloseModelIcon, MagnifyingIcon } from './svgComponents';
import API_URL from '../../common/api';
import './slider.css';

class ImageSlider extends Component {
  IMAGE_DEFAULT_LENGTH = 7;

  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      isModalOpen: false,
      expandImages: false,
    };
  }

  expand = () => {
    this.setState({
      expandImages: true,
    });
  };

  openModal = (index) => {
    this.setState({
      currentImageIndex: index,
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  goToNextImage = () => {
    if (this.state.currentImageIndex !== this.props.images.length - 1) {
      this.setState(() => ({
        currentImageIndex: this.state.currentImageIndex + 1,
      }));
    }
  };

  goToPreviousImage = () => {
    if (this.state.currentImageIndex !== 0) {
      this.setState(() => ({
        currentImageIndex: this.state.currentImageIndex - 1,
      }));
    }
  };

  render() {
    const { images } = this.props;
    const { currentImageIndex, isModalOpen, expandImages } = this.state;

    const imageEndIndex = expandImages ? images.length : this.IMAGE_DEFAULT_LENGTH;
    const hasMore = !expandImages && images.length > this.IMAGE_DEFAULT_LENGTH;

    return (
      <div className="image-slider">
        {images.slice(0, imageEndIndex).map((image, index) => (
          <div key={index} className="image-slider__image">
            {/* <MagnifyingIcon /> */}
            <img
              src={`${API_URL}/jobs/${this.props.jobId}/image/${image}`}
              alt={`Image ${index}`}
              onClick={() => this.openModal(index)}
            />
          </div>
        ))}

        {hasMore ? (
          <div className="more__slide" onClick={() => this.expand()}>
            +  {images.length - this.IMAGE_DEFAULT_LENGTH} more
          </div>
        ) : null}

        {isModalOpen && (
          <div className="image-slider__modal">
             <div className="backdrop-shadow" onClick={this.closeModal}></div>
            <div className="image-slider-wrapper">
             
              <div className="modal-content">

              <div className="image-slider__header">
                <CloseModelIcon
                  className="modal-close"
                  onClick={this.closeModal}
                />
              </div>

                <div className="modal-navigation">
                  {currentImageIndex === 0 ? (
                    <ArrowLeft className={'slider-arrow-disabled'} />
                  ) : (
                    <ArrowLeft onClick={this.goToPreviousImage} />
                  )}
                </div>

                <img
                  src={`${API_URL}/jobs/${this.props.jobId}/image/${images[currentImageIndex]}`}
                  alt={`Image ${currentImageIndex}`}
                />

                <div className="modal-navigation">
                  {this.state.currentImageIndex !==
                  this.props.images.length - 1 ? (
                    <ArrowRight onClick={this.goToNextImage} />
                  ) : (
                    <ArrowRight className={'slider-arrow-disabled'} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ImageSlider;
