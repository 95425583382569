
// @flow

import React from 'react';
import {
  Button,
  Icon,
  Modal,
} from 'semantic-ui-react';

type Props = {
  deleteUser: Function,
  modalOpen: boolean,
  isDeletingUser: boolean,
  closeModal: Function,
};

function deleteUserForm({ deleteUser, modalOpen, closeModal, isDeletingUser }: Props) {
  return (<Modal size="tiny" open={modalOpen} closeIcon={<Icon className="icon-cancel" onClick={closeModal} />}>
    <Modal.Header>Delete User</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        Are you sure that you want to delete this user?
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <button className="cancel" onClick={closeModal}>Cancel</button>
      <Button color="red" onClick={deleteUser} loading={isDeletingUser}><Icon name="save" /> Delete User</Button>
    </Modal.Actions>
  </Modal>);
}

// // decorate
// deleteUserForm = reduxForm({ // eslint-disable-line no-func-assign
//   form: 'deleteUser',
// })(deleteUserForm);

export default deleteUserForm;
