// @flow

import React from 'react';
import { Modal, Image, Loader, Label, Icon } from 'semantic-ui-react';
import { timeCreated, setImageSrcFromResources } from '../../common/helpers';

const filterOutEmpty = (imageResources) =>
  imageResources.filter((item) => item.length > 0);
export default class ImageSlideshow extends React.Component {
  // eslint-disable-line
  constructor(props) {
    super(props);
    this.state = { count: 0 };
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  handleNext() {
    this.setState((prevState) => {
      let count = prevState.count;
      count += 1;
      return { count };
    });
  }

  handlePrev() {
    this.setState((prevState) => {
      let count = prevState.count;
      count -= 1;
      return { count };
    });
  }

  componentWillUnMount() {
    this.setState(() => ({
      count: 0,
    }));
  }

  props: {
    onMount: Function,
    handleModalClose: Function,
    isFetching: boolean,
    images: Array,
    imageResources: Array,
    id: Number,
    created: string,
    open: boolean,
  };

  render() {
    const {
      created,
      isFetching,
      onMount,
      images,
      imageResources,
      open,
      handleModalClose,
      id,
    } = this.props;

    return (
      <Modal
        open={open}
        onMount={() => onMount(imageResources, id)}
        basic
        size="small"
        className="image-modal"
        closeIcon={
          <Icon className="icon-cancel" onClick={() => handleModalClose()} />
        }>
        <Modal.Header>
          <Label>
            <Icon name="clock" />
            {timeCreated(created)}
          </Label>
        </Modal.Header>
        <Modal.Content>
          <div className="bin-alert-lightbox">
            <div className="btn-wrapper">
              <button
                className="previous-btn"
                onClick={() => this.handlePrev()}
                disabled={this.state.count <= 0}>
                <Icon name="angle left" size="huge" />
              </button>
            </div>
            <div className="image-wrapper">
              {isFetching ? (
                <Loader active size="big" inline="centered" />
              ) : (
                <Image
                  src={setImageSrcFromResources(
                    id,
                    filterOutEmpty(imageResources),
                    this.state.count,
                  )}
                  alt="bin"
                />
              )}
            </div>
            <div className="btn-wrapper">
              <button
                className="next-btn"
                onClick={() => this.handleNext()}
                disabled={
                  this.state.count >= filterOutEmpty(imageResources).length - 1
                }>
                <Icon name="angle right" size="huge" />
              </button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
