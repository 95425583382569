// @flow
import 'babel-polyfill';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CSSTransitionGroup } from 'react-transition-group';
import { Icon, Loader, Dimmer } from 'semantic-ui-react';
import * as accountActionCreators from '../../redux/modules/account';
import * as messageActionCreators from '../../redux/modules/messages';
import * as councilActionCreators from '../../redux/modules/councils';
import { setCenterAddress } from '../../redux/modules/map';
import { resetSearch, resetIsFetching } from '../../redux/modules/search';
import { resetLiveJobIsFetching } from '../../redux/modules/liveJob';
import { resetLiveCollectionIsFetching } from '../../redux/modules/map'
import { resetAlertsIsFetching } from '../../redux/modules/alerts';
import { resetReportsIsFetching } from '../../redux/modules/reports';
import { resetTicketsIsFetching } from '../../redux/modules/tickets';
import { resetJobsIsFetching } from '../../redux/modules/jobs';
import Message from '../../components/Message';
import Header from '../../components/Header';
import Login from '../../containers/Login';
import CouncilSelect from '../../containers/CouncilSelect';
import RequestPasswordReset from '../../containers/RequestPasswordReset';
import SetPassword from '../../containers/SetPassword';
import LiveCollections from '../../containers/LiveCollections';
import UserManagement from '../../containers/UserManagement';
import Alerts from '../../containers/Alerts';
import Jobs from '../../containers/Jobs';
import Reports from '../../containers/Reports';
import Account from '../../containers/Account';
import Documents from '../../containers/Documents';
import AccountManagement from '../../containers/AccountManagement';
import NotFound from '../../containers/NotFound';
import { privateModules } from '../../common/options';
import Tickets from '../../containers/Tickets';
import * as ticketsActionCreators from '../../redux/modules/tickets';

import CrnDetail from '../../containers/CrnDetail';
import LiveJobs from '../LiveJobs';

type PrivateProps = {
  component: Function,
  isAuthed: boolean,
  userAccess: Object,
  alwaysAllowed: boolean,
  hasCouncilSelected: boolean,
};

type PublicProps = {
  component: Function,
  isAuthed: boolean,
  userAccess: Object,
};

function findFirstAccessibleRoute(userAccess) {
  if (userAccess) {
    for (let i = 0; i < privateModules.length; i += 1) {
      for (let j = 0; j < userAccess.length; j += 1) {
        if (privateModules[i].name === userAccess[j]) {
          return privateModules[i].url;
        }
      }
    }
  }
  return '/account';
}

function isAccessibleRoute(userAccess, path) {
  if (userAccess) {
    for (let i = 0; i < privateModules.length; i += 1) {
      if (privateModules[i].url === path) {
        for (let j = 0; j < userAccess.length; j += 1) {
          if (privateModules[i].name === userAccess[j]) {
            return privateModules[i].url;
          }
        }
      }
    }
  }
  return false;
}

function PrivateRoute({
  component: Component,
  isAuthed,
  hasCouncilSelected,
  userAccess,
  alwaysAllowed,
  ...rest
}: PrivateProps) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthed) {
          if (alwaysAllowed || isAccessibleRoute(userAccess, rest.path)) {
            return <Component {...props} />;
          }
          return <Redirect to={findFirstAccessibleRoute(userAccess)} />;
        }
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

function PublicRoute({
  component: Component,
  isAuthed,
  userAccess,
  ...rest
}: PublicProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={findFirstAccessibleRoute(userAccess)} />
        )
      }
    />
  );
}

class App extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogoClear = this.handleLogoClear.bind(this);
    this.handleCouncilChange = this.handleCouncilChange.bind(this);
  }

  componentWillMount() {
    this.props.checkAuthenticated();
    document.body.className = this.props.account.isAuthed
      ? 'logged-in'
      : 'logged-out';
  }

  componentWillReceiveProps(nextProps) {
    const { title, content, type } = nextProps.messages;

    if (title && title.length) {
      toast.info(<Message title={title} content={content} type={type} />);
      this.props.resetMessage();
      this.props.resetIsFetching();
      this.props.resetLiveJobIsFetching();
      this.props.resetLiveCollectionIsFetching();
      this.props.resetAlertsIsFetching();
      this.props.resetReportsIsFetching();
      this.props.resetTicketsIsFetching();
      this.props.resetJobsIsFetching();
    }
  }

  componentWillUpdate(nextProps) {
    document.body.className = nextProps.account.isAuthed
      ? 'logged-in'
      : 'logged-out';
  }

  props: {
    unAuthenticate: Function,
    deactivateCouncil: Function,
    checkAuthenticated: Function,
    resetMessage: Function,
    resetSearch: Function,
    resetIsFetching: Function,
    setCenterAddress: Function,
    account: Object,
    messages: Object,
    location: Object,
    search: Object,
    councils: Object,
  };

  handleLogout() {
    this.props.unAuthenticate();
  }

  handleCouncilChange() {
    this.props.deactivateCouncil();
    this.props.resetTicketState();
  }

  handleLogoClear() {
    this.props.resetSearch();
    this.props.setCenterAddress(null);
  }

  render() {
    const { account, location, councils } = this.props;

    const CustomClose = ({ closeToast }) => (
      <span className="toastify__close" onClick={closeToast}>
        <Icon className="icon-cancel" />
      </span>
    );


    return (
      <div>
        <Helmet
          titleTemplate="%s - Cleanaway"
          defaultTitle="Cleanaway"
          meta={[{ name: 'Cleanaway', content: 'Customer Portal' }]}
        />
        <ToastContainer
          position="top-right"
          closeButton={<CustomClose />}
          autoClose={6000}
        />
        {!account.hasCheckedJwt ? (
          <Dimmer
            active
            className="comp"
            style={{ backgroundColor: '#0093E6' }}>
            <Loader size="massive" />
          </Dimmer>
        ) : (
          <div style={{ height: '100%' }}>
            {account.isAuthed ? (
              <Header
                handleLogout={this.handleLogout}
                handleCouncilChange={
                  this.props.councils.available.length > 1 &&
                  this.handleCouncilChange
                }
                account={councils.active}
                user={account.info.user}
                userAccess={councils.accountModuleAccess}
                location={location}
                handleLogoClear={this.handleLogoClear}
              />
            ) : null}
            <div className="content">
              <CSSTransitionGroup
                transitionName="fadeInOut"
                transitionEnterTimeout={250}
                transitionLeaveTimeout={250}>
                <Switch key={location.pathname} location={location}>
                  <Route
                    path="/"
                    exact
                    render={() => {
                      return account.isAuthed ? (
                        <Redirect to="/live-collections" />
                      ) : (
                        <Redirect to="/login" />
                      );
                    }}
                  />

                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/live-collections"
                    component={LiveCollections}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/live-jobs"
                    component={LiveJobs}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/alerts"
                    component={Alerts}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/jobs"
                    component={Jobs}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/tickets"
                    component={Tickets}
                  />
                   <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/crn/:id"
                    component={CrnDetail}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/reports"
                    component={Reports}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    alwaysAllowed
                    path="/account"
                    component={Account}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/user-management"
                    component={UserManagement}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/invoices"
                    component={Documents}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/account-management"
                    component={AccountManagement}
                  />
                  <PrivateRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/council-select"
                    component={CouncilSelect}
                  />
                  <PublicRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/login"
                    component={Login}
                  />
                  <PublicRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/request-password-reset"
                    component={RequestPasswordReset}
                  />
                  <PublicRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/set-password"
                    component={SetPassword}
                  />
                  <PublicRoute
                    isAuthed={account.isAuthed}
                    userAccess={councils.accountModuleAccess}
                    path="/portal"
                    component={Login}
                  />
                  <Route component={NotFound} />
                </Switch>
              </CSSTransitionGroup>
            </div>
            {/* {this.props.isAuthed ? <Footer /> : null} */}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      account: state.account,
      councils: state.councils,
      messages: state.messages,
      search: state.search,
      tickets: state.tickets,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          ...accountActionCreators,
          ...messageActionCreators,
          ...councilActionCreators,
          ...ticketsActionCreators,
          setCenterAddress,
          resetSearch,
          resetIsFetching,
          resetLiveJobIsFetching,
          resetLiveCollectionIsFetching,
          resetAlertsIsFetching,
          resetReportsIsFetching,
          resetTicketsIsFetching,
          resetJobsIsFetching,
        },
        dispatch,
      ),
  )(App),
);
