const azureSession = {
  setToken: (token) => {
    sessionStorage.setItem('accessToken', token);
  },
  setExpires: (expiresOn) => {
    sessionStorage.setItem('expiresOn', expiresOn);
  },
  getToken: () => sessionStorage.getItem('accessToken'),
  removeItems: () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('expiresOn');
  },
};

export default azureSession;
