import { SLiveHistoricIndicator, SLiveHistoricIndicatorDot } from './index.sc.js'

import React from 'react';

type Props = {
  isLive: boolean,
};

const getIndicatorText = (isLive: boolean): string =>
  isLive ? 'Live' : 'Historic';

const LiveHistoricIndicator = ({ isLive }: Props): JSX.Element => (
  <SLiveHistoricIndicator>
    <SLiveHistoricIndicatorDot isLive={isLive} />
    <span>{getIndicatorText(isLive)}</span>
  </SLiveHistoricIndicator>
);

export default LiveHistoricIndicator;
