import JwtDecode from 'jwt-decode';

export class JwtManager {
  static exists() {
    return !!localStorage.getItem('jwt');
  }

  static get() {
    return localStorage.getItem('jwt');
  }

  static getData() {
    if (JwtManager.exists()) {
      try {
        return JwtDecode(JwtManager.get());
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  static set(jwt) {
    localStorage.setItem('jwt', jwt);
  }

  static remove() {
    localStorage.removeItem('jwt', null);
  }
}

export const jwt = JwtManager;
