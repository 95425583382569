/* eslint-disable */
// import React from 'react';
// import moment from 'moment';
// import ReactDOMServer from 'react-dom/server';
import {
  createClusterInfoBubble,
  createInfoBubbleForEvent,
  removeAllExistingBubbles,
} from './HandleInfoBubbles';

export const createLiftBubble = (
  activeEvent,
  ui,
  setActiveEvent,
  setClickedTruck = null,
) => {
  if (Array.isArray(activeEvent.position)) {
    activeEvent.position = {
      lat: activeEvent.position[0],
      lng: activeEvent.position[1],
    };
  }

  removeAllExistingBubbles(ui);

  if (typeof activeEvent.isCluster === 'function') {
    const map = ui.getMap();
    map.setCenter(activeEvent.getPosition());
    map.setZoom(map.getZoom() + 1);

    if (map.getZoom() === 18) {
      createClusterInfoBubble(activeEvent, ui, setActiveEvent, setClickedTruck);
    }
    return;
  }
  createInfoBubbleForEvent(activeEvent, ui, setActiveEvent, setClickedTruck);
};
