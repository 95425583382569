
/* eslint-disable */

import HEREMarker from '../HEREMarker';
import icon from '../types/icon';
import { getWasteStream } from '../../../common/options';

class TruckClusterHelper {
  /**
  * Return an icon object for the HEREMarker class
  * @returns {{image: string, width: number, height: number}}
  */
  static createTruckClusterIcon(number, wasteStreams): icon {
    return new icon({
      count: number,
      countBreakdown: wasteStreams,
      width: 28,
      height: 28,
    });
  }

  /**
   * Return content for bubble
   * @param {*} trucksData
   */
  static TruckClusterData(trucksData) {
    let ret = '';

    trucksData.forEach((truck) => {
      const { wasteStream = 'Refuse', workOrders = [], registration, id } = truck;

      let workOrderStr = '';
      if (workOrders) {
        Object.values(workOrders).forEach((workOrder) => {
          workOrderStr += `<span class="job">${workOrder.workOrderType}</span>`;
          workOrderStr += '<hr />';
        });
      }

      const wasteStreamObject = getWasteStream(wasteStream);

      ret += `<div class="${wasteStreamObject.key} marker-container">
        <span class="waste-stream">${wasteStreamObject.text}</span>
        <span class="TruckCluster-id">Truck ID #${id}</span>
        <span class="rego">Registration: ${registration}</span>
      </div>`;
    });

    return ret;
  }
}

class TruckClusterMarker extends HEREMarker {
  /**
  * Get minimum vars for a marker and add TruckCluster specific attributes
  *
  * @param TruckClusterProps
  */
  constructor(ui, point, trucks, min, max, withActiveBlueRing) {
    const data = trucks;
    const ws = [];
    trucks.forEach((truck) => {
      ws.push(truck.wasteStream);
    });

    const icon = TruckClusterHelper.createTruckClusterIcon(trucks.length, ws);
    const markerOptions = {
      lat: point.lat,
      lng: point.lng,
      icon,
      shouldOpenNotification: false,
      min,
      max,
      data: TruckClusterHelper.TruckClusterData(data || {}),
      active: withActiveBlueRing,
      iconType: "Truck Cluster " + trucks.length,
    };
    return super(ui, markerOptions);
  }
}

export default TruckClusterMarker;
