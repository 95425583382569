import React from 'react';
import styled from 'styled-components';

interface IconChevronProps {
  open: Boolean,
  style: string
}

const SIconChevron = styled.span`
  display: flex;
  svg {
      transform: ${(props) => props.open ? 'rotate(270deg)' : 'rotate(90deg)'};
      width: 13px;
      height: 17px;
      transition: transform 300ms;
  }
`;

export const IconChevron = ({ open, style, color }: IconChevronProps) => (
  <SIconChevron open={open} style={style}>
    <svg width="44px" height="80px" viewBox="0 0 55 80" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Fill-1" fill={color || '#FFFFFF'} points="15 0 55 40 15 80 0 65 25 40 0 15"></polygon>
      </g>
    </svg>
  </SIconChevron>
);

const AngleDownIcon = styled.i`
font-size: 1.3em;
width: 22px;
margin-top: 2px;
`

export const IconArrowDown = () => (
  <AngleDownIcon><i className='angle down icon'></i></AngleDownIcon>
)