// @flow

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const SButtonViewAlert = styled.button`
  border-radius: 6px;
  border: 1px solid rgba(151,151,151,0.3);
  float: right;
  width: 77px;
  height: 34px;
  font-size: 13px;
  font-weight: bold;
  color: #0095e3;
  background: transparent;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

type Props = {
  children: string,
  handleClick: Function
};

const ButtonViewAlert = ({ children, handleClick }: Props): React.Element => (
  <SButtonViewAlert onClick={handleClick}>
    <Icon name="camera" color={'blue'} />
    {children}
  </SButtonViewAlert>
);

export default ButtonViewAlert;
