// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import { Table, Button, Container, Loader, Grid } from 'semantic-ui-react';
import CustomReport from '../../containers/CustomReport';
import PageHeader from '../../components/PageHeader';
import ManageSubscriptions from '../../containers/ManageSubscriptions';
import * as reportActionCreators from '../../redux/modules/reports';
import { capitalized } from '../../common/helpers';

class ReportsContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.download = this.download.bind(this);
    this.edit = this.edit.bind(this);
  }

  componentDidMount() {
    if (!this.props.reports.items.length) {
      this.props.requestReports(this.props.territoryId);
    }
  }

  download(report) {
    const payload = {
      timeSegment: report.timeSegment,
      dataGroup: report.dataGroup,
      metrics: report.metrics,
      orderBy: report.orderBy,
    };
    this.props.requestExportReport(payload, this.props.territoryId);
  }

  create() {
    if (this.props.reports.menuItems.length > 0) {
      this.props.setDataGroup(this.props.reports.menuItems[0].key);
    }
    this.props.setSelectedReport(null);
    this.props.clearPreparedRequest();
    this.props.history.push('/reports/custom-report');
  }

  edit(report) {
    this.props.setSelectedReport(report);
    this.props.recordPreparedRequest(report, true);

    this.props.history.push('/reports/custom-report', { report });
  }

  props: {
    requestReports: Function,
    requestExportReport: Function,
    recordPreparedRequest: Function,
    clearPreparedRequest: Function,
    setSelectedReport: Function,
    setDataGroup: Function,
    history: Object,
    match: Object,
    reports: Object,
  };

  render() {
    const { match, reports } = this.props;

    const reportItems = reports.items.map((report) => (
      <Table.Row key={report.id}>
        <Table.Cell className="name">
          <span className="mobile-heading">Report Name</span>
          {report.reportName}
        </Table.Cell>
        <Table.Cell className="author">
          <span className="mobile-heading">Author</span>
          {report.author
            ? `${report.author.firstName} ${report.author.lastName}`
            : ''}
        </Table.Cell>
        <Table.Cell className="schedule">
          <span className="mobile-heading">Schedule</span>
          {report.timeSegment
            ? capitalized(report.timeSegment.toLowerCase())
            : ''}
        </Table.Cell>
        <Table.Cell className="subscriptions">
          <span className="mobile-heading">Subscriptions</span>
          <span className={!report.receivers ? 'none' : null}>
            <strong>
              {(report.receivers && report.receivers.length) || 0}
            </strong>{' '}
            <Link
              className="ui manage-subscriptions"
              to={`/reports/${report.id}/manage-subscriptions`}>
              Manage
            </Link>
          </span>
        </Table.Cell>
        <Table.Cell className="actions">
          <Button
            color="blue"
            className="basic primary massive"
            onClick={() => this.edit(report)}>
            Edit
          </Button>
        </Table.Cell>
      </Table.Row>
    ));

    const table = (
      <Table basic padded celled size="large" className="standard reports">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Report Name</Table.HeaderCell>
            <Table.HeaderCell>Author</Table.HeaderCell>
            <Table.HeaderCell>Schedule</Table.HeaderCell>
            <Table.HeaderCell>Subscriptions</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{reportItems}</Table.Body>
      </Table>
    );

    return (
      <div className="comp reports">
        <Route path={`${match.url}/custom-report`} component={CustomReport} />
        <Route
          path={`${match.url}/:id/manage-subscriptions`}
          component={ManageSubscriptions}
        />
        <Route
          exact
          path={match.url}
          render={() => (
            <Container>
              <Helmet
                title="Reports"
                meta={[{ name: 'Reports', content: 'Customer Portal' }]}
              />
              <PageHeader
                title="Reports"
                action={
                  <Button
                    onClick={() => this.create()}
                    className="ui green massive button right floated">
                    Create Report
                  </Button>
                }
              />

              {/* show table or loader */}
              {reports.items.length ? (
                table
              ) : (
                <Loader
                  active={reports.fetchingReports}
                  size="massive"
                  inline="centered"
                />
              )}

              {/* no results */}
              {!reports.items.length && !reports.fetchingReports ? (
                <p
                  style={{
                    color: '#424852',
                    textAlign: 'center',
                    fontSize: '16px',
                  }}>
                  No results
                </p>
              ) : null}

              <Grid>
                <Grid.Column only="mobile">
                  <div className="actions">
                    <Button
                      onClick={() => this.create()}
                      className="ui green massive button right floated">
                      Create Report
                    </Button>
                  </div>
                </Grid.Column>
              </Grid>
            </Container>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    reports: state.reports,
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) => bindActionCreators(reportActionCreators, dispatch),
)(ReportsContainer);
