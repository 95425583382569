// @flow

import React from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import ToggleButton from '../../ToggleButton';
import RenderIcon from '../RenderIcon';

import {
  SMenu,
  SListIconWrapper,
  SMenuHeadline,
  SCheckboxWrapper,
} from '../sharedStyle.sc';

import {
  SToggleButtonControlButton,
  SLiftsCheckboxWrapper,
  SLabelText,
  SLabelFilter,
} from './index.sc';

type OptionsInternalState = {
  value: string,
  isActive: boolean,
};
type State = {
  options: Array<OptionsInternalState>,
};

type Props = {
  name: string,
  options: Object,
  style: string,
  hideHeadline: boolean,
  setFilters: Function,
  disabled: boolean,
  activeDate: String,
  activeOption?: string,
};

class SingleSelectAllowNull extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterActive: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { options, disabled, activeDate, activeOption } = nextProps;
    let resetFiltersFlag = true;

    Object.keys(options).forEach((optionKey) => {
      if (options[optionKey].isActive) {
        resetFiltersFlag = false;
      }
      if (disabled) {
        options[optionKey].isActive = false;
      }
    });

    this.setState({
      filterActive: !resetFiltersFlag,
    });

    if (activeOption !== null && this.props.activeOption !== activeOption) {
      this.setActiveOption(activeOption);
    }

    if (this.props.activeDate !== activeDate) {
      this.resetState();
    }

    if (this.props.activeOption !== activeOption && activeOption === null) {
      this.resetState();
    }

    if (disabled) {
      this.disableFilter();
    }
  }


  setFirstOptionAsActive = () => {
    const { options } = this.props;
    options[Object.keys(options)[0]].isActive = true;
    this.props.setFilters(options);
  };

  setActiveOption = (activeOption) => {
    const { options } = this.props;
    Object.keys(options).forEach((optionKey) => {
      options[optionKey].isActive = activeOption === options[optionKey].value;
    });
    this.setState({
      filterActive: true,
    });
  };

  disableFilter = () => {
    this.setState({
      filterActive: false,
    });
  };
  resetOptions = () => {
    const { options } = this.props;
    Object.keys(options).forEach((optionKey) => {
      options[optionKey].isActive = false;
    });
    this.props.setFilters(options);
  };

  handleToggleCheckbox = (key) => {
    if (!this.state.filterActive) return;
    const { options } = this.props;

    this.resetOptions();

    options[key].isActive = true;
    this.props.setFilters(options);
  };

  resetState = () => {
    this.disableFilter();
    this.resetOptions();
  };

  render() {
    const { style, name, options, hideHeadline } = this.props;

    return (
      <SMenu style={style}>
        {!hideHeadline && <SMenuHeadline>{name}</SMenuHeadline>}

        <SToggleButtonControlButton>
          <div>
            <SLabelFilter htmlFor={'activeFilter'}>
              <Icon name="trash" />
              <SLabelText>{'Show Bin Lifts'}</SLabelText>
            </SLabelFilter>
          </div>
          <div>
            <ToggleButton
              checked={this.state.filterActive}
              id={'activeFilter'}
              onChange={() =>
                this.setState(
                  {
                    filterActive: !this.state.filterActive,
                  },
                  () => {
                    if (!this.state.filterActive) {
                      this.resetOptions();
                    } else {
                      this.setFirstOptionAsActive();
                    }
                  },
                )
              }
              disabled={this.props.disabled}
            />
          </div>
        </SToggleButtonControlButton>

        {Object.keys(options).map((key) => (
          <div key={options[key].value}>
            <SListIconWrapper className={name.toLocaleLowerCase()}>
              <RenderIcon
                isActive={this.state.filterActive}
                option={options[key]}
                name={name.toLocaleLowerCase()}
              />
            </SListIconWrapper>
            <SCheckboxWrapper>
              <SLiftsCheckboxWrapper active={this.state.filterActive}>
                <Checkbox
                  checked={options[key].isActive}
                  onChange={() => {
                    this.handleToggleCheckbox(key);
                  }}
                  label={options[key].text}
                />
              </SLiftsCheckboxWrapper>
            </SCheckboxWrapper>
          </div>
        ))}
      </SMenu>
    );
  }
}

export default SingleSelectAllowNull;
