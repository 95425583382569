import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  LeftArrowInactive,
  RightArrowInactive,
  LeftArrowActive,
  RightArrowActive,
  CompletedIcon,
  ExceptionIcon,
} from './svgComponents';
import './liveJob.css';
import { connect } from 'react-redux';
import * as liveJobActionCreators from '../../../redux/modules/liveJob';
import { bindActionCreators } from 'redux';

export class LiveMapCarosuel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: 0,
      jobs: props.jobs,
      jobIndex: 0,
    };
    this.handleRightArrowClick = this.handleRightArrowClick.bind(this);
    this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
    this.viewJobDetail = this.viewJobDetail.bind(this);
    this.viewCrnDetail = this.viewCrnDetail.bind(this);
  }

  handleLeftArrowClick() {
    if (this.state.currentPosition < 0) {
      this.setState((prevState) => {
        return {
          currentPosition: prevState.currentPosition + 100,
        }        
      });

      if(this.state.jobIndex - 1 >= 0) {
        this.setState((prevState) => {
          return {
            jobIndex: prevState.jobIndex - 1
          }
      }, () => this.props.setUserInteraction({jobNumber: this.props.jobs[this.state.jobIndex]?.jobNumber}));
      }
    }
  }

  componentWillReceiveProps(nextProps)
  {
    this.setWorkOrderCard(nextProps);
  }

  componentDidMount() {
    this.setWorkOrderCard(this.props);
  }

  setWorkOrderCard(nextProps) {    
    const selectedJobNumber = nextProps.liveJob?.userInteraction?.jobNumber;
    const jobs = nextProps.jobs;
    let selectedJobIndex = 0;
    if(selectedJobNumber && jobs) {
      const index = jobs.findIndex(job => job.jobNumber === selectedJobNumber);
      if(index >= 0)
      {
        selectedJobIndex = index;                 
      }      
    }
    
    this.setState({
      currentPosition: selectedJobIndex * -100,
      jobIndex: selectedJobIndex,
    });
  }

  handleRightArrowClick() {
    if (this.state.currentPosition > -(this.props.jobs.length - 1) * 100) {
      this.setState((prevState) => {
        return {
          currentPosition: prevState.currentPosition - 100,
        }        
      });

      if(this.state.jobIndex + 1 < this.props.jobs.length) {
        this.setState((prevState) => {
          return {            
            jobIndex: prevState.jobIndex + 1,
          };
        }, () => this.props.setUserInteraction({jobNumber: this.props.jobs[this.state.jobIndex]?.jobNumber}));
      }
    }
  }

  getIcon(jobStatus) {
    if (jobStatus === 'Completed') {
      return <CompletedIcon />;
    } else if (jobStatus === 'Exception') {
      return <ExceptionIcon />;
    }
    return null;
  }

  viewCrnDetail(ticket) {

    this.props.history.push(
      `/crn/${encodeURIComponent(ticket.jobId)}`
    );
  }

  viewJobDetail(ticket) {
    this.props.history.push(
      `/crn/${encodeURIComponent(ticket.jobId)}`
    );
  }

  render() {
    return (
      <div className="sliding-divs-container">
        <div
          className="sliding-divs"
          style={{ transform: `translateX(${this.state.currentPosition}%)` }}>
          {this.props.jobs.map((job, index) => {

            const isPreviousDisabled =this.state.currentPosition === 0;
            const isNextEnabled = this.state.currentPosition > -(this.props.jobs.length - 1) * 100;

            return <div key={job.jobId} className={`sliding-div`}>
            <div className={`job-content job-content-${job.status}`}>
              <div className={`header `}>
                <button onClick={this.handleLeftArrowClick} className={isPreviousDisabled ? 'cursor-disabled': ''}>
                  {isPreviousDisabled  ? (
                    <LeftArrowInactive  />
                  ) : (
                    <LeftArrowActive />
                  )}
                </button>
                <label>{job.jobNumber}</label>
                <button onClick={this.handleRightArrowClick} className={!isNextEnabled ? 'cursor-disabled': ''}>
                  {isNextEnabled ? (
                    <RightArrowActive />
                  ) : (
                    <RightArrowInactive  />
                  )}
                </button>
              </div>
              <div className="job-info-content">
                <div className="table-row template-1fr-2fr">
                  <div className="label">Job Status </div>
                  <div
                    className={`valueText value-status-text value-text-${job.status}`}>
                    <div>
                      <div className="flex-center-gap">
                        {this.getIcon(job.status)} {job.status}
                      </div>
                      <label>{job.reasonCode}</label>
                    </div>
                  </div>
                </div>
                <div className="table-row template-1fr-2fr">
                  <div className="label">Service Type </div>
                  <div className="valueText">{job.serviceType}</div>
                </div>
                <div className="table-row template-1fr-2fr">
                  <div className="label">Job Type </div>
                  <div className="valueText">{job.jobTypeDescription}</div>
                </div>
                <div className="table-row template-1fr-2fr">
                  <div className="label">CRN / Reference No. </div>
                  <div
                    className="valueText wo-number"
                    onClick={() => this.viewCrnDetail(job)}>
                    {job.crn}
                  </div>
                </div>
                <div className="table-row template-1fr-2fr">
                  <div className="label">Job Name</div>
                  <div className="valueText">
                    <div>
                      <div className="flex-center-gap">{job.name}</div>
                    </div>
                  </div>
                </div>
                <div className="table-row template-1fr-2fr bb-zero">
                  <div className="label">WO Number </div>
                  <div className="valueText ">{job.workOrderNumber}</div>
                </div>
                <div className="job-info-footer">
                  <button onClick={() => this.viewJobDetail(job)}>
                    View Job Details
                  </button>
                  {job.status === 'Completed' || job.status === 'Exception' ? (
                    <p>
                      <strong>Completed </strong> &nbsp;&nbsp;
                      {moment(job.completedDate).format(
                        'ddd, DD MMM YYYY hh:mma',
                      )}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => ({    
    liveJob: state.liveJob,    
  }),  
  (dispatch) =>
    bindActionCreators(
      {        
        ...liveJobActionCreators,       
      },
      dispatch,
    ),
)(LiveMapCarosuel));