import { Observable } from 'rxjs/Observable';
import { ajax, handleError, maxTimeout } from '../../common/AjaxClient';
import { jwt } from '../../common/JwtManager';
import azureSession from '../../azure/authentication/session';
import { showMessage } from './messages';
import {
  unSnake,
  triggerDownload,
  isAggreg,
  isDateField,
  isAddressField,
} from '../../common/helpers';
import { HAS_UNAUTHENTICATED } from './account';
import API_URL from '../../common/api';

/** *************************
 *          CONSTANTS
 **************************** */

// Aggregator groups obey different rules
const AGG_GROUPS = ['View_WorkOrderAGG'];

const REQUEST_REPORTS = 'REQUEST_REPORTS';
const RECEIVE_REPORTS = 'RECEIVE_REPORTS';

const REQUEST_REPORT_TYPES = 'REQUEST_REPORT_TYPES';
const RECEIVE_REPORT_TYPES = 'RECEIVE_REPORT_TYPES';

const UPDATE_REPORT = 'UPDATE_REPORT';
const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';

const DELETING_SUBSCRIBER = 'DELETING_SUBSCRIBER';
const POSTING_SUBSCRIBER = 'POSTING_SUBSCRIBER';

const REQUEST_CONFIG_SAVE = 'REQUEST_CONFIG_SAVE';
const RECEIVE_CONFIG_SAVE = 'RECEIVE_CONFIG_SAVE';

const REQUEST_CONFIG_EDIT = 'REQUEST_CONFIG_EDIT';
const RECEIVE_CONFIG_EDIT = 'RECEIVE_CONFIG_EDIT';

const REQUEST_CONFIG_DELETE = 'REQUEST_CONFIG_DELETE';
const RECEIVE_CONFIG_DELETE = 'RECEIVE_CONFIG_DELETE';

const REQUEST_EXPORT_REPORT = 'REQUEST_EXPORT_REPORT';
const RECEIVE_EXPORT_REPORT = 'RECEIVE_EXPORT_REPORT';

const REQUEST_REPORT_PREVIEW = 'REQUEST_REPORT_PREVIEW';
const RECEIVE_REPORT_PREVIEW = 'RECEIVE_REPORT_PREVIEW';
const RESET_REPORT_PREVIEW = 'RESET_REPORT_PREVIEW';

const TOGGLE_REPORT_MODAL = 'TOOGLE_REPORT_MODAL';

const SET_DATA_GROUP = 'SET_DATA_GROUP';

const UPDATE_EDITED_RECIEVERS = 'UPDATE_EDITED_RECIEVERS';

const PREPARED_REQUEST_RECORD = 'PREPARED_REQUEST_RECORD';
const PREPARED_REQUEST_CLEAR = 'PREPARED_REQUEST_CLEAR';

const REPORT_SET_SELECTED = 'REPORT_SET_SELECTED';
const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

/** *************************
 *       ACTION CREATORS
 **************************** */

// get all saved reports
export const requestReports = (territoryId) => ({
  type: REQUEST_REPORTS,
  territoryId,
});

const receiveReports = (payload) => ({
  type: RECEIVE_REPORTS,
  payload,
});
// update data group
export const setDataGroup = (payload) => ({
  type: SET_DATA_GROUP,
  payload,
});

// get report types for form config
export const requestReportTypes = () => ({
  type: REQUEST_REPORT_TYPES,
});

const receiveReportTypes = (payload) => ({
  type: RECEIVE_REPORT_TYPES,
  payload,
});

// export report
export const requestExportReport = (payload, territoryId) => ({
  type: REQUEST_EXPORT_REPORT,
  payload,
  territoryId,
});

const receiveExportReport = (payload, filename) => ({
  type: RECEIVE_EXPORT_REPORT,
  payload,
  filename,
});

// report preview
export const requestReportPreview = (payload, territoryId) => ({
  type: REQUEST_REPORT_PREVIEW,
  payload,
  territoryId,
});

export const resetReportPreview = () => ({
  type: RESET_REPORT_PREVIEW,
});

const receiveReportPreview = (payload) => ({
  type: RECEIVE_REPORT_PREVIEW,
  payload,
});

// add/remove subscribers
export const postingSubscriber = (payload, territoryId) => ({
  type: POSTING_SUBSCRIBER,
  payload,
  territoryId,
});

export const deletingSubscriber = (payload, territoryId) => ({
  type: DELETING_SUBSCRIBER,
  payload,
  territoryId,
});

// update report
export const updateReport = (payload, territoryId) => ({
  type: UPDATE_REPORT,
  payload,
  territoryId,
});

const updateReportSuccess = (payload) => ({
  type: UPDATE_REPORT_SUCCESS,
  payload,
});

// save config
export const requestConfigSave = (payload, callback, territoryId) => ({
  type: REQUEST_CONFIG_SAVE,
  payload,
  callback,
  territoryId,
});

const receiveConfigSave = (payload) => ({
  type: RECEIVE_CONFIG_SAVE,
  payload,
});

// edit config
export const requestConfigEdit = (payload, id, callback, territoryId) => ({
  type: REQUEST_CONFIG_EDIT,
  payload,
  id,
  callback,
  territoryId,
});

const receiveConfigEdit = (payload) => ({
  type: RECEIVE_CONFIG_EDIT,
  payload,
});

// delete config
export const requestConfigDelete = (payload, callback) => ({
  type: REQUEST_CONFIG_DELETE,
  payload,
  callback,
});

const receiveConfigDelete = (payload) => ({
  type: RECEIVE_CONFIG_DELETE,
  payload,
});

export const toggleReportModal = (payload) => ({
  type: TOGGLE_REPORT_MODAL,
  payload,
});

export const updateEditedRecievers = (payload, territoryId) => ({
  type: UPDATE_EDITED_RECIEVERS,
  payload,
  territoryId,
});

export const recordPreparedRequest = (payload, saveFormValues) => ({
  type: PREPARED_REQUEST_RECORD,
  payload,
  saveFormValues: !!saveFormValues,
});

export const clearPreparedRequest = () => ({
  type: PREPARED_REQUEST_CLEAR,
});

export const setSelectedReport = (payload) => ({
  type: REPORT_SET_SELECTED,
  payload,
});

export const resetReportsIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

/** *************************
 *           EPICS
 **************************** */

// get reports
const requestReportsEpic = (action$) =>
  action$
    .ofType(REQUEST_REPORTS)
    .switchMap((action) =>
      ajax.get(
        `/report/config?territoryId=${action.territoryId}`,
        null,
        (payload) =>
          Observable.of(receiveReports(payload.response.reportConfigs)),
      ),
    );

// get report types
const requestReportTypesEpic = (action$) =>
  action$
    .ofType(REQUEST_REPORT_TYPES)
    .switchMap(() =>
      ajax.get('/report/type', null, (payload) =>
        Observable.of(receiveReportTypes(payload.response.metrics)),
      ),
    );

// get report preview
const requestReportPreviewEpic = (action$) =>
  action$
    .ofType(REQUEST_REPORT_PREVIEW)
    .switchMap((action) =>
      ajax.post(
        `/report/preview?territoryId=${action.territoryId}`,
        action.payload,
        null,
        (payload) =>
          Observable.of(receiveReportPreview(payload.response.preview)),
      ),
    );

// add subscribers
const postingSubscriberEpic = (action$) =>
  action$
    .ofType(POSTING_SUBSCRIBER)
    .map((action) => updateReport(action.payload, action.territoryId));

// remove subscribers
const deletingSubscriberEpic = (action$) =>
  action$
    .ofType(DELETING_SUBSCRIBER)
    .map((action) => updateReport(action.payload, action.territoryId));

// update report
const updateReportEpic = (action$) =>
  action$.ofType(UPDATE_REPORT).switchMap((action) =>
    ajax.post(
      `/report/config/${action.payload.id}?territoryId=${action.territoryId}`,
      {
        ...action.payload,
      },
      null,
      (payload) =>
        Observable.of(
          showMessage('Success', 'Subscriptions saved', 'success'),
          updateReportSuccess(payload.response),
        ),
    ),
  );

// export report
const exportReportEpic = (action$) =>
  action$.ofType(REQUEST_EXPORT_REPORT).switchMap((action) => {
    const h = {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    };
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    // Construct a useful filename
    let trace = 'CUSTOM';
    let isDateRange =
      !!action.payload.from &&
      !!action.payload.from.format &&
      !!action.payload.to &&
      !!action.payload.to.format;
    if (action.payload.timeSegment) {
      trace = action.payload.timeSegment;
    } else if (isDateRange) {
      trace = `${action.payload.from.format(
        'YMMDD',
      )}-${action.payload.to.format('YMMDD')}`;
    }

    //Hotfix reduction of date moment object to string to ensure moment is used and local is applied, if time not specified ensure it is start of day
    if (isDateRange) {
      action.payload.from = !action.payload.timeSegment
        ? action.payload.from.startOf('day').format()
        : action.payload.from.format();
      action.payload.to = !action.payload.timeSegment
        ? action.payload.to.endOf('day').format()
        : action.payload.to.format();
    }

    const filename = `${action.payload.dataGroup}-${trace}-Report.csv`;
    const request = {
      headers: h,
      responseType: 'arraybuffer',
      url: `${API_URL}/report/export?territoryId=${action.territoryId}`,
      method: 'POST',
      body: action.payload,
    };

    return Observable.ajax(request)
      .timeout(maxTimeout)
      .flatMap((payload) =>
        Observable.of(receiveExportReport(payload, filename)),
      )
      .catch((e) => handleError(e));
  });

// save report config
const saveReportEpic = (action$) =>
  action$.ofType(REQUEST_CONFIG_SAVE).switchMap((action) => {
    console.log(action);
    return ajax.post(
      `/report/config?territoryId=${action.territoryId}`,
      {
        ...action.payload,
      },
      null,
      (payload) => {
        if (action.callback) {
          action.callback();
        }
        return Observable.of(
          receiveConfigSave(payload.response),
          showMessage('Success', 'Report saved', 'success'),
        );
      },
    );
  });

// edit report config
const editReportEpic = (action$) =>
  action$.ofType(REQUEST_CONFIG_EDIT).switchMap((action) =>
    ajax.post(
      `/report/config/${action.id}?territoryId=${action.territoryId}`,
      {
        ...action.payload,
      },
      null,
      (payload) => {
        if (action.callback) {
          action.callback();
        }
        return Observable.of(
          receiveConfigEdit(payload.response),
          showMessage('Success', 'Report edited', 'success'),
        );
      },
    ),
  );

// delete report config
const deleteReportEpic = (action$) =>
  action$.ofType(REQUEST_CONFIG_DELETE).switchMap((action) => {
    console.log(action);
    return ajax.delete(
      `/report/config/${action.payload.id}?territoryId=${action.payload.territoryId}`,
      null,
      () => {
        if (action.callback) {
          action.callback();
        }
        return Observable.of(
          receiveConfigDelete(action.payload),
          showMessage('Success', 'Report deleted', 'success'),
        );
      },
    );
  });

// EXPORT EPICS
export const reportsEpics = {
  requestReportsEpic,
  requestReportTypesEpic,
  requestReportPreviewEpic,
  postingSubscriberEpic,
  deletingSubscriberEpic,
  updateReportEpic,
  exportReportEpic,
  saveReportEpic,
  editReportEpic,
  deleteReportEpic,
};

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  fetchingReports: false,
  fetchingTypes: false,
  fetchingPreview: false,
  fetchingExport: false,
  updatingReport: false,
  postingSubscriber: false,
  deletingSubscriber: false,
  postingReport: false,
  export: '',
  preview: {
    headers: [],
    rows: [],
  },
  reportTypes: [],
  items: [],
  isDeletingReport: null,
  reportModalOpen: '',
  preparedRequest: null,
  dataGroup: null,
  menuItems: [],
  canSubmit: false,
  edited: {
    reportSchedule: '',
    receivers: [],
  },
  reportFormValues: {},
  selectedReport: null,
  mode: null,
  id: null,
  elementsSelected: false,
  defaultDataGroup: null,
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case REQUEST_REPORTS:
      return {
        ...state,
        fetchingReports: true,
      };
    case REQUEST_CONFIG_SAVE:
      return {
        ...state,
        postingReport: true,
      };
    case REQUEST_CONFIG_EDIT:
      return {
        ...state,
        postingReport: true,
      };
    case REQUEST_REPORT_PREVIEW: {
      return {
        ...state,
        fetchingPreview: true,
      };
    }
    case REQUEST_REPORT_TYPES:
      return {
        ...state,
        fetchingTypes: true,
      };
    case REQUEST_EXPORT_REPORT:
      return {
        ...state,
        fetchingExport: true,
      };
    case UPDATE_REPORT:
      return {
        ...state,
        updatingReport: true,
      };
    case SET_DATA_GROUP:
      return {
        ...state,
        dataGroup: action.payload,
      };
    case POSTING_SUBSCRIBER:
      return {
        ...state,
        postingSubscriber: true,
      };
    case DELETING_SUBSCRIBER:
      return {
        ...state,
        deletingSubscriber: true,
      };
    case RECEIVE_EXPORT_REPORT:
      triggerDownload(action.payload.response, action.filename, 'text/csv');
      return {
        ...state,
        fetchingExport: false,
        export: action.payload,
        reportModalOpen: '',
      };
    case RECEIVE_REPORT_TYPES: {
      const tmpMenuItems = [];
      action.payload.map((module) => {
        let textLabel = unSnake(module.dataGroup);
        textLabel = textLabel.replace(/([a-z])([A-Z])/g, '$1 $2');
        const tmpGroupObject = {
          key: module.dataGroup,
          value: module.dataGroup,
          text: textLabel,
          active: false,
          disabled: false,
        };
        // menuItems.push(tmpGroupObject);
        tmpMenuItems.push(tmpGroupObject);
        return module;
      });
      const defaultDataGroup = tmpMenuItems[0].key;
      return {
        ...state,
        fetchingTypes: false,
        reportTypes: action.payload,
        menuItems: tmpMenuItems,
        defaultDataGroup,
        dataGroup: !state.dataGroup ? defaultDataGroup : state.dataGroup,
      };
    }
    case RECEIVE_REPORTS:
      return {
        ...state,
        fetchingReports: false,
        items: action.payload,
      };
    case RECEIVE_REPORT_PREVIEW:
      return {
        ...state,
        fetchingPreview: false,
        preview: action.payload,
      };
    case UPDATE_REPORT_SUCCESS: {
      const newItems = state.items.map((item) => {
        if (item.id === action.payload.config.id) {
          return {
            ...action.payload.config,
          };
        }
        return item;
      });
      return {
        ...state,
        items: newItems,
        updatingReport: false,
        postingSubscriber: false,
        deletingSubscriber: false,
      };
    }
    case RESET_REPORT_PREVIEW:
      return {
        ...state,
        fetchingPreview: false,
        preview: initialState.preview,
      };
    case RECEIVE_CONFIG_SAVE:
      return {
        ...state,
        postingReport: false,
        items: state.items.concat([action.payload.config]),
        reportModalOpen: false,
      };
    case RECEIVE_CONFIG_EDIT: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < state.items.length; i += 1) {
        if (prevUpdateItems[i].id === action.payload.config.id) {
          prevUpdateItems.splice(i, 1);
        }
      }
      return {
        ...state,
        postingReport: false,
        items: state.items.concat([action.payload.config]),
        reportModalOpen: false,
        preparedRequest: null,
        dataGroup: null,
      };
    }
    case REQUEST_CONFIG_DELETE:
      return {
        ...state,
        isDeletingReport: action.payload.id,
      };
    case RECEIVE_CONFIG_DELETE: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < state.items.length; i += 1) {
        if (prevUpdateItems[i].id === action.payload.id) {
          prevUpdateItems.splice(i, 1);
        }
      }
      return {
        ...state,
        items: prevUpdateItems,
        isDeletingReport: null,
      };
    }
    case TOGGLE_REPORT_MODAL:
      return {
        ...state,
        reportModalOpen: action.payload,
      };
    case UPDATE_EDITED_RECIEVERS:
      return {
        ...state,
        edited: action.payload,
      };
    case PREPARED_REQUEST_RECORD: {
      const ret = {
        ...state,
        preparedRequest: action.payload,
      };

      const report = action.payload;
      const dataGroup = report && report.dataGroup ? report.dataGroup : null;
      ret.dataGroup = dataGroup;

      // Save form values to state
      if (action.saveFormValues) {
        if (!!report.dataGroup && !!report.metrics) {
          const obj = {
            dates: {},
            address: {},
            receivers: [],
          };
          obj[dataGroup] = {};

          for (let i = 0; i < report.metrics.length; i += 1) {
            let index = dataGroup;
            if (isDateField(report.metrics[i].fieldName)) {
              index = 'dates';
            }
            if (isAddressField(report.metrics[i].fieldName)) {
              index = 'address';
            }

            obj[index][report.metrics[i].fieldName] = { open: true };
            if (
              !!report.metrics[i].filters &&
              report.metrics[i].filters.length > 0
            ) {
              const importedFilters = [];
              for (let f = 0; f < report.metrics[i].filters.length; f += 1) {
                importedFilters.push(report.metrics[i].filters[f]);
              }
              obj[index][report.metrics[i].fieldName].filters = importedFilters;
            }
          }
          obj.receivers = report.receivers;
          ret.reportFormValues = obj;
        }
      }

      // Determine whether the user can submit the form
      if (report.metrics) {
        // Define whether the user can preview/submit
        let coreFieldSelected = false;
        let hasAggregator = false;
        let hasNonAggregator = false;
        let aggregCondition = true;
        let elementsSelected = false;

        report.metrics.forEach((field) => {
          // If there is even only one field in metrics, that means at least one element is selected
          elementsSelected = true;
          if (
            !isDateField(field.fieldName) &&
            !isAddressField(field.fieldName)
          ) {
            // Check if a core field is selected
            coreFieldSelected = true;
          }
          // Aggregator fields
          if (isAggreg(field.fieldName)) {
            hasAggregator = true;
          } else {
            hasNonAggregator = true;
          }

          if (AGG_GROUPS.includes(dataGroup)) {
            // Both aggreg fields and non-aggreg fields need to be selected for View_WorkOrderAGG
            aggregCondition = hasAggregator && hasNonAggregator;
          } else if (hasAggregator) {
            // Regarding other data groups, you need at least one non-aggreg field
            aggregCondition = hasNonAggregator;
          }
        });

        ret.canSubmit = coreFieldSelected && aggregCondition;
        ret.elementsSelected = elementsSelected;
      } else {
        ret.canSubmit = false;
        ret.elementsSelected = false;
      }

      return ret;
    }
    case PREPARED_REQUEST_CLEAR:
      return {
        ...state,
        preparedRequest: {},
        reportFormValues: {
          metrics: state?.preparedRequest?.metrics?.map(m => { return {...m, checked: false}; })
        },
        canSubmit: false,
        elementsSelected: false,
        dataGroup: state.defaultDataGroup ? state.defaultDataGroup : null,
      };
    case REPORT_SET_SELECTED: {
      let id = null;
      let mode = 'add';
      if (action.payload) {
        id = action.payload.id;
        mode = 'edit';
      }
      return {
        ...state,
        selectedReport: action.payload,
        id,
        mode,
      };
    }
    case RESET_IS_FETCHING:
      return {
        ...state,
        fetchingReports: false,
        fetchingTypes: false,
        fetchingPreview: false,
        fetchingExport: false,
        postingReport: false,
        postingSubscriber: false,
      }
    default:
      return state;
  }
}
