
// @flow

import styled from 'styled-components';

const Button = styled.button`
  padding: 5px 5px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
`;

export default Button;
