
// @flow

import React from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Form,
  Button,
  Container,
  Grid,
  Icon,
} from 'semantic-ui-react';
import validate from './validate';

type Props = {
  handleSubmit: Function,
  updatingReport: boolean,
};

function AddSubscriptionForm({ handleSubmit, updatingReport }: Props) {
  return (
    <Container text>

      <Form onSubmit={handleSubmit}>
        <Grid className="actions">
          <Grid.Column mobile={16} tablet={9} computer={11} only="tablet computer"></Grid.Column>
          <Grid.Column tablet={2} computer={1} verticalAlign="middle" only="tablet computer">
            <Link to="/reports" className="link item cancel">
                  Cancel
            </Link>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={4} verticalAlign="middle" >
            <Button
              type="submit"
              size="massive"
              color="green"
              fluid
              loading={updatingReport}
              disabled={updatingReport}
            >
              <Icon name="save" />
                  Save
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} verticalAlign="middle" only="mobile">
            <Link to="/reports" className="link item cancel">
                  Cancel
            </Link>
          </Grid.Column>
        </Grid>
      </Form>

    </Container>


  );
}

// decorate
AddSubscriptionForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'addSubscription',
  validate,
})(AddSubscriptionForm);


export default AddSubscriptionForm;
