const url = {
  development: 'https://cleanawaydev.azurewebsites.net/api',
  conductDev: 'https://cleanawaydev.azurewebsites.net/api',
  cleanawayDev: 'https://cleanaviewdev.azurewebsites.net/api',
  staging: 'https://cleanaviewtest.azurewebsites.net/api',
  production: 'https://cleanaviewprod.azurewebsites.net/api',
};
const env = process.env.REACT_APP_ENV || 'development';
console.log('=== ENV === ', env);
export default url[env];
