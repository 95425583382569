import { Configuration, LogLevel } from '@azure/msal-browser';

const env = process.env.REACT_APP_ENV || 'development';

const adEnvConfig: { [key: string]: Configuration } = {
  development: {
    clientId: 'c0a44e0c-7f80-4f25-80a3-fffd6127e556',
    authority:
      'https://login.microsoftonline.com/b91ee7f8-db45-4e3f-8cfd-a0b83e7f6ae3',
    redirectUri: 'http://localhost:3000/login',
  },
  conductDev: {
    clientId: 'c0a44e0c-7f80-4f25-80a3-fffd6127e556',
    authority:
      'https://login.microsoftonline.com/b91ee7f8-db45-4e3f-8cfd-a0b83e7f6ae3',
    redirectUri: 'https://cleanaviewdev.z8.web.core.windows.net/login',
  },
  cleanawayDev: {
    clientId: '016dbccd-11e9-4c5a-a2b3-5fd953b6b492',
    authority:
      'https://login.microsoftonline.com/c7c69742-978a-45b6-9ee1-5b0f8f85dc21',
    redirectUri: 'https://cleanaviewclientdev.z8.web.core.windows.net/login',
  },
  staging: {
    clientId: '96fbc379-24f7-452a-a47b-5d33e9f3af73',
    authority:
      'https://login.microsoftonline.com/c7c69742-978a-45b6-9ee1-5b0f8f85dc21',
    redirectUri: 'https://cleanaviewclienttest.z8.web.core.windows.net/login',
  },
  production: {
    clientId: 'c0debfbb-83bd-47f6-a9b3-613e35b421a2',
    authority:
      'https://login.microsoftonline.com/c7c69742-978a-45b6-9ee1-5b0f8f85dc21',
    redirectUri: 'https://www.cleanaview.net/login',
  },
};

export const protectedResources = {
  identity: {
    endpoint: 'https://cleanawaydev.azurewebsites.net/api/v1/identity',
    scopes: ['api://8c6c9d70-7784-4e30-bc3b-a4a56d94989a/user_impersonation'], // e.g. api://xxxxxx/access_as_user
  },
};

const envScopes = {
  development: [
    'api://8c6c9d70-7784-4e30-bc3b-a4a56d94989a/user_impersonation',
  ],
  conductDev: ['api://8c6c9d70-7784-4e30-bc3b-a4a56d94989a/user_impersonation'],
  cleanawayDev: [
    'api://5bae77d8-498c-457f-a9bd-5e48948ca2f3/user_impersonation',
  ],
  staging: ['api://553a52eb-c845-48aa-9dc5-65bee13b5ea2/user_impersonation'],
  production: ['api://42936e78-bd4b-4a9e-ab2c-2c8aa8db8e24/user_impersonation'],
};

export const scopes = envScopes[env];

export const loginRequest = {
  scopes: scopes,
};

// Development Azure AD config
const AUTH_CONFIG = {
  auth: {
    ...adEnvConfig[env],
    navigateToLoginRequestUrl: false,
  },
};

const CACHE_SYSTEM_CONFIG = {
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const config = {
  ...AUTH_CONFIG,
  ...CACHE_SYSTEM_CONFIG,
};

export default config;
