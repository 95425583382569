// @Flow

import React from 'react';
import styled from 'styled-components';

const SLoadingWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 200px;
  height: 50px;
  z-index: 90;
  background: #fff;
  border-radius: 4px;
  padding: 0 5px;
  display: flex;
  justify-content: flex-start;

  .loader {
    border: 5px solid #024b6d;
    border-radius: 50%;
    border-top: 5px solid #8eadbb;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin: 12px 0 12px 10px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SLoadingLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  color: #024b6d;
  margin-left: 10px;
`;

const getSingleLoadingMessage = (loading) => {
  switch (loading) {
    case 'isFetchingTrucks':
      return 'Loading Live Jobs'
    case 'isFetchingLiveJobs': 
      return 'Loading truck data';
    case 'isFetchingWastestream':
      return 'Loading lift data';
    case 'isFetchingActivityArea':
      return 'Loading historic data';
    default:
      return 'Loading data';
  }
};

const getLoadingMessage = (loadingStates) => {
  const loading = [];

  Object.keys(loadingStates).forEach((LoadingStatesKey) => {
    if (loadingStates[LoadingStatesKey]) {
      loading.push(LoadingStatesKey);
    }
  });

  return loading.length <= 1
    ? getSingleLoadingMessage(loading[0])
    : 'Loading data';
};

type Props = {
  isFetching: boolean,
  isFetchingTrucks: boolean,
  isFetchingWastestream: boolean,
  isFetchingActivityArea: boolean,
  isFetchingLiveJobs: boolean
};

const LoadingIndicator = ({ isFetching, isFetchingLiveJobs, isFetchingTrucks, isFetchingWastestream, isFetchingActivityArea }: Props) => (
  (
    isFetching ||
    isFetchingTrucks ||
    isFetchingWastestream ||
    isFetchingActivityArea ||
    isFetchingLiveJobs
  ) ? (
      // eslint-disable-next-line react/jsx-indent
      <SLoadingWrapper>
        <div className="loader"></div>
        <SLoadingLabel>
          {getLoadingMessage({
            isFetching,
            isFetchingTrucks,
            isFetchingLiveJobs,
            isFetchingWastestream,
            isFetchingActivityArea,
          })}
        </SLoadingLabel>
      </SLoadingWrapper>
    ) : null
);

export default LoadingIndicator;
