
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Moment from 'react-moment';
import { Button, Container, Menu, Header, Icon, Grid } from 'semantic-ui-react';
import diff from 'object-diff';
import EditUserForm from '../../components/EditUserForm';
import DeleteUserForm from './deleteUserForm';
import * as userActionCreators from '../../redux/modules/users';

class EditUserContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleResendInvite = this.handleResendInvite.bind(this);
    this.toggleSuspend = this.toggleSuspend.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.state = {
      modalOpen: false,
    };
  }

  // Get user from state not props to ensure updated (if available)
  getUser() {
    let user = this.props.location.state.user;
    Object.values(this.props.items).forEach((item) => {
      if (item.id === this.props.location.state.user.id) {
        user = {
          ...item,
        };
      }
    });
    return user;
  }

  props: {
    isUpdating: boolean,
    isDeleting: boolean,
    updateUser: Function,
    deleteUser: Function,
    resendInvite: Function,
    location: Object,
    items: Array,
    currentUserRole: string,
    currentUserAccountId: string,
    history: Object,
    isInviting: boolean,
    isDeleting: boolean,
  };

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  removeUser() {
    const user = this.getUser();
    this.closeModal();
    this.props.deleteUser(user, this.props.history.goBack);
  }

  handleUpdate(values) {
    const user = this.getUser();
    const { id, role, email, firstName, lastName, suspended, password } = values;
    const { ...delta } = diff({
      ...user,
    }, values);
    const data = {
      id,
      role,
      email,
      firstName,
      lastName,
      suspended,
      ...delta,
    };
    if (password) {
      data.password = password;
    }
    // If no changes to modules, send initial values
    if (!data.modules) {
      data.modules = values.modules;
    }
    const accountId = (this.props.currentUserRole === 'ADMIN' && this.props.location.state && this.props.location.state.item) ? this.props.location.state.item.account.id : this.props.currentUserAccountId;

    this.props.updateUser(data, accountId);
  }

  handleResendInvite() {
    const user = this.getUser();
    this.props.resendInvite(user.id);
  }

  toggleSuspend() {
    const user = this.getUser();
    const values = {
      id: user.id,
      suspended: !user.suspended,
    };
    this.handleUpdate(values);
  }

  render() {
    const Back = this.props.location.state.item !== undefined ? this.props.location.state.item.account.name : 'All Users';

    const initialValues = this.getUser();

    const modules = {};

    if (initialValues.modules && Array.isArray(initialValues.modules)) {
      initialValues.modules.forEach((m) => {
        modules[m.name] = true;
      });
      initialValues.modules = modules;
    }

    // Todo: move to common
    const createdTimestamp = initialValues.created.replace(/\+0$/, '+00:00');

    return (
      <div className="comp secondary-comp">
        <Helmet
          title="Edit User"
          meta={[
            { name: 'Edit User', content: 'Customer Portal' },
          ]}
        />
        <Menu secondary stackable>
          <span role="button" tabIndex={0} className="link item" onClick={this.props.history.goBack}>
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            {Back}
          </span>
        </Menu>
        <Container text>
          <Grid className="multi-header">
            <Grid.Row>
              <Grid.Column floated="left" mobile={16} tablet={6} computer={6}>
                <Header as="h1" floated="left">Edit User</Header>
              </Grid.Column>
              <Grid.Column floated="right" mobile={16} tablet={10} computer={10}>
                <Moment className="timestamp computer-only" format="DD/MM/YYYY h:mm a">{createdTimestamp}</Moment>
                <Button className="invite" loading={this.props.isInviting} onClick={this.handleResendInvite}>Resend Invite</Button>
                <Button className="remove" loading={this.props.isDeleting} onClick={this.openModal}>Delete User</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <EditUserForm
            onSubmit={this.handleUpdate}
            isUpdating={this.props.isUpdating}
            initialValues={initialValues}
            currentUserRole={this.props.currentUserRole}
            historyBack={this.props.history.goBack}
          />
          <DeleteUserForm
            deleteUser={this.removeUser}
            modalOpen={this.state.modalOpen}
            closeModal={this.closeModal}
            isDeletingUser={this.props.isDeleting}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isUpdating: state.users.isUpdating,
    isDeleting: state.users.isDeleting,
    deleteModal: state.users.deleteModal,
    currentUserRole: state.account.info.user.role,
    currentUserAccountId: state.account.info.account.id,
    isInviting: state.users.isInviting,
    items: state.users.items,
  }),
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(EditUserContainer);
