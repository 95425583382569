
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Container, Header, Menu, Icon } from 'semantic-ui-react';
import * as userActionCreators from '../../redux/modules/users';
import AddUserForm from '../../components/AddUserForm';

class AddUserContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  props: {
    isPosting: boolean,
    postUser: Function,
    currentUserRole: string,
    currentUserAccountId: string,
    location: Object,
    history: Object,
  };

  handleUpdate(values) {
    // Validated and enforced server side
    const accountId = (this.props.currentUserRole === 'ADMIN' && this.props.location.state && this.props.location.state.item) ? this.props.location.state.item.account.id : this.props.currentUserAccountId;
    this.props.postUser(values, accountId, this.props.currentUserAccountId);
  }

  render() {
    const Back = this.props.location.state ? this.props.location.state.item.account.name : 'All Users';

    return (
      <div className="comp secondary-comp">
        <Helmet
          title="Add User"
          meta={[
            { name: 'Add User', content: 'Customer Portal' },
          ]}
        />
        <Menu secondary stackable>
          <span role="button" tabIndex={0} className="link item" onClick={this.props.history.goBack}>
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            {Back}
          </span>
        </Menu>
        <Container text>
          <Header as="h1" className="form-header">Add User</Header>
          <AddUserForm
            onSubmit={this.handleUpdate}
            isPosting={this.props.isPosting}
            currentUserRole={this.props.currentUserRole}
            historyBack={this.props.history.goBack}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isPosting: state.users.isPosting,
    currentUserRole: state.account.info.user.role,
    currentUserAccountId: state.account.info.account.id,
  }),
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(AddUserContainer);
