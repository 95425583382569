// @flow

import React, { Component } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { getWasteStream } from '../../common/options';
import MapDataToggle from './MapDataToggle';
import TruckLiftsEventsList from './TruckLiftEventsList';
import { getEventColourFromWasteStream } from '../../common/wasteStreamColour';

import {
  STruckInfo,
  STruckName,
  STruckWrapper,
  STruckHeader,
  STruckInfoBaseWrapper,
  STruckInfoRow,
  STruckCreatedInfo,
  STruckSidebarWrapper,
  SHr,
  SIcon,
} from './index.sc';

const toUpperCase = (text) => {
  if (typeof text !== 'string') return text;
  return text.toUpperCase();
};

const renderMapDataToggles = (
  handleToggle,
  mapDataToggles,
  liftCount,
  isToday,
  activeDate,
) => (
  <STruckInfoBaseWrapper>
    {Object.keys(mapDataToggles).map((toggle) => (
      <MapDataToggle
        key={toggle}
        type={toggle}
        label={mapDataToggles[toggle].label}
        icon={mapDataToggles[toggle].icon}
        checked={mapDataToggles[toggle].show}
        shouldShowDynamicData={mapDataToggles[toggle].shouldShowDynamicData}
        liftCount={liftCount}
        isToday={isToday}
        onChange={handleToggle}
        activeDate={activeDate}
      />
    ))}
  </STruckInfoBaseWrapper>
);

type TruckSidebarProps = {
  wasteStream: string,
  imageUrl: string,
  id: string,
  registration: string,
  created: string,
  handleToggle: Function,
  mapDataToggles: Object,
  truckLifts: Array<Object>,
  truckLiftsLoading: boolean,
  alertMarkers: Array<Object>,
  setActiveEvent: Function,
  activeEvent: Object,
  handleThumb: Function,
  resetClickedTruck: Function,
  ui: Object,
  isToday: Boolean,
  activeDate: string | null,
};

type TruckSidebarState = {
  virtualListHeight: number,
};

class TruckSidebar extends Component<TruckSidebarProps, TruckSidebarState> {
  state: TruckSidebarState = {
    virtualListHeight: 0,
  };

  componentDidMount() {
    this.updateVirtualListHeight();
    window.addEventListener('resize', this.updateVirtualListHeight.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateVirtualListHeight.bind(this),
    );
  }

  updateVirtualListHeight() {
    const windowHeight = window.innerHeight;
    const element = document.getElementById('sidebar-truck-wrapper');
    const headerBar = document.getElementsByClassName('header-bar');
    const secMenu = document.getElementsByClassName('ui secondary');

    if (element && headerBar.length >= 1 && secMenu.length >= 1) {

      const truckWrapperHeight = element.offsetHeight || 0;
      const headerHeight = headerBar[0].offsetHeight || 0;
      const secondaryMenuHeight = secMenu[0].offsetHeight || 0;

      const virtualListHeight =  windowHeight - truckWrapperHeight - headerHeight - secondaryMenuHeight;
      this.setState({ virtualListHeight });
    }
  }

  render() {
    const {
      wasteStream,
      imageUrl,
      id,
      registration,
      created,
      handleToggle,
      mapDataToggles,
      truckLifts,
      alertMarkers,
      setActiveEvent,
      resetClickedTruck,
      activeEvent,
      handleThumb,
      truckLiftsLoading,
      ui,
      isToday,
      activeDate,
    } = this.props;

    const { virtualListHeight } = this.state;
    return (
      <STruckSidebarWrapper>
        <STruckWrapper
          id={'sidebar-truck-wrapper'}
          key={`${wasteStream}-${id}`}
          truckTypeColor={getEventColourFromWasteStream(wasteStream)}>
          <STruckHeader>
            <SIcon src={imageUrl} verticalAlign="middle" />
            <STruckName>
              {toUpperCase(getWasteStream(wasteStream).text)}
            </STruckName>
            <Icon
              className="icon-cancel"
              onClick={resetClickedTruck}
              style={{
                position: 'absolute',
                right: '5px',
                top: '12px',
                cursor: 'pointer',
              }}
            />
          </STruckHeader>
          <SHr />
          <STruckInfo>
            <STruckInfoRow>
              <span className={'key'}>TRUCK ID</span>
              <strong className={'value'}>#{toUpperCase(id)}</strong>
            </STruckInfoRow>
            <STruckInfoRow>
              <span className={'key'}>REGISTRATION</span>
              <strong className={'value'}>{toUpperCase(registration)}</strong>
            </STruckInfoRow>
          </STruckInfo>
          {renderMapDataToggles(
            handleToggle,
            mapDataToggles,
            truckLifts.length,
            isToday,
            activeDate,
          )}
          <STruckInfo>
            <STruckCreatedInfo>
              <strong className={'key'}>LIFT ACTIVITIES</strong>
              <span className={'value'}>Last updated {created}</span>
            </STruckCreatedInfo>
          </STruckInfo>
        </STruckWrapper>
        {truckLiftsLoading ? (
          <Loader active />
        ) : (
          <TruckLiftsEventsList
            truckLifts={truckLifts}
            alertMarkers={alertMarkers}
            setActiveEvent={setActiveEvent}
            activeEvent={activeEvent}
            handleThumb={handleThumb}
            virtualListHeight={virtualListHeight}
            ui={ui}
            isToday={isToday}
          />
        )}
      </STruckSidebarWrapper>
    );
  }
}

export default TruckSidebar;
