import React from 'react';
import styled from 'styled-components';

interface ArrowProps {
    style: string,
    previous: boolean
}

const SPrevNextArrow = styled.span`
position: absolute;
top: 15px;
${(props) => props.previous ? 'left' : 'right'}: 30px;
 svg {
     transform: ${(props) => props.previous ? 'rotate(180deg)' : ''};
     width: 12px;
     height: 18px;
 }
`;

export const Arrow = ({ style, previous }: ArrowProps) => (
  <SPrevNextArrow previous={previous} style={style}>
    <svg width="12px" height="18px" viewBox="0 0 12 18" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M0.897611788,17.6023882 C0.632535979,17.3373121 0.5,17.0203004 0.5,16.6513433 C0.5,16.2823864 0.632535979,15.9653744 0.897611788,15.7002986 L7.60328262,9.00537417 L0.897611788,2.29970278 C0.632535979,2.03462587 0.5,1.71761474 0.5,1.34865838 C0.5,0.979699277 0.632535979,0.662688147 0.897611788,0.397611237 C1.16268787,0.132537079 1.48149076,0 1.8540298,0 C2.22656884,0 2.54537173,0.132537079 2.81044781,0.397611237 L11.407463,9.00537417 L2.81044781,17.6023882 C2.53104319,17.867464 2.21224057,18 1.8540298,18 C1.49581902,18 1.17701641,17.867464 0.897611788,17.6023882 Z" id="Fill-1" fill="#759aaa"></path>
      </g>
    </svg>
  </SPrevNextArrow>
);

