
/* eslint-disable */

import Promise from 'bluebird';
import { assignIn, forEach } from 'lodash';

export const loadedScripts = new Map();
export const scriptTags = {};

/**
*
* @param scripts {Scripts} - An object with all the scripts required. Keys are script names, values are URLs.
*/
export function cache(scripts) {
  forEach(scripts, (script, name) => {
    assignIn(scriptTags, {
      [name]: {
        name,
        onLoad: onLoad.bind(null, name),
        script,
        tag: getScript(script, name),
      },
    });
  });
}

export function getScriptStub(name) {
  return scriptTags[name];
}

/**
 * Callback to be fired when each script has loaded.
 * @param name {string} - The name of the string that has just loaded.
 */
function onLoad(name, callback) {
  const stored = loadedScripts.get(name);
  if (stored.hasLoaded) {
    callback(null, stored);
  } else if (stored) {
    stored.promise.then(() => {
      stored.wasRejected ? callback(stored.error) : callback(null, stored);
    });
  }
}

/**
 * Get a script from a remote location.
 * @param name {string} - The name of the script to be retrieved.
 * @param url {string} - The URL/location of the script to be retrieved.
 */
function getScript(url, name) {
  if (!loadedScripts.has(name) && !document.querySelector(`script[src="${url}"]`)) {
    const tag = document.createElement('script');
    const promise = new Promise((resolve, reject) => {
      const body = document.getElementsByTagName('body')[0];
      // make sure the script type is javascript
      // and that scripts are loaded in order using
      // the "async" option
      assignIn(tag, {
        async: false,
        type: 'text/javascript',
      });
      function handleResult(event) {
        const stored = loadedScripts.get(name);
        if (event.type === 'load') {
          stored.hasLoaded = true;
          resolve(url);
        } else if (event.type === 'error') {
          stored.wasRejected = true;
          reject(event);
        }
      }
      // add load and error event listeners
      tag.addEventListener('load', handleResult);
      tag.addEventListener('error', handleResult);
      assignIn(tag, { src: url });
      body.appendChild(tag);
      return tag;
    });
    const scriptObject = {
      hasLoaded: false,
      wasRejected: false,
      promise,
      tag,
    };
    loadedScripts.set(name, scriptObject);
  }
  return loadedScripts.get(name);
}

export default cache;
