import React from 'react';
import HMapInstance from '../instance/live-map-instance';
import { LiveJobStatusButton } from './svgComponents';
import '../styles/map.css';

export default class LiveJobMap extends React.Component {
  constructor(props) {
    super(props);
  }

  setHMapState = (name, value) => {
    this.props.setHMapState({ [name]: value });
  };

  resizeMap = () => {
    if (this.props.map) {
      this.props.map.getViewPort().resize();
    }
  };
  
  render() {
    return (
      <HMapInstance
        {...this.props}
        map={this.props.map}
        setHMapState={this.setHMapState}
        resizeMap={this.resizeMap}
        setUIElement={this.props.setUIElement}>
        <LiveJobStatusButton onClickJobButton={this.props.onClickJobButton} />
      </HMapInstance>
    );
  }

  
}
