// @flow

/**
 * Takes a partial MenuCollection[] and renders it to list with checkboxes and creates a local
 * state to manage what checkBoxes should be ticked or not.
 *
 * When the onChange trigger on any of the Checkboxes the new internal state get published to
 * injected prop setActiveType.
 *
 * The toggle of checked is based on the prop activeType containing the value of the value in question.
 *
 *  If the checkbox for defaultValue triggers all other Options[] isActive get set to false.
 *
 */

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import {
  SMenu,
  SListIconWrapper,
  SMenuHeadline,
  SCheckboxWrapper,
} from '../sharedStyle.sc';
import RenderIcon from '../RenderIcon';

type OptionsInternalState = {
  value: string,
  isActive: boolean,
};
type State = {
  options: Array<OptionsInternalState>,
};

type Props = {
  name: string,
  options: Object,
  style: string,
  hideHeadline: Boolean,
  setFilters: Function,
};

const resetFilterState = (options) => {
  const resetOptionsState = options;
  Object.keys(options).forEach((optionKey) => {
    resetOptionsState[optionKey].isActive = optionKey === 'all';
  });
  return resetOptionsState;
};

class MultiSelectNoNull extends React.Component<Props, State> {
  handleToggleCheckbox = (key) => {
    const { options } = this.props;

    if (key === 'all' && options[key].isActive === false) {
      return this.props.setFilters(resetFilterState(options));
    }
    // Disable 'All' if any options are selected
    if (options[key].value !== 'all') {
      options.all.isActive = false;
    }

    // Toggle the selected option
    options[key].isActive = !options[key].isActive;

    // If no options are selected, activate All
    const active = Object.keys(options).filter(
      (optionKey) => !!options[optionKey].isActive,
    );
    active.length ? null : (options.all.isActive = true);
    this.props.setFilters(options);
  };

  render() {
    const { style, name, options, hideHeadline, containerStyle } = this.props;

    return (
      <SMenu style={style}>
        {!hideHeadline && <SMenuHeadline>{name}</SMenuHeadline>}
        <div
          style={containerStyle}>
          {Object.keys(options).map((key) => (
            <div key={options[key].value}>
              <SListIconWrapper className={name.toLocaleLowerCase()}>
                <RenderIcon
                  option={options[key]}
                  name={name.toLocaleLowerCase()}
                />
              </SListIconWrapper>
              <SCheckboxWrapper>
                <Checkbox
                  checked={options[key].isActive}
                  disabled={options[key].isDisabled}
                  onChange={() => {
                    this.handleToggleCheckbox(key);
                  }}
                  label={options[key].text}
                />
              </SCheckboxWrapper>
            </div>
          ))}
        </div>
      </SMenu>
    );
  }
}

export default MultiSelectNoNull;
