import styled from 'styled-components';

export const SBinEventWrapper = styled.div`
  display: flex;
  background: #fff;
  color: #004b6d;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.09);
  border-bottom: 4px solid ${(props) => props.alertTypeColour};
`;

export const SReasonsHeadline = styled.h5`
  margin: 5px 0;
  color: ${(props) => props.alertTypeColour};
`;

export const SBinEventCol = styled.div`
  padding: 10px;
  position: relative;
`;

export const SAlertIcon = styled.img`
  width: 20px;
`;

export const STruckLink = styled.a`
  float: left;
  line-height: 2.1;
  font-size: 12px;
  cursor: pointer;
`;

export const STruckIcon = styled.img`
  float: left;
  width: 12px;
  transform: rotate(45deg);
  margin: 0 15px 0 0;
`;

export const SSecondReason = styled.p`
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`;

export const SCBinEventImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:after {
    content: '\\E806';
    font-family: "fontello";
    position: absolute;
    bottom: 8px;
    right: 5px;
    color: #FFFFFF;
  }
`;

export const SBinEventImageThumbNail = styled.img`
  width: 80px;
  height: 67px;
  position: relative;
`;
