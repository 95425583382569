// @flow

// import { AppContainer } from 'react-hot-loader';
import React from 'react';
import 'rxjs';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { store } from './redux/configureStore';
import App from './containers/App/';

// If Google maps isn't loaded, replace it with an empty function to stop any errors.
if (!window.ga) {
  window.ga = function () {};
}

const history = createBrowserHistory();
history.listen(() => {
  const page = window.location.pathname + window.location.search;
  window.ga('send', 'pageview', page);
});

const Main = () => (
  // <AppContainer>
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
  // </AppContainer>
);

export default Main;
