
// @flow

import React from 'react';
import Button from './Button';
import Box from './Box';
import Inner from './Inner';

export default function Hamburger(props: { isActive: boolean }) {
  return (
    <Button>
      <Box>
        <Inner className={props.isActive ? 'is-active' : null} />
      </Box>
    </Button>
  );
}
