// @flow

import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mapActionCreators from '../../../../redux/modules/map';

import { SLiftIcon } from '../../../TruckSidebar/TruckLiftEventsList/TruckLiftEvent/index.sc';
import { getEventColourFromWasteStream } from '../../../../common/wasteStreamColour';
import HMapMethods from '../../../HereMap/instance/h-map-methods';
import { IconTruck } from '../../../Icons/Truck';
import LiftsFilter from './Filter/LiftsFilter';

const STabContentLift = styled.div`
  display: flex;
  padding: 1.5em;
  border-bottom: 1px solid rgba(215, 215, 215, 0.77);
  opacity: ${(props) => (props.isFocused ? '1' : '.2')};
  .icon-wrapper {
    flex: 1;
    position: relative;
  }
  .content-wrapper {
    flex: 5;
    display: flex;
    flex-direction: column;
    .title {
      text-transform: uppercase;
      font-size: 0.75em;
      font-weight: bold;
    }
  }
  .distance-wrapper {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    .distance {
      font-size: 11px;
      font-weight: bold;
      color: rgba(8, 36, 65, 0.5);
    }
  }

  .truck-details {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 13px;
    color: rgba(8, 36, 65, 0.5);
    text-decoration: underline;
    .waste-stream {
      cursor: pointer;
      margin-left: 0.5em;
    }
  }
`;
const SELECTING_TRUCK_SHOULD_NOT_REMOVE_ADDRESS_SIDEBAR = false;

const getDistanceFromSearch = (lift, addressInfo) =>
  Math.round(
    new HMapMethods().alertDistanceFromSearchResult(
      { lat: lift.position[0], lng: lift.position[1] },
      {
        lat: addressInfo.position.lat,
        lng: addressInfo.position.lng,
      },
    ),
  );

const isFocused = (activeEvent, uuid) => {
  if (activeEvent.uuid === null) return true;
  return activeEvent.uuid === uuid;
};

const getTruckLiftsSortedByDistanceFromSearch = (truckLifts, addressInfo) =>
  truckLifts
    .map((lift) => ({
      ...lift,
      ...{ distance: getDistanceFromSearch(lift, addressInfo) },
    }))
    .sort((a, b) => (a.distance < b.distance ? -1 : 1));

type ConnectedTabContentListLiftsProps = {
  truckLifts: Array<Object>,
  dataRadius: Number,
  setClickedTruck: Function,
  map: Object,
  setLiftsFilter: Function,
  addressInfo: Object,
  setActiveEvent: Function,
  activeEvent: Object,
};
type ConnectedTabContentListLiftsState = {
  virtualListHeight: number,
};

class ConnectedTabContentListLifts extends Component<
  ConnectedTabContentListLiftsProps,
  ConnectedTabContentListLiftsState,
> {
  state: ConnectedTabContentListLiftsState = {
    virtualListHeight: 0,
  };

  componentDidMount() {
    this.updateVirtualListHeight();
    window.addEventListener('resize', this.updateVirtualListHeight.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateVirtualListHeight.bind(this),
    );
  }

  updateVirtualListHeight() {
    if (this.refs.listRef) {
      const windowHeight = window.innerHeight;
      const wrapper = document.getElementById('address-sidebar-wrapper');

      if (wrapper) {
        const addressSidebarHeight = wrapper.offsetHeight || 0;
        const headerHeight =
          document.getElementsByClassName('header-bar')[0].offsetHeight || 0;
        const secondaryMenuHeight =
          document.getElementsByClassName('ui secondary')[0].offsetHeight || 0;

        const virtualListHeight =
          windowHeight -
          addressSidebarHeight -
          headerHeight -
          secondaryMenuHeight;
        this.setState({ virtualListHeight });
      }
    }
  }

  render() {
    const {
      truckLifts,
      dataRadius,
      setClickedTruck,
      addressInfo,
      map,
      setLiftsFilter,
      setActiveEvent,
      activeEvent,
    } = this.props;
    return (
      <div className={'active-lifts'} ref="listRef">
        <LiftsFilter dataRadius={dataRadius} setLiftsFilter={setLiftsFilter} />
        <div
          style={{
            height: `${this.state.virtualListHeight}px`,
            overflowY: 'auto',
          }}>
          {getTruckLiftsSortedByDistanceFromSearch(truckLifts, addressInfo).map(
            (lift, index) =>
              (map.liftsFilter.includes('all') ||
                map.liftsFilter.includes(lift.wasteStream)) && (
                <STabContentLift
                  key={`${lift.uuid}-${index}`}
                  onClick={() => {
                    setClickedTruck(
                      lift.truckId,
                      SELECTING_TRUCK_SHOULD_NOT_REMOVE_ADDRESS_SIDEBAR,
                    );
                    setActiveEvent(lift.uuid, 'binLift', lift);
                  }}
                  isFocused={isFocused(activeEvent, lift.uuid)}>
                  <div className="icon-wrapper">
                    <SLiftIcon
                      unsetPosition
                      themeColor={getEventColourFromWasteStream(
                        lift.wasteStream,
                      )}
                    />
                  </div>
                  <div className="content-wrapper">
                    <span className="title">Lift Recorded</span>
                    <span className="date">
                      {moment(lift.created).parseZone().format('h:mm a MMM Do')}
                    </span>
                    <div className="truck-details">
                      <IconTruck
                        wasteStream={lift.wasteStream}
                        size="20px"
                        rotate="45"
                        shadow
                      />
                      <span className="waste-stream">
                        {lift.wasteStream} #{lift.truckId}
                      </span>
                    </div>
                  </div>
                  <div className="distance-wrapper">
                    <span className="distance">{lift.distance}m away</span>
                  </div>
                </STabContentLift>
              ),
          )}
        </div>
      </div>
    );
  }
}

const TabContentListLifts = connect(
  (state) => ({
    map: state.map,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...mapActionCreators,
      },
      dispatch,
    ),
)(ConnectedTabContentListLifts);

export default TabContentListLifts;
