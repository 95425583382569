// @Flow

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

const SToggleButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  .ui.fitted.toggle.checkbox,
  .ui.toggle.checkbox input {
    width: 34px;
  }

  .ui.toggle.checkbox {
    .box:after,
    label:after {
      width: 14px;
      height: 14px;
      margin-top: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
    }
    input ~ .box:after,
    input ~ label:after {
      left: 0.25rem;
    }

    input:checked ~ .box:after,
    input:checked ~ label:after {
      left: 15px;
      background: #fff !important;
      border-radius: 500rem !important;
      width: 14px !important;
      height: 14px !important;
      margin-top: 3px !important;
    }

    .box:before,
    label:before {
      width: 34px;
    }
  }
`;

type Props = {
  onChange: Function,
  checked: boolean,
  id: string,
  disabled?: boolean,
};

const ToggleButton = ({
  onChange,
  checked,
  id,
  disabled = false,
}: Props): React.Element => (
  <SToggleButtonWrapper>
    <Checkbox
      toggle
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      id={id}
    />
  </SToggleButtonWrapper>
);

export default ToggleButton;
