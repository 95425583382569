import styled from 'styled-components';
import mediaQueryBreakPoints from '../../common/mediaQueryBreakPoints';

export const SSingleDateWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 230px;
  margin: 0 1.25em;
  height: 44px;

  .DayPicker,
  .DayPicker__horizontal,
  .DayPicker_weekHeader__verticalScrollable,
  .DayPickerKeyboardShortcuts_panel,
  .DayPickerNavigation_container__vertical,
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay__default,
  .CalendarDay__outside,
  .CalendarDay__blocked_minimum_nights,
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover,
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover,
  .DateInput,
  .DateInput_input__focused {
    background: #f4f4f4;
  }

  .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 6px;
  }

  .DayPickerNavigation {
    &_button {
      &__default,
      &__default:hover,
      &__default:active {
        margin: 11px 0 0 0;
        border: 1px solid transparent;
      }
    }
  }

  .CalendarMonth {
    padding-bottom: 1em !important;
    &_caption {
      color: #648797;
      font-size: 14px;
      line-height: 2;
      padding-top: 10px;
      padding-bottom: 0.5em;
      margin-bottom: 2.875em;
    }
  }

  .DayPicker {
    &_weekHeader {
      top: 60px;
      &_ul {
        color: #799aaa;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: rgba(125, 175, 213, 0.2);
      width: 100%;
      bottom: 31px;
    }
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: rgba(125, 175, 213, 0.2);
      width: 100%;
      top: 45px;
      z-index: 999;
    }
  }

  .CalendarDay {
    width: 24px !important;
    height: 20px !important;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 !important;
    color: #004b6d;
    &:focus {
      outline: none;
    }
    &__default,
    &__default:hover {
      border: 1px solid transparent;
    }

    &__blocked_out_of_range,
    &__blocked_out_of_range:hover {
      color: #ff6956;
      border: 1px solid transparent;
      cursor: not-allowed;
    }

    &__selected {
      background: #289fd6;
      border: 1px solid transparent;
      color: #fff;

      &:active {
        background: #0095e3;
        border: 1px solid transparent;
      }

      &:hover {
        background: #0095e3;
        border: 1px solid transparent;
      }
    }
  }

  .SingleDatePicker_picker {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  }

  .SingleDatePickerInput {
    background: #004b6d;
    border-radius: 5px;
    min-width: 230px;
    height: 44px;

    &_calendarIcon {
      float: left;
      margin: 0 5px;
      height: 100%;
      &_svg {
        fill: #fff;
      }
      img {
        width: 18px;
      }
      &:focus {
        outline: none;
      }
    }

    .DateInput {
      background: transparent;
      display: flex;
      align-items: center;
      height: 44px;
      position: absolute;
      width: 100%;
      z-index: 9999;
      &_fang {
        left: 30px;
        margin-top: 5px;
      }
      &_fangStroke {
        fill: #f4f4f4;
        stroke: #f4f4f4;
      }
      &_input {
        width: 100%;
        color: #fff;
        padding-left: 100px;
        background: transparent;
        font-family: 'Lato';
        cursor: pointer;
        &__focused {
          border-bottom: 2px solid transparent;
          background: transparent;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }

  .picked-today__true {
    height: 44px;
    .SingleDatePicker {
      .DateInput {
        &_input {
        }
      }
    }
  }

  @media (max-width: ${mediaQueryBreakPoints.tabletBreakpoint}) {
    margin: 0;
  }
`;

export const STodayIndicator = styled.span`
  position: absolute;
  color: #fff;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  left: 48px;
  top: -1px;
  font-weight: bold;
`;

export const SIconWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0.75em;
  color: white;
  font-size: 1.4em;
  height: 44px;
  display: flex;
  align-items: center;
  .icon {
    font-weight: bold;
  }
`;

export const STodayLink = styled.a`
  visibility: ${(props) => (props.display ? 'visible' : 'hidden')};
  background-color: #f4f4f4 !important;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5em 0.5em 1.5em;
  font-size: 12px;
  font-weight: bold;
  margin-top: -12px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .today {
    color: #39424d;
  }

  .date {
    color: #759aaa;
  }
`;
