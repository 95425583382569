// @flow

import React from 'react';
import moment from 'moment';
import { BinEvents } from '../../index.d';
import API_URL from '../../../../common/api';
import * as options from '../../../../common/options';
import {
  SBinEventWrapper,
  SBinEventCol,
  SBinEventImageThumbNail,
  SAlertIcon,
  SReasonsHeadline,
  SSecondReason,
  STruckLink,
  STruckIcon,
  SCBinEventImageWrapper,
} from './index.sc';

const getTruckForWasteStream = (WasteStream: string): string =>
  options.truckImages[WasteStream];

const getAlterIcon = (reason: string): string => options.alertIcons[reason];

const getAlertColour = (reason: string): string => options.alertColour[reason];

const getImageSrc = (id, imageResources): string =>
  `${API_URL}/event/${id}/image/${encodeURIComponent(
    imageResources[0],
  )}?size=tiny`;

const formatCreatedDate = (date: string): string =>
  moment(date).parseZone().format('hh:mm A  DD/MM/YYYY');

type Props = {
  BinEventsList: Array<BinEvents>,
  setClickedTruck: Function,
  handleThumb: Function,
};

const TabBinEventsList = ({
  BinEventsList,
  setClickedTruck,
  handleThumb,
}: Props): React.Element => (
  <div>
    {BinEventsList.map(
      (
        {
          reason,
          secondaryReason,
          created,
          wasteStream,
          truckId,
          id,
          $id,
          imageResources,
        },
        index,
      ) => (
        <SBinEventWrapper
          alertTypeColour={getAlertColour(reason)}
          key={`${wasteStream}-${$id}-${index}`}>
          <SBinEventCol>
            <SCBinEventImageWrapper
              onClick={() => handleThumb(imageResources, created, id)}>
              <SBinEventImageThumbNail
                src={getImageSrc(id, imageResources)}
                alt={'bin event'}
              />
            </SCBinEventImageWrapper>
          </SBinEventCol>

          <SBinEventCol>
            <SAlertIcon src={getAlterIcon(reason)} alt={`${reason} alert`} />
            <SReasonsHeadline alertTypeColour={getAlertColour(reason)}>
              {reason}
            </SReasonsHeadline>
            <SSecondReason>Not displayed{secondaryReason}</SSecondReason>
            <p>{formatCreatedDate(created)}</p>

            <STruckLink onClick={() => setClickedTruck(truckId)}>
              <STruckIcon src={getTruckForWasteStream(wasteStream)} />
              {wasteStream} - #{truckId}
            </STruckLink>
          </SBinEventCol>
        </SBinEventWrapper>
      ),
    )}
  </div>
);
export default TabBinEventsList;
