
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import { Table, Icon, Divider, Loader } from 'semantic-ui-react';
import AddUser from '../../containers/AddUser';
import EditUser from '../../containers/EditUser';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';
import * as userActionCreators from '../../redux/modules/users';

class UserManagementContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
  }

  componentDidMount() {
    if (!this.props.items.length) {
      this.props.requestUsers();
    }
  }

  props: {
    match: Object,
    history: Object,
    isFetching: boolean,
    items: Array,
    requestUsers: Function,
  };

  edit(user) {
    this.props.history.push(`/user-management/${user.id}/edit`, { user });
  }

  render() {
    const { match, items } = this.props;

    const userItems = items.map((user) =>
      (<Table.Row key={user.id} onClick={() => this.edit(user)}>
        <Table.Cell className="first-name">{user.firstName}</Table.Cell>
        <Table.Cell className="last-name">{user.lastName}</Table.Cell>
        <Table.Cell className="email">{user.email}</Table.Cell>
        <Table.Cell className="type">{user.role}</Table.Cell>
        <Table.Cell className="status">
          {user.suspended ? 'Suspended' : 'Active'}
          <Icon className="icon-right" style={{ float: 'right' }} />
        </Table.Cell>
      </Table.Row>)
    );

    const table = (
      <div>
        <Table basic padded celled size="large" selectable className="user-management standard">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{userItems}</Table.Body>
        </Table>
        <Divider />
      </div>
    );

    return (
      <div>
        <Route path={`${match.url}/add-user`} component={AddUser} />
        <Route path={`${match.url}/:id/edit`} component={EditUser} />
        <Route
          exact
          path={match.url}
          render={() => (
            <div className="comp">
              <Helmet
                title="User Management"
                meta={[
                  { name: 'User Management', content: 'Customer Portal' },
                ]}
              />
              <FluidContainer fluid>
                <PageHeader
                  title="User Management"
                  action={
                    <Link
                      to={`${match.url}/add-user`}
                      className="ui green massive button right floated"
                    >
                      <Icon name="plus" />Add User
                    </Link>
                  }
                />
                {this.props.items.length
                  ? table
                  : <Loader active={this.props.isFetching} size="massive" inline="centered" style={{ marginTop: '50px' }} />
                }

                {!this.props.items.length && !this.props.isFetching
                  ? <p style={{ color: '#424852', textAlign: 'center', fontSize: '16px' }}>No results</p>
                  : null
                }
              </FluidContainer>
            </div>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    items: state.users.items,
    isFetching: state.users.isFetching,
  }),
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(UserManagementContainer);
