
// @flow

import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

type Props = {
  placeholder?: string,
  options: Object,
  className?: string,
  input: Object,
  defaultValue?: any,
  label?: string,
  icon?: string,
  meta: Object,
  inline?: boolean,
  search?: boolean,
  selection?: boolean,
  multiple?: boolean,
};

CustomDropdown.defaultProps = {
  placeholder: null,
  className: null,
  label: null,
  icon: null,
  search: null,
  selection: null,
  defaultValue: null,
  inline: false,
  multiple: false,
};

export default function CustomDropdown({ placeholder, search, multiple, selection, defaultValue, options, className, input, label, icon, inline, meta: { touched, error } }: Props) {
  const dirty = touched && error ? true : false; // eslint-disable-line no-unneeded-ternary

  return (
    <Form.Field error={dirty} className={className}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <Dropdown
        // {...input}
        placeholder={placeholder}
        options={options}
        search={search}
        selection={selection}
        multiple={multiple}
        value={defaultValue || input.value}
        onChange={(event, data) => input.onChange(data.value)}
        name={name}
        icon={icon}
        defaultValue={defaultValue}
        inline={inline}
      />
    </Form.Field>
  );
}
