
// @flow

import React from 'react';
import './styles/sort.css';

type Props = {
  action: Function,
  sortBy: string,
  sortDirection: string,
  selected: string,
  label: string,
};

const ascendingSVG = (
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" className="sort-ascending">
    <path d="M4.5 0L0 4.67245H9L4.5 0Z" fill="#004B6D"/>
  </svg>
);

const descendingSVG = (
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 4.67285L9 0.000400543L4.08479e-07 0.000399756L4.5 4.67285Z" fill="#004B6D"/>
  </svg>
);

const defaultSVG = (
  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0L0 4.67245H9L4.5 0ZM4.5 12L9 7.34056H0L4.5 12Z" fill="#004B6D"/>
  </svg>
);


export default class SortButton extends React.Component {
  constructor(props : Props) {
    super(props);
    this.state = {
      action: props.action,
      sortBy: props.sortBy,
      sortDirection: props.sortDirection,
      selected: props.selected,
      label: props.label,
    };
  }

  /**
   * Determine the direction to apply
   * In some cases, there are several sort buttons in the same page, therefore we need to check whether the selected "sortBy" matches this button
   * Otherwise it means the sorting applies to another button
   * @returns 
   */
  determineDirection() {
    if(this.props.selected == this.props.sortBy) {
      return this.props.sortDirection;
    }
    return null;
  }

  click = () => {
    let newDirection = (this.determineDirection() == 'ascending') ? 'descending' : 'ascending';
    this.props.action(this.props.sortBy, newDirection);
  };


  render = () => {
    let svg = defaultSVG;

    switch(this.determineDirection()) {
      case 'ascending':
        svg = ascendingSVG;
        break;
      case 'descending':
        svg = descendingSVG;
        break;
    }
    
    return ( <span className="sort-button" onClick={this.click}>{this.state.label} <span className="sort-icon">{svg}</span></span> );
  }
}
