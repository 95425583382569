// @flow

import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Header,
  Menu,
  Container,
  Icon,
  Grid,
  Label,
  Segment,
  Divider,
} from 'semantic-ui-react';

type Props = {
  location: Object,
};

export default function Ticket({ location }: Props) {
  const {
    id,
    address,
    primaryReason,
    workOrderType,
    workOrderTypeWasteStream,
    councilReferenceNumber,
    scheduledStartDate,
    actualEndDate,
    status,
    modified,
  } = location.state.ticket;

  const modifiedTimestamp = modified.replace(/\+0$/, '+00:00');
  const fixDate = (string) => {
    if (!string) {
      return '';
    }
    return moment(string).parseZone().format('DD/MM/YYYY h:mm a');
  };

  const scheduledDate = moment(scheduledStartDate, 'YYYY-MM-DD');
  const completedDate = moment(actualEndDate, 'YYYY-MM-DD');
  const emptyDate = moment('0001-01-01', 'YYYY-MM-DD');

  return (
    <div>
      <Helmet
        title="Ticket"
        meta={[{ name: 'Ticket', content: 'Cleanaview' }]}
      />
      <Menu secondary stackable>
        <Link to="/tickets" className="link item">
          <Icon className="icon-left" style={{ marginRight: '15px' }} />
          All Tickets
        </Link>
      </Menu>
      <Container text className="ticket-view">
        <Grid className="multi-header">
          <Grid.Row>
            <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
              <Header as="h1">
                Ticket <span>#{id}</span>
              </Header>
              <span className="time-stamp computer-only">
                {fixDate(modifiedTimestamp)}
              </span>
              {/* TODO: AEST is a temporary solution */}
            </Grid.Column>
            <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
              <Label className="status">
                <Label
                  color="{status == 'Assigned' ? 'green' : 'grey}"
                  circular
                  empty
                />{' '}
                {status}
              </Label>
              <span
                className="time-stamp mobile-only"
                style={{ float: 'right' }}>
                {fixDate(modifiedTimestamp)}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="snug-left snug-right">
              <Segment secondary padded="very" className="gray-seg">
                <h3>Address</h3>
                <p>{`${address.streetNumber} ${address.streetName}, ${address.suburb}, ${address.postCode}`}</p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="snug-left">
              <Segment secondary padded="very" className="gray-seg">
                <h3>Ticket Type</h3>
                <p>{workOrderType}</p>
              </Segment>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="snug-right">
              <Segment secondary padded="very" className="gray-seg">
                <h3>Ticket Type Service</h3>
                <p>{workOrderTypeWasteStream}</p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="snug-right">
              <Segment secondary padded="very" className="gray-seg">
                <h3>Scheduled Date</h3>
                <p>
                  {scheduledStartDate && !scheduledDate.isSame(emptyDate)
                    ? fixDate(scheduledStartDate)
                    : 'Not Assigned'}
                </p>
                <h3>Completed Date</h3>
                <p>
                  {status == 'Completed' && !completedDate.isSame(emptyDate)
                    ? fixDate(actualEndDate)
                    : 'Not Completed'}
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="snug-left">
              <Segment secondary padded="very" className="gray-seg">
                <h3>Council Reference Number</h3>
                <p>
                  {!councilReferenceNumber ||
                  councilReferenceNumber.trim() == ''
                    ? 'Not Available'
                    : councilReferenceNumber}
                </p>
                <h3>Completed Reason Code</h3>
                <p>
                  {primaryReason ||
                    (status == 'Completed' ? 'None' : 'Not Completed')}
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider className="make-flat" style={{ marginBottom: '0' }} />
        <p className="foot-contact make-flat">
          For any changes please call Cleanaway 13 13 39
        </p>
      </Container>
    </div>
  );
}
