// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Menu,
  Icon,
  Table,
  Loader,
} from 'semantic-ui-react';
import AddSubscriptionForm from './AddSubscriptionForm';
import AddReceiverForm from './AddReceiverForm';
import * as reportActionCreators from '../../redux/modules/reports';

class ManageSubscriptionsContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.removeSubscription = this.removeSubscription.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReportFrequencyChange =
      this.handleReportFrequencyChange.bind(this);
  }

  componentWillMount() {
    const payload = {
      reportSchedule: '',
      receivers: [],
    };
    this.props.updateEditedRecievers(payload, this.props.territoryId);
  }

  componentDidMount() {
    if (!this.props.reports.items.length) {
      this.props.requestReports(this.props.territoryId);
    }
  }

  componentWillReceiveProps(nextProps) {
    // only load first time if the edited or reportSchedule prop is empty
    if (
      nextProps.post &&
      (!this.props.edited || !this.props.edited.reportSchedule)
    ) {
      const payload = {
        reportSchedule: nextProps.post.timeSegment,
        receivers: nextProps.post.receivers,
      };
      this.props.updateEditedRecievers(payload, this.props.territoryId);
    }
  }
  /**
   * When the user changes the frequency store the changed value
   */

  handleReportFrequencyChange(value) {
    const payload = {
      reportSchedule: value,
      receivers: this.props.edited.receivers,
    };
    this.props.updateEditedRecievers(payload, this.props.territoryId);
  }

  /**
  Add all updated subscriber setting
  */
  handleSubmit() {
    const payload = {
      ...this.props.post,
      timeSegment: this.props.edited.reportSchedule,
      receivers: this.props.edited.receivers,
    };
    this.props.postingSubscriber(payload, this.props.territoryId);
  }

  /**
   * Add a subscriber to this report, (not persited)
   *
   * @param {object} values, { email } destructured
   * @return {void} fires the 'updating edit' epic
   */
  handleUpdate({ email }) {
    const payload = {
      reportSchedule: this.props.userSetReportSchedule,
      receivers: this.props.edited.receivers.concat(email),
    };

    this.props.updateEditedRecievers(payload, this.props.territoryId);
  }

  /**
   * Remove a subscriber from this report
   *
   * @param {string} email
   * @return {void} fires the 'deleting subscriber' epic
   */
  removeSubscription(email) {
    const receivers = [...this.props.edited.receivers];
    receivers.splice(receivers.indexOf(email), 1);
    const payload = {
      reportSchedule: this.props.userSetReportSchedule,
      receivers,
    };
    this.props.updateEditedRecievers(payload, this.props.territoryId);
  }

  props: {
    reports: Object,
    post: Object,
    userSetReportSchedule: String,
    editedReceivers: Array,
    edited: Object,
    postingSubscriber: Function,
    requestReports: Function,
    updateEditedRecievers: Function,
  };

  render() {
    const { post, reports, userSetReportSchedule, editedReceivers } =
      this.props;

    /* eslint-disable */
    let reportName = '';

    let subscriptions = (
      <Table.Row>
        <Table.Cell width={16}>
          <Loader active={this.props.reports.fetchingReports} size="massive" inline="centered" />
        </Table.Cell>
      </Table.Row>
    );

    let manageSubscriptionsContainer = (
      <Container className="manage-subscriptions">
        <Loader active={this.props.reports.fetchingReports} size="massive" inline="centered" />
      </Container>
    );
    if (post && post.receivers) {
      reportName = post.reportName;
      let table = null;
      if (editedReceivers.length) {
        subscriptions = editedReceivers.map((email, i) => (
          <Table.Row key={i}>
            <Table.Cell className="email" width={12}>
              {email}
            </Table.Cell>
            <Table.Cell className="remove" width={4}>
              <a
                className="remove"
                role="button"
                loading={reports.deletingSubscriber}
                onClick={() => this.removeSubscription(email)}>
                Remove
              </a>
            </Table.Cell>
          </Table.Row>
        ));

        table = (
          <Container text>
            <Table
              basic
              padded
              celled
              unstackable
              className="standard subscriptions">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Email Address</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{subscriptions}</Table.Body>
            </Table>
          </Container>
        );
      }

      manageSubscriptionsContainer = (
        <div className="manage-subscriptions">
          <Container text>
            <Header as="h1">
              Manage Subscriptions
              <Header.Subheader>{reportName}</Header.Subheader>
            </Header>
          </Container>

          <AddReceiverForm
            initialValues={{ reportSchedule: post.timeSegment }}
            handleUpdate={this.handleUpdate}
            updatingReport={reports.postingSubscriber}
            handleReportFrequencyChange={this.handleReportFrequencyChange}
            post={post}
          />
          {table}
          <AddSubscriptionForm
            initialValues={{
              reportFrequency: userSetReportSchedule
                ? userSetReportSchedule
                : post.timeSegment,
            }}
            onSubmit={this.handleSubmit}
            updatingReport={reports.postingSubscriber}
          />
        </div>
      );
    }
    /* eslint-enable */

    return (
      <div>
        <Helmet
          title="Manage Subscriptions"
          meta={[{ name: 'Manage Subscriptions', content: 'Customer Portal' }]}
        />
        <Menu secondary stackable>
          <Link to="/reports" className="link item">
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            All Reports
          </Link>
        </Menu>
        {manageSubscriptionsContainer}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const selector = formValueSelector('addReceiver');
    const id = ownProps.match.params.id;
    // find current post from redux by param id
    // const postObj = state.reports.items.find((r) => r.id === parseInt(id, 10));
    const postObj = state.reports.items.find((r) => r.id === id);
    return {
      id,
      post: postObj,
      reports: state.reports,
      userSetReportSchedule: selector(state, 'reportSchedule'),
      edited: state.reports.edited,
      editedReceivers: state.reports.edited.receivers,
      territoryId: state.councils.active.territoryId,
    };
  },
  (dispatch) => bindActionCreators(reportActionCreators, dispatch),
)(ManageSubscriptionsContainer);
