import { getTruckColor } from '../../../common/wasteStreamColour';
// Returns an instance of HERE maps from the window to this file.
const getHInstance = () => {
  if (window.H) return window.H;
  console.error("Couldn't find HERE Maps instance");
  return false;
};

export const mapStyle = () => {
  const H = getHInstance();

  H.map.SpatialStyle({
    strokeColor: '#05A',
    fillColor: 'rgba(55, 85, 170, 0.1)',
    lineWidth: 3,
    lineCap: 'round',
    lineJoin: 'miter',
    miterLimit: 10,
    lineDash: [],
    lineDashOffset: 0,
  });
};

const getStrokeStyle = (shouldDrawThickLine: boolean) =>
  shouldDrawThickLine
    ? {
        lineWidth: 8,
        lineDash: [],
        lineDashOffset: 0,
      }
    : {
        lineWidth: 3,
        lineCap: 'butt',
        lineJoin: 'miter',
        lineDash: [10, 10],
        lineDashOffset: 25,
      };

const getArrowStyle = (shouldDrawThickLine) => ({
  fillColor: '#666666',
  frequency: 10,
  width: shouldDrawThickLine ? 4 : 8,
  length: 6,
});

export const truckPathStyle = (
  truckColourKey,
  shouldDrawThickLine,
  hexColor = '',
) => ({
  style: {
    ...{
      strokeColor: hexColor !== '' ? hexColor : getTruckColor(truckColourKey),
    },
    ...getStrokeStyle(shouldDrawThickLine),
  },
  arrows: getArrowStyle(shouldDrawThickLine),
});

export const arrowsStyle = (shouldDrawLightArrows) => ({
  style: {
    lineWidth: 10,
    strokeColor: shouldDrawLightArrows ? "#ffffff" : "#444444",
    lineDash: [0, 4],
    lineDashOffset: 1,
    lineTailCap: "butt",
    lineHeadCap: "arrow-head",
  },
});

export const circleStyle = {
  style: {
    strokeColor: '#0095e3', // Color of the perimeter
    lineWidth: 1,
    fillColor: 'rgba(0, 149, 227, 0.08)', // Color of the circle
  },
};
