// @flow

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import VirtualList from 'react-tiny-virtual-list';
import { getEventColourFromWasteStream } from '../../../common/wasteStreamColour';
import TruckLiftsEvent from './TruckLiftEvent';

import {
  STruckLiftEvent,
  SIconWrapper,
  SLiftEventConnector,
  SLiftEventInfoWrapper,
  SLiftCreatedHeader,
} from './TruckLiftEvent/index.sc';

const SLiftEventsWrapper = styled.div`
  height: ${(props) => props.height}px;
`;

type Props = {
  alertMarkers: Array<Object>,
  truckLifts: Array<Object>,
  setActiveEvent: Function,
  activeEvent: Object,
  handleThumb: Function,
  virtualListHeight: number,
  ui: Object,
  isToday: Boolean,
};

const mergeLiftsAndAlertsByDate = (lifts, alerts) =>
  [...lifts, ...alerts]
    .slice()
    .sort((a, b) =>
      moment(a.created).toDate() > moment(b.created).toDate() ? -1 : 1,
    );

const getEventKey = (events, index) => {
  if (events[index].uuid) {
    return events[index].uuid + index;
  }
  return `${events[index].id}-${JSON.stringify(
    events[index].created,
  )}-${index}`;
};

const getEventIndex = (events, activeEventUuid) => {
  const eventIndex = events.map((event) => event.uuid).indexOf(activeEventUuid);
  return eventIndex === -1 ? null : eventIndex;
};

const TruckLiftsEventsList = ({
  alertMarkers,
  truckLifts,
  setActiveEvent,
  activeEvent,
  handleThumb,
  virtualListHeight,
  ui,
  isToday,
}: Props): React.Element => {
  const events = mergeLiftsAndAlertsByDate(truckLifts, alertMarkers);

  const scrollToIndex =
    events.length === 0 ? null : getEventIndex(events, activeEvent.uuid);

  return (
    <SLiftEventsWrapper height={virtualListHeight}>
      <VirtualList
        width="100%"
        height={virtualListHeight}
        itemCount={isToday ? events.length : events.length + 1}
        scrollToAlignment={'center'}
        scrollToIndex={isToday ? scrollToIndex : scrollToIndex + 1}
        itemSize={60} // Also supports variable heights (array or function getter)
        renderItem={({ index, style }) => {
          if (events.length === 0) {
            return '';
          }

          const listIndex = isToday ? index : index - 1;

          return listIndex === -1 ? (
            <div style={style} key={'stop-collection' + index}>
              <STruckLiftEvent isFocused centerContent cursor={'default'}>
                <SIconWrapper className={'icon-wrapper'}>
                  <SLiftEventConnector
                    className={'lift-event-connector'}
                    themeColor={getEventColourFromWasteStream(
                      events[0].wasteStream,
                    )}
                  />
                </SIconWrapper>
                <SLiftEventInfoWrapper noPaddingTop>
                  <SLiftCreatedHeader>{'STOP COLLECTION'}</SLiftCreatedHeader>
                </SLiftEventInfoWrapper>
              </STruckLiftEvent>
            </div>
          ) : (
            <div style={style} key={getEventKey(events, listIndex)}>
              <TruckLiftsEvent
                id={events[listIndex].id}
                created={events[listIndex].created}
                reason={events[listIndex].reason}
                setActiveEvent={setActiveEvent}
                uuid={events[listIndex].uuid}
                liftEvent={events[listIndex]}
                activeEvent={activeEvent}
                themeColor={getEventColourFromWasteStream(
                  events[listIndex].wasteStream,
                )}
                imageResources={events[listIndex].imageResources}
                handleThumb={handleThumb}
                ui={ui}
              />
            </div>
          );
        }}
      />
    </SLiftEventsWrapper>
  );
};

export default TruckLiftsEventsList;
