import { Observable } from 'rxjs/Observable';
import { showMessage, SHOW_MESSAGE } from './messages';
import { ajax } from '../../common/AjaxClient';
import { jwt } from '../../common/JwtManager';
import azureSession from '../../azure/authentication/session';
import { signOut, acquireToken } from '../../azure/authentication';
import { recieveCouncils, selectCouncil, deactivateCouncil } from './councils';

/** *************************
 *          CONSTANTS
 **************************** */

const AUTHENTICATE = 'AUTHENTICATE';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export const HAS_UNAUTHENTICATED = 'HAS_UNAUTHENTICATED';

const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

const CHECK_AUTHENTICATED = 'CHECK_AUTHENTICATED';
const AUTH_CHECKED = 'AUTH_CHECKED';

const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT';
const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';
const RECEIVE_PIN_REQUIRED = 'RECEIVE_PIN_REQUIRED';

const STORE_TOKEN = 'STORE_TOKEN';

const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';

const REQUEST_ACCOUNT_WITH_PIN = 'REQUEST_ACCOUNT_WITH_PIN';

const RESET_PIN_REQUIRED = 'RESET_PIN_REQUIRED';

const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';

const SET_PASSWORD = 'SET_PASSWORD';
const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const authenticate = (id) => ({
  type: AUTHENTICATE,
  id,
});

export const unAuthenticate = () => ({
  type: UNAUTHENTICATE,
});

export const hasUnauthenticated = () => ({
  type: HAS_UNAUTHENTICATED,
});

export const authenticationHasFailed = (status) => ({
  type: AUTHENTICATION_FAILED,
  status,
});

export const checkAuthenticated = () => ({
  type: CHECK_AUTHENTICATED,
});

export const authChecked = () => ({
  type: AUTH_CHECKED,
});

export const requestAccount = (token) => ({
  type: REQUEST_ACCOUNT,
  token,
});

export const requestAccountWithPin = (payload) => ({
  type: REQUEST_ACCOUNT_WITH_PIN,
  payload,
});

export const receiveAccount = (id, payload) => ({
  type: RECEIVE_ACCOUNT,
  id,
  payload,
});

export const updateAccount = (id, payload) => ({
  type: UPDATE_ACCOUNT,
  id,
  payload,
});

export const resetPinRequired = () => ({
  type: RESET_PIN_REQUIRED,
});

export const requestPasswordReset = (email) => ({
  type: REQUEST_PASSWORD_RESET,
  email,
});

export const requestPasswordResetSuccess = () => ({
  type: REQUEST_PASSWORD_RESET_SUCCESS,
});

export const setPassword = (email, password, token) => ({
  type: SET_PASSWORD,
  email,
  password,
  token,
});

export const setPasswordSuccess = () => ({
  type: SET_PASSWORD_SUCCESS,
});

/** *************************
 *           HELPERS
 **************************** */
const councilSwitch = (councils) => {
  if (councils.length === 1) {
    return selectCouncil(councils[0]);
  } else {
    return recieveCouncils(councils);
  }
};

/** *************************
 *           EPICS
 **************************** */

// authenticate
const authenticateEpic = (action$) =>
  action$.ofType(REQUEST_ACCOUNT).switchMap((action) =>
    ajax.get('/identity', null, (payload) => {
      sessionStorage.setItem('userAccount', JSON.stringify(payload.response));
      return Observable.of(
        receiveAccount(
          payload.response.user.adId,
          payload.response,
          Date.now(),
        ),
        councilSwitch(payload.response.account),
        authenticate(payload.response.user.adId),
      );
    }),
  );

const checkAuthenticatedEpic = (action$) =>
  action$.ofType(CHECK_AUTHENTICATED).switchMap(() => {
    const user = sessionStorage.getItem('userAccount');
    if (user !== null) {
      console.log('User from Session');
      const userParsed = JSON.parse(user);
      return Observable.of(
        receiveAccount(userParsed.adId, userParsed, Date.now()),
        councilSwitch(userParsed.account),
        authenticate(userParsed.adId),
        authChecked(),
      );
    } else {
      console.log('Fetching User details...');
      acquireToken();
      return Observable.of(authChecked());
    }
  });

// Remove JWT
const unauthenticateEpic = (action$) =>
  action$.ofType(UNAUTHENTICATE).switchMap(() => {
    sessionStorage.removeItem('activeCouncil');
    sessionStorage.removeItem('userAccount');
    azureSession.removeItems();
    signOut('popup');
    window.ga('set', 'userId', null);
    return Observable.of(hasUnauthenticated());
  });

/** *************************
 *          REDUCERS
 **************************** */

const initialAccountState = {
  token: '',
  pinRequired: false,
  account: {},
  userAccess: {},
  user: {},
  outcome: {},
};

function accountInfo(state = initialAccountState, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

function generateAuthToken(state = initialAccountState, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT:
      if (action.payload.token) {
        return action.payload.token;
      }
      return null;
    case STORE_TOKEN:
      if (action.token) {
        return action.token;
      }
      return null;
    default:
      return state;
  }
}

const initialState = {
  isFetching: false,
  isUpdating: false,
  isAuthed: false,
  authenticateFailedStatusCode: undefined,
  hasCheckedJwt: false,
  info: {
    account: {
      id: null,
      lat: null,
      lng: null,
      hasMapLayer: false,
    },
  },
  isSettingPassword: false,
  isRequestingReset: false,
  requestResetSuccess: false,
  passwordSetSuccess: false,
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case AUTH_CHECKED:
      return {
        ...state,
        hasCheckedJwt: true,
      };
    case AUTHENTICATE:
      return {
        ...state,
        isAuthed: true,
      };
    case HAS_UNAUTHENTICATED:
      return {
        ...state,
        isAuthed: false,
        info: {},
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        isFetching: false,
        authenticateFailedStatusCode: action.status,
        hasCheckedJwt: true,
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        isSettingPassword: false,
        isRequestingReset: false,
      };
    case REQUEST_ACCOUNT:
    case REQUEST_ACCOUNT_WITH_PIN:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        isUpdating: true,
      };
    case RECEIVE_ACCOUNT:
      return action.payload === null
        ? {
            ...state,
            isFetching: false,
          }
        : {
            ...state,
            isFetching: false,
            info: accountInfo(state[action.id], action),
            authToken: generateAuthToken(state[action.id], action),
          };
    case STORE_TOKEN:
      return {
        ...state,
        authToken: generateAuthToken(state[action.id], action),
      };
    case RECEIVE_PIN_REQUIRED:
      return {
        ...state,
        isFetching: false,
        info: {
          pinRequired: action.payload.pinRequired,
        },
      };
    case RESET_PIN_REQUIRED:
      return {
        ...state,
        info: {
          pinRequired: false,
        },
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        info: {
          ...state.info,
          user: action.payload,
        },
      };
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        isRequestingReset: true,
      };
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isRequestingReset: false,
        requestResetSuccess: true,
      };
    case SET_PASSWORD:
      return {
        ...state,
        isSettingPassword: true,
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSettingPassword: false,
        passwordSetSuccess: true,
      };
    default:
      return state;
  }
}

export const accountEpics = {
  authenticateEpic,
  checkAuthenticatedEpic,
  unauthenticateEpic,
};
