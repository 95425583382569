// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';
import {
  SMenuInnerWrapper,
  SCloseButton,
  SMenuToggleButton,
  SMenuWrapper,
  SResetButton,
} from './index.sc';
import { MultiSelectNoNull, SingleSelectAllowNull } from '../FilterMenu';
import { Props, State } from '.';
import { IconChevron } from '../Icons/Chevron';
import { wasteStreams, alertTypes, lifts } from '../../common/filters';

const DEFAULT_FILTERS = {
  trucks: {
    name: 'Trucks',
    options: wasteStreams,
    defaultValue: 'all',
    type: 'multiSelectNoNull',
  },
  lifts: {
    name: 'Lifts',
    options: lifts,
    defaultValue: null,
    type: 'singleSelectAllowNull',
  },
  alerts: {
    name: 'Alerts',
    options: alertTypes,
    defaultValue: 'all',
    type: 'multiSelectNoNull',
  },

};

class AlertsTrucksFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      activeFilters: [],
      filterMenus: JSON.parse(JSON.stringify(DEFAULT_FILTERS)),
    };
  }
  setFilters = (key, options) => {
    const { filterMenus } = this.state;

    filterMenus[key].options = options;

    const filterMenu = filterMenus[key].options;

    this.setState({ filterMenus });

    const activeFilters = Object.values(filterMenu).filter(
      (value) => !!value.isActive,
    );

    const payload = activeFilters.map((filter) => filter.value);

    this.setState({ activeFilters });

    switch (key) {
      case 'alerts':
        this.props.handleAlertFilter(payload);
        break;
      case 'trucks':
        this.props.handleTruckFilter(payload);
        break;
      case 'lifts':
        this.props.handleBinLiftsFilter(payload);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(
          "Couldn't match the key to any filters, must be Alerts or Trucks",
        );
    }
  };

  toggleMenu = () =>
    this.setState({
      isOpen: !this.state.isOpen,
    });

  isFilterActive = () => {
    const { filterMenus } = this.state;

    const trucks = Object.values(
      Object.values(filterMenus.trucks.options),
    ).filter((option) => option.isActive && option.value !== 'all');

    const alerts = Object.values(
      Object.values(filterMenus.alerts.options),
    ).filter((option) => option.isActive && option.value !== 'all');

    return !!(trucks.length || alerts.length);
  };

  resetFilter = () => {
    const filterMenus = JSON.parse(JSON.stringify(DEFAULT_FILTERS));

    this.setState({ filterMenus });

    this.props.handleTruckFilter(filterMenus.trucks);
    this.props.handleAlertFilter(filterMenus.alerts);
    this.props.handleBinLiftsFilter(filterMenus.lifts);
  };

  renderMenuType = (key: string): React.ReactElement => {
    const { filterMenus } = this.state;
    const { clickedTruck, activeDate } = this.props;
    const menuType = filterMenus[key].type;
    switch (menuType) {
      case 'multiSelectNoNull':
        return (
          <MultiSelectNoNull
            key={key}
            name={filterMenus[key].name}
            options={filterMenus[key].options}
            activeDate={activeDate}
            setFilters={(options) => this.setFilters(key, options)}
          />
        );
      case 'singleSelectAllowNull':
        return (
          <SingleSelectAllowNull
            key={key}
            name={filterMenus[key].name}
            options={filterMenus[key].options}
            activeOption={this.props.activeLiftsWasteStream}
            disabled={!!clickedTruck}
            activeDate={activeDate}
            setFilters={(options) => this.setFilters(key, options)}
          />
        );
      default:
        throw new Error(`renderMenuType can not map ${typeof menuType} ${menuType} to an menu`);
    }
  };

  render() {
    const { filterMenus } = this.state;

    return (
      <div>
        <SMenuToggleButton
          role="presentation"
          className={'ui button dropdown selection user-dropdown'}
        >
          <div className="toggle" onClick={() => this.toggleMenu()}>
            <Icon
              name={'sliders'}
              style={{
                float: 'left',
                transform: 'rotate(270deg)',
                opacity: '1',
              }}
            />
            <span
              className={
                this.isFilterActive()
                  ? 'filter-status active'
                  : 'filter-status inactive'
              }
            ></span>
            <span style={{ marginLeft: '0.5em' }}>{this.props.buttonText}</span>
            <IconChevron
              open={this.state.isOpen}
              style={{
                right: 'calc(42px + 1em)',
                position: 'absolute',
              }}
            ></IconChevron>
          </div>
          <SResetButton onClick={this.resetFilter}>
            <Icon name={'undo'} />
          </SResetButton>
        </SMenuToggleButton>
        <SMenuWrapper
          className={`menu transition ${
            this.state.isOpen ? 'visible' : ''
          }`}
          style={{ display: this.state.isOpen ? 'block' : 'none' }}
        >
          <SMenuInnerWrapper>
            {Object.keys(filterMenus).map((key) => this.renderMenuType(key))}
          </SMenuInnerWrapper>
          <div>
            <SCloseButton onClick={this.toggleMenu}>Done</SCloseButton>
          </div>
        </SMenuWrapper>
      </div>
    );
  }
}

export default AlertsTrucksFilter;
