// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const SSingleAddressCard = styled.div`
  padding: 30px 10px;
  float: left;
  width: 100%;
  background: #fff;
  z-index: 99;
  position: relative;
  i {
    float: left;
  }
`;
const SAddressLineWrapper = styled.div`
  float: left;
  padding: 10px 0;
  p {
    margin: 0.2em 0;
  }
`;

type Props = {
  children: string,
};

const AddressSidebar = ({ children }: Props): React.Element => (
  <SSingleAddressCard>
    <Icon name="point" size={'huge'} color={'blue'} />
    <SAddressLineWrapper>
      <p>
        <strong>ADDRESS</strong>
      </p>
      <p>{children}</p>
    </SAddressLineWrapper>
  </SSingleAddressCard>
);

export default AddressSidebar;
