import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/skip';
import moment from 'moment';
import { ajax } from '../../../common/AjaxClient';
import { UNAUTHENTICATE } from '../../../redux/modules/account';
import { showMessage } from '../../../redux/modules/messages';

const temporaryTimeStamp = (): moment => moment().format();

export default class SubscribeToApiEndpoint {
  constructor(url: string, updateFrequencyInMS: number) {
    this.url = url;
    this.updateFrequencyInMS = updateFrequencyInMS || 5000000;
    this.latestData = undefined;
    this.pollingInterval = undefined;
    this.pathData = new BehaviorSubject(null);
    this.ajaxGetListener = new Observable(null);
  }

  pathData$ = (): Observable => this.pathData.asObservable();

  updateQuery = (url) => {
    this.latestData = undefined;
    this.url = url;
  };

  resetApplicationStateIfAuthenticationIsMissing = (data) =>
    data.type === UNAUTHENTICATE ? window.location.reload() : '';

  stopPollingAPIData = () => window.clearInterval(this.pollingInterval);

  getAndPublishApiData = () => {
    this.ajaxGetListener = ajax
      .get(`${this.url}`, null, null, (error) => {
        const errorMessage = error && error.response ? error.response.outcome.message : error.message;
        return showMessage(errorMessage);
      })
      .subscribe((data) => {
        if (data) {
          this.resetApplicationStateIfAuthenticationIsMissing(data);
        }

        this.latestData = temporaryTimeStamp();
        this.pathData.next(data);
        this.ajaxGetListener.unsubscribe();
      });
  };

  startSubscribingToApiData = () => {
    this.getAndPublishApiData();
    this.pollingInterval = window.setInterval(
      this.getAndPublishApiData,
      this.updateFrequencyInMS,
    );
  };
}
