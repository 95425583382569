import React from 'react';
import styled from 'styled-components';

const SCTrucksLoadingOverlay = styled.div`
        background-color: rgba(0,58,78,0.8);
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        .overlay-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .loader {
                border: 7px solid #0095e3;
                border-radius: 50%;
                border-top: 7px solid #004b6d;
                width: 46px;
                height: 46px;
                -webkit-animation: spin 1s linear infinite; /* Safari */
                animation: spin 1s linear infinite;
                margin-bottom: 1.25em;
            }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
            .title {
                color: white;
                font-size: 1.15em;
                margin-bottom: 0.5em;
                font-weight: bold;
            }
            .subtitle {
                color: #49c1ff;
            }
        }


`;

const TrucksLoadingOverlay = () =>
  (<SCTrucksLoadingOverlay>
    <div className="overlay-content">
      <div className="loader"></div>
      <p className="title">Truck data is loading.</p>
      <p className="subtitle">This may take some time. Please be patient.</p>
    </div>
  </SCTrucksLoadingOverlay>);

export default TrucksLoadingOverlay;
