import { Observable } from 'rxjs/Observable';
import { ajax, handleError, maxTimeout } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { RESET_SEARCH } from './search';
import { jwt } from '../../common/JwtManager';
import API_URL from '../../common/api';
import azureSession from '../../azure/authentication/session';
import { showMessage } from './messages';

/** *************************
 *          CONSTANTS
 **************************** */

const REQUEST_ALERTS = 'REQUEST_ALERTS';
const REQUEST_MORE_ALERTS = 'REQUEST_MORE_ALERTS';
const RECEIVE_ALERTS = 'RECEIVE_ALERTS';

const REQUEST_BIN_THUMBNAILS = 'REQUEST_BIN_THUMBNAILS';
const RECEIVE_BIN_THUMBNAILS = 'RECEIVE_BIN_THUMBNAILS';

const REQUEST_BIN_IMAGES = 'REQUEST_BIN_IMAGES';
const RECEIVE_BIN_IMAGES = 'RECEIVE_BIN_IMAGES';
const SELECT_IMAGE = 'SELECT_IMAGE';
const RESET_DISPLAYED_IMAGE = 'RESET_DISPLAYED_IMAGE';

const RESET_ITEMS = 'RESET_ITEMS';
const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const requestAlerts = (payload) => ({
  type: REQUEST_ALERTS,
  payload,
});

export const requestMoreAlerts = (payload) => ({
  type: REQUEST_MORE_ALERTS,
  payload,
});

export const requestSuccess = (payload) => ({
  type: RECEIVE_ALERTS,
  payload,
});

export const requestBinThumbnails = (idObject, callsLeft) => ({
  type: REQUEST_BIN_THUMBNAILS,
  idObject,
  callsLeft,
});

export const receiveBinThumbnails = (thumbnail, binEvent, imageIndex) => ({
  type: RECEIVE_BIN_THUMBNAILS,
  thumbnail,
  binEvent,
  imageIndex,
});

export const requestBinImages = (binEvent, imageId) => ({
  type: REQUEST_BIN_IMAGES,
  binEvent,
  imageId,
});

export const receiveBinImages = (binEvent, imageId, imageSrc) => ({
  type: RECEIVE_BIN_IMAGES,
  binEvent,
  imageId,
  imageSrc,
});

export const selectImage = (binEvent, imageId) => ({
  type: SELECT_IMAGE,
  binEvent,
  imageId,
});

export const resetDisplayedImage = () => ({
  type: RESET_DISPLAYED_IMAGE,
});

export const resetItems = () => ({
  type: RESET_ITEMS,
});

export const resetAlertsIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

/** *************************
 *           EPICS
 **************************** */

/* get alerts */
const binEventEndpoint = (payload) => {
  const {
    dateTo,
    dateFrom,
    offset,
    from,
    limit,
    addressSearchId,
    reason,
    territoryId,
  } = payload;
  const encodedOffset = encodeURIComponent(offset);
  let endpoint = `/event/binevents?territoryId=${territoryId}&dateFrom=${dateFrom}T00:00:00${encodedOffset}&dateTo=${dateTo}T23:59:59${encodedOffset}&from=${from}&limit=${limit}`;

  if (reason && reason !== 'all') {
    endpoint += `&reason=${encodeURIComponent(reason)}`;
  }
  if (addressSearchId) {
    endpoint += `&addressId=${addressSearchId}`;
  }
  return endpoint;
};

const requestAlertsEpic = (action$) =>
  action$.ofType(REQUEST_ALERTS, REQUEST_MORE_ALERTS).switchMap((action) => {
    const url = binEventEndpoint(action.payload);
    return ajax.get(url, null, (payload) =>
      Observable.of(requestSuccess({
        data: payload.response.binEvents,
        resetData: action.payload.resetData
      })),
    );
  });

/* after the alert list is retrieved, get the image ids for all thumbnails */
const requestBinThumbnailsIDEpic = (action$, { getState }) =>
  action$.ofType(RECEIVE_ALERTS).switchMap(() => {
    const items = getState().alerts.items;

    const obj = {};
    for (let i = 0; i < items.length; i += 1) {
      const itemId = items[i].internalId;
      if (
        obj[itemId] === undefined ||
        (obj[itemId] !== undefined && obj[itemId].imageId === undefined) ||
        (obj[itemId] !== undefined &&
          obj[itemId].imageId !== undefined &&
          items[i].imageResources.length > obj[itemId].imageId.length)
      ) {
        obj[itemId] = {};
        const img = [];
        for (let r = 0; r < items[i].imageResources.length; r += 1) {
          img.push(items[i].imageResources[r]);
        }
        obj[itemId].imageId = img;
      }
    }
    return Observable.of(requestBinThumbnails(obj, null));
  });

/* iterate through the thumbnails id's and hit the endpoint for one that hasn't been stored yet */
const requestBinThumbnailsEpic = (action$, { getState }) =>
  action$.ofType(REQUEST_BIN_THUMBNAILS).switchMap((action) => {
    const obj = getState().alerts.binIdImg;

    const binEventIDs = Object.keys(obj);
    let callsLeft;

    if (action.callsLeft === null) {
      // First Call
      callsLeft = binEventIDs.length;
    } else {
      callsLeft = action.callsLeft;
    }
    const i = binEventIDs.length - callsLeft;
    let imageHash = '';
    const imageIndex = 0;
    let url = '';

    // Request setup
    // Set Headers
    const h = { Accept: 'image/png' };
    h['Content-Type'] = 'image/png';
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    const request = {
      headers: h,
      responseType: 'arraybuffer',
    };

    if (
      obj[binEventIDs[i]] !== undefined &&
      obj[binEventIDs[i]].imageId[imageIndex] !== undefined &&
      obj[binEventIDs[i]].imageId[imageIndex] !== '' &&
      obj[binEventIDs[i]].thumbnail === undefined
    ) {
      // Use the first image as a thumbnail
      imageHash = obj[binEventIDs[i]].imageId[imageIndex];
      url = `${API_URL}/event/${binEventIDs[i]}/image/${imageHash}?size=thumb`;
      if (callsLeft > 1) {
        request.url = url;
        return Observable.ajax(request)
          .flatMap((payload) =>
            Observable.of(
              receiveBinThumbnails(
                payload.response,
                binEventIDs[i],
                imageIndex,
              ),
              requestBinThumbnails(obj, callsLeft - 1),
            ),
          )
          .catch(() => {
            // Fail silently
          });
      }
      // Last call
      request.url = url;
      return Observable.ajax(request).flatMap((payload) =>
        Observable.of(
          receiveBinThumbnails(payload.response, binEventIDs[i], imageIndex),
        ),
      );
    }
    // No images, skip to the next one
    if (callsLeft > 1) {
      return Observable.of(requestBinThumbnails(obj, callsLeft - 1));
    }
    // No images and last call
    return Observable.empty();
  });

/* hit the endpoint for an image */
// TODO: If the image already exists in obj, no need to call the endpoint,
// just change to that particular image
const requestBinImagesEpic = (action$, { getState }) =>
  action$.ofType(REQUEST_BIN_IMAGES).switchMap((action) => {
    const obj = getState().alerts.binIdImg;
    const imageUrl = encodeURIComponent(
      obj[action.binEvent].imageId[action.imageId],
    );
    const url = `${API_URL}/event/${action.binEvent}/image/${imageUrl}?size=main`;
    // TODO Dry out these alternate ajaxClient requests
    // Set Headers
    const h = { Accept: 'image/png' };
    h['Content-Type'] = 'image/png';
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    const request = {
      url,
      headers: h,
      responseType: 'arraybuffer',
    };
    return Observable.ajax(request).timeout(maxTimeout).flatMap((payload) =>
      Observable.of(
        receiveBinImages(action.binEvent, action.imageId, payload.response),
      ),
    ).catch((e) => handleError(e));
  });


/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  isFetchingMoreItems: false,
  addressSearchId: null,
  reason: '',
  from: 0,
  limit: 20,
  items: [],
  receivedImageID: {},
  binIdImg: {},
  isFetchingAlertImage: false,
  selectedImageSrc: null,
  selectedImageId: null,
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case REQUEST_ALERTS:
      return {
        ...state,
        ...action.payload,
        isFetching: true,
      };
    case REQUEST_MORE_ALERTS:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreItems: true,
        addressSearchId: action.payload.addressSearchId,
        reason: action.payload.reason,
        from: action.payload.from,
        limit: action.payload.limit,
      };
    case RECEIVE_ALERTS:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreItems: false,
        items: action.payload.resetData ? action.payload.data : [...state.items].concat(action.payload.data),
      };
    case REQUEST_BIN_THUMBNAILS:
      return {
        ...state,
        binIdImg: action.idObject,
      };
    case RECEIVE_BIN_THUMBNAILS: {
      const binIdImg = state.binIdImg;
      if (binIdImg[action.binEvent]) {
        binIdImg[action.binEvent].thumbnail = action.thumbnail;
      }
      return {
        ...state,
        binIdImg,
      };
    }
    case REQUEST_BIN_IMAGES:
      return {
        ...state,
        isFetchingAlertImage: true,
        selectedImageSrc: null,
      };
    case RECEIVE_BIN_IMAGES: {
      const binImagesBinIdImg = state.binIdImg;
      if (binImagesBinIdImg[action.binEvent]) {
        binImagesBinIdImg[action.binEvent].image = action.imageSrc;
      }
      return {
        ...state,
        binIdImg: binImagesBinIdImg,
        selectedImageSrc: action.imageSrc,
        selectedImageId: action.imageId,
        isFetchingAlertImage: false,
      };
    }
    case SELECT_IMAGE:
      return {
        ...state,
        selectedImageSrc: state.binIdImg[action.binEvent][action.imageId],
        selectedImageId: action.imageId,
        isFetchingAlertImage: false,
      };
    case RESET_DISPLAYED_IMAGE:
      return {
        ...state,
        selectedImageSrc: null,
        selectedImageId: null,
        isFetchingAlertImage: false,
      };
    case RESET_ITEMS:
    case RESET_SEARCH:
      return {
        ...state,
        addressSearchId: null,
        from: 0,
        items: [],
      };
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreItems: false,
        isFetchingAlertImage: false,
      }
    default:
      return state;
  }
}

export const alertsEpics = {
  requestAlertsEpic,
  requestBinThumbnailsIDEpic,
  requestBinThumbnailsEpic,
  requestBinImagesEpic,
};
