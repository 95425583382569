
import { emailRegex } from '../../common/options';

export default (values) => {
  const errors = {};

  // first name
  if (!values.firstname) {
    errors.firstname = 'Required.';
  }

  // last name
  if (!values.lastname) {
    errors.lastname = 'Required.';
  }

  // email
  if (!values.email) {
    errors.email = 'Required.';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  return errors;
};
