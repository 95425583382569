
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Header, Menu, Icon } from 'semantic-ui-react';
import * as accountsActionCreators from '../../redux/modules/accounts';
import AddAccountForm from '../../components/AddAccountForm';

class AddAccountContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  props: {
    isPosting: boolean,
    accountPost: Function,
  };

  handleUpdate(values) {
    this.props.accountPost(values);
  }

  render() {
    return (
      <div className="comp secondary-comp">
        <Helmet
          title="Add Account"
          meta={[
            { name: 'Add Account', content: 'Customer Portal' },
          ]}
        />
        <Menu secondary stackable>
          <Link to="/account-management" className="link item">
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            All Accounts
          </Link>
        </Menu>
        <Container text>
          <Header as="h1" className="form-header">Add New Account</Header>
          <AddAccountForm
            onSubmit={this.handleUpdate}
            isPosting={this.props.isPosting}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isPosting: state.accounts.isPosting,
  }),
  (dispatch) => bindActionCreators(accountsActionCreators, dispatch)
)(AddAccountContainer);

