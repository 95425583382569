import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { Menu, Container, Icon, Grid } from 'semantic-ui-react';
import WorkOrderJobs from './workOrderJobs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActionCreators from '../../redux/modules/search';
import LoadingIndicator from './loadingIndicator';
import * as jobsActionCreators from '../../redux/modules/jobs';
import {
  UnAssignedIcon,
  RightArrowIcon,
  UnAssignedIndicator,
  CompletedIndicator,
} from './svgComponents';
import './collapsible.css';
import './job.css';
import queryString from 'query-string';

export class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.viewWorkOrder = this.viewWorkOrder.bind(this);
    this.state = {
      selectedWorkOrder: null,
    };
  }

  viewWorkOrder(workOrderId) {
    const workOrderInfo = this.props.jobs.crnWorkOrderList?.workOrders?.find(
      (workOrder) => workOrder.workOrderNumber === workOrderId,
    );

    if (workOrderInfo) {
      this.setState({
        selectedWorkOrderId: workOrderId,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      selectedWorkOrder: null,
    });
  }

  getJobId() {
    return decodeURIComponent(this.props.match.params?.id);
  }

  componentWillReceiveProps(nextProps) {
    const { crnDetailFetchApiError } = nextProps.jobs;
    if (crnDetailFetchApiError && crnDetailFetchApiError.status === 404) {
      this.props.history.push('/jobs');
      this.props.resetWorkOrderJobs();
    }
  }

  componentDidMount() {
    const jobId = this.getJobId();
    if (jobId) {
      this.props.requestCRNWorkOrders(this.props.territoryId, jobId);
    } else {
      this.props.history.push('/live-collections');
    }
    window.scrollTo(0, 0);
  }

  getWorkOrderInfo() {
    const params = queryString.parse(this.props.location.search);
    const { isFetching, isFetchingWorOrderJobs, crnWorkOrderList } =
      this.props.jobs;

    const workOrdersWithJobId = crnWorkOrderList?.workOrders?.filter(
      (workOrder) => workOrder.jobs?.filter(
        job => job.jobId === this.getJobId()
      )?.length > 0);
    const workOrderNumber =
      this.state.selectedWorkOrderId || params.workorder || (workOrdersWithJobId && workOrdersWithJobId.length > 0 ? workOrdersWithJobId[0].workOrderNumber : undefined) || null;
    
    const selectedJobId = location?.pathname?.split('/')?.[2];

    let workOrderInfo =
      crnWorkOrderList?.workOrders?.find(
        (workOrder) => workOrder.workOrderNumber === workOrderNumber,
      ) ||
      crnWorkOrderList?.workOrders?.[0] ||
      null;

    const selectedJobInfo = workOrderInfo?.jobs.find(
      (job) => job.jobId === selectedJobId,
    );

    const jobNumber =
      (selectedJobInfo && selectedJobInfo.jobNumber) ||
      (workOrderInfo && workOrderInfo?.jobs?.[0]?.jobNumber) ||
      crnWorkOrderList?.workOrders?.[0]?.jobs?.[0]?.jobNumber ||
      null;

    return {
      jobNumber,
      isFetching,
      crnWorkOrderList,
      isFetchingWorOrderJobs,
      workOrderInfo,
    };
  }

   addSpacesBetweenCapital(str) {
    return str ? str.replace(/([a-z])([A-Z])/g, '$1 $2'): null;
  }

  render() {
    const {
      jobNumber,
      isFetching,
      isFetchingWorOrderJobs,
      crnWorkOrderList,
      workOrderInfo,
    } = this.getWorkOrderInfo();

    return (
      <div className="comp secondary-comp">
        <Helmet title="Jobs" meta={[{ name: 'Jobs', content: 'Cleanaview' }]} />
        <Menu secondary stackable>
          <a onClick={() => this.props.history.goBack()} className="link item">
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            Back
          </a>
        </Menu>
        {
          isFetching ?
            <div /> :
            <div className="job-view-crn-title">
              <div>
                {
                  crnWorkOrderList?.crn ?
                    <label>
                      CRN <span>{crnWorkOrderList?.crn}</span>
                    </label> :
                    <label>
                      No CRN for this work order
                    </label>
                }
                <p>{crnWorkOrderList?.addressShort}</p>
              </div>
              <div>
                <button>
                  {crnWorkOrderList?.crnStatus === 'Completed' ? (
                    <CompletedIndicator />
                  ) : (
                    <UnAssignedIcon />
                  )}
                  <span>{this.addSpacesBetweenCapital(crnWorkOrderList?.crnStatus)}</span>
                </button>
              </div>
            </div>
        }
        
        <Container className="job-view-container" fluid>
          {!isFetching && workOrderInfo ? (
            <Grid columns={crnWorkOrderList?.workOrders.length > 1 ? 2 : 1}>
              {
                crnWorkOrderList?.workOrders.length >  1 ? <Grid.Column width={4} className="work-orders-left-column">
                <div className="content-left">
                  <div className="content-left-header">
                    <h2>Work Orders</h2>
                    <ul>
                      {crnWorkOrderList?.workOrders.map((order) => {
                        return (
                          <li
                            onClick={() =>
                              this.viewWorkOrder(order.workOrderNumber)
                            }
                            className={
                              workOrderInfo.workOrderNumber ===
                              order.workOrderNumber
                                ? 'selected'
                                : null
                            }>
                            <div className="job-view-container-left">
                              <label>WO #{`${order.workOrderNumber}`}</label>
                              <span>{order.serviceType}</span>
                            </div>
                            <div className="job-view-container-right">
                              <button>
                                {order.workOrderStatus === 'Completed' ? (
                                  <CompletedIndicator />
                                ) : (
                                  <UnAssignedIcon />
                                )}
                                <span>{this.addSpacesBetweenCapital(order.workOrderStatus)}</span>
                              </button>
                              <RightArrowIcon />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Grid.Column>:  null
              }
              <Grid.Column width={crnWorkOrderList?.workOrders.length >  1 ? 12: 24} className="left-border-pd-zero">
                <div className="jobs-work-order-panel">
                  {isFetchingWorOrderJobs ? (
                    <LoadingIndicator />
                  ) : (
                    <div>
                      <div className={crnWorkOrderList?.workOrders.length >  1 ? "content-right three-quarter-width" : "content-right full-width"}>
                        <div className="content-right-bottom">
                          <div className="content-right-bottom-left">
                            <div className="header-top">
                              <h3>
                                Work Order
                                <span>{` ${workOrderInfo?.workOrderNumber}`}</span>
                              </h3>
                            </div>
                            <div className="content-right-detail">
                              <h6>{workOrderInfo?.serviceType}</h6>
                            </div>
                          </div>
                          <div className="content-right-bottom-right">
                            <button>
                              {workOrderInfo?.workOrderStatus ===
                              'Completed' ? (
                                <CompletedIndicator />
                              ) : (
                                <UnAssignedIndicator />
                              )}
                              {workOrderInfo?.workOrderStatus}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="content-below">
                        <h3>Jobs in this work order</h3>
                        <WorkOrderJobs
                          jobs={workOrderInfo?.jobs}
                          selectedJob={jobNumber}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          ) : (
            isFetching ?
            <LoadingIndicator /> : <></>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      jobs: state.jobs,
      search: state.search,
      territoryId: state.councils.active.territoryId,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          ...searchActionCreators,
          ...jobsActionCreators,
        },
        dispatch,
      ),
  )(Jobs),
);
