// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { formValueSelector } from 'redux-form';
import { Container, Menu, Icon } from 'semantic-ui-react';
import moment from 'moment';
import * as reportActionCreators from '../../redux/modules/reports';
import { stripSpaces, unSnake } from '../../common/helpers';
import ConfigReportForm from './configReportForm';
import ConfigReportDetailsForm from './configReportDetailsForm';
import ConfigReportDownloadForm from './configReportDownloadForm';
import ReportPreview from '../ReportPreview';

export const MODAL_DOWNLOAD = 'MODAL_DOWNLOAD';
export const MODAL_DETAILS = 'MODAL_DETAILS';

class CustomReportContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleExport = this.handleExport.bind(this);
    this.handleSaveConfig = this.handleSaveConfig.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.prepareRequest = this.prepareRequest.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleGroupDataChange = this.handleGroupDataChange.bind(this);
    this.handleFocusInput = this.handleFocusInput.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.remove = this.remove.bind(this);
    this.preview = this.preview.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.state = {
      modalOpen: false,
      startDate: moment(),
      endDate: moment(),
      focusedInput: null,
      dateRangeChoice: 'DAILY',
    };
  }

  componentWillMount() {
    if (!this.props.reports.reportTypes.length) {
      this.props.requestReportTypes();
    }
  }

  /**
   * Open or close the report or download config modal
   */
  toggleModal(data) {
    this.props.toggleReportModal(data);
  }

  /**
   * Save report config
   */
  handleSaveConfig() {
    const configValues = this.props.reports.preparedRequest;
    if (configValues) {
      configValues.reportName =
        this.props.reports.mode === 'add'
          ? this.props.reportName
          : this.props.editReportName;
      configValues.timeSegment = this.props.timeSegment || 'DAILY';
      const callback = () => this.props.history.goBack();
      if (!this.props.reports.id) {
        // Creating new report
        this.props.requestConfigSave(
          configValues,
          callback,
          this.props.territoryId,
        );
      } else {
        // Editing existing report
        this.props.requestConfigEdit(
          configValues,
          this.props.reports.id,
          callback,
          this.props.territoryId,
        );
      }
    }
  }

  /**
   * When form values change, fire the report preview epic
   *
   * @param {object} values
   * @return {object} returns the new preview object into redux
   */
  handleChange(values) {
    // hit the endpoint only if date isn't selected or (if it is),
    // then another item has to be selected too.
    this.prepareRequest(values);
  }

  /**
    When the user changes the groupData
  */
  handleGroupDataChange(groupDataName, disabled) {
    if (disabled) {
      return false;
    }
    this.props.setDataGroup(groupDataName);
    return true;
  }

  /**
   * Takes form values and prepares the request body object into correct format
   *
   * @param {object} values
   * @return {object} returns a payload object for use within epic requests
   */
  prepareRequest(values) {
    const payload = {};

    if (!!values && Object.keys(values).length > 0) {
      const { metrics } = values;
      const dataGroup = this.props.reports.dataGroup;
      payload.dataGroup = dataGroup;
      payload.receivers = this.props.reports.selectedReport
        ? this.props.reports.selectedReport.receivers
        : [];
      payload.metrics = [];
      // Not required anymore as timeSegment will be defined on subscription management
      // payload.timeSegment = timeSegment || 'DAILY'; // time period, default daily

      const pushMetrics = (fields) => {
        Object.keys(fields).forEach((f) => {
          let toAdd = true;
          let filters = null;
          if (typeof fields[f] === 'object') {
            // check is object and not boolean
            if (!fields[f].open) {
              toAdd = false;
            }
            if (fields[f].filters) {
              // check if already array (multi select), or plain string (text input)
              filters = Array.isArray(fields[f].filters)
                ? fields[f].filters
                : [fields[f].filters];
            }
          } else if (!fields[f]) {
            toAdd = false;
          }

          if (toAdd) {
            payload.metrics.push({
              fieldName: `${f}`,
              alias: stripSpaces(unSnake(f)),
              filters,
            });
          }
        });
      };

      if (metrics) {
        if (metrics[dataGroup]) {
          pushMetrics(metrics[dataGroup]);
        }

        if (metrics.dates) {
          pushMetrics(metrics.dates);
        }

        if (metrics.address) {
          pushMetrics(metrics.address);
        }
      }
    }

    this.props.recordPreparedRequest(payload);
    return payload;
  }

  preview() {
    this.props.recordPreparedRequest(this.props.reports.preparedRequest, true);
    this.props.history.push('/reports/custom-report/preview');
  }

  resetForm() {
    this.props.clearPreparedRequest();
  }

  handleFocusInput(focusedInput) {
    this.setState({ focusedInput });
  }

  handleDateChange(obj) {
    this.setState(obj);
  }

  /**
   * Take values and request export object for redux
   * @return {void} fires the 'request export' epic
   */
  handleExport() {
    const payload = this.props.reports.preparedRequest;
    if (payload) {
      if (this.state.dateRangeChoice !== 'CUSTOM') {
        payload.timeSegment = this.state.dateRangeChoice;
      } else {
        payload.timeSegment = null;
        payload.from = this.state.startDate;
        payload.to = this.state.endDate;
      }
      // fire action
      this.props.requestExportReport(payload, this.props.territoryId);
    }
  }

  /**
   * Delete report and return to Reports index
   * @param   {Object}  report
   */
  remove(id) {
    this.props.requestConfigDelete(
      { id, territoryId: this.props.territoryId },
      () => this.props.history.goBack(),
    );
  }

  /**

  * */
  handleDateRangeChange(value) {
    this.setState({ dateRangeChoice: value });
  }

  props: {
    match: Object,
    location: Object,
    history: Object,
    requestReportTypes: Function,
    requestExportReport: Function,
    requestConfigSave: Function,
    requestConfigEdit: Function,
    requestConfigDelete: Function,
    toggleReportModal: Function,
    recordPreparedRequest: Function,
    clearPreparedRequest: Function,
    setDataGroup: Function,
    editReportName: Function,
    reports: Object,
    formValuesMetrics: Object,
    timeValues: Object,
    addressValues: Object,
    reportName: string,
    timeSegment: string,
  };

  render() {
    const { match, reports, formValuesMetrics, timeValues, addressValues } =
      this.props;
    const initialValues = {
      metrics: reports.reportFormValues,
      reportName:
        !!this.props.location &&
        !!this.props.location.state &&
        !!this.props.location.state.report
          ? this.props.location.state.report.reportName
          : null,
    };
    const formValues = {
      metrics: formValuesMetrics,
    };

    return (
      <div>
        <Route path={`${match.url}/preview`} component={ReportPreview} />
        <Route
          exact
          path={match.url}
          render={() => (
            <Container>
              <Helmet
                title="Custom Report"
                meta={[{ name: 'Custom Report', content: 'Customer Portal' }]}
              />
              <Menu secondary stackable>
                <Link to="/reports" className="link item">
                  <Icon className="icon-left" style={{ marginRight: '15px' }} />
                  All Reports
                </Link>
              </Menu>
              <div className="custom-reports">
                <ConfigReportForm
                  reports={reports}
                  toggleModal={this.toggleModal}
                  modalOpen={this.state.modalOpen}
                  formValues={formValues}
                  timeValues={timeValues}
                  addressValues={addressValues}
                  onChange={this.handleChange}
                  initialValues={initialValues}
                  handleGroupDataChange={this.handleGroupDataChange}
                  menuItems={reports.menuItems}
                  match={match}
                  mode={reports.mode}
                  save={this.handleSaveConfig}
                  remove={this.remove}
                  preview={this.preview}
                  resetForm={this.resetForm}
                  id={reports.id}
                />
                <ConfigReportDetailsForm
                  onSubmit={this.handleSaveConfig}
                  initialValues={
                    this.props.location.state !== undefined
                      ? this.props.location.state.report
                      : {}
                  }
                  modalOpen={reports.reportModalOpen === MODAL_DETAILS}
                  toggleModal={this.toggleModal}
                  postingReport={reports.postingReport}
                  reportName={this.props.reportName}
                />
                <ConfigReportDownloadForm
                  onSubmit={this.handleExport}
                  initialValues={
                    this.props.location.state !== undefined
                      ? this.props.location.state.report
                      : {}
                  }
                  modalOpen={reports.reportModalOpen === MODAL_DOWNLOAD}
                  toggleModal={this.toggleModal}
                  fetchingExport={reports.fetchingExport}
                  initStartDate={this.state.startDate}
                  initEndDate={this.state.endDate}
                  dateRangeChoice={this.state.dateRangeChoice}
                  focusedInput={this.state.focusedInput}
                  handleDateChange={this.handleDateChange}
                  handleFocusInput={this.handleFocusInput}
                  handleDateRangeChange={this.handleDateRangeChange}
                />
              </div>
            </Container>
          )}
        />
      </div>
    );
  }
}

// decorate form selector
const selector = formValueSelector('configReport');
const detailsSelector = formValueSelector('configReportDetails');

// decorate in connect and reduxForm
export default connect(
  (state) => ({
    reports: state.reports,
    // grab the data groups out of redux for the config form
    formValuesMetrics: state.reports.reportTypes.length
      ? selector(state, 'metrics')
      : {},
    reportName: detailsSelector(state, 'reportName'),
    editReportName: selector(state, 'reportName'),
    timeSegment: selector(state, 'timeSegment'),
    timeValues: selector(state, 'dates'),
    addressValues: selector(state, 'address'),
    initialValues: {},
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) => ({
    ...bindActionCreators(reportActionCreators, dispatch),
    dispatch,
  }),
)(CustomReportContainer);
