// @Flow

import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import { Option } from '../index.d';
import LiftIcon from './LiftIcon';

type Props = {
  option: Option,
  name: string,
  isActive: boolean,
};

const RenderIcon = ({
  option,
  name,
  isActive = true,
}: Props): React.Element | void => {

  if(!option.icon && !option.image && !option.dot) {
    return null;
  }

  if (option.icon) {
    return <Icon className={name} {...option.icon} />;
  }

  if (option.image) {
    return <Image className={name} {...option.image} />;
  }

  if (option.dot) {
    return <LiftIcon colour={option.dot.color} isActive={isActive} />;
  }

  // eslint-disable-next-line no-console
  console.error(`Unknown icon for ${typeof option} ${option}`);
};

export default RenderIcon;
