import general from '../assets/images/trucks/general.svg';
import green from '../assets/images/trucks/green.svg';
import recycle from '../assets/images/trucks/recycle.svg';
import hard from '../assets/images/trucks/hard.svg';
import organics from '../assets/images/trucks/organics.svg';
import glass from '../assets/images/trucks/glass.svg';
import other from '../assets/images/trucks/other.svg';

import contaminated from '../assets/images/alerts/contaminated.svg';
import notPresented from '../assets/images/alerts/not_presented.svg';
import binDamaged from '../assets/images/alerts/bin_damaged.svg';
import binNotEmptied from '../assets/images/alerts/bin_not_emptied.svg';
import otherAlert from '../assets/images/alerts/other.svg';

export const alertIcons = {
  Contaminated: contaminated,
  'Not Presented': notPresented,
  'Bin Damaged': binDamaged,
  'Bin Not Emptied': binNotEmptied,
  Other: otherAlert,
};

export const alertColour = {
  Contaminated: '#B0D500',
  'Not Presented': '#F5292A',
  'Bin Damaged': '#000000',
  'Bin Not Emptied': '#8D43FF',
  Other: '#0095E3',
};

export const truckImages = {
  Refuse: general,
  Recycle: recycle,
  Greenwaste: green,
  Hardwaste: hard,
  Glass: glass,
  Organics: organics,
  Other: other,
};

// Time zones
const timezones = [
  {
    value: 'Australia/Adelaide',
    text: 'Australia/Adelaide',
  },
  {
    value: 'Australia/Brisbane',
    text: 'Australia/Brisbane',
  },
  {
    value: 'Australia/Broken_Hill',
    text: 'Australia/Broken_Hill',
  },
  {
    value: 'Australia/Currie',
    text: 'Australia/Currie',
  },
  {
    value: 'Australia/Darwin',
    text: 'Australia/Darwin',
  },
  {
    value: 'Australia/Eucla',
    text: 'Australia/Eucla',
  },
  {
    value: 'Australia/Hobart',
    text: 'Australia/Hobart',
  },
  {
    value: 'Australia/Lindeman',
    text: 'Australia/Lindeman',
  },
  {
    value: 'Australia/Lord_Howe',
    text: 'Australia/Lord_Howe',
  },
  {
    value: 'Australia/Melbourne',
    text: 'Australia/Melbourne',
  },
  {
    value: 'Australia/Perth',
    text: 'Australia/Perth',
  },
  {
    value: 'Australia/Sydney',
    text: 'Australia/Sydney',
  },
];

// waste streams
export const wasteStreams = [
  {
    value: 'all',
    icon: { name: 'unordered list', flipped: 'horizontally' },
    text: 'All Trucks',
    key: null,
    isActive: true,
  },
  {
    value: 'Refuse',
    image: { src: general },
    text: 'Refuse',
    key: 'general',
    isActive: false,
  },
  {
    value: 'Recycle',
    image: { src: recycle },
    text: 'Recycle',
    key: 'recycle',
    isActive: false,
  },
  {
    value: 'Greenwaste',
    image: { src: green },
    text: 'Green Waste',
    key: 'green',
    isActive: false,
  },
  {
    value: 'Hardwaste',
    image: { src: hard },
    text: 'Hard Waste',
    key: 'hard',
    isActive: false,
  },
  {
    value: 'Other',
    image: { src: other },
    text: 'Other',
    key: 'other',
    isActive: false,
  },
  {
    value: 'Glass',
    image: { src: glass },
    text: 'Glass',
    key: 'glass',
    isActive: false,
  },
  {
    value: 'Organics',
    image: { src: organics },
    text: 'Organics',
    key: 'organics',
    isActive: false,
  },
];

export const getWasteStream = (streamStr) => {
  let wasteStream = null;
  Object.values(wasteStreams).forEach((stream) => {
    if (stream.value === streamStr) {
      wasteStream = stream;
    }
  });
  if (wasteStream) {
    return wasteStream;
  }
  Object.values(wasteStreams).forEach((stream) => {
    if (stream.key === 'other') {
      wasteStream = stream;
    }
  });
  return wasteStream;
};

// This was replaced a long time ago and left in the code for reference, but it matches up with the cleanaway account
const OLD_HERE_MAPS = {
  apiKey: 'nmthNptoS9JVtRYt5r9g3v0gs9k4QiPZ9YOTyJ_cutc',
};

// HERE maps config
const hereMaps = {
  ...OLD_HERE_MAPS,
};

export const months = [
  {
    value: 'january',
    text: 'January',
  },
  {
    value: 'february',
    text: 'February',
  },
  {
    value: 'march',
    text: 'March',
  },
  {
    value: 'april',
    text: 'April',
  },
  {
    value: 'may',
    text: 'May',
  },
  {
    value: 'june',
    text: 'June',
  },
  {
    value: 'july',
    text: 'July',
  },
  {
    value: 'august',
    text: 'August',
  },
  {
    value: 'september',
    text: 'September',
  },
  {
    value: 'october',
    text: 'October',
  },
  {
    value: 'november',
    text: 'November',
  },
  {
    value: 'december',
    text: 'December',
  },
];

const alertTypes = [
  {
    value: 'all',
    icon: { name: 'unordered list', flipped: 'horizontally' },
    text: 'All Alerts',
    isActive: true,
  },
  {
    value: 'Contaminated',
    image: { src: contaminated },
    text: 'Contaminated',
    isActive: false,
  },
  {
    value: 'Not Presented',
    image: { src: notPresented },
    text: 'Not Presented',
    isActive: false,
  },
  {
    value: 'Bin Damaged',
    image: { src: binDamaged },
    text: 'Bin Damaged',
    isActive: false,
  },
  {
    value: 'Bin Not Emptied',
    image: { src: binNotEmptied },
    text: 'Bin Not Emptied',
    isActive: false,
  },
  {
    value: 'Other',
    image: { src: otherAlert },
    text: 'Other',
    isActive: false,
  },
];

const ticketStatuses = [
  {
    value: 'Unassigned',
    label: 'Unassigned',
  },
  {
    value: 'Assigned',
    label: 'Assigned',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
];

const ticketTypes = [
  {
    value: '',
    text: 'All Tickets',
  },
  {
    value: 'Missed Bins',
    text: 'Missed Bins',
  },
  {
    value: 'Hard Waste',
    text: 'Hard Waste',
  },
  {
    value: 'Deployment',
    text: 'Deployment',
  },
  {
    value: 'Street Litter',
    text: 'Street Litter',
  },
  {
    value: 'Special Events',
    text: 'Special Events',
  },
  {
    value: 'Event Capture',
    text: 'Event Capture',
  },
  {
    value: 'Commercial',
    text: 'Commercial',
  },
  {
    value: 'Other',
    text: 'Other',
  },
];

const roleOptions = [
  {
    value: 'ADMIN',
    text: 'Admin',
    hierarchyOrder: 1,
  },
  {
    value: 'MUNI_ADMIN',
    text: 'Muni Admin',
    hierarchyOrder: 5,
  },
  {
    value: 'MUNI_WORKER',
    text: 'Muni Worker',
    hierarchyOrder: 10,
  },
];

const stateOptions = [
  {
    value: 'QLD',
    text: 'QLD',
  },
  {
    value: 'NSW',
    text: 'NSW',
  },
  {
    value: 'VIC',
    text: 'VIC',
  },
  {
    value: 'SA',
    text: 'SA',
  },
  {
    value: 'WA',
    text: 'WA',
  },
  {
    value: 'TAS',
    text: 'TAS',
  },
  {
    value: 'NT',
    text: 'NT',
  },
  {
    value: 'ACT',
    text: 'ACT',
  },
];

const accountModules = [
  {
    id: 1,
    name: 'LIVE_SERVICE',
    label: 'Live Services',
  },
  {
    id: 2,
    name: 'LIVE_JOBS',
    label: 'Tickets',
  },
  {
    id: 4,
    name: 'ALERTS',
    label: 'Alerts',
  },
  /*
  {
    id: 5,
    name: 'USER_MANAGEMENT',
    label: 'User Management',
  },
  {
    id: 6,
    name: 'ACCOUNT_MANAGEMENT',
    label: 'Account Management',
  },
  */
  {
    id: 3,
    name: 'REPORTS',
    label: 'Reports',
  },
  {
    id: 7,
    name: 'INVOICE',
    label: 'Invoice',
  },
];

const emailRegex = /^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const privateModules = [
  {
    name: 'LIVE_SERVICE',
    url: '/live-collections',
  },
  {
    name: 'LIVE_JOBS',
    url: '/jobs',
  },
  {
    name: 'LIVE_JOBS',
    url: '/live-jobs',
  },
  {
    name: 'LIVE_JOBS',
    url: '/crn/:id',
  },
  {
    name: 'TICKETS',
    url: '/crn/:id',
  },
  {
    name: 'ALERTS',
    url: '/alerts',
  },
  {
    name: 'TICKETS',
    url: '/tickets',
  },
  {
    name: 'JOBS_LIST',
    url: '/jobs',
  },
  {
    name: 'REPORTS',
    url: '/reports',
  },
  // {
  //   name: 'INVOICE',
  //   url: '/invoices',
  // },
  {
    name: 'ACCOUNT_MANAGEMENT',
    url: '/account-management',
  },
  {
    name: 'USER_MANAGEMENT',
    url: '/user-management',
  },
  {
    name: 'ACCOUNT',
    url: '/account',
  },
  {
    name: 'COUNCIL_SELECT',
    url: '/council-select',
  },
];

const reportDateOptions = [
  {
    key: 'daily',
    value: 'DAILY',
    text: 'Daily',
  },
  {
    key: 'weekly',
    value: 'WEEKLY',
    text: 'Weekly',
  },
  {
    key: 'monthly',
    value: 'MONTHLY',
    text: 'Monthly',
  },
  {
    key: 'quarterly',
    value: 'QUARTERLY',
    text: 'Quarterly',
  },
];

const reportDownloadOptions = [
  {
    key: 'today',
    value: 'TODAY',
    text: 'Today',
  },
  {
    key: 'yesterday',
    value: 'YESTERDAY',
    text: 'Yesterday',
  },
  {
    key: '7days',
    value: '7DAYS',
    text: 'Last 7 days',
  },
  {
    key: '30days',
    value: '30DAYS',
    text: 'Last 30 days',
  },
  {
    key: 'custom',
    value: 'CUSTOM',
    text: 'Custom',
  },
];

const reportTimePeriodOptions = [
  {
    id: 0,
    name: 'Hour',
    label: 'Hour',
  },
  {
    id: 1,
    name: 'Day',
    label: 'Day',
  },
  {
    id: 2,
    name: 'Week',
    label: 'Week',
  },
  {
    id: 3,
    name: 'Month',
    label: 'Month',
  },
  {
    id: 4,
    name: 'Quarter',
    label: 'Quarter',
  },
  {
    id: 5,
    name: 'Year',
    label: 'Year',
  },
];

const reportAddressOptions = [
  {
    id: 0,
    name: 'StreetNumber',
    label: 'Street Number',
  },
  {
    id: 1,
    name: 'Street',
    label: 'Street',
  },
  {
    id: 2,
    name: 'Suburb',
    label: 'Suburb',
  },
  {
    id: 3,
    name: 'PostalCode',
    label: 'Postal Code',
  },
  {
    id: 4,
    name: 'State',
    label: 'State',
  },
];

const customReportsColumnSizes = {
  Time_Periods: 2,
  View_WorkOrder: 3,
  View_BinEvent: 3,
  View_VehicleRun: 2,
  View_DisposalEvent: 3,
  View_WorkOrderAGG: 3,
};

const reportTimeSegments = [];
reportDateOptions.forEach((segmentOption) => {
  reportTimeSegments.push({
    value: segmentOption.value,
    text: segmentOption.value,
  });
});

const INVOICE_START_YEAR = 2016;

export {
  timezones,
  alertTypes,
  ticketStatuses,
  ticketTypes,
  roleOptions,
  hereMaps,
  stateOptions,
  accountModules,
  emailRegex,
  privateModules,
  reportDateOptions,
  reportTimePeriodOptions,
  customReportsColumnSizes,
  reportTimeSegments,
  reportDownloadOptions,
  reportAddressOptions,
  INVOICE_START_YEAR,
};
