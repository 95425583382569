
// @flow

import React from 'react';
import {
  Grid,
  Image,
} from 'semantic-ui-react';

import Logo from '../../common/images/general/cleanaway.svg';

export default function Branding() {
  return (
    <Grid style={{ marginBottom: '25px' }}>
      <Grid.Row>

        <Grid.Column floated="left" width={16} textAlign="center">
          <Image src={Logo} alt="Cleanaway" inline />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
