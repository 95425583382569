
export default class icon {
  constructor(props) {
    this.count = props.count;
    this.opacity = props.opacity;
    this.countBreakdown = props.countBreakdown;
    this.image = props.image;
    this.width = props.width;
    this.height = props.height;
    this.rotation = props.rotation || 0;
  }
}
