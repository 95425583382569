/**
 * HELPER FUNCTIONS
 */

import moment from 'moment';
import {
  roleOptions,
  reportTimePeriodOptions,
  reportAddressOptions,
} from './options';
import noImage from './images/general/no_image.png';
import API_URL from '../common/api';

/**
 * unSnake()
 *
 * Take a snaked cased string and convert it to a capitalized title
 *
 * @param {string} s snake cased string
 * @return {string} returns a capitalized and spaced version of s
 */

export function unSnake(s) {
  return s
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.substr(1))
    .join(' ');
}

/**
 * toSnake()
 *
 * Note Does not convert camelCase to snake_case but converts English "Normal Text" to snake "normal_text"
 *
 * @param {string} s snake cased string
 * @return {string} returns a lowercased _ snake case
 */

export function toSnake(s) {
  return s.toLowerCase().replace(/ /g, '_');
}

/**
 * capitalized()
 *
 * Take a lowercased string and capitalized the first character
 *
 * @param {string} s lowercased string
 * @return {string} returns a capitalized version of s
 */

export function capitalized(s) {
  if (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  return '';
}

/**
 * stripWhiteSpaces()
 *
 * Remove spaces from a string
 *
 * @param {string} s string
 * @return {string} returns the string without spaces
 */
export function stripSpaces(s) {
  return s.replace(' ', '');
}

/**
 * getDirection()
 *
 * Converts degree into more readable 'general direction', like 'NW'
 *
 * @param {number} deg
 * @return {string} returns a general direction version of deg
 */

export function getDirection(deg) {
  switch (true) {
    case deg > 330 || deg <= 30:
      return 'North';
    case deg > 30 && deg <= 60:
      return 'North East';
    case deg > 60 && deg <= 120:
      return 'East';
    case deg > 120 && deg <= 150:
      return 'South East';
    case deg > 150 && deg <= 210:
      return 'South';
    case deg > 210 && deg <= 240:
      return 'South West';
    case deg > 240 && deg <= 300:
      return 'West';
    case deg > 300 && deg <= 330:
      return 'North West';
    default:
      return '';
  }
}

/**
 * getShortMonth()
 *
 * Converts an integer month into a short string version
 *
 * @param {number} month
 * @return {string} returns a short version of the month number
 */

export function getShortMonth(month) {
  switch (true) {
    case month === 1:
      return 'January';
    case month === 2:
      return 'February';
    case month === 3:
      return 'March';
    case month === 4:
      return 'April';
    case month === 5:
      return 'May';
    case month === 6:
      return 'June';
    case month === 7:
      return 'July';
    case month === 8:
      return 'August';
    case month === 9:
      return 'September';
    case month === 10:
      return 'October';
    case month === 11:
      return 'November';
    case month === 12:
      return 'December';
    default:
      return '';
  }
}

/** *
 * passwordStrengthCheck()
 *
 * Verify the strength of a given password
 *
 * @param {string} pwd
 * @return {*} returns validation information
 */
export function passwordStrengthCheck(pwd) {
  return {
    valid:
      pwd.length >= 8 &&
      pwd.match(/[0-9]/) &&
      pwd.match(/[a-z]/) &&
      pwd.match(/[A-Z]/),
    rule: 'The password should contain at least 8 characters, including at least 1 number, 1 lower case letter, 1 upper case letter',
  };
}

/**
 * getAvailableRolesForUser()
 *
 * Return the list of roles a user can create/update
 *
 * @param {string} role
 */
export function getAvailableRolesForUser(role) {
  // Get current role hierarchy
  let currentHierarchy = 0;
  let found = false;
  for (let i = 0; i < roleOptions.length; i += 1) {
    if (roleOptions[i].value === role) {
      currentHierarchy = roleOptions[i].hierarchyOrder;
      found = true;
    }
  }
  if (!found) {
    return [];
  }
  // Check against all roles
  const availableRoles = [];
  for (let j = 0; j < roleOptions.length; j += 1) {
    if (roleOptions[j].hierarchyOrder >= currentHierarchy) {
      availableRoles.push({
        value: roleOptions[j].value,
        text: roleOptions[j].text,
      });
    }
  }
  return availableRoles;
}

/**
 * Date to clean display conversions
 *
 * @param timeStamp
 * @returns {*}
 */
export function reportedMinutes(timeStamp) {
  if (!timeStamp) {
    return 'Not specified';
  }
  return moment(timeStamp).parseZone().format('MMM Do YYYY, h:mm a');
}

export function convertIndate(timeStamp) {
  if (!timeStamp) {
    return 'Not specified';
  }
  return moment(timeStamp).parseZone().format('MMM Do YYYY');
}

export function timeCreated(timeStamp) {
  return moment(timeStamp).parseZone().format('HH:mm a, DD/MM/YYYY');
}

/**
 * Convert an array buffer to a usable image string
 * Todo make mime type dynamic
 */
export function setImageSrc(arrayBuffer) {
  if (!arrayBuffer) {
    return noImage;
  }

  return `data:image/png;base64,${btoa(
    new Uint8Array(arrayBuffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      '',
    ),
  )}`;
}

export const setImageSrcFromResources = (id, imageResources, imageIndex) =>
  `${API_URL}/event/${id}/image/${encodeURIComponent(
    imageResources[imageIndex],
  )}?size=large`;

/**
 * Determine whether the field is an aggregator
 * @param   {String}  fieldName
 * @returns {Boolean}
 */
export function isAggreg(fieldName) {
  return fieldName.search(/Sum_/) === 0 || fieldName.search(/Count_/) === 0;
}

/**
 * Determine whether a field belongs to the date section (day, month, hour...)
 * @param   {String}  fieldName
 *
 * @returns {Boolean}
 */
export function isDateField(fieldName) {
  for (let o = 0; o < reportTimePeriodOptions.length; o += 1) {
    if (fieldName === reportTimePeriodOptions[o].name) {
      return true;
    }
  }
  return false;
}

/**
 * Determine whether a field belongs to the address section (street, street number...)
 * @param   {String}  fieldName
 *
 * @returns {Boolean}
 */
export function isAddressField(fieldName) {
  for (let p = 0; p < reportAddressOptions.length; p += 1) {
    if (fieldName === reportAddressOptions[p].name) {
      return true;
    }
  }
  return false;
}

/**
 * Trigger download from content and filename
 * @param {String}  content   File content
 * @param {String}  fileName  File name
 * @param {String}  mimeType  File type (required for Safari)
 */
export function triggerDownload(content, fileName, mimeType) {
  if (typeof Blob !== 'undefined') {
    const blob = new Blob([new Uint8Array(content)], { type: mimeType });
    if (window.navigator && window.navigator.msSaveBlob) {
      // IE11
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Other browsers
      const reader = new window.FileReader();
      reader.onloadend = () => {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.style.display = 'none';
        link.href = reader.result;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      };
      reader.readAsDataURL(blob);
    }
  }
}