
import { passwordStrengthCheck } from '../../common/helpers';


export default (values) => {
  const errors = {};

  // password
  if (!values.newPassword) {
    errors.newPassword = 'Required.';
  } else {
    const check = passwordStrengthCheck(values.newPassword);
    if (!check.valid) {
      errors.newPassword = check.rule;
    }
  }

  // confirm password
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required.';
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }

  return errors;
};
