import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

const isValidDateString = (string: string): boolean =>
  /^(0?[1-9]|[12][0-9]|3[01])[-]((0?[1-9]|1[012])[-](19|20)?[0-9]{2})*$/.test(
    string,
  );

const parseDate = (date: string): moment =>
  moment(date.split('-').reverse().join('-'));

export const dayFromGivenDate = (
  date: string | null,
  isToday: boolean,
  accountTime: string,
): Object => {
  if (date !== null && !isValidDateString(date)) {
    throw `${date} is not not a valid input for function dayFromGivenDate`;
  }

  const accountOffset = accountTime.split('+')[1];
  const baseDate = date ? parseDate(date) : moment.parseZone(accountTime);
  const dateTo = isToday ? baseDate.clone().add(1, 'days') : baseDate;

  return {
    dateTo: dateTo.format(DATE_FORMAT),
    dateFrom: baseDate.format(DATE_FORMAT),
    offset: `+${accountOffset}`,
  };
};

export const getLastYearDateRangeForQuery = (date: string | null): Object => ({
  dateTo: moment(date).parseZone().add(1, 'days').format(DATE_FORMAT),
  dateFrom: moment(date).parseZone().add(-365, 'days').format(DATE_FORMAT),
  offset: `+${date.split('+')[1]}`,
});


export const getCurrentYearDateRangeForQuery = (date: string | null): Object => ({
  dateTo: moment(date).parseZone().format(DATE_FORMAT),
  dateFrom: moment(date).parseZone().format(DATE_FORMAT),
  offset: `+${date.split('+')[1]}`,
});
