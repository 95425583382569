import React from 'react';
import styled from 'styled-components';
import * as options from '../../common/options';

interface IconTruckProps {
    wasteStream: string,
    size: string,
    rotate: string,
    shadow: boolean
}

const SIconTruck = styled.div`
 width: ${(props) => props.size ? props.size : '18px'}; 
 height: ${(props) => props.size ? props.size : '18px'};
 transform: rotate(${(props) => props.rotate ? props.rotate : '90'}deg);
 display: flex;
 align-items: center;
 justify-content: center;
 img {
     height: ${(props) => props.size ? props.size : '18px'};
     filter: ${(props) => props.shadow ? 'drop-shadow(0 3px 4px #dadada)' : ''};
 }
`;

export const IconTruck = ({ wasteStream, size, rotate, shadow }: IconTruckProps) => {
  const imageFile = options.truckImages[wasteStream];
  return (
    <SIconTruck size={size} rotate={rotate} shadow={shadow}>
      <img
        alt="Truck"
        src={`${imageFile || options.truckImages.Other}`}
      />
    </SIconTruck>
  );
};
