
export default function getScriptMap(secure) {
  const apiVersion = 'v3';
  const codeVersion = '3.1';
  // get the relevant protocol for the HERE Maps API
  let protocol = '';
  if (secure === true) {
    protocol = 'https:';
  }
  const baseUrl = `${protocol}//js.api.here.com/${apiVersion}/${codeVersion}`;
  const coreScript = `${baseUrl}/mapsjs-core.js`;
  const serviceScript = `${baseUrl}/mapsjs-service.js`;
  const uiScript = `${baseUrl}/mapsjs-ui.js`;
  const dataScript = `${baseUrl}/mapsjs-data.js`;
  const mapEventsScript = `${baseUrl}/mapsjs-mapevents.js`;
  const clusterScript = `${baseUrl}/mapsjs-clustering.js`;
  const coreLegacyScript = `${baseUrl}/mapsjs-core-legacy.js`;
  const serviceLegacyScript = `${baseUrl}/mapsjs-service-legacy.js`;
  
  // return an array with all script names within
  return {
    coreScript,
    serviceScript,
    uiScript,
    dataScript,
    mapEventsScript,
    clusterScript,
    coreLegacyScript,
    serviceLegacyScript,
  };
}
