import styled from 'styled-components';


const SSearchResult = styled.div`
    display: flex;
    color: #004b6d;
    font-size: 1.25em;
    font-weight: normal;
    padding: 0 20px;
    height: 48px;
    align-items: center;
`;

export const SAddressResult = styled(SSearchResult)`
.map-icon {
    width: 36px;
}
.suburb {
    text-transform: capitalize;
}
`;

export const STruckResult = styled(SSearchResult)`
    .label {
        margin-left: 1em;
    }
`;
