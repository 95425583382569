
// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import Input from '../Input';
import validate from './validate';

type Props = {
  handleSubmit: Function,
  isUpdating: boolean,
  pristine: boolean,
};

function AccountForm({ handleSubmit, isUpdating, pristine }: Props) {
  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Form.Group widths="equal">
        <Field name="firstName" label="First Name" component={Input} type="text" />
        <Field name="lastName" label="Last Name" component={Input} type="text" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="email" label="Email" component={Input} type="email" className="half-flex" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="password" label="New Password" component={Input} type="password" />
        <Field name="confirmPassword" label="Confirm Password" component={Input} type="password" />
      </Form.Group>
      <div className="form-footer clearfix">
        <Button
          type="submit"
          size="massive"
          color="green"
          loading={isUpdating}
          floated="right"
          disabled={pristine || isUpdating}
        >
          Update
        </Button>
        <Link to="/live-collections">Cancel</Link>
      </div>
    </Form>
  );
}

// decorate
AccountForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'account',
  validate,
})(AccountForm);

export default AccountForm;
