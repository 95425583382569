import React from 'react';
import styled from 'styled-components';
import {MultiSelectNoNull} from '../../../../FilterMenu';

const SLiftsFilterMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 245px;
  color: white;
  background-color: #004b6d;
  border-radius: 6px;
  padding: 10px 20px 20px 20px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
  .ui.checkbox label {
    color: white;
  }
  &:after {
    bottom: 100%;
    left: 90%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #004b6d;
    border-width: 10px;
    margin-left: -10px;
  }
`;

export const SCloseButton = styled.button`
  width: 100%;
  margin-top: 15px;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: bold;
  color: #004b6d;
  background-color: #72c955;
  border: none;
  border-radius: 7px;
  padding: 14px 21px;
  cursor: pointer;
  transition: 200ms background-color;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #82dd64;
  }
`;

type LiftsFilterMenuProps = {
  toggleFilter: Function,
  setFilters: Function,
  options: Object,
};

export const LiftsFilterMenu = ({
  toggleFilter,
  setFilters,
  options,
}: LiftsFilterMenuProps) => {
  return (
    <SLiftsFilterMenu>
      <MultiSelectNoNull
        name="Lifts"
        options={options}
        setFilters={setFilters}
        style={{ padding: 0 }}
        hideHeadline></MultiSelectNoNull>
      <SCloseButton onClick={toggleFilter}>Done</SCloseButton>
    </SLiftsFilterMenu>
  );
};
export default LiftsFilterMenu;
