import { Observable } from 'rxjs/Observable';
import { ajax, handleError, maxTimeout } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { RESET_SEARCH } from './search';
import {
  getLiveJobStatusFilters,
  constructServiceTypeFilters,
  getJobsTableFilters,
} from '../../common/filters';
import { showMessage } from './messages';

const LIVEJOB_MAP_REQUEST_TRUCKS = 'LIVEJOB_MAP_REQUEST_TRUCKS';
const LIVEJOB_MAP_RECEIVE_TRUCKS = 'LIVEJOB_MAP_RECEIVE_TRUCKS';

const LIVEJOB_MAP_REQUEST_JOB_STATUS = 'LIVEJOB_MAP_REQUEST_JOB_STATUS';
const LIVEJOB_MAP_RECEIVE_JOB_STATUS = 'LIVEJOB_MAP_RECEIVE_JOB_STATUS';

const LIVEJOB_MAP_SET_ALERT_FILTER = 'LIVEJOB_MAP_SET_ALERT_FILTER';
const LIVEJOB_MAP_SET_TRUCK_FILTER = 'LIVEJOB_MAP_SET_TRUCK_FILTER';

const LIVEJOB_MAP_REQUEST_LIVEJOB_MAP_LAYER =
  'LIVEJOB_MAP_REQUEST_LIVEJOB_MAP_LAYER';
const LIVEJOB_MAP_RECEIVE_MAP_LAYER = 'LIVEJOB_MAP_RECEIVE_MAP_LAYER';

const LIVEJOB_MAP_SET_CENTER_ADDRESS = 'LIVEJOB_MAP_SET_CENTER_ADDRESS';
const LIVEJOB_MAP_RESET_CENTER_ADDRESS = 'LIVEJOB_MAP_RESET_CENTER_ADDRESS';

const LIVEJOB_MAP_REQUEST_ADDRESS = 'LIVEJOB_MAP_REQUEST_ADDRESS';
const LIVEJOB_MAP_RECEIVE_ADDRESS = 'LIVEJOB_MAP_RECEIVE_ADDRESS';
const RESET_LIVE_JOB_MARKERS = 'LIVE_JOB_RESET_LIVE_JOB_MARKERS';

const LIVEJOB_MAP_REQUEST_BIN_IMAGE_PREVIEW =
  'LIVEJOB_MAP_REQUEST_BIN_IMAGE_PREVIEW';
const LIVEJOB_MAP_RECEIVE_BIN_IMAGE_PREVIEW =
  'LIVEJOB_MAP_RECEIVE_BIN_IMAGE_PREVIEW';

const LIVEJOB_MAP_REQUEST_ALL_BIN_IMAGES = 'LIVEJOB_MAP_REQUEST_ALL_BIN_IMAGES';
const LIVEJOB_MAP_RECEIVE_ALL_BIN_IMAGES = 'LIVEJOB_MAP_RECEIVE_ALL_BIN_IMAGES';

const LIVEJOB_SET_USER_INTERACTION = 'LIVEJOB_SET_USER_INTERACTION';

const LIVE_JOB_MAP_REQUEST_ALERTS = 'LIVE_JOB_MAP_REQUEST_ALERTS';
const LIVE_JOB_MAP_RECEIVE_ALERTS = 'LIVE_JOB_MAP_RECEIVE_ALERTS';

const LIVE_JOB_REQUEST_JOB_SERVICE_TYPES = 'LIVE_JOB_REQUEST_JOB_SERVICE_TYPES';
const LIVE_JOB_RECEIVE_JOB_SERVICE_TYPES = 'LIVE_JOB_RECEIVE_JOB_SERVICE_TYPES';

const RESET_IS_FETCHING = 'RESET_IS_FETCHING';
const LIVEJOB_RESET_USER_INTERACTION = 'LIVEJOB_RESET_USER_INTERACTION';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const requestLiveJobServiceType = (payload) => ({
  type: LIVE_JOB_REQUEST_JOB_SERVICE_TYPES,
  payload,
});

export const receiveLiveJobServiceTypes = (payload) => ({
  type: LIVE_JOB_RECEIVE_JOB_SERVICE_TYPES,
  payload,
});

export const resetLiveJobMarkers = () => ({
  type: RESET_LIVE_JOB_MARKERS,
});

export const requestAlertsMap = (payload, territoryId) => ({
  type: LIVE_JOB_MAP_REQUEST_ALERTS,
  date: payload,
  territoryId,
});

const receiveAlerts = (payload) => ({
  type: LIVE_JOB_MAP_RECEIVE_ALERTS,
  payload,
});

export const requestLiveJobMap = (payload) => ({
  type: LIVEJOB_MAP_REQUEST_TRUCKS,
  payload,
});

export const requestLiveJobStatus = (payload) => ({
  type: LIVEJOB_MAP_REQUEST_JOB_STATUS,
  payload,
});

const receiveLiveJobStatus = (payload) => ({
  type: LIVEJOB_MAP_RECEIVE_JOB_STATUS,
  payload,
});

const receiveLiveJobs = (payload) => ({
  type: LIVEJOB_MAP_RECEIVE_TRUCKS,
  payload,
});
export const requestMapLayer = (payload) => ({
  type: LIVEJOB_MAP_REQUEST_LIVEJOB_MAP_LAYER,
  payload,
});

export const receiveMapLayer = (payload) => ({
  type: LIVEJOB_MAP_RECEIVE_MAP_LAYER,
  payload,
});

export const setCenterAddress = (payload) => ({
  type: LIVEJOB_MAP_SET_CENTER_ADDRESS,
  payload,
});

export const resetCenterAddress = () => ({
  type: LIVEJOB_MAP_RESET_CENTER_ADDRESS,
});

const receiveAddress = (payload) => ({
  type: LIVEJOB_MAP_RECEIVE_ADDRESS,
  payload,
});

export const setAddressWithJobs = (payload) => ({
  type: LIVEJOB_SET_ADDRESS_WITH_JOBS,
  payload,
});

export const setUserInteraction = (payload) => ({
  type: LIVEJOB_SET_USER_INTERACTION,
  payload,
});

export const resetLiveJobIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

export const resetUserInteraction = () => ({
  type: LIVEJOB_RESET_USER_INTERACTION,
})

/** *************************
 *           EPICS
 **************************** */

const binEventEndpoint = (date, territoryId) => {
  const { from, limit } = {
    from: '0',
    limit: '1000',
  };
  const { dateTo, dateFrom, offset } = date;
  return `/event/binevents?territoryId=${territoryId}&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
    offset,
  )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(
    offset,
  )}&from=${from}&limit=${limit}`;
};

const requestAlertsMapEpic = (action$) =>
  action$
    .ofType(LIVE_JOB_MAP_REQUEST_ALERTS)
    .switchMap((action) =>
      ajax.get(
        binEventEndpoint(action.date, action.territoryId),
        null,
        (payload) => Observable.of(receiveAlerts(payload.response.binEvents)),
      ),
    );

const requestLiveJobMapEpic = (action$) =>
  action$.ofType(LIVEJOB_MAP_REQUEST_TRUCKS).switchMap((action) => {
    const territoryId = action.payload.territoryId;
    const date = action.payload.date.dateFrom;

    const serviceType = action.payload.filters.serviceType;
    const status = action.payload.filters.status;
    const includeAssigned =
      status.length === 0 || status.includes('all')
        ? true
        : status.includes('Assigned');
    const IncludeCompleted =
      status.length === 0 || status.includes('all')
        ? true
        : status.includes('Completed');
    const IncludeException =
      status.length === 0 || status.includes('all')
        ? true
        : status.includes('Exception');

    let endpoint = `/jobs/map?territoryId=${territoryId}&date=${date}&IncludeAssigned=${includeAssigned}&IncludeCompleted=${IncludeCompleted}&IncludeExceptions=${IncludeException}`;

    if (serviceType.length > 0 && !serviceType.find((i) => i === 'all')) {
      endpoint += `&serviceType=${serviceType}`;
    }

    return ajax.get(endpoint, null, (payload) => {
      return Observable.of(receiveLiveJobs(payload.response));
    });
  });
// request trucks - fetch once, then poll every 2 mins
const requestJobServiceTypeEpic = (action$) =>
  action$.ofType(LIVE_JOB_REQUEST_JOB_SERVICE_TYPES).switchMap((action) => {
    const territoryId = action.payload ? action.payload.territoryId : null;
    return ajax.get(
      territoryId
        ? `/jobServiceTypes?territoryId=${territoryId}`
        : `/jobServiceTypes`,
      null,
      (payload) => {
        return Observable.of(
          receiveLiveJobServiceTypes(payload.response.serviceTypes),
        );
      },
    );
  });

const requestLiveJobStatusEpic = (action$) =>
  action$.ofType(LIVEJOB_MAP_REQUEST_JOB_STATUS).switchMap((action) => {
    const territoryId = action.payload.territoryId;
    const date = action.payload.date.dateFrom;
    return ajax.get(
      `/jobs/stats?territoryId=${territoryId}&date=${date}`,
      null,
      (payload) => {
        return Observable.of(receiveLiveJobStatus(payload.response));
      },
    );
  });

// get map layer for given account
const formatMapLayer = (councilName) =>
  councilName.replace(' ', '_').replace('/', '%2F');

const requestMapLayerEpic = (action$) =>
  action$.ofType(LIVEJOB_MAP_REQUEST_LIVEJOB_MAP_LAYER).switchMap((action) => {
    const url = `https://cleanaviewstaticprd.blob.core.windows.net/boundaries/LGA-${formatMapLayer(
      action.payload,
    )}.json`;
    const h = { Accept: 'application/json' };
    h['Content-Type'] = 'application/json';
    const request = {
      url,
      headers: h,
    };
    return Observable.ajax(request).timeout(maxTimeout).flatMap((payload) =>
      Observable.of(receiveMapLayer(payload.response)),
    ).catch((e) => handleError(e));
  });

// get address
const createAddressUrl = (payload) => {
  const { id, activeDate, dayRange, range, lat, lng, territoryId } = payload;

  let base = `/address?addressId=${id}&territoryId=${territoryId}&lat=${lat}&lng=${lng}`;
  if (dayRange) {
    const { dateFrom, dateTo, offset } = dayRange;
    base =
      base +
      `&dateFrom=${dateFrom}T00:00:00${encodeURIComponent(
        offset,
      )}&dateTo=${dateFrom}T23:59:59${encodeURIComponent(offset)}`;
  }
  if (range) {
    base = base + `&range=${range}`;
  }
  return base;
};

const requestAddressEpic = (actions$) =>
  actions$.ofType(LIVEJOB_MAP_REQUEST_ADDRESS).mergeMap((action) => {
    return Observable.zip(
      // get work orders and bin events
      ajax.get(createAddressUrl(action.payload)),
      // get truck lifts
      (address) => ({
        ...address.response,
        position: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
        truckLifts: formatTruckLiftsFromAddressResponse(address.response),
      }),
    ).flatMap((payload) => {
      return Observable.of(
        setCenterAddress({
          ...action.payload,
          ...{ dataRadius: payload.dataRadius },
        }),
        receiveAddress(payload),
      );
    });
  });

const formatTruckLiftsFromAddressResponse = (response) => {
  if (!response || !response.trucks) {
    return [];
  }
  return response.trucks
    .map((truck) =>
      truck.lifts.map((lift) => ({
        created: lift.created,
        position: lift.position,
        wasteStream: truck.wasteStream,
        truckId: truck.truckId,
        uuid: lift.uuid,
      })),
    )
    .reduce((acc, val) => acc.concat(val), []);
};

export const formatTruckLiftsResponse = (trucks) =>
  trucks
    .map((truck) =>
      truck.lifts.map((lift) => ({
        created: lift.created,
        position: lift.position,
        wasteStream: truck.wasteStream,
        truckId: truck.truckId,
        uuid: lift.uuid,
      })),
    )
    .reduce((acc, val) => acc.concat(val), []);

const receiveAddressEpic = (actions$) =>
  actions$.ofType(LIVEJOB_MAP_RECEIVE_ADDRESS).switchMap((action) => {
    if (action.payload.binEvents) {
      return Observable.of(requestBinImagePreview(action.payload.binEvents, 0));
    }
    return Observable.empty();
  });

const initialState = {
  isFetching: true,
  liveJobStatus: null,
  isFetchingLiveJobStatus: false,
  isFetchingLiveJobs: false,
  fetchingImages: false,
  providedTimeLocalCouncil: null,
  serviceTypes: [],
  centerOnAddress: {
    dataRadius: 0,
    id: 0,
    infirmAssistance: false,
    streetName: '',
    streetNumber: 0,
    streetType: '',
    suburb: '',
    postCode: 0,
  },
  liveJobMarkers: [],
  clickedLiveJobs: null,
  statusFilter: 'all',
  workOrderTypeFilter: 'all',
  mapLayer: null,
  sidebar: false,
  addressInfo: {
    dataRadius: null,
    workOrders: [],
    binEvents: [],
    truckLifts: [],
    outcome: { error: false },
  },
  images: [],
  filterMenus: {
    status: {
      name: 'Status',
      options: ['all'],
      defaultValue: 'all',
      type: 'multiSelectNoNull',
    },
    serviceType: {
      name: 'Service Type',
      options: ['all'],
      defaultValue: null,
      type: 'multiSelectNoNull',
    },
  },
  jobsFilterMenus: {
    status: {
      name: 'Status',
      options: ['all'],
      defaultValue: 'all',
      type: 'multiSelectNoNull',
    },
    serviceType: {
      name: 'Service Type',
      options: ['all'],
      defaultValue: null,
      type: 'multiSelectNoNull',
    },
  },
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case RESET_LIVE_JOB_MARKERS:
      return {
        ...state,
        liveJobMarkers: [],
      };
    case HAS_UNAUTHENTICATED:
      return initialState;
    case LIVE_JOB_MAP_RECEIVE_ALERTS:
      return {
        ...state,
        isFetching: false,
        alertMarkers: action.payload,
      };
    case LIVE_JOB_MAP_RECEIVE_ALERTS:
      return {
        ...state,
        isFetching: false,
        alertMarkers: action.payload,
      };
    case LIVE_JOB_RECEIVE_JOB_SERVICE_TYPES:
      const savedServiceTypes = state?.filterMenus?.serviceType?.options;
      const intialNumberOfKeys = 2;
      const shouldUseSavedOptions = savedServiceTypes ? Object.keys(savedServiceTypes).length > intialNumberOfKeys : false; 
      const filterMenus = {
        status: {
          name: 'Status',
          options: shouldUseSavedOptions ? state?.filterMenus?.status?.options : getLiveJobStatusFilters(),
          defaultValue: 'all',
          type: 'multiSelectNoNull',
        },
        serviceType: {
          name: 'Service Type',
          options: shouldUseSavedOptions ? savedServiceTypes : constructServiceTypeFilters(action.payload),
          defaultValue: null,
          type: 'multiSelectNoNull',
        },
      };

      const jobsFilterMenus = {
        status: {
          name: 'Status',
          options: getJobsTableFilters(),
          defaultValue: 'all',
          type: 'multiSelectNoNull',
        },
        serviceType: {
          name: 'Service Type',
          options: constructServiceTypeFilters(action.payload),
          defaultValue: null,
          type: 'multiSelectNoNull',
        }
      };

      return {
        ...state,
        isFetching: false,
        serviceTypes: action.payload,
        filterMenus: filterMenus,
        jobsFilterMenus: jobsFilterMenus,
      };      
    case LIVEJOB_MAP_REQUEST_TRUCKS:
      return {
        ...state,
        isFetching: true,
        isFetchingLiveJobs: true,
      };
    case LIVEJOB_MAP_REQUEST_JOB_STATUS:
      return {
        ...state,
        isFetching: true,
        isFetchingLiveJobStatus: true,
      };
    case LIVEJOB_MAP_RECEIVE_MAP_LAYER:
      return {
        ...state,
        mapLayer: action.payload,
      };
    case LIVEJOB_MAP_RECEIVE_JOB_STATUS:
      return {
        ...state,
        isFetching: false,
        isFetchingLiveJobStatus: false,
        liveJobStatus: action.payload.jobStats,
      };
    case LIVEJOB_MAP_RECEIVE_TRUCKS:
      const selectedAddressExists = state.userInteraction?.addressWithJobs ? action.payload.addresses.filter(addressWithJobs => addressWithJobs.address.addressNo == state.userInteraction.addressWithJobs.address.addressNo).length > 0 : false;

      return {
        ...state,
        isFetching: false,
        isFetchingLiveJobs: false,
        providedTimeLocalCouncil: action.payload.accountTime,
        liveJobMarkers: action.payload.addresses,
        userInteraction:  
        {
          ...state.userInteraction,          
          addressWithJobs: selectedAddressExists ? state.userInteraction?.addressWithJobs : null,
        },
      };
    case LIVEJOB_MAP_SET_ALERT_FILTER:
      return {
        ...state,
        alertFilter: action.payload,
      };
    case LIVEJOB_MAP_SET_TRUCK_FILTER:
      return {
        ...state,
        truckFilter: action.payload,
      };

    case LIVEJOB_MAP_SET_CENTER_ADDRESS:
      return {
        ...state,
        centerOnAddress: action.payload,
      };
    case LIVEJOB_MAP_RESET_CENTER_ADDRESS:
      return {
        ...state,
        centerOnAddress: initialState.centerOnAddress,
      };
    case LIVEJOB_MAP_REQUEST_ADDRESS:
      return {
        ...state,
        isFetching: true,
        sidebar: true,
      };
    case LIVEJOB_MAP_RECEIVE_ADDRESS:
      return {
        ...state,
        addressInfo: action.payload,
        isFetching: false,
      };
    case LIVEJOB_MAP_REQUEST_BIN_IMAGE_PREVIEW:
      return {
        ...state,
      };
    case LIVEJOB_MAP_RECEIVE_BIN_IMAGE_PREVIEW: {
      const addressInfo = state.addressInfo;
      if (action.image) {
        const match = addressInfo.binEvents.find(
          (binEvent) => binEvent.$id === action.eventId,
        );
        match.previewImage = action.image;
      }
      return {
        ...state,
        addressInfo,
      };
    }
    case LIVEJOB_MAP_REQUEST_ALL_BIN_IMAGES:
      return {
        ...state,
        fetchingImages: true,
      };
    case LIVEJOB_MAP_RECEIVE_ALL_BIN_IMAGES:
      return {
        ...state,
        fetchingImages: false,
        images: action.payload,
      };
    case RESET_SEARCH:
      return {
        ...state,
        sidebar: false,
        addressInfo: initialState.addressInfo,
      };
    case LIVEJOB_SET_USER_INTERACTION:
      return {
        ...state,
        clickedTruck: action.payload,
        userInteraction: {
          ...state.userInteraction,
          ...action.payload
        },
        /*
        {
          addressWithJobs: object
          jobNumber: string
          mapZoom: number
          mapCenter: {lat, lng}
          activeDate: Date
          activeDateString: string
          isToday: boolean          
        }
        */      
      };
    case LIVEJOB_RESET_USER_INTERACTION:
      resetFilterMenus(state.filterMenus);
      return {
        ...state,
        userInteraction: {
          mapZoom: undefined,
          mapCenter: undefined,
          addressWithJobs: undefined,
          activeDate: undefined,
          activeDateString: undefined,
        },        
      }    
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
        liveJobStatus: undefined,
      }
    default:
      return state;
  }
}

export const liveJobEpic = {
  requestLiveJobMapEpic,
  requestAddressEpic,
  receiveAddressEpic,
  requestMapLayerEpic,
  requestAlertsMapEpic,
  requestLiveJobStatusEpic,
  requestJobServiceTypeEpic,
};

export function resetFilterMenus(filterMenus) {
  for (let key in filterMenus) {
    const menu = filterMenus[key];
    for (let option in menu.options) {
      if (menu.options[option] && typeof menu.options[option] === 'object') {
        if (menu.options[option].value !== 'all') {
          menu.options[option].isActive = false;
        } else {
          menu.options[option].isActive = true;
        }
      }
    }
  }
}

