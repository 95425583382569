// @flow

import React from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import TabContentListTickets from './TabContentLists/Tickets';
import TabBinEventsList from './TabContentLists/BinEvents';
import SSingleAddressCard from '../SingleAddressCard';
import { WorkOrders, BinEvents, Lifts } from './index.d';
import TabContentListLifts from './TabContentLists/Lifts';
import HMapMethods from '../HereMap/instance/h-map-methods';

const FIELDS_FOR_TABS = [
  {
    name: 'LIFTS',
    key: 'truckLifts',
  },
  {
    name: 'ALERTS',
    key: 'binEvents',
  },
  {
    name: 'TICKETS',
    key: 'workOrders',
  },
];

const SAddressSearchWrapper = styled.div`
  border-top: 6px solid #0095e3;
  float: left;
  width: 100%;
`;

const renderTabHeader = (
  tabName: string,
  numberOfCardsInTab: number,
): React.Element => (
  <Menu.Item key={`tab-${tabName}`}>
    {tabName}
    <Label>{numberOfCardsInTab}</Label>
  </Menu.Item>
);

const getAlertsInRadius = (addressInfo, alertMarkers) =>
  addressInfo.position
    ? alertMarkers.filter(
        (alert) =>
          new HMapMethods().alertDistanceFromSearchResult(alert.position, {
            lat: addressInfo.position.lat,
            lng: addressInfo.position.lng,
          }) <= addressInfo.dataRadius,
      )
    : [];

const getTabPanes = (
  addressInfo: AddressInfo,
  setClickedTruck: Function,
  alertMarkers,
  setActiveEvent,
  activeEvent,
  handleThumb: Function,
) => {
  const mergedTabs = {
    ...addressInfo,
    ...{ binEvents: getAlertsInRadius(addressInfo, alertMarkers) },
  };

  const countTicketCount = (tickets) => {
    return [...new Set(tickets.map(item => item.id))].length;
  }

  return FIELDS_FOR_TABS.map((tab) => ({
    menuItem: renderTabHeader(
      tab.name,
      mergedTabs[tab.key] ? tab.name === "TICKETS" ? countTicketCount(mergedTabs[tab.key]) :  mergedTabs[tab.key].length : 0,
    ),
    render: () =>
      renderTabBody(
        mergedTabs[tab.key],
        mergedTabs.dataRadius,
        tab.key,
        setClickedTruck,
        addressInfo,
        setActiveEvent,
        activeEvent,
        handleThumb,
      ),
  }));
};

const renderTabBody = (
  bodyContent: Lifts | BinEvents | WorkOrders,
  dataRadius: Number,
  type: string,
  setClickedTruck: Function,
  addressInfo: AddressInfo,
  setActiveEvent,
  activeEvent,
  handleThumb: Function,
): React.Element => {
  switch (type) {
    case 'truckLifts':
      return (
        <TabContentListLifts
          dataRadius={dataRadius}
          truckLifts={bodyContent}
          addressInfo={addressInfo}
          setClickedTruck={setClickedTruck}
          setActiveEvent={setActiveEvent}
          activeEvent={activeEvent}
        />
      );
    case 'workOrders':
      return (
        <Tab.Pane className={'grey-background'}>
          <TabContentListTickets workOrders={bodyContent} />
        </Tab.Pane>
      );
    case 'binEvents':
      return (
        <Tab.Pane className={'grey-background'}>
          <TabBinEventsList
            BinEventsList={bodyContent}
            setClickedTruck={setClickedTruck}
            handleThumb={handleThumb}
          />
        </Tab.Pane>
      );
    default:
      console.error(
        `Unexpected outcome in renderTabBody type ${type} is not valid`,
      );
      return (
        <Tab.Pane className={'transparent-background'}>
          <code>{JSON.stringify(bodyContent)}</code>
        </Tab.Pane>
      );
  }
};

type AddressInfo = {
  truckLifts: Array<Lifts>,
  binEvents: Array<BinEvents>,
  workOrders: Array<WorkOrders>,
};

type Props = {
  addressSearchValue: string,
  addressInfo: AddressInfo,
  setClickedTruck: Function,
  alertMarkers: Array<Object>,
  setActiveEvent: Function,
  activeEvent: Object,
  handleThumb: Function,
};

const AddressSidebar = ({
  addressSearchValue,
  addressInfo,
  setClickedTruck,
  alertMarkers,
  setActiveEvent,
  activeEvent,
  handleThumb,
}: Props): React.Element => (
  <SAddressSearchWrapper id={'address-sidebar-wrapper'}>
    <SSingleAddressCard>{addressSearchValue}</SSingleAddressCard>
    <Tab
      panes={getTabPanes(
        addressInfo,
        setClickedTruck,
        alertMarkers,
        setActiveEvent,
        activeEvent,
        handleThumb,
      )}
    />
  </SAddressSearchWrapper>
);

export default AddressSidebar;
