// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';

type Props = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  activeElement: string,
  elementsSelected: boolean,
};

export default function SidebarMenuItem({ item, handleClick, activeElement, elementsSelected }: Props) {
  const menuItem = (
    <Grid.Row
      className={{ 'menu-item': true, active: (item.key === activeElement), disabled: elementsSelected && (activeElement !== item.key) }}
      onClick={() => (handleClick(item.value, elementsSelected))}
      role="menuitem"
      key={item.key}
    >
      <span>{item.text}</span><Icon name="chevron right" />
    </Grid.Row>
  );
  return (
    menuItem
  );
}
