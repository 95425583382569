import { Observable } from 'rxjs/Observable';
import { ticketStatuses } from '../../common/options';
import { ajax } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { RESET_SEARCH } from './search';

/** *************************
 *          CONSTANTS
 **************************** */

const POST_TICKET = 'POST_TICKET';
const POST_SUCCESS = 'POST_SUCCESS';

const REQUEST_TICKETS = 'REQUEST_TICKETS';
const REQUEST_MORE_TICKETS = 'REQUEST_MORE_TICKETS';
const RECEIVE_TICKETS = 'RECEIVE_TICKETS';

const SELECT_STATUSES = 'SELECT_STATUSES';
const SELECT_TYPE = 'SELECT_TYPE';
const RESET_TICKET_STATE = 'RESET_TICKET_STATE';

const CLEAR_TICKETS = 'CLEAR_TICKETS';
const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const postTicket = (payload) => ({
  type: POST_TICKET,
  payload,
});

export const postSuccess = (payload) => ({
  type: POST_SUCCESS,
  payload,
});

export const requestTickets = (
  territoryId,
  selectedStatuses,
  selectedType,
  from,
  limit,
  addressId,
) => ({
  type: REQUEST_TICKETS,
  territoryId,
  selectedStatuses,
  selectedType,
  from,
  limit,
  addressId,
});

export const requestMoreTickets = (
  territoryId,
  selectedStatuses,
  selectedType,
  from,
  limit,
  addressId,
) => ({
  type: REQUEST_MORE_TICKETS,
  territoryId,
  selectedStatuses,
  selectedType,
  from,
  limit,
  addressId,
});

export const receiveTickets = (payload) => ({
  type: RECEIVE_TICKETS,
  payload,
});

export const selectStatuses = (selectedStatuses) => ({
  type: SELECT_STATUSES,
  selectedStatuses,
});

export const selectType = (selectedType) => ({
  type: SELECT_TYPE,
  selectedType,
});

export const clearTickets = () => ({
  type: CLEAR_TICKETS,
});

export const resetTicketState = () => ({
  type: RESET_TICKET_STATE,
});

export const resetTicketsIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

/** *************************
 *           EPICS
 **************************** */

const workorderEndpoint = (args) => {
  const {
    territoryId,
    from,
    limit,
    selectedStatuses,
    addressId,
    selectedType,
  } = args;

  let queryString = `/workorder?territoryId=${territoryId}&from=${from}&limit=${limit}`;
  if (selectedStatuses) {
    queryString += `&status=${selectedStatuses}`;
  }
  if (selectedType) {
    queryString += `&workOrderType=${selectedType}`;
  }
  if (addressId) {
    queryString += `&addressId=${addressId}`;
  }
  console.log('workorderEndpoint: ', queryString);
  return queryString;
};
// get tickets
const requestTicketsEpic = (action$) =>
  action$.ofType(REQUEST_TICKETS, REQUEST_MORE_TICKETS).switchMap((action) => {
    return ajax.get(workorderEndpoint(action), null, (payload) =>
      Observable.of(receiveTickets(payload.response.workOrders)),
    );
  });

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  isPosting: false,
  items: [],
  selectedStatuses: {},
  selectedType: '',
  from: 0,
  limit: 20,
  addressId: null,
};

ticketStatuses.forEach((ticketStatus) => {
  initialState.selectedStatuses[ticketStatus.value] = true;
  return true;
});

export default function tickets(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case POST_TICKET:
      return {
        ...state,
        isPosting: true,
      };
    case POST_SUCCESS:
      return {
        ...state,
        isPosting: false,
      };
    case REQUEST_TICKETS:
      return {
        ...state,
        isFetching: true,
        from: action.from,
        limit: action.limit,
        addressId: action.addressId,
        territoryId: action.territoryId,
      };
    case REQUEST_MORE_TICKETS:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreTickets: true,
        from: action.from,
        limit: action.limit,
        addressId: action.addressSearchId,
        territoryId: action.territoryId,
      };
    case RECEIVE_TICKETS:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreTickets: false,
        items: [...state.items].concat(action.payload),
        showLoadMore: action.payload.length >= state.limit,
      };
    case SELECT_STATUSES:
      return {
        ...state,
        selectedStatuses: action.selectedStatuses,
        items: [],
      };
    case SELECT_TYPE:
      return {
        ...state,
        selectedType: action.selectedType,
        items: [],
      };
    case RESET_SEARCH:
      return {
        ...state,
        addressId: null,
      };
    case CLEAR_TICKETS:
      return {
        ...state,
        items: [],
      };
    case RESET_TICKET_STATE:
      return {
        ...state,
        ...initialState,
        items: [],
      };
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreTickets: false,
      };
    default:
      return state;
  }
}

export const ticketsEpics = {
  requestTicketsEpic,
};