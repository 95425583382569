// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Menu, Icon, Header, Grid, Container } from 'semantic-ui-react';
import Moment from 'react-moment';
import * as accountsActionCreators from '../../redux/modules/accounts';
import AddAccountForm from '../../components/AddAccountForm';

class EditAccountContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(values) {
    this.props.updateAccounts(values);
  }

  props: {
    history: Object,
    isUpdating: boolean,
    updateAccounts: Function,
    location: Object,
    // account: number,
  };

  render() {
    // const { account } = this.props.account;
    const { account } = this.props.location.state.item;
    const modules = {};
    if (account.modules) {
      account.modules.forEach((m) => {
        modules[m.name] = true;
      });
    }
    const initialValues = {
      ...account,
      modules: { ...modules },
    };
    const createdTimestamp = account.created.replace(/\+0$/, '+00:00');

    return (
      <div className="comp secondary-comp">
        <Helmet
          title="Edit Account"
          meta={[{ name: 'Edit Account', content: 'Customer Portal' }]}
        />
        <Menu secondary stackable>
          <span
            role="button"
            tabIndex={0}
            className="link item"
            onClick={this.props.history.goBack}
          >
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            {account.name}
          </span>
        </Menu>
        <Container text className="account-edit">
          <Grid className="multi-header">
            <Grid.Row>
              <Grid.Column floated="left" mobile={16} tablet={10} computer={10}>
                <Header as="h1">{account.name}</Header>
                <Header
                  as="h3"
                  style={{ fontSize: '30px', marginTop: '0', color: '#004A6E' }}
                  className="computer-only"
                >
                  Edit Account
                </Header>
              </Grid.Column>
              <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
                <span className="timestamp">
                  Created{' '}
                  <Moment format="DD MMMM YYYY">{createdTimestamp}</Moment>
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <AddAccountForm
            onSubmit={this.handleUpdate}
            isUpdating={this.props.isUpdating}
            initialValues={initialValues}
            history={history}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    // (state, ownProps) => ({
    //   account: state.accounts.items.find((i) => i.account.id === parseInt(ownProps.match.params.id, 10)),
    isUpdating: state.accounts.isUpdating,
  }),
  (dispatch) => bindActionCreators(accountsActionCreators, dispatch),
)(EditAccountContainer);
