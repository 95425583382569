
import { Observable } from 'rxjs/Observable';
import { ajax } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { showMessage, SHOW_MESSAGE } from './messages';
import { accountModules } from '../../common/options';

/** *************************
*          CONSTANTS
**************************** */

const REQUEST_USERS = 'REQUEST_USERS';
const RECEIVE_USERS = 'RECEIVE_USERS';

const POST_USER = 'POST_USER';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';

const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL';
const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL';

const RESEND_INVITE = 'RESEND_INVITE';
const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS';

/** *************************
*       ACTION CREATORS
**************************** */

export const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const receiveUsers = (payload) => ({
  type: RECEIVE_USERS,
  payload,
});

export const postUser = (payload, accountId, currentAccountId) => ({
  type: POST_USER,
  payload,
  accountId,
  currentAccountId,
});

const postUserSuccess = (payload, accountId, currentAccountId) => ({
  type: POST_USER_SUCCESS,
  payload,
  accountId,
  currentAccountId,
});

export const updateUser = (payload, accountId) => ({
  type: UPDATE_USER,
  payload,
  accountId,
});

export const updateUserSuccess = (payload, accountId) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
  accountId,
});

export const deleteUser = (payload, callback) => ({
  type: DELETE_USER,
  payload,
  callback,
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  id,
});

export const openDeleteModal = () => ({
  type: OPEN_DELETE_MODAL,
});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_MODAL,
});

export const resendInvite = (id) => ({
  type: RESEND_INVITE,
  id,
});

export const resendInviteSuccess = () => ({
  type: RESEND_INVITE_SUCCESS,
});

/** *************************
*           EPICS
**************************** */

// add user
const postUserEpic = (action$) =>
  action$.ofType(POST_USER)
    .switchMap((action) => {
      const modules = [];
      accountModules.forEach((module) => {
        if (!!action.payload.modules && action.payload.modules[module.name]) {
          modules.push(module);
        }
      });
      return ajax.post('/user', {
        accountId: action.accountId,
        user: {
          ...action.payload,
          suspended: false,
          modules,
        },
      }, null, (payload) => Observable.of(
        postUserSuccess(payload.response.user, action.accountId, action.currentAccountId),
        showMessage('Success', 'User has been added', 'success')
      ));
    });

// request users
const requestUsersEpic = (action$) =>
  action$.ofType(REQUEST_USERS)
    .switchMap(() =>
      ajax.get('/user', null, (payload) => Observable.of(
        receiveUsers(payload.response.users),
      ))
    );

// update user
const updateUserEpic = (action$) =>
  action$.ofType(UPDATE_USER)
    .switchMap((action) => {
      const modules = [];
      accountModules.forEach((module) => {
        if (!!action.payload.modules && action.payload.modules[module.name]) {
          modules.push(module);
        }
      });
      return ajax.post(`/user/${action.payload.id}`, {
        user: {
          ...action.payload,
          modules,
        },
      }, null, (payload) => {
        if (payload.response.outcome.error) {
          return Observable.of(
            showMessage('Error', payload.response.outcome.message, 'error')
          );
        }

        return Observable.of(
          updateUserSuccess(payload.response.user, action.accountId),
          showMessage('Success', 'User details updated', 'success')
        );
      });
    });

// suspend user
const deleteUserEpic = (action$) =>
  action$.ofType(DELETE_USER)
    .switchMap((action) =>
      ajax.delete(`/user/${action.payload.id}`, null, () => {
        if (action.callback) {
          action.callback();
        }
        return Observable.of(
          closeDeleteModal(),
          deleteUserSuccess(action.payload.id),
          showMessage('Success', 'User has been deleted', 'success'),
        );
      })
    );

// resend invite
const resendInviteEpic = (action$) =>
  action$.ofType(RESEND_INVITE)
    .switchMap((action) =>
      ajax.get(`/user/send_invite/${action.id}`, null, () => Observable.of(
        resendInviteSuccess(),
        showMessage('Success', 'Invite has been resent', 'success')
      ))
    );

/** *************************
*          REDUCERS
**************************** */

const initialState = {
  isFetching: false,
  fetchedPageCount: 0,
  lastUpdated: 0,
  isPosting: false,
  isUpdating: false,
  isDeleting: false,
  isInviting: false,
  deleteModal: false,
  items: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case REQUEST_USERS:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        isUpdating: true,
      };
    case POST_USER:
      return {
        ...state,
        isPosting: true,
      };
    case DELETE_USER:
      return {
        ...state,
        isDeleting: true,
      };
    case OPEN_DELETE_MODAL:
      return {
        ...state,
        deleteModal: true,
      };
    case CLOSE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: false,
      };
    case RECEIVE_USERS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case UPDATE_USER_SUCCESS: {
      const prevUpdateItems = state.items;
      for (let i = 0; i < prevUpdateItems.length; i += 1) {
        if (prevUpdateItems[i].id === action.payload.id) {
          prevUpdateItems[i] = action.payload;
        }
      }
      return {
        ...state,
        items: prevUpdateItems,
        isUpdating: false,
      };
    }
    case POST_USER_SUCCESS: {
      const prevNewItems = state.items;
      // Only add if same account and users have previously been loaded
      if (prevNewItems.length > 0 && action.accountId === action.currentAccountId) {
        prevNewItems.push(action.payload);
      }
      return {
        ...state,
        items: prevNewItems,
        isPosting: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      const items = state.items;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].id === action.id) {
          items.splice(i, 1);
        }
      }
      return {
        ...state,
        items,
        isDeleting: false,
      };
    }
    case RESEND_INVITE:
      return {
        ...state,
        isInviting: true,
      };
    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        isInviting: false,
      };
    case SHOW_MESSAGE :
      return {
        ...state,
        isUpdating: false,
        isPosting: false,
        isDeleting: false,
        isFetching: false,
        isInviting: false,
      };
    default :
      return state;
  }
}

export const usersEpics = {
  postUserEpic,
  requestUsersEpic,
  updateUserEpic,
  deleteUserEpic,
  resendInviteEpic,
};
