import React from 'react';
import { Icon } from 'semantic-ui-react';
import {
  SMenuInnerWrapper,
  SCloseButton,
  SMenuToggleButton,
  SMenuWrapper,
  SResetButton,
} from '../AlertsTrucksFilter/index.sc';
import { MultiSelectNoNull } from '../FilterMenu';
import { Props, State } from '.';
import { IconChevron } from '../Icons/Chevron';
import { resetFilterMenus } from '../../redux/modules/liveJob';

export default class LiveJobFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      activeFilters: [],
      filterMenus: props.filterMenus,
    };
  }


  setFilters = (key, options) => {
    const { filterMenus } = this.props;
    filterMenus[key].options = options;
    const filterMenu = filterMenus[key].options;
    const activeFilters = Object.values(filterMenu).filter(
      (value) => !!value.isActive,
    );
    const payload = activeFilters.map((filter) => filter.value);
    this.setState({ filterMenus, activeFilters });
    this.props.handleFilter(key, payload);
  };

  toggleMenu = () =>
    this.setState({
      isOpen: !this.state.isOpen,
    });

  isFilterActive = () => {
    const { filterMenus } = this.props;

    const status = Object.values(
      Object.values(filterMenus.status.options),
    ).filter((option) => typeof option === 'object' && option.isActive && option.value !== 'all');

    const serviceTypes = Object.values(
      Object.values(filterMenus.serviceType.options),
    ).filter((option) => typeof option === 'object' && option.isActive && option.value !== 'all');

    return !!(status.length || serviceTypes.length);
  };

  resetFilter = () => {
    const { filterMenus } = this.props;

    resetFilterMenus(filterMenus);

    this.setState({
      activeFilters: [],
      filterMenus: filterMenus,
    });

    if (this.props.resetFilters) this.props.resetFilters();
  };

  renderMenuType = (key: string): React.ReactElement => {
    const { filterMenus, activeDate } = this.props;
    const menuType = filterMenus[key].type;

    switch (menuType) {
      case 'multiSelectNoNull':
        return (
          <MultiSelectNoNull
            key={key}
            name={filterMenus[key].name}
            containerStyle={this.props.containerStyle}
            options={filterMenus[key].options}
            activeDate={activeDate}
            setFilters={(options) => this.setFilters(key, options)}
          />
        );
      default:
        throw new Error(
          `renderMenuType can not map ${typeof menuType} ${menuType} to an menu`,
        );
    }
  };

  render() {
    const { filterMenus } = this.props;

    return (
      <div>
        <SMenuToggleButton
          role="presentation"
          className={'ui button dropdown selection user-dropdown'}>
          <div className="toggle" onClick={() => this.toggleMenu()}>
            <Icon
              name={'sliders'}
              style={{
                float: 'left',
                transform: 'rotate(270deg)',
                opacity: '1',
              }}
            />
            <span
              className={
                this.isFilterActive()
                  ? 'filter-status active'
                  : 'filter-status inactive'
              }></span>
            <span style={{ marginLeft: '0.5em' }}>{this.props.buttonText}</span>
            <IconChevron
              open={this.state.isOpen}
              style={{
                right: 'calc(42px + 1em)',
                position: 'absolute',
              }}></IconChevron>
          </div>
          <SResetButton onClick={this.resetFilter}>
            <Icon name={'undo'} />
          </SResetButton>
        </SMenuToggleButton>
        <SMenuWrapper
          className={`menu transition ${this.state.isOpen ? 'visible' : ''}`}
          style={{ display: this.state.isOpen ? 'block' : 'none' }}>
          <SMenuInnerWrapper>
            {Object.keys(filterMenus).map((key) => this.renderMenuType(key))}
          </SMenuInnerWrapper>
          <div>
            <SCloseButton onClick={this.toggleMenu}>Done</SCloseButton>
          </div>
        </SMenuWrapper>
      </div>
    );
  }
}
