/** *************************
 *   USER INVOICES MODULE
 **************************** */

import { Observable } from 'rxjs/Observable';
import { ajax } from '../../common/AjaxClient';
import { jwt } from '../../common/JwtManager';
import { triggerDownload } from '../../common/helpers';
import { HAS_UNAUTHENTICATED } from './account';
import azureSession from '../../azure/authentication/session';

/** *************************
 *          CONSTANTS
 **************************** */

const REQUEST_USER_INVOICES = 'REQUEST_USER_INVOICES';
const RECEIVE_USER_INVOICES = 'RECEIVE_USER_INVOICES';

const REQUEST_USER_INVOICE_DOWNLOAD = 'REQUEST_USER_INVOICE_DOWNLOAD';
const RECEIVE_USER_INVOICE_DOWNLOAD = 'RECEIVE_USER_INVOICE_DOWNLOAD';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const requestUserInvoices = () => ({
  type: REQUEST_USER_INVOICES,
});

const receiveUserInvoices = (payload) => ({
  type: RECEIVE_USER_INVOICES,
  payload,
});

export const requestUserInvoiceDownload = (id, name) => ({
  type: REQUEST_USER_INVOICE_DOWNLOAD,
  id,
  name,
});

const receiveUserInvoiceDownload = (payload, fileName) => ({
  type: RECEIVE_USER_INVOICE_DOWNLOAD,
  payload,
  fileName,
});

/** *************************
 *           EPICS
 **************************** */

// Get All Invoices for this account
export const requestUserInvoicesEpic = (action$) =>
  action$
    .ofType(REQUEST_USER_INVOICES)
    .switchMap(() =>
      ajax.get('/invoice', null, (payload) =>
        Observable.of(receiveUserInvoices(payload.response.invoices)),
      ),
    );

// Download the invoice file by invoice id
export const receiveUserInvoiceDownloadEpic = (action$) =>
  action$.ofType(REQUEST_USER_INVOICE_DOWNLOAD).switchMap((action) => {
    const h = {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    };
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    // Construct a useful filename
    const filename = action.name;
    const request = {
      headers: h,
      responseType: 'arraybuffer',
      url: `/portal/invoice/file/${action.id}`,
      method: 'GET',
    };
    return Observable.ajax(request)
      .flatMap((payload) =>
        Observable.of(receiveUserInvoiceDownload(payload, filename)),
      )
      .catch(() => {
        // Fail silently
      });
  });

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetchingUserInvoices: false,
  items: [],
  isDownloadingUserInvoice: null,
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case REQUEST_USER_INVOICES:
      return {
        ...state,
        isFetchingUserInvoices: true,
      };
    case RECEIVE_USER_INVOICES:
      return {
        ...state,
        isFetchingUserInvoices: false,
        items: action.payload,
      };
    case REQUEST_USER_INVOICE_DOWNLOAD:
      return {
        ...state,
        isDownloadingUserInvoice: action.id,
      };
    case RECEIVE_USER_INVOICE_DOWNLOAD:
      triggerDownload(
        action.payload.response,
        action.fileName,
        'application/octet-stream',
      );
      return {
        ...state,
        isDownloadingUserInvoice: null,
      };
    default:
      return state;
  }
}

export const invoiceEpics = {
  requestUserInvoicesEpic,
  receiveUserInvoiceDownloadEpic,
};
