
// @flow

import React from 'react';
import {
  Segment,
  Grid,
} from 'semantic-ui-react';

import Wrapper from './Wrapper';

export default function BoxForm(props: { children: any }) {
  return (
    <Wrapper centered verticalAlign="middle">
      <Grid.Column style={{ maxWidth: '450px' }}>
        <Segment padded="very" className="box-form">
          {props.children}
        </Segment>
      </Grid.Column>
    </Wrapper>
  );
}
