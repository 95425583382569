import styled from 'styled-components';

export const SMenu = styled.div`
  flex: 50%;
  padding: 0 1.5em;
  min-width: 200px;
  border-right: solid 1px rgba(151, 151, 151, 0.2);

  :last-of-type {
    border-right: none;
    padding-right: 1em;
  }
`;

export const SListIconWrapper = styled.div`
  width: 12px;
  float: left;
  margin: 0 10px 0 0;
  position: absolute;

  &.alerts {
    margin: 0;
    width: 20px;
  }

  img.trucks {
    transform: rotate(45deg);
  }
`;

export const SMenuHeadline = styled.h5`
  font-size: 18px;
  margin-top: 0.875em;
  margin-bottom: 35px;
  font-weight: bold;
  font-family: 'Lato';
`;

export const SCheckboxWrapper = styled.div`
  margin: 1.2em 0;
  font-weight: bold;

  .checkbox {
    margin: 0 0 0 10px;
    width: 100%;

    label:before, label: after {
      left: 175px !important;
      font-weight: normal !important;
      font-size: 0.875em !important;
      border-radius: 4px !important;
      width: 17px !important;
      height: 17px !important;
    }
  }
`;
