import styled from 'styled-components';

export const STruckSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const STruckInfoBaseWrapper = styled.div`
  float: left;
  width: 100%;
`;

export const STruckHeader = styled(STruckInfoBaseWrapper)`
  padding: 10px 20px;
  height: 65px;
  display: flex;
  align-items: center;
`;

export const STruckInfo = styled(STruckInfoBaseWrapper)`
  padding: 10px 20px;
  height: 77px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const STruckName = styled.h4`
  font-family: 'Lato';
  color: #082441;
  margin: 0;
  font-size: 13px;
`;

export const STruckWrapper = styled.div`
  border-top: 6px solid ${(props) => props.truckTypeColor};
  border-bottom: 1px solid #d8d8d8;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  position: relative;
`;

export const STruckInfoRow = styled(STruckInfoBaseWrapper)`
  color: #082441;
  font-weight: bold;
  margin: 5px 0;

  .key {
    opacity: 0.5;
    float: left;
    font-size: 13px;
  }
  .value {
    float: right;
  }
`;

export const STruckCreatedInfo = styled(STruckInfoBaseWrapper)`
  margin: 5px 0;

  .key,
  .value {
    float: left;
    width: 100%;
    color: #082441;
    font-size: 13px;
  }

  .key {
    font-weight: bold;
  }

  .value {
    opacity: 0.3;
  }
`;

export const SHr = styled.hr`
  float: left;
  width: 100%;
  border: 0;
  border-top: 1px solid #d8d8d8;
  margin: 0;
`;

export const SIcon = styled.img`
  width: 16px;
  margin: 0 20px 0 10px;
  transform: rotate(90deg);
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.12);
`;
