import * as React from 'react';

import moment from 'moment';

import binLift from '../../../assets/images/icons/binLift.svg';

import TruckMarker from '../classes/TruckMarker';
import TruckClusterMarker from '../classes/TruckClusterMarker';

import LiftMarker from '../classes/LiftMarker';
import { createLiftBubble } from '../helpers/LiftBubble';
import {truckPathStyle, circleStyle, arrowsStyle} from '../styles/styles';
import AlertMarker, {
  AlertIcon,
  makerShouldBeFocused,
} from '../classes/AlertMarker';
import { getWasteStream } from '../../../common/options';
import {
  getAlertsSVGColor,
  getTruckSVGColorRgb,
} from '../../../common/wasteStreamColour';
import {
  getClusterColor,
  getLiftIcon,
} from '../helpers/HMapWastestreamCluster/icons';

/* eslint-disable */

export const ZOOM_LEVEL_DETAILED_VIEW_BREAKPOINT = 15;
export const SHOW = 'show';
export const DEFAULT_ADDRESS_RADIUS = 10;
export const HIDE = 'hide';
const ALERT_BUBBLE_CLASS_NAME = 'alert_bubble';

type Props = {
  ui: Object,
  map: Object,
  currentZoom: number,
  clickedTruck: Object,
  handleClickedTruckMarker: Function,
};

class HMapMethods extends React.Component<Props> {
  shouldShowZoomedOutDetails = (currentZoom) =>
    currentZoom < ZOOM_LEVEL_DETAILED_VIEW_BREAKPOINT;

  deg2rad = (deg) => deg * (Math.PI / 180);

  alertDistanceFromSearchResult = (alertPosition, limit) => {
    const lat1 = alertPosition.lat;
    const lon1 = alertPosition.lng;

    const lat2 = limit.lat;
    const lon2 = limit.lng;

    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000;
    return d;
  };

  markActiveTruck = (truck) => {
    const { clickedTruck } = this.props;

    clickedTruck && clickedTruck.id === truck.id
      ? (truck.active = true)
      : (truck.active = false);

    return truck;
  };

  toggleTravelPath(truckPathPolylines, toggle) {
    if (truckPathPolylines) {
      truckPathPolylines.forEach((line) => {
        if (line) {
          line.path.setVisibility(toggle.show);
          line.arrows.setVisibility(toggle.show);
        }
      });
    }
  }

  toggleBinLifts(truckLiftsMarkers, toggle) {
    if (
      truckLiftsMarkers &&
      !this.shouldShowZoomedOutDetails(this.props.currentZoom)
    ) {
      truckLiftsMarkers.setVisibility(toggle.show);
    }
  }

  //
  //      HERE Maps Object Generators
  //

  generateCircle(lat: Number, lng: Number, dataRadius: Number) {
    return new H.map.Circle(
      // The central point of the circle
      { lat, lng },
      // The radius of the circle in meters
      dataRadius,
      circleStyle,
    );
  }

  generateBinLiftsGroup(
    binLiftData,
    activeEvent,
    setActiveEvent,
    shouldShowBinLifts,
  ) {
    const { ui } = this.props;
    const group = new H.map.Group();

    if (
      shouldShowBinLifts === false &&
      activeEvent.type === 'binLift' &&
      activeEvent.uuid
    ) {
      setActiveEvent(null, null);
    }

    if (binLiftData) {
      binLiftData.forEach((lift) => {
        const newLiftMarker = new LiftMarker(
          ui,
          lift,
          activeEvent,
          setActiveEvent,
        );

        group.addObject(newLiftMarker);
      });
    }
    // Add listener for Info Bubbles
    group.addEventListener(
      'tap',
      (event) => {
        const active = setActiveEvent(event.target.getData().uuid, 'binLift');
        active
          ? createLiftBubble(event.target.getData(), ui, setActiveEvent)
          : null;
      },
      false,
    );

    return group;
  }

  generateTruckPathPolyline = (truck, pathData, currentZoom) => {
    if (pathData.positions.positionGeoJson.geometry.coordinates.length <= 0) {
      console.error(`No path found for truck id: ${truck.id}`);
      return;
    }

    const lineStrings = [];

    pathData.positions.positionGeoJson.geometry.coordinates.forEach(
      (coords) => {
        const lineString = new H.geo.LineString();
        coords.forEach((coord) => {
          lineString.pushPoint({
            lat: coord[0],
            lng: coord[1],
          });
        });
        lineStrings.push(lineString);
      },
    );
    const multiLineString = new H.geo.MultiLineString(lineStrings);

    const truckColourKey = getWasteStream(pathData.wasteStream).key;

    const path = new H.map.Polyline(
      multiLineString,
      truckPathStyle(
        truckColourKey,
        this.shouldShowZoomedOutDetails(currentZoom),
      ),
    );

    const arrows = new H.map.Polyline(
      multiLineString,
      arrowsStyle(this.shouldShowZoomedOutDetails(currentZoom))
    );

    return { path, arrows };
  };

  getClusteringTheme = (clickedTruck) => {
    const CLUSTERING_THEME = {
      getClusterPresentation: function (cluster) {
        const clusterTrucks = [];
        let withActiveBlueRing = false;

        cluster.forEachDataPoint((point) => {
          const truck = point.getData();
          clickedTruck && `${truck.id}`.trim() === `${clickedTruck.id}`.trim()
            ? (withActiveBlueRing = true)
            : null;
          clusterTrucks.push(truck);
        });

        const truckClusterMarker = new TruckClusterMarker(
          null,
          cluster.getPosition(),
          clusterTrucks,
          cluster.getMinZoom(),
          cluster.getMaxZoom(),
          withActiveBlueRing,
        );

        // // Bind cluster data to the marker
        truckClusterMarker.setData(cluster);

        return truckClusterMarker;
      },
      getNoisePresentation: function (noisePoint) {
        // Get a reference to data object our noise points
        const truck = noisePoint.getData();
        const clickedTruckId = clickedTruck ? `${clickedTruck.id}`.trim() : '';
        const isActiveTruck = clickedTruckId === `${truck.id}`.trim();
        const truckMarker = new TruckMarker(
          truck,
          noisePoint.getMinZoom(),
          isActiveTruck,
        );

        return truckMarker;
      },
    };
    return CLUSTERING_THEME;
  };

  generateHtmlForClusterList(trucksInClusters) {
    let html = '';

    trucksInClusters.forEach((truck) => {
      const truckWasteStream = truck.wasteStream
        ? truck.wasteStream.toLowerCase()
        : `Other`;
      html =
        html +
        `<div class="marker-container">
                  <p class="waste-stream">
                    ${truckWasteStream}
                  </p>
                  <span class="TruckCluster-id">
                    Truck ID #${truck.id}
                  </span>
                  <span class="rego">
                    Registration: ${truck.registration}
                  </span>
                </div>`;
    });
    return html;
  }

  addUiBubble(bubbleContent) {
    const { ui } = this.props;

    this.removePreviousAlertBubbles();
    ui.addBubble(bubbleContent);
    const pop = bubbleContent.getElement();
    pop.classList.add(ALERT_BUBBLE_CLASS_NAME);
  }

  generateMarkersForActiveTrucks = (truckData, localTime) =>
    truckData
      .map((truck) => {
        const baseDate = moment.parseZone(localTime);
        return moment(truck.latestPositions.created).isBetween(
          baseDate.clone().add(-1, 'days'),
          baseDate,
        )
          ? new H.clustering.DataPoint(
              truck.latestPositions.lat,
              truck.latestPositions.lng,
              null,
              truck,
            )
          : undefined;
      })
      .filter(Boolean);

  generateTruckClusters(
    clickedTruck,
    truckData,
    handleClickedTruckMarker,
    localTime,
  ) {
    const dataPoints = this.generateMarkersForActiveTrucks(
      truckData,
      localTime,
    );
    // Create a clustered data provider and a theme implementation
    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        strategy: H.clustering.Provider.Strategy.DYNAMICGRID,
        // Maximum radius of the neighborhood
        eps: 30,
        // minimum weight of points required to form a cluster
        minWeight: 1,
      },
      theme: this.getClusteringTheme(clickedTruck),
    });

    clusteredDataProvider.addEventListener('tap', (event) => {
      const data = event.target.getData();

      if (data.id) {
        handleClickedTruckMarker(event.target.getData(), '', false); // value false is to not zoom into truck
      } else {
        var target = event.target;

        this.props.map.getViewModel().setLookAtData({
          bounds: target.getData().getBoundingBox()
        }, true);

        // calculate best camera data to fit object's bounds
        const cameraData = this.props.map.getViewModel().getLookAtData();
        // we set new zoom level taking into account 'maxZoom' value

        // At max level create a list of the trucks  in cluster and
        // render it as a ui bubble
        if (cameraData.zoom === 19) {
          const dataPoints = [];

          target.getData().forEachDataPoint((point) => {
            dataPoints.push(point.getData());
          });
          const bubble = new H.ui.InfoBubble(target.getGeometry(), {
            content: this.generateHtmlForClusterList(dataPoints),
          });

          this.addUiBubble(bubble);
        }
      }
    });

    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

    return clusteringLayer;
  }

  removePreviousAlertBubbles() {
    const { ui } = this.props;
    ui.getBubbles().forEach((bubble) => {
      if (bubble.getElement().className.indexOf(ALERT_BUBBLE_CLASS_NAME) !== -1) {
        ui.removeBubble(bubble);
      }
    });
  }

  generateAlertMarkers(
    alertData,
    markersToDraw,
    activeEvent,
    setActiveEvent,
    clickedTruck,
    handleThumb,
    setClickedTruck,
  ) {
    const group = new H.map.Group();

    Object.values(alertData).forEach((alert) => {
      const color = getAlertsSVGColor(alert.reason);

      const alertOpacity = markersToDraw.includes(alert)
        ? makerShouldBeFocused(activeEvent, alert.uuid)
          ? 1
          : 0.2
        : 0;

      const alertIcon = AlertIcon(color, alertOpacity);

      const icon = new H.map.Icon(alertIcon, {size: { w: 20, h: 20}});
      const marker = new H.map.Marker(alert.position, { icon: icon });

      marker.addEventListener(
        'tap',
        (event) => {
          const bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
            content: new AlertMarker(alert).getMarkup(),
          });

          this.addUiBubble(bubble);

          const pop = bubble.getElement();

          const img =
            pop.childNodes[0].childNodes[1].childNodes[0].childNodes[2]
              .childNodes[0];

          // attach modal handler
          const filterOutEmpty = alert.imageResources.filter(
            (item) => item.length > 0,
          );
          if (filterOutEmpty.length > 0) {
            img.addEventListener('click', () => {
              handleThumb(alert.imageResources, alert.created, alert.id);
            });


          } else {
            const image = img.childNodes[0].childNodes[0];
            image.style.pointerEvents = 'none';
          }

          pop.addEventListener('click', (event) => {
            if (event && event.target && event.target.dataset.truckId) {
              setClickedTruck(event.target.dataset.truckId);
            }
          });

          if (clickedTruck) {
            setActiveEvent(alert.uuid, 'alerts');
            group.removeAll();
          }
        },
        false,
      );
      group.addObject(marker);
    });

    return group;
  }
  drawActivityPolygonLine = (coords) => {
    const lineString = new H.geo.LineString();
    coords.forEach((coord) => {
      lineString.pushPoint({
        lat: coord[0],
        lng: coord[1],
      });
    });
    return lineString;
  };

  drawAreaPolygon(
    coords,
    wasteStream,
    activeActivityAreaLabel,
    setActiveActivityLabel,
    closedActivityArea,
  ) {
    if (closedActivityArea.indexOf(wasteStream) !== -1) {
      return null;
    }
    const wasteStreamIsActive =
      activeActivityAreaLabel === null ||
      activeActivityAreaLabel === wasteStream;

    const { red, green, blue } = getTruckSVGColorRgb(wasteStream);
    const polygon = new H.map.Polygon(this.drawActivityPolygonLine(coords), {
      style: {
        fillColor: `rgba(${red}, ${green}, ${blue}, ${
          wasteStreamIsActive ? '.5' : '.1'
        })`,
        strokeColor: `rgba(${red}, ${green}, ${blue}, ${
          wasteStreamIsActive ? '.9' : '.2'
        })`,
        lineWidth: 3,
      },
    });

    polygon.addEventListener(
      'pointerenter',
      (e) => {
        if (wasteStream !== activeActivityAreaLabel) {
          setActiveActivityLabel(wasteStream);
        }
      },
      false,
    );

    polygon.addEventListener(
      'pointerleave',
      function () {
        if (wasteStream === activeActivityAreaLabel) {
          setActiveActivityLabel(null);
        }
      },
      false,
    );

    return polygon;
  }

  createActivityAreaPopUp(
    position,
    boundary,
    setActiveActivityLabel,
    activeActivityAreaLabel,
    handleBinLiftsFilter,
    closeActiveActivityLabel,
    closedActivityArea,
  ) {
    if (closedActivityArea.indexOf(boundary.wasteStream) !== -1) {
      return;
    }
    const { ui } = this.props;
    const icon = getLiftIcon(28).replace(
      '{colour}',
      getClusterColor([boundary.wasteStream]),
    );
    const wasteStreamCssClassName = boundary.wasteStream
      .replace(/\s/g, '_')
      .toLowerCase();

    const bubble = new H.ui.InfoBubble(position, {
      content: `<div class="activity_area_pop_up">
        <div>
          <div class='icon ${wasteStreamCssClassName}'><img src="${binLift}" alt="bin lift" /></div>
          <div class='waste_stream'>${
            getWasteStream(boundary.wasteStream).text
          }</div>
          <div data-close="${boundary.wasteStream}" class="close">
          </div>
       </div>
        <div class='set_wastestream'>
          <a data-wastestream='${
            getWasteStream(boundary.wasteStream).text
          }'>Show ${boundary.liftCount} lifts on map</a>
        </div>
      </div>`,
    });

    ui.addBubble(bubble);

    bubble
      .getElement()
      .classList.add(
        'activity_area_pop_up',
        wasteStreamCssClassName,
        activeActivityAreaLabel === null ||
          activeActivityAreaLabel === boundary.wasteStream
          ? 'active'
          : 'background',
      );

    bubble.getElement().addEventListener(
      'click',
      (event) => {
        setActiveActivityLabel(boundary.wasteStream);
        if (event.target.dataset.wastestream) {
          handleBinLiftsFilter([boundary.wasteStream]);
        }

        if (event.target.dataset.close) {
          closeActiveActivityLabel(event.target.dataset.close);
        }
      },
      false,
    );

    return bubble;
  }
}

export default HMapMethods;
