import React from 'react';
import ReactDOMServer from 'react-dom/server';

/**
 * Returns the SVG path to generate the shape filling the marker
 * @param {*} fillColour 
 * @returns 
 */
const fillPath = (fillColour) => `
  <path d="M46 25C46 41 27 58.5 27 58.5C27 58.5 8 40.5 8 25C8 14.5066 16.5066 6 27 6C37.4934 6 46 14.5066 46 25Z" fill="${fillColour}"/>
`;

/**
 * Returns the SVG path to generate the shape adding shading to the marker:
 * - if the marker is selected, it is completely transparent and has no effect
 * - if the marker is neither selected nor hovered, it is white and 20% visible to attenuate the colour
 * - if the marker is hovered, it is black and 20% visible to strengthen the colour
 * @param {*} selected 
 * @param {*} state 
 * @returns 
 */
const shadingPath = (selected, state) => `
  <path d="M46 25C46 41 27 58.5 27 58.5C27 58.5 8 40.5 8 25C8 14.5066 16.5066 6 27 6C37.4934 6 46 14.5066 46 25Z" fill="${(!selected && state != 'hover') ? 'white' : 'black'}" fill-opacity="${(!selected || state == 'hover') ? '0.2' : '0'}" />
`;

/**
 * Returns the SVG path to generate the border of the marker, which is only meant to appear when the marker is selected
 * @param {*} strokeColour 
 * @param {*} selected 
 * @returns 
 */
const borderPath = (strokeColour, selected) => `
  <path d="M44.5 25C44.5 32.4853 40.014 40.5233 35.2972 46.8538C32.9652 49.9835 30.6299 52.6264 28.877 54.487C28.1349 55.2746 27.4987 55.9205 27.0132 56.4021C26.5253 55.9066 25.8833 55.2402 25.1335 54.4279C23.3786 52.5269 21.0412 49.8353 18.707 46.6719C13.9808 40.2667 9.5 32.2318 9.5 25C9.5 15.335 17.335 7.5 27 7.5C36.665 7.5 44.5 15.335 44.5 25Z" stroke="${strokeColour}" stroke-width="5"  stroke-opacity="${selected ? '1' : '0'}" />
`;

const EllipseBaseIcon = (type, width, height, state, selected) => {
  let fillColour, strokeColour, symbolPaths;
  switch(type) {
    case 'assigned':
      fillColour = '#FF9649';
      strokeColour = '#FFDD66';
      symbolPaths = '<path d="M24.2067 19H29.794C31.8533 19 33 20.157 33 22.1395V28.854C33 30.869 31.8533 32 29.794 32H24.2067C22.18 32 21 30.869 21 28.854V22.1395C21 20.157 22.18 19 24.2067 19V19ZM24.3867 22.029V22.0225H26.3793C26.6667 22.0225 26.9 22.25 26.9 22.5288C26.9 22.8155 26.6667 23.043 26.3793 23.043H24.3867C24.0993 23.043 23.8667 22.8155 23.8667 22.536C23.8667 22.2565 24.0993 22.029 24.3867 22.029V22.029ZM24.3867 25.981H29.6133C29.9 25.981 30.1333 25.7535 30.1333 25.474C30.1333 25.1945 29.9 24.9663 29.6133 24.9663H24.3867C24.0993 24.9663 23.8667 25.1945 23.8667 25.474C23.8667 25.7535 24.0993 25.981 24.3867 25.981ZM24.3867 28.9515H29.6133C29.8793 28.9255 30.08 28.7039 30.08 28.4445C30.08 28.178 29.8793 27.957 29.6133 27.931H24.3867C24.1867 27.9115 23.9933 28.0025 23.8867 28.1715C23.78 28.334 23.78 28.5485 23.8867 28.7175C23.9933 28.88 24.1867 28.9775 24.3867 28.9515V28.9515Z" fill="white"/>';
      break;
    case 'success':
      fillColour = '#438A7B';
      strokeColour = '#00EBB7';
      symbolPaths = '<path d="M35.5373 20.5994L34.2002 19.205C34.0691 19.0683 33.9183 19 33.7479 19C33.5775 19 33.4267 19.0683 33.2956 19.205L25.2531 27.5915L21.4187 23.5726C21.2876 23.4495 21.1368 23.388 20.9664 23.388C20.796 23.388 20.6452 23.4495 20.5141 23.5726L19.177 24.9669C19.059 25.1036 19 25.2608 19 25.4385C19 25.6162 19.059 25.7734 19.177 25.9101L24.8008 31.795C24.9188 31.9317 25.0663 32 25.2433 32C25.4203 32 25.5677 31.9317 25.6857 31.795L35.5373 21.5426C35.6553 21.4059 35.7143 21.2453 35.7143 21.0607C35.7143 20.8762 35.6553 20.7224 35.5373 20.5994Z" fill="white"/>';
      break;
    case 'exception':
      fillColour = '#77180B';
      strokeColour = '#FF6363';
      symbolPaths = `
        <path d="M24.2067 19H29.794C31.8533 19 33 20.157 33 22.1395V28.854C33 30.869 31.8533 32 29.794 32H24.2067C22.18 32 21 30.869 21 28.854V22.1395C21 20.157 22.18 19 24.2067 19V19ZM24.3867 22.029V22.0225H26.3793C26.6667 22.0225 26.9 22.25 26.9 22.5288C26.9 22.8155 26.6667 23.043 26.3793 23.043H24.3867C24.0993 23.043 23.8667 22.8155 23.8667 22.536C23.8667 22.2565 24.0993 22.029 24.3867 22.029V22.029ZM24.3867 25.981H29.6133C29.9 25.981 30.1333 25.7535 30.1333 25.474C30.1333 25.1945 29.9 24.9663 29.6133 24.9663H24.3867C24.0993 24.9663 23.8667 25.1945 23.8667 25.474C23.8667 25.7535 24.0993 25.981 24.3867 25.981ZM24.3867 28.9515H29.6133C29.8793 28.9255 30.08 28.7039 30.08 28.4445C30.08 28.178 29.8793 27.957 29.6133 27.931H24.3867C24.1867 27.9115 23.9933 28.0025 23.8867 28.1715C23.78 28.334 23.78 28.5485 23.8867 28.7175C23.9933 28.88 24.1867 28.9775 24.3867 28.9515V28.9515Z" fill="white"/>
        <circle cx="40.5" cy="13.5" r="7" fill="#FF2727" stroke="white"/>
        <path d="M42 10.5953C42 10.796 41.9717 11.0518 41.9151 11.3629C41.8585 11.6739 41.783 12.01 41.6887 12.3712C41.5943 12.7324 41.5047 13.0686 41.4198 13.3796C41.3349 13.6906 41.2594 13.9465 41.1934 14.1472C41.1274 14.3478 41.0943 14.4482 41.0943 14.4482H39.9057C39.9057 14.4482 39.8726 14.3478 39.8066 14.1472C39.7406 13.9465 39.6651 13.6906 39.5802 13.3796C39.4953 13.0686 39.4057 12.7324 39.3113 12.3712C39.217 12.01 39.1415 11.6739 39.0849 11.3629C39.0283 11.0518 39 10.796 39 10.5953C39 10.1538 39.1462 9.77759 39.4387 9.46656C39.7311 9.15552 40.0849 9 40.5 9C40.9151 9 41.2689 9.15552 41.5613 9.46656C41.8538 9.77759 42 10.1538 42 10.5953ZM40.5 15.4114C40.8396 15.4114 41.1274 15.5368 41.3632 15.7876C41.5991 16.0385 41.717 16.3445 41.717 16.7057C41.717 17.0669 41.5991 17.3729 41.3632 17.6237C41.1274 17.8746 40.8396 18 40.5 18C40.1604 18 39.8726 17.8746 39.6368 17.6237C39.4009 17.3729 39.283 17.0669 39.283 16.7057C39.283 16.3445 39.4009 16.0385 39.6368 15.7876C39.8726 15.5368 40.1604 15.4114 40.5 15.4114Z" fill="white"/>
      `;
      break;

  }  
  return `<svg width="${width}" height="${height}" viewBox="0 0 54 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#shadow)">
      ${fillPath(fillColour)}
      ${shadingPath(selected, state)}
      ${borderPath(strokeColour, selected)}
      ${symbolPaths}
    </g>
    <filter id="shadow" color-interpolation-filters="sRGB">
      <feDropShadow dx="0" dy="2" stdDeviation="4" flood-opacity="0.3"/>
    </filter>
  </svg>`;
}

export const EllipseAssignedIcon = (width, height, state, selected) => EllipseBaseIcon('assigned', width, height, state, selected);
export const EllipseSuccessIcon = (width, height, state, selected) => EllipseBaseIcon('success', width, height, state, selected);
export const EllipseExceptionIcon = (width, height, state, selected) => EllipseBaseIcon('exception', width, height, state, selected);

export default class LiveJobMarker {
  constructor(props) {
    this.props = props;
  }
  getMarkup = () =>
    ReactDOMServer.renderToStaticMarkup(LiveJobData(this.props));
}

function LiveJobData({ name, status, multiple, addressShort, reasonCode, jobTypeDescription }) {
  if (multiple) {
    return (
      <div className={`live-job-alert-bubble live-job-status-Multiple`}>
        <p className="note">
          Multiple jobs on this address. Click on the pin to show more detail.
        </p>
        <div className="table-row">
          <div className="label">Address</div>
          <div className="valueText">{addressShort}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`live-job-alert-bubble live-job-status-${status}`}>
        <div className="table-row">
          <div className="label">Name</div>
          <div className="valueText">{name}</div>
        </div>
        <div className="table-row">
          <div className="label">Address</div>
          <div className="valueText">{addressShort}</div>
        </div>
        <div className="table-row">
          <div className="label">Status</div>
          <div className="valueText">
            <strong>{status}</strong>
            <div>{reasonCode}</div>
          </div>
        </div>
        <div className="table-row">
          <div className="label">Job Type</div>
          <div className="valueText">{jobTypeDescription}</div>
        </div>
      </div>
    );
  }
}
