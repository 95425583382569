
// @flow

import React from 'react';
import { Form, Select } from 'semantic-ui-react';
import ErrorMsg from './ErrorMsg';

type Props = {
  placeholder?: string,
  options: Object,
  className?: string,
  input: Object,
  label?: string,
  icon?: string,
  meta: Object,
  inline: boolean,
};

Custom.defaultProps = {
  placeholder: null,
  className: null,
  label: null,
  icon: null,
  inline: false,
};

export default function Custom({ placeholder, options, className, input, label, icon, inline, meta: { touched, error, warning } }: Props) {
  const dirty = touched && error ? true : false; // eslint-disable-line no-unneeded-ternary

  return (
    <Form.Field error={dirty} className={className}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      {touched && ((error && <ErrorMsg>{error}</ErrorMsg>) || (warning && <span>{warning}</span>))}
      <Select
        placeholder={placeholder}
        options={options}
        {...input}
        value={input.value}
        onChange={(event, data) => input.onChange(data.value)}
        name={name}
        icon={icon}
        inline={inline}
      />
    </Form.Field>
  );
}
