import styled from 'styled-components';

export const SToggleButtonControlButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SLiftsCheckboxWrapper = styled.div`
  opacity: ${(props) => props.active ? 1 : 0.3};
  .checkbox {
    label:before,
    label:after {
      border-radius: 555em !important;
    }
  }
`;


export const SLabelText = styled.strong`
  margin-left: 25px;
`;

export const SLabelFilter = styled.label`
  margin: 0 7px;
`;
