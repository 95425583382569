import styled from 'styled-components';

export const SMenuToggleButton = styled.div`
  display: block;
  float: right;
  max-width: 120px;
  justify-content: unset !important;
  padding: 0 !important;
  padding-left: 1em !important;

  .toggle {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .filter-status {
    transition: opacity 250ms;
    opacity: 0;
    &.active {
      background-color: #b6ff6a;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 9px;
      left: 30px;
      border: 3px solid #004b6d;
      border-radius: 50%;
      opacity: 1;
    }
  }
`;

export const SMenuWrapper = styled.div`
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
  background-color: #004b6d;
  color: #fff;
  padding: 2.375em 10px 1.375em 10px;
  border-radius: 5px;
  position: absolute;
  right: 0;
  width: 750px;
  top: 55px;
`;

export const SMenuInnerWrapper = styled.div`
  display: flex;
  :after {
    bottom: 100%;
    left: 90%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #004b6d;
    border-width: 10px;
    margin-left: -10px;
  }
`;

export const SCloseButton = styled.button`
  border-radius: 7px;
  background-color: #72c955;
  color: #004b6d;
  font-size: 14px;
  font-weight: bold;
  padding: 14px 21px;
  margin: 25px 10px 0 0;
  border: none;
  font-family: 'Lato';
  float: right;
  cursor: pointer;
  transition: 200ms background-color;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #82dd64;
  }
`;

export const SResetButton = styled.button`
  position: relative;
  top: 0;
  right: 0;
  appearance: none;
  background: none;
  border: none;
  color: white;
  border-left: rgba(151, 151, 151, 0.2) 1px solid;
  width: 42px;
  height: 44px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;
