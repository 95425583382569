
// @flow

import React from 'react';
import { Checkbox } from 'semantic-ui-react';

type Props = {
  input: Object,
  label?: string,
  className?: string,
  disabled?: boolean,
  value: string,
};

Check.defaultProps = {
  label: null,
  className: null,
  disabled: null,
};

export default function Check({ input, label, className, disabled, value }: Props) {
  return (
    <Checkbox
      {...input}
      value={value}
      onChange={(e, { checked }) => input.onChange(checked)}
      label={label}
      className={className}
      defaultChecked={!!input.value}
      disabled={disabled}
    />
    // @TODO: defaultChecked should probably be removed as value is set - but needs to be thoroughly tested
  );
}
