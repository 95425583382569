
// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Form,
  Button,
  Icon,
  Modal,
} from 'semantic-ui-react';
import Input from '../../components/Input';

type Props = {
  handleSubmit: Function,
  onSubmit: Function,
  modalOpen: boolean,
  toggleModal: Function,
  postingReport: boolean,
  reportName: string,
};

function configReportDetailsForm({ handleSubmit, modalOpen, toggleModal, onSubmit, postingReport, reportName }: Props) {
  return (

    <Modal size="tiny" open={modalOpen} closeIcon={<Icon className="icon-cancel" onClick={() => toggleModal('')} />}>
      <Modal.Header>Save Custom Report</Modal.Header>

      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={handleSubmit} >
            <Form.Group>
              <Field name="reportName" label="Report Name*" placeholder="Report Name" component={Input} />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <button className="cancel" onClick={() => toggleModal('')}>Cancel</button>
        <Button color="green" onClick={() => onSubmit()} loading={postingReport} disabled={!reportName}><Icon name="save" /> Save</Button>
      </Modal.Actions>

    </Modal>

  );
}

// decorate
configReportDetailsForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'configReportDetails',
})(configReportDetailsForm);

export default configReportDetailsForm;
