import React from 'react';
import './popup.css';
import {
  CircularProgress,
  OrangeCircle,
  GreenCircle,
  RedCircle,
  CollapseIcon,
} from './svgComponents';

import {
  Loader,  
} from 'semantic-ui-react';

export default class LiveJobStatus extends React.Component {
  getServiceTypeTable(jobStatByServiceType) {
    const items = [];

    for (let serviceType in this.props.filters.serviceType.options) {
      if (serviceType != 'all') {
        const service = jobStatByServiceType.find(
          (type) => type.serviceType === serviceType,
        );

        items.push(
          <tr key={serviceType}>
            <td>{serviceType}</td>
            <td>{service ? service.assignedCount : 0}</td>
            <td>{service ? service.completedCount : 0}</td>
            <td>{service ? service.exceptionCount : 0}</td>
            <td>{service ? service.totalCount : 0}</td>
            <td className="table-controls">
              <CircularProgress
                size={20}
                strokeWidth={6}
                percentage={service ? service.completedPercent : 0}
                trackColor="#ccc"
                barColor="#4ECDB1"
              />
              <span>{service ? service.completedPercent : 0} %</span>
            </td>
          </tr>,
        );
      }
    }

    return items;
  }

  render() {
    if (!this.props.liveJobStatus) {
      return (
        <div className="live-job-status-modal">
          <div className="live-job-status-header" style={{marginBottom: "20px"}}>
            <h3>Live Jobs Status</h3>
            <CollapseIcon onClick={this.props.onCloseModal} />
          </div>
          <Loader
            active={this.props.isFetching}
            size="massive"
            inline="centered"
          />
        </div>
      )
    }

    const {
      assignedCount,
      completedCount,
      completedPercent,
      exceptionCount,
      totalCount,
      jobStatByServiceType,
      exceptionPercent,
    } = this.props.liveJobStatus;

    return (
      <div className="live-job-status-modal">
        <div className="live-job-status-header">
          <h3>Live Jobs Status</h3>
          <CollapseIcon onClick={this.props.onCloseModal} />
        </div>

        <div className="live-job-job-summary">
          <div className="live-job-summary-card">
            <label>Assigned Jobs</label>
            <div className="live-job-card-values">
              <OrangeCircle />
              <span>{assignedCount}</span>
            </div>
          </div>
          <div className="live-job-summary-card">
            <label>Completed Jobs <span>No Exceptions</span></label>
            <div className="live-job-card-values">
              <GreenCircle />
              <span>{completedCount}</span>
            </div>
          </div>
          <div className="live-job-summary-card">
            <label>Completed Jobs <span>With Exceptions</span></label>
            <div className="live-job-card-values">
              <RedCircle />
              <span>{exceptionCount}</span>
            </div>
          </div>
          <div className="live-job-summary-card">
            <label>Total Jobs</label>
            <div className="live-job-card-values">
              <span>{totalCount}</span>
            </div>
          </div>
          <div className="live-job-summary-card">
            <label>% Completed Jobs <span>No Exceptions</span></label>
            <div className="live-job-card-values">
              <span>{completedPercent}%</span>
            </div>
          </div>
          <div className="live-job-summary-card">
            <label>% Completed Jobs <span>With Exceptions</span></label>
            <div className="live-job-card-values">
              <span>{exceptionPercent}%</span>
            </div>
          </div>
        </div>

        <div className="live-job-table-summary">
          <div className="main-wrapper">
            <div className="responsive-table">
              <table cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th>Service Type</th>
                    <th>Assigned</th>
                    <th>
                      <div>Completed</div>
                      <span>No Exceptions</span>
                    </th>
                    <th>
                      <div>Completed</div>
                      <span>With Exceptions</span>
                    </th>
                    <th>Total</th>
                    <th>
                      <div>% Completed</div>
                      <span>incl. Exceptions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.getServiceTypeTable(jobStatByServiceType)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
