import { Observable } from 'rxjs/Observable';
import { ticketStatuses } from '../../common/options';
import { ajax } from '../../common/AjaxClient';
import { HAS_UNAUTHENTICATED } from './account';
import { RESET_SEARCH } from './search';
import moment from 'moment';

/** *************************
 *          CONSTANTS
 **************************** */

const POST_TICKET = 'JOBS_POST_TICKET';
const POST_SUCCESS = 'JOBS_POST_SUCCESS';
const RESET_WORK_ORDER_JOBS = 'JOBS_RESET_WORK_ORDER_JOBS';
const REQUEST_TICKETS = 'JOBS_REQUEST_TICKETS';
const REQUEST_MORE_TICKETS = 'JOBS_REQUEST_MORE_TICKETS';
const RECEIVE_TICKETS = 'JOBS_RECEIVE_TICKETS';
const SELECT_STATUSES = 'JOBS_SELECT_STATUSES';
const SELECT_TYPE = 'JOBS_SELECT_TYPE';
const CLEAR_TICKETS = 'JOBS_CLEAR_TICKETS';
const REQUEST_CRN_WORKORDERS = 'JOBS_REQUEST_CRN_WORKORDERS';
const RECEIVE_CRN_WORKORDERS = 'JOBS_RECEIVE_CRN_WORKORDERS';
const REQUEST_CRN_WORKORDERS_ERROR = 'JOBS_REQUEST_CRN_WORKORDERS_ERROR';
const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

/** *************************
 *       ACTION CREATORS
 **************************** */

export const resetWorkOrderJobs = () => ({
  type: RESET_WORK_ORDER_JOBS,
});

export const postTicket = (payload) => ({
  type: POST_TICKET,
  payload,
});

export const postSuccess = (payload) => ({
  type: POST_SUCCESS,
  payload,
});

export const requestCRNWorkOrders = (territoryId, jobId) => ({
  type: REQUEST_CRN_WORKORDERS,
  territoryId,
  jobId,
});

export const receiveCRNWorkOrders = (payload) => ({
  type: RECEIVE_CRN_WORKORDERS,
  payload,
});

export const requestJobs = (
  territoryId,
  selectedStatuses,
  serviceType,
  from,
  limit,
  addressId,
  startDate,
  endDate,
  searchTerm,
  sortBy,
  sortDirection,
) => ({
  type: REQUEST_TICKETS,
  territoryId,
  selectedStatuses,
  serviceType,
  from,
  limit,
  addressId,
  startDate,
  endDate,
  searchTerm,
  sortBy,
  sortDirection,
});

export const requestMoreJobs = (
  territoryId,
  selectedStatuses,
  serviceType,
  from,
  limit,
  addressId,
  startDate,
  endDate,
  searchTerm,
  sortBy,
  sortDirection,
) => ({
  type: REQUEST_MORE_TICKETS,
  territoryId,
  selectedStatuses,
  serviceType,
  from,
  limit,
  addressId,
  startDate,
  endDate,
  searchTerm,
  sortBy,
  sortDirection,
});

export const receiveTickets = (payload) => ({
  type: RECEIVE_TICKETS,
  payload,
});

export const selectStatuses = (selectedStatuses) => ({
  type: SELECT_STATUSES,
  selectedStatuses,
});

export const selectType = (selectedType) => ({
  type: SELECT_TYPE,
  selectedType,
});

export const clearTickets = () => ({
  type: CLEAR_TICKETS,
});

export const resetJobsIsFetching = () => ({
  type: RESET_IS_FETCHING,
});

/** *************************
 *           EPICS
 **************************** */

const workorderEndpoint = (args) => {
  const {
    territoryId,
    from,
    limit,
    selectedStatuses,
    addressId,
    serviceType,
    startDate,
    endDate,
    searchTerm,
    sortBy,
    sortDirection,
  } = args;

  let queryString = `/jobs/list?territoryId=${territoryId}&from=${from}&limit=${limit}`;

  if (selectedStatuses && selectedStatuses.length > 0) {
    const includeAssigned =
      selectedStatuses.includes('all') || selectedStatuses.includes('Assigned');
    const includeCompleted =
      selectedStatuses.includes('all') ||
      selectedStatuses.includes('Completed');
    const includeUnassigned =
      selectedStatuses.includes('all') ||
      selectedStatuses.includes('Unassigned');
    const includeException =
      selectedStatuses.includes('all') ||
      selectedStatuses.includes('Exception');

    queryString += `&IncludeUnassigned=${includeUnassigned}&IncludeAssigned=${includeAssigned}&IncludeCompleted=${includeCompleted}&IncludeExceptions=${includeException}`;
  } else if (!selectedStatuses || selectedStatuses.length === 0) {
    queryString += `&IncludeUnassigned=true&IncludeAssigned=true&IncludeCompleted=true&IncludeExceptions=true`;
  }

  if (serviceType && serviceType.length > 0) {
    if (!serviceType.includes('all')) {
      queryString += `&serviceType=${serviceType.join(',')}`;
    }
  }

  if (addressId) {
    queryString += `&addressId=${addressId}`;
  }

  if (startDate) {
    queryString += `&dateFrom=${moment(startDate).format('YYYY-MM-DD')}`;
  }

  if (endDate) {
    queryString += `&dateTo=${moment(endDate).format('YYYY-MM-DD')}`;
  } else if (!endDate && startDate) {
    queryString += `&dateTo=${moment(startDate).format('YYYY-MM-DD')}`;
  }

  if (searchTerm) {
    queryString += `&search=${searchTerm}`;
  }

  if (sortBy) {
    queryString += `&sortOrder=${sortBy}`;
  }

  if (sortDirection) {
    queryString += `&sortDirection=${sortDirection}`;
  }

  return queryString;
};

// get tickets
const requestJobsEpic = (action$) =>
  action$.ofType(REQUEST_TICKETS, REQUEST_MORE_TICKETS).switchMap((action) =>
    ajax.get(workorderEndpoint(action), null, (payload) =>
      Observable.create((observer) => {
        observer.next(
          receiveTickets({
            workOrders: payload.response.jobs,
            concat: action.type === REQUEST_MORE_TICKETS,
            searchTerm: action.searchTerm,
          }),
        );
        observer.complete();
      }),
    ),
  );

// get tickets
const receiveCrnWorkOrders = (action$) =>
  action$.ofType(REQUEST_CRN_WORKORDERS).switchMap((action) =>
    ajax.get(
      `/jobs/crn/${action.jobId}?territoryId=${action.territoryId}`,
      null,
      (payload) =>
        Observable.create((observer) => {
          observer.next(receiveCRNWorkOrders(payload.response));
          observer.complete();
        }),
      (error) => {
        return {
          type: REQUEST_CRN_WORKORDERS_ERROR,
          error,
        };
      },
    ),
  );

/** *************************
 *          REDUCERS
 **************************** */

const initialState = {
  isFetching: false,
  isPosting: false,
  items: [],
  selectedStatuses: {},
  selectedType: '',
  from: 0,
  limit: 20,
  addressId: null,
  workOrderInfo: null,
  crnWorkOrderList: [],
};

ticketStatuses.forEach((ticketStatus) => {
  initialState.selectedStatuses[ticketStatus.value] = true;
});

export default function tickets(state = initialState, action) {
  switch (action.type) {
    case HAS_UNAUTHENTICATED:
      return initialState;
    case POST_TICKET:
      return {
        ...state,
        isPosting: true,
      };
    case POST_SUCCESS:
      return {
        ...state,
        isPosting: false,
      };
    case REQUEST_CRN_WORKORDERS_ERROR:
      return {
        ...state,
        isFetching: false,
        workOrderInfo: null,
        isFetchingWorOrderJobs: false,
        searchTerm: '',
        crnDetailFetchApiError: action.error
      };
    case REQUEST_TICKETS:
      return {
        ...state,
        isFetching: true,
        from: action.from,
        limit: action.limit,
        addressId: action.addressId,
        territoryId: action.territoryId,
        isFetchingWorOrderJobs: true,
        crnDetailFetchApiError: null
      };
    case REQUEST_MORE_TICKETS:
      return {
        ...state,
        isFetchingMoreTickets: true,
        isFetchingWorOrderJobs: false,
        from: action.from,
        limit: action.limit,
        addressId: action.addressSearchId,
        territoryId: action.territoryId,
        crnDetailFetchApiError: null
      };
    case RECEIVE_TICKETS:
      return {
        ...state,
        isFetching: false,
        searchTerm: action.payload.searchTerm,
        isFetchingMoreTickets: false,
        isFetchingWorOrderJobs: false,
        items: action.payload.concat
          ? [...state.items].concat(action.payload.workOrders)
          : [...action.payload.workOrders],
        showLoadMore: action.payload.workOrders.length >= state.limit,
      };
    case REQUEST_CRN_WORKORDERS:
      return {
        ...state,
        isFetching: true,        
      };
    case RECEIVE_CRN_WORKORDERS:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreTickets: false,
        crnWorkOrderList: action.payload,
      };
    case RESET_WORK_ORDER_JOBS:
      return {
        ...state,
        workOrderInfo: null,
        isFetchingWorOrderJobs: false,
        searchTerm: '',
        crnDetailFetchApiError: null
      };
    case SELECT_STATUSES:
      return {
        ...state,
        selectedStatuses: action.selectedStatuses,
        items: [],
      };
    case SELECT_TYPE:
      return {
        ...state,
        selectedType: action.selectedType,
        items: [],
      };
    case RESET_SEARCH:
      return {
        ...state,
        addressId: null,
      };
    case CLEAR_TICKETS:
      return {
        ...state,
        items: [],
      };
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
        isFetchingMoreTickets: false,
        isFetchingWorOrderJobs: false,
      }
    default:
      return state;
  }
}

export const jobEpics = {
  requestJobsEpic,
  receiveCrnWorkOrders,
};
