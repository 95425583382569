import './popup.css';
import LiveMapCarosuel from './liveMapCarosuel';
import {
  EllipseAssignedIcon,
  EllipseExceptionIcon,
  EllipseSuccessIcon,
} from '../classes/LiveJobMarkers';
import { CloseIcon } from './svgComponents';

const DEFAULT_WIDTH = 42;
const DEFAULT_HEIGHT = 48;

export default function LiveJobDetailPopup({
  workOrder,
  onCloseModal,
  requestCRNWorkOrders,
  territoryId,
}) {
  let icon = null;
  if (workOrder.jobs.length === 1) {
    const iconFunction =
      workOrder.jobs[0].status === 'Completed'
        ? EllipseSuccessIcon
        : workOrder.jobs[0].status === 'Exception'
        ? EllipseExceptionIcon
        : EllipseAssignedIcon;
    icon = iconFunction(DEFAULT_WIDTH, DEFAULT_HEIGHT, 'regular', false);
  }

  return (
    <div
      className={`live-job-detail-popup job-content-${
        workOrder.jobs.length === 1 ? workOrder.jobs[0]?.status : 'Default'
      }`}>
      <div className="controls">
        <div className="control-section control-section-marker-icon">
          {workOrder.jobs.length > 1 ? (
            <button>{workOrder.jobs.length}</button>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: icon }} />
          )}
          <h2>{workOrder.jobs.length > 1 ? 'Jobs' : 'Job'}</h2>
        </div>
        <div className="model-close" onClick={onCloseModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="address-card">
        <div className="address-info">
          <label>Address</label>
          <label>{workOrder.address.addressShort}</label>
        </div>
        <div className="address-info">
          <label>Cleanaway Address Number</label>
          <label>{workOrder.address.addressNo}</label>
        </div>
      </div>
      <div>
        <LiveMapCarosuel
          requestCRNWorkOrders={requestCRNWorkOrders}
          jobs={workOrder.jobs}
          territoryId={territoryId}
        />
      </div>
    </div>
  );
}
