
import { assignIn } from 'lodash';
const loadedLinks = new Map();

export default function getLink(url, name) {
  if (!loadedLinks.has(name) && !document.querySelector(`link[href="${url}"]`)) {
    const link = document.createElement('link');
    const body = document.getElementsByTagName('body')[0];
    assignIn(link, {
      href: url,
      rel: 'stylesheet',
      type: 'text/css',
    });
    body.appendChild(link);
    const linkObject = {
      hasLoaded: false,
      link,
      wasRejected: false,
    };
    loadedLinks.set(name, linkObject);
  }
  return loadedLinks.get(name);
}
