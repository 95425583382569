/* eslint-disable */
import React from 'react';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';

const handleBubbleEvents = (
  bubble,
  ui,
  setActiveEvent,
  setClickedTruck = null,
) => {
  bubble.addEventListener('statechange', () => {
    setActiveEvent(null, null);
  });

  ui.addBubble(bubble);
  const bubbleElement = bubble.getElement();

  bubbleElement.addEventListener('click', (event) => {
    if (
      event &&
      event.target &&
      event.target.dataset.truckId &&
      setClickedTruck
    ) {
      removeAllExistingBubbles(ui);
      setClickedTruck(event.target.dataset.truckId);
      setActiveEvent(null, null);
    }
  });

  bubbleElement.classList.add('hint_bubble');
};


const createInfoBubbleMarkupForEvent = (activeEvent) =>
  new H.ui.InfoBubble(activeEvent.position, {
    content: ReactDOMServer.renderToStaticMarkup(
      <div id={activeEvent.uuid}>
        <p className="title">
          Lift Recorded:{' '}
          {activeEvent.created
            ? moment.parseZone(activeEvent.created).format('DD/MM/YYYY hh:mm A')
            : ''}
        </p>
        <a
          className="sub_title"
          data-truck-id={`${activeEvent.truckId.trim()}`}>
          #{activeEvent.truckId ? activeEvent.truckId : ''}
        </a>
      </div>,
    ),
  });

 const createInfoBubbleMarkupForCluster = (activeEvent) => {
  let html = '';

  activeEvent.forEachDataPoint((singleEvent) => {
    const singleEventData = singleEvent.getData();
    html += `<div id="${singleEventData.uuid}">
    <p className="title">
      Lift Recorded:
      ${
        singleEventData.created
          ? moment.parseZone(singleEventData.created).format('DD/MM/YYYY hh:mm A')
          : ''
      }
    </p>
    <a
      className="sub_title"
      data-truck-id="${
        singleEventData.truckId ? singleEventData.truckId.trim() : ''
      }">
      #${singleEventData.truckId ? singleEventData.truckId : ''}
    </a>
  </div>`;
  });
  return new H.ui.InfoBubble(activeEvent.getPosition(), {
    content: html,
  });
}

export const removeAllExistingBubbles = (ui) =>
  ui.getBubbles().forEach((bubble) => {
    const bubbleElement = bubble.getElement();
    if (bubbleElement.classList && bubbleElement.classList.contains('activity_area_pop_up')) {
      return;
    } else {
    ui.removeBubble(bubble);
  }
  });

export const createInfoBubbleForEvent = (
  activeEvent,
  ui,
  setActiveEvent,
  setClickedTruck = null,
) => {
  const bubble = createInfoBubbleMarkupForEvent(activeEvent);
  handleBubbleEvents(bubble, ui, setActiveEvent, setClickedTruck);
};

export const createClusterInfoBubble = (
  activeEvent,
  ui,
  setActiveEvent,
  setClickedTruck,
) => {
  const bubble = createInfoBubbleMarkupForCluster(activeEvent);
  handleBubbleEvents(bubble, ui, setActiveEvent, setClickedTruck);
};