/* eslint-disable */

import HEREMarker from '../HEREMarker';
import icon from '../types/icon';
import { getWasteStream } from '../../../common/options';
import { reportedMinutes } from '../../../common/helpers';

export class TruckHelper {
  /**
   * Define waste stream types and the associated colour
   * @type {{general: string, recycle: string, green: string, hard_rubbish: string}}
   */
  static wasteStream = {
    Refuse: 'red',
    Recycle: 'yellow',
    Greenwaste: 'green',
    other: 'grey',
  };

  static imageSize = {
    w: 26,
    h: 57,
  };

  /**
   * Get static type image url and return an icon object for the HEREMarker class
   *
   * @param type
   * @param heading
   * @returns {{image: string, width: number, height: number}}
   */
  static createTruckIcon(type, heading): icon {
    const wasteStream = getWasteStream(type);
    const imageUrl =
      process.env.PUBLIC_URL + `/assets/trucks/${wasteStream.key}.svg`;

    return new icon({
      image: imageUrl,
      width: TruckHelper.imageSize.w,
      height: TruckHelper.imageSize.h,
      rotation: heading,
      opacity: '1',
    });
  }

  /**
   *
   * @param type
   * @param time
   * @returns {string}
   */
  static truckData({
    wasteStream = 'Refuse',
    workOrders = [],
    registration,
    id,
    latestPositions,
  }) {
    const wasteStreamObject = getWasteStream(wasteStream);
    return {
      registration,
      id,
      wasteStream,
      workOrders,
      wasteStreamObject,
      imageUrl:
        process.env.PUBLIC_URL + `/assets/trucks/${wasteStreamObject.key}.svg`,
      created: reportedMinutes(latestPositions.created),
    };
  }
}

class TruckMarker extends HEREMarker {
  /**
   * Get minimum vars for a marker and add truck specific attributes
   *
   * @param truckProps
   */
  constructor(truckProps, minZoom, active) {
    const latestPosition = truckProps.latestPositions;
    const lat = latestPosition.lat;
    const lng = latestPosition.lng;
    const heading = latestPosition.direction;
    const type = truckProps.wasteStream;
    const data = truckProps;
    const icon = TruckHelper.createTruckIcon(type, heading);
    const markerOptions = {
      lat,
      lng,
      icon,
      active,
      min: minZoom,
      shouldOpenNotification: false,
      data: TruckHelper.truckData(data || {}),
    };
    return super(null, markerOptions);
  }
}

export default TruckMarker;
