import { Observable } from 'rxjs/Observable';
import azureSession from '../azure/authentication/session';
import { showMessage } from '../redux/modules/messages';
import API_URL from './api';
import { acquireToken } from '../azure/authentication';
import moment from 'moment';
import { store } from '../redux/configureStore';
import { authenticationHasFailed } from '../redux/modules/account';

const HTTP_UNAUTHORIZED = 401;
export const maxTimeout = 60000;

const checkTokenExpiry = () => {
  const expires = sessionStorage.getItem('expiresOn');
  var a = moment(new Date(expires));
  var b = moment(new Date());
  const diff = a.diff(b, 'minutes');
  if (diff <= 5) {
    acquireToken();
  }
};

export const handleError = (e) => {
  const errorMessage = e && e.message ? e.message : '';
  if(errorMessage) {
    return Observable.of(
      showMessage(
        errorMessage
      )
    );
  }

  return Observable.of(
    showMessage(
      'An unexpected error has occurred.'
    )
  )
}

export class AjaxClient {
  static treatHeaders(headers) {
    let h = headers;
    if (!h) {
      h = {};
    }
    if (!h['Content-Type']) {
      h['Content-Type'] = 'application/json';
    }
    if (!h.Accept) {
      h.Accept = 'application/json';
    }
    if (azureSession.getToken()) {
      h.Authorization = `Bearer ${azureSession.getToken()}`;
    }
    return h;
  }

  static handle(call, responseHandler, errorHandler) {
    checkTokenExpiry();
    const ret = responseHandler ? call.flatMap(responseHandler) : call;

    return ret.catch((e) => {
      store.dispatch(authenticationHasFailed(e.status));

      if(errorHandler) {
        store.dispatch(errorHandler(e));
      }

      const lastAction = showMessage(
        e && e.response ? e.response.outcome.message : e.message,
      );
      if (e && e.status === HTTP_UNAUTHORIZED && azureSession.getToken()) {
        acquireToken();
      }
      return Observable.of(lastAction);
    });
  }

  static get(url, headers, responseHandler, errorHandler) {
    const h = AjaxClient.treatHeaders(headers);
      return AjaxClient.handle(
        Observable.ajax.get(API_URL + url, h).timeout(maxTimeout),
        responseHandler,
        errorHandler
      );
  }

  static getLocal(url, headers, responseHandler) {
    const h = AjaxClient.treatHeaders(headers);
    return AjaxClient.handle(Observable.ajax.get(url, h).timeout(maxTimeout), responseHandler);
  }

  static post(url, body, headers, responseHandler, errorHandler) {
    const h = AjaxClient.treatHeaders(headers);
    return AjaxClient.handle(
      Observable.ajax.post(API_URL + url, body, h).timeout(maxTimeout),
      responseHandler,
      errorHandler
    );
  }

  static delete(url, headers, responseHandler, errorHandler) {
    const h = AjaxClient.treatHeaders(headers);
    return AjaxClient.handle(
      Observable.ajax.delete(API_URL + url, h).timeout(maxTimeout),
      responseHandler,
      errorHandler
    );
  }
}

export const ajax = AjaxClient;
