import * as React from 'react';
import { circleStyle } from '../styles/styles';
import LiveJobMarker, {
  EllipseAssignedIcon,
  EllipseExceptionIcon,
  EllipseSuccessIcon,
} from '../classes/LiveJobMarkers';

const ALERT_BUBBLE_CLASS_NAME = 'live_job_alert_bubble';
const ALERT_BUBBLE_MULTIPLE_CLASS_NAME = 'live_job_multiple_alert_bubble';
const SINGLE_REGULAR_WIDTH = 50;
const SINGLE_REGULAR_HEIGHT = 60;
const MULTIPLE_REGULAR_DIAMETER = 50;
const MULTIPLE_REGULAR_BORDER = MULTIPLE_REGULAR_DIAMETER / 10;
const LIVE_JOB_MODAL_MARGIN = 10;
const MAP_X = 550; // Extra space for the "Live Jobs Status" panel
const MAP_Y = 215;
const MULTIPLE_TEXT_LAG = 3;
const SMALL_RATIO = 2 / 3;
const SINGLE_DIMENSION_SETS = {
  REGULAR: { width: SINGLE_REGULAR_WIDTH, height: SINGLE_REGULAR_HEIGHT },
  SMALL: { width: SINGLE_REGULAR_WIDTH * SMALL_RATIO, height: SINGLE_REGULAR_HEIGHT * SMALL_RATIO }
};
const MULTIPLE_DIMENSION_SETS = {
  REGULAR: { diameter: MULTIPLE_REGULAR_DIAMETER, border: MULTIPLE_REGULAR_BORDER, lag: MULTIPLE_TEXT_LAG },
  SMALL: { diameter: MULTIPLE_REGULAR_DIAMETER * SMALL_RATIO, border: MULTIPLE_REGULAR_BORDER * SMALL_RATIO, lag: MULTIPLE_TEXT_LAG * SMALL_RATIO }
};
const ZOOM_LEVEL_PIN_FULL_SIZE = 12;
const UPSIZE_SELECTED_RATIO = 1.5;

class HMapMethods extends React.Component {
  group;
  dimensions;
  dimensionSet;
  nextZIndex = 1000;
  selectedZIndex = 1000000;
  selectedAddress;
  selectAddress;

  constructor(props) {
    super(props);
    this.selectAddress = props.selectAddress;
  }

  generateCircle = (lat, lng, dataRadius) =>
    new H.map.Circle({ lat, lng }, dataRadius, circleStyle);

  addUiBubble = (bubbleContent, uiBubbleClassName) => {
    const { ui } = this.props;
    this.removePreviousAlertBubbles();
    ui.addBubble(bubbleContent);
    bubbleContent.getElement().classList.add(uiBubbleClassName);
  };

  removePreviousAlertBubbles = () => {
    const { ui } = this.props;
    ui.getBubbles().forEach((bubble) => {
      if (bubble.getElement().className.includes(ALERT_BUBBLE_CLASS_NAME)) {
        ui.removeBubble(bubble);
      }
      if (bubble.getElement().className.includes(ALERT_BUBBLE_MULTIPLE_CLASS_NAME)) {
        ui.removeBubble(bubble);
      }
    });
  };

  changeZoomLevel(zoom) {
    this.dimensionSet =  this.getDimensionSetForZoom(zoom);
  }

  /**
   * Select a specific pin
   * Will unselect all other pins
   * @param {string} addressNo Address Number identifying the pin - a NULL value will unselect all pins
   */
  selectPin(addressNo, forceReRender) {
    
    if(!!this.group) {
      const objects = this.group.getObjects();
      objects.forEach(marker => {
        const previousSelected = marker.selected;
        const newSelected = (marker.job.address.addressNo == addressNo);
        marker.selected = newSelected;
        // Only refresh the icon if it has changed to avoid unnecessary flickering
        if(previousSelected != newSelected || forceReRender === true) {
          this.setIcon(marker);
          this.applyZIndex(marker);
        }
      });
    }
  }

  /**
   * Apply the relevant z-index according to whether the marker is selected or not
   * @param {H.map.Marker} marker 
   */
  applyZIndex(marker) {
    if(marker.selected) {
      // Send to the selectedZIndex layer where it can't be overlayed
      marker.setZIndex(this.selectedZIndex);
    } else {
      // Send back to the regular hovered marker layers
      marker.setZIndex(this.nextZIndex);
    }
  }

  makeSVG(tag, attrs) {
    var el = document.createElementNS('http://www.w3.org/2000/svg', tag);
    for (var k in attrs) {
      el.setAttribute(k, attrs[k]);
    }
    return el;
  }

  getIconForMultipleJobs(jobs, d, b, l, state, selected) {
    const colours = {
      Assigned: '#FFDD66',
      Completed: '#00EBB7',
      Exception: '#FF6363',
    };

    const diameter = selected ? d * UPSIZE_SELECTED_RATIO : d;
    const border = selected ? b * UPSIZE_SELECTED_RATIO : b;
    const lag = selected ? l * UPSIZE_SELECTED_RATIO : l;

    const radius = diameter / 2;
    const colourRadius = radius - border;

    const svg = document.createElement('SVG');
    svg.setAttribute("width", diameter);
    svg.setAttribute("height", diameter);
    svg.setAttribute("xmlns", 'http://www.w3.org/2000/svg');

    if(selected) {
      const borderPath = this.makeSVG('circle', {
        cx: radius,
        cy: radius,
        r: radius,
        fill: '#000000'
      });
      svg.append(borderPath);
    }
    
    let startAngle=0, endAngle=0;
    for(var i=0; i<jobs.length; i++) {
      const angle = 2 * Math.PI / jobs.length;
      endAngle += angle;
      const pathStr =
        "M "+ (radius)+","+(radius)+" "+
        "L "+ (Math.cos(startAngle)*colourRadius+radius)+","+
              (Math.sin(startAngle)*colourRadius+radius)+" "+
        "A "+ (colourRadius)+","+(colourRadius)+
              " 0 "+(angle<Math.PI ? "0" : "1") + " 1 "+
              (Math.cos(endAngle)*colourRadius+radius)+","+
              (Math.sin(endAngle)*colourRadius+radius)+" "+
        "Z";
      const svgPath = this.makeSVG('path', {
        d: pathStr,
        fill: colours[jobs[i].status]
      });
      svg.append(svgPath);
      startAngle += angle;
    }

    const discPath = this.makeSVG('circle', {
      cx: radius,
      cy: radius,
      r: radius - border - border,
      fill: state == 'hover' ? '#cccccc' : '#ffffff'
    });
    svg.append(discPath);
    const textElement = this.makeSVG('text', {
      x: radius,
      y: radius + lag,
      'font-size': (radius * 2 / 3)+'px',
      'text-anchor': 'middle',
      'dominant-baseline': 'middle',
      'font-family': 'sans-serif',
      'font-weight': 'bold',
    });
    textElement.textContent = jobs.length;
    svg.append(textElement);

    return new H.map.Icon(svg.outerHTML, {
      anchor: { x: radius, y: radius }
    });
  }

  getIconForSingleJob(status, w, h, state, selected) {
    let iconFunction;
    switch(status) {
      case 'Completed':
        iconFunction = EllipseSuccessIcon;
        break;
      case 'Exception':
        iconFunction = EllipseExceptionIcon;
        break;
      default:
        iconFunction = EllipseAssignedIcon;
    }
    const widht = selected ? w * UPSIZE_SELECTED_RATIO : w;
    const height = selected ? h * UPSIZE_SELECTED_RATIO : h;
    return new H.map.Icon(iconFunction(widht, height, state, selected));
  }

  setIcon(marker) {
    let icon;

    if (marker.job.jobs.length > 1) {
      const set = MULTIPLE_DIMENSION_SETS[this.dimensionSet];
      icon = this.getIconForMultipleJobs(marker.job.jobs, set.diameter, set.border, set.lag, marker.state, marker.selected);
    } else if (marker.job.jobs.length > 0) {
      const set = SINGLE_DIMENSION_SETS[this.dimensionSet];
      icon = this.getIconForSingleJob(marker.job.jobs[0].status, set.width, set.height, marker.state, marker.selected);
    }

    if(!!icon) {
      marker.setIcon(icon);
    }
  }

  generateContent(job) {
    let markerParams;

    if (job.jobs.length > 1) {
      markerParams = {
        multiple: true,
        addressShort: job.jobs[0].addressShort,
      };
    } else if (job.jobs.length > 0) {
      markerParams = job.jobs[0];
    }

    return new LiveJobMarker(markerParams).getMarkup();
  }
  
  /**
  * What dimension set to load for the markers given the current zoom?
  * @param {number} zoom
  * @returns {string} Name of the dimension set
  */
  getDimensionSetForZoom(zoom) {
    return zoom >= ZOOM_LEVEL_PIN_FULL_SIZE
      ? 'REGULAR'
      : 'SMALL';
  }
  
  adjustModalPosition(multijobs, selected) {
    const elements = document.getElementsByClassName('H_ib');
    const element = elements[0];
    if(element) {
      var tooltipRect = element.getBoundingClientRect();
      const subElement = element.getElementsByClassName('live-job-alert-bubble')[0];
      const bodyElement = element.getElementsByClassName('H_ib_body')[0];
      bodyElement.style.right = null;
      let x = tooltipRect.left - LIVE_JOB_MODAL_MARGIN;
      let y = tooltipRect.top - LIVE_JOB_MODAL_MARGIN;
      if(x - subElement.offsetWidth < MAP_X) {
        subElement.className += ` live-${multijobs ? 'multiple-' : ''}job-alert-bubble-move-right`;
      }
      if(y - subElement.offsetHeight < MAP_Y) {
        subElement.className += ` live-${multijobs ? 'multiple-' : ''}job-alert-bubble-move-bottom`;
      }
    }
  }

  /**
   * Set marker custom properties
   * Will re-use properties of existing marker if any
   * @param {*} marker
   * @param {*} existingMarker
   * @param {*} job
   */
  setMarkerCustomProperties(marker, existingMarker, job) {
    marker.job = !!existingMarker?.job ? existingMarker.job : job;
    marker.state = !!existingMarker?.state ? existingMarker.state : 'regular';
    marker.selected = !!existingMarker?.selected ? existingMarker.selected : false;
    if(!!job.address.addressNo && !!this.selectedAddress && job.address.addressNo == (this.selectedAddress.id || this.selectedAddress.addressNo)) {
      marker.selected = true;
    } else {
      marker.selected = false;
    }
  }

  buildMarker(job, showLiveJobPopup, existingMarker, map) {
    const position = {
      lat: job.address.latitude,
      lng: job.address.longitude,
    };

    let marker;
    let content = '';

    if (job.jobs.length > 0) {
      let enterTimeoutId;

      content = this.generateContent(job);

      if (job.jobs.length > 0) {
        marker = new H.map.Marker(position);
      }
      this.setMarkerCustomProperties(marker, existingMarker, job);
      this.setIcon(marker);
      this.applyZIndex(marker);

      marker.addEventListener(
        'tap',
        () => {
          this.props.setUserInteraction({ addressWithJobs: job });
          this.selectPin(job.address.addressNo);
          showLiveJobPopup(job);
        },
        false,
      );

      const getTimeout = () => map?.getZoom() >= 20 ? 1000 : 100;

      marker.addEventListener(
        'pointerenter',
        (event) => {
          if (marker.state != 'hover') {
            marker.state = 'hover';
            // setIcon causes pointerleave and pointerenter to be fired again.
            // delay setIcon to avoid flickering
            setTimeout(() => this.setIcon(marker), getTimeout());
            this.sendMarkerToTheForeground(marker);

            enterTimeoutId = setTimeout(() => {
              const bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
                content: content
              });
              this.addUiBubble(bubble, ALERT_BUBBLE_CLASS_NAME);

              const element = bubble.getElement();
              element.opacity = 0;
              element.classList.add('fade-in-marker');

              setTimeout(() => {
                element.style.opacity = 1;
                this.adjustModalPosition(job.jobs.length > 1);
              }, 100);
            }, 400);
          }
        },
        false,
      );

      marker.addEventListener(
        'pointerleave',
        () => {
          if (marker.state != 'regular') {
            marker.state = 'regular';
            setTimeout(() => this.setIcon(marker), getTimeout());
            enterTimeoutId = setTimeout(() => {
              this.removePreviousAlertBubbles();
            }, 400);
          }
        },
        false,
      );

      this.group.addObject(marker);
    }

    return marker;
  }

  sendMarkerToTheForeground(marker) {
    // If the marker is selected, it is already at the highest level (selectedZIndex) therefore does not need to be moved
    if(!marker.selected) {
      marker.setZIndex(this.nextZIndex++);
    }
  }

  generateLiveJobMarkers = (liveJobFiltered, showLiveJobPopup, zoom, map, selectedAddress, selectedAddressWithJobs) => {
    if(!this.group) {
      this.group = new H.map.Group();
    }
    const existingMarkers = this.group.getObjects();
    const newDimensionSet = this.getDimensionSetForZoom(zoom);
    if(existingMarkers.length == 0 || this.dimensionSet != newDimensionSet) {
      this.dimensionSet = newDimensionSet;
      this.group.removeAll();
      this.selectedAddress = selectedAddress;
      Object.values(liveJobFiltered).forEach(job => {
        const existingMarker = existingMarkers.find(marker => marker.job.address.addressNo == job.address.addressNo);
        this.buildMarker(job, showLiveJobPopup, existingMarker, map);
      });
      
      if(selectedAddressWithJobs) {
        this.selectPin(selectedAddressWithJobs.address.addressNo);
        showLiveJobPopup(selectedAddressWithJobs)
      }
      
    }

    return this.group;
  };

}

export default HMapMethods;
