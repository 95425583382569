import React from 'react';
import { Loader } from 'semantic-ui-react';
import './job.css';

export default class LoadingIndicator extends React.Component {
  render() {
    return (
      <div className="loading-indicator-popup">
        <Loader active={true} size="massive" inline="centered" />;
      </div>
    );
  }
}
