
// @flow

import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import {
  Form,
  Button,
  Container,
  Grid,
} from 'semantic-ui-react';
import { unSnake } from '../../common/helpers';
import Input from '../../components/Input';
import validate from './validate';
import { reportDateOptions } from '../../common/options';
import Drop from '../../components/DropDown';

type Props = {
  handleSubmit: Function,
  handleUpdate: Function,
  handleReportFrequencyChange: Function,
  updatingReport: boolean,
  pristine: boolean,
  dispatch: Function,
  reset: Function,
};

function AddReceiverForm({ handleSubmit, handleUpdate, handleReportFrequencyChange, updatingReport, pristine, dispatch, reset }: Props) {
  const reportScheduleFilter = (
    <Field
      component={Drop}
      name="reportSchedule"
      placeholder={`Choose ${unSnake(name)}`}
      search
      selection
      options={reportDateOptions}
      icon="angle down"
      onChange={(e, value) => (handleReportFrequencyChange(value))}
    />);

  const submitAndReset = (data) => {
    handleUpdate(data);
    reset();
    dispatch(change('addReceiver', 'reportSchedule', data.reportSchedule));
  };

  return (
    <div className="add-subscription">
      <Container text>
        <Form onSubmit={handleSubmit(submitAndReset.bind(this))}>
          <Grid>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16}>
                <h4>Report Schedule</h4>
                { reportScheduleFilter }
              </Grid.Column>

            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={12} computer={13}>
                <h4>Add Subscription</h4>
                <Field name="email" placeholder="Enter email" component={Input} type="email" />
              </Grid.Column>
              <Grid.Column tablet={4} computer={3} verticalAlign="bottom" only="tablet computer">
                <Button
                  type="submit"
                  size="massive"
                  color="green"
                  loading={updatingReport}
                  disabled={pristine || updatingReport}

                >
                    Add
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row mobile={16} only="mobile">
              <Grid.Column>
                <Button
                  type="submit"
                  size="massive"
                  color="green"
                  loading={updatingReport}
                  disabled={pristine || updatingReport}
                >
                      Add
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    </div>


  );
}

// decorate
AddReceiverForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'addReceiver',
  validate,
})(AddReceiverForm);

export default AddReceiverForm;
