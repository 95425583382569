import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import RequestPasswordResetForm from '../../components/RequestPasswordResetForm';
import * as accountActionCreators from '../../redux/modules/account';

class RequestPasswordResetContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleRequestPasswordReset = this.handleRequestPasswordReset.bind(this);
  }

  props: {
    isRequestingReset: boolean,
    requestResetSuccess: boolean,
    requestPasswordReset: Function,
  };

  handleRequestPasswordReset(values) {
    this.props.requestPasswordReset(values.email);
  }

  render() {
    return (
      <div style={{ height: '100%' }} className="comp">
        <Helmet
          title="Request Password Reset"
          meta={[
            { name: 'Request Password Reset', content: 'Customer Portal' },
          ]}
        />
        <RequestPasswordResetForm
          onSubmit={this.handleRequestPasswordReset}
          isRequestingReset={this.props.isRequestingReset}
          requestResetSuccess={this.props.requestResetSuccess}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isRequestingReset: state.account.isRequestingReset,
    requestResetSuccess: state.account.requestResetSuccess,
  }),
  (dispatch) => bindActionCreators(accountActionCreators, dispatch)
)(RequestPasswordResetContainer);
