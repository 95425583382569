
// @flow

import styled from 'styled-components';

const Box = styled.span`
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative;
`;

export default Box;
