
// @flow

import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Form, Button, Divider, Header } from 'semantic-ui-react';
import { getAvailableRolesForUser } from '../../common/helpers';
import Input from '../Input';
import Check from '../Check';
import Select from '../Select';
import validate from './validate';
import { accountModules } from '../../common/options';

type Props = {
  handleSubmit: Function,
  isUpdating: boolean,
  pristine: boolean,
  currentUserRole: string,
  historyBack: Function,
};

function EditUserForm({ handleSubmit, isUpdating, pristine, currentUserRole, historyBack }: Props) {
  const modulesCB = [];
  accountModules.forEach((module) => (
    modulesCB.push(<Field key={module.name} component={Check} label={module.label} name={module.name} className="field" />)
  ));

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Form.Group widths="equal">
        <Field name="firstName" label="First Name" component={Input} type="text" />
        <Field name="lastName" label="Last Name" component={Input} type="text" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="email" label="Email" component={Input} type="email" />
        <Field name="role" icon="angle down" label="Role" component={Select} options={getAvailableRolesForUser(currentUserRole)} />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="password" label="New Password" component={Input} type="password" />
        <Field name="confirmPassword" label="Confirm Password" component={Input} type="password" />
      </Form.Group>
      <Header as="h3" style={{ marginBottom: '40px' }}>User Module Access</Header>
      <Form.Group inline>
        <FormSection name="modules">
          {modulesCB}
        </FormSection>
      </Form.Group>
      <Divider style={{ margin: '50px 0' }} />
      <div className="form-footer clearfix">
        <Button
          type="submit"
          size="massive"
          color="green"
          loading={isUpdating}
          floated="right"
          disabled={pristine || isUpdating}
        >
          Update
        </Button>
        <a role="presentation" className="cancel" onClick={historyBack}>Cancel</a>
      </div>
    </Form>
  );
}

// decorate
EditUserForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'editUser',
  validate,
})(EditUserForm);

export default EditUserForm;
