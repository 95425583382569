// @flow

import React from 'react';
import moment from 'moment';
import { toSnake } from '../../../../common/helpers';
import { createLiftBubble } from '../../../../components/HereMap/helpers/LiftBubble';
import ButtonViewAlert from './ButtonViewAlert';
import {
  SLiftEventAlertIcon,
  SLiftIcon,
  STruckLiftEvent,
  SIconWrapper,
  SLiftEventConnector,
  SLiftEventInfoWrapper,
  SLiftCreatedHeader,
  SLiftCreated,
} from './index.sc';

const getCreateAlertIconSvgPath = (reason: string): string =>
  process.env.PUBLIC_URL + `/assets/alerts/${toSnake(reason)}.svg`;

const getCreateAlertIcon = (reason: string): React.Element => (
  <SLiftEventAlertIcon src={getCreateAlertIconSvgPath(reason)} alt={reason} />
);

const getIconForLift = (
  reason: string | null,
  themeColor: string,
): React.Element =>
  reason ? getCreateAlertIcon(reason) : <SLiftIcon themeColor={themeColor} />;

const formatCreatedDate = (date: string): string =>
  moment(date).parseZone().format('hh:mm A  DD/MM/YYYY');

const isFocused = (activeEvent, uuid) => {
  if (activeEvent.uuid === null) return true;
  return activeEvent.uuid === uuid;
};
const eventType = (reason: string): string => (!reason ? 'binLift' : 'alerts');

const handleLiftClick = (liftEvent, setActiveEvent, ui) => {
  const active = setActiveEvent(
    liftEvent.uuid,
    eventType(liftEvent.reason),
    liftEvent,
  );
  active ? createLiftBubble(liftEvent, ui, setActiveEvent) : null;
};

type Props = {
  reason: string,
  created: string,
  themeColor: string,
  uuid: String,
  setActiveEvent: Function,
  activeEvent: Object,
  liftEvent: Object,
  handleThumb: Function,
  imageResources: Array<String>,
  id: String,
  ui: Object,
};

const TruckLiftsEvent = ({
  reason,
  created,
  themeColor,
  setActiveEvent,
  uuid,
  activeEvent,
  liftEvent,
  handleThumb,
  imageResources,
  id,
  ui,
}: Props): React.Element => (
  <STruckLiftEvent
    onClick={() => handleLiftClick(liftEvent, setActiveEvent, ui)}
    isFocused={isFocused(activeEvent, uuid)}>
    <SIconWrapper className={'icon-wrapper'}>
      <SLiftEventConnector
        className={'lift-event-connector'}
        themeColor={themeColor}
      />
      {getIconForLift(reason, themeColor)}
    </SIconWrapper>
    <SLiftEventInfoWrapper>
      <SLiftCreatedHeader>{reason || 'LIFT RECORDED'}</SLiftCreatedHeader>
      <SLiftCreated>{formatCreatedDate(created)}</SLiftCreated>
    </SLiftEventInfoWrapper>
    {reason ? (
      <ButtonViewAlert
        handleClick={() => handleThumb(imageResources, created, id)}>
        View
      </ButtonViewAlert>
    ) : (
      ''
    )}
  </STruckLiftEvent>
);

export default TruckLiftsEvent;
