import React, { Component } from 'react';
// import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search, Button } from 'semantic-ui-react';
import * as searchActionCreators from '../../redux/modules/search';
import * as mapActionCreators from '../../redux/modules/map';
import { STruckResult, SAddressResult } from './index.sc';
import { IconTruck } from '../Icons/Truck';
import { IconMap } from '../Icons/Map';
import { IconClear } from '../Icons/Clear';
import { dayFromGivenDate } from '../../common/timeOffsetHelper';
// import Message from '../../components/Message';
/* eslint-disable */

const handleOnSuccessGeoCode = (description, props) => {
  props.requestAddress({
    ...description,
    activeDate: props.activeDate,
    territoryId: props.territoryId,
    dayRange: props.activeDate
      ? dayFromGivenDate(
          props.activeDate,
          props.isToday,
          props.council.localTime,
        )
      : null,
    lat: description.lat,
    lng: description.lng,
    range: props.council.range,
  });
};

type SearchBarProps = {
  onFocus: Function,
  onBlur: Function,
  searchValueChanged: Function,
  resetCenterAddress: Function,
  resetSearch: Function,
  setSearchValue: Function,
  requestAddress: Function,
  handleClearSearch: Function,
  handleReceiveClickedSearch: Function,
  shown: Boolean,
  search: Object,
  activeDate: string,
  isToday: Boolean,
  territoryId: any,
  council: any,
  isLiveJobsPage: Boolean,
};

class SearchBar extends Component<SearchBarProps> {
  handleSearchChange = (e, { value }) => {
    const { territoryId } = this.props;
    this.props.searchValueChanged(value, territoryId, this.props.excludeTruck, this.props.isLiveJobsPage);
    if (!value) {
      this.props.isLiveJobsPage ? this.props.resetLiveJobsSearch() : this.props.resetSearch();
    }
  };

  handleResultSelect = (e, { result }) => {
    const description = JSON.parse(result.description);
    switch (result.title) {
      case 'address':
        if (this.props.handleReceiveClickedTruck) {
          this.props.handleReceiveClickedTruck(null);
        }
        if (this.props.handleReceiveClickedSearch)
         {
          this.props.handleReceiveClickedSearch(
            description,
          );
         }
        if (this.props.setSearchValue) this.props.setSearchValue(description, this.props.isLiveJobsPage);

        if(!this.props.isLiveJobsPage) {
          handleOnSuccessGeoCode(description, this.props);
        }
        
        break;
      case 'truck':
        this.props.handleClearSearch();
        this.props.handleReceiveClickedTruck(description);
        break;
      default:
        console.error(
          'Result is not a valid type, must be an address or truck',
        );
    }
  };

  renderResult = ({ description, title }) => {
    const result = JSON.parse(description);
    switch (title) {
      case 'address':
        return this.renderAddressResult(result);
      case 'truck':
        return this.renderTruckResult(result);
      default:
        console.error(
          'Result is not a valid type, must be an address or truck',
        );
    }
  };

  renderAddressResult = (address) => (
    <SAddressResult>
      <div className="map-icon">
        <IconMap />
      </div>
      <div className="inner">
        <span className="street">
          {address?.streetNumber} {address?.streetName},
        </span>{' '}
        <span className="suburb">{address?.suburb.toLowerCase()}</span>
      </div>
    </SAddressResult>
  );

  renderTruckResult = (truck) => (
    <STruckResult>
      <IconTruck wasteStream={truck.wasteStream} shadow />
      <div className="inner">
        <span className="label">
          {truck.wasteStream} #{truck.id}
        </span>
      </div>
    </STruckResult>
  );

  render() {
    const { shown, onFocus, onBlur, search } = this.props;

    const items = this.props.isLiveJobsPage ? search.liveJobsItems : search.items;
    // Transform search items to match the format Semantic UI React is expecting.
    const searchAddresses = items.addresses.slice(0, 8).map((s) => ({
      description: JSON.stringify(s),
      key: s.id,
      title: 'address',
    }));

    const searchTrucks = items.trucks
      ? items.trucks?.slice(0, 8).map((s) => ({
          description: JSON.stringify(s),
          key: s.id,
          title: 'truck',
        }))
      : [];

    const searchItems = searchAddresses.concat(searchTrucks);
    const hasTrucksSearchResults =
    items.truck && items.truck.length === 0;

    const hasAddressSearchResults =
    items.addresses && items.addresses.length === 0;
    return (
      <Search
        className={`item search-input ${shown ? 'shown' : 'hidden'}`}
        onResultSelect={this.handleResultSelect}
        showNoResults={
          !search.isFetching &&
          hasTrucksSearchResults &&
          hasAddressSearchResults
        }
        onSearchChange={this.handleSearchChange}
        onFocus={onFocus}
        onBlur={onBlur}
        noResultsMessage="No address found"
        noResultsDescription="Are you sure this is correct?"
        results={searchItems}
        value={this.props.defaultValue || (this.props?.isLiveJobsPage ? search.liveJobsValue : search.value)}
        resultRenderer={this.renderResult}
        input={{
          icon: 'search',
          label: (
            <Button onClick={this.props.handleClearSearch}>
              <IconClear />
            </Button>
          ),
          labelPosition: 'right',
          iconPosition: 'left',
          placeholder: 'Search...',
          loading: search.isFetching && search.value.length > 2,
        }}
      />
    );
  }
}

export default connect(
  (state) => ({
    search: state.search,
    account: state.account.info.account,
    council: state.councils.active,
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...searchActionCreators,
        ...mapActionCreators,
      },
      dispatch,
    ),
)(SearchBar);
