
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Header, Menu, Icon } from 'semantic-ui-react';
import * as ticketActionCreators from '../../redux/modules/tickets';
import RaiseTicketForm from '../../components/RaiseTicketForm';

class RaiseTicketContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  props: {
    isPosting: boolean,
    postTicket: Function,
  };

  handleUpdate(values) {
    this.props.postTicket(55, values);
  }

  render() {
    return (
      <div>
        <Helmet
          title="Raise Ticket"
          meta={[
            { name: 'Raise Ticket', content: 'Customer Portal' },
          ]}
        />
        <Menu secondary stackable>
          <Link to="/tickets" className="link item">
            <Icon className="icon-left" style={{ marginRight: '15px' }} />
            All Tickets
          </Link>
        </Menu>
        <Container text>
          <Header as="h1" className="form-header">Raise Ticket</Header>
          <RaiseTicketForm
            onSubmit={this.handleUpdate}
            isPosting={this.props.isPosting}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({ isPosting: state.tickets.isPosting }),
  (dispatch) => bindActionCreators(ticketActionCreators, dispatch)
)(RaiseTicketContainer);

