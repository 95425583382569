// @flow

import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Form, Button, Divider, Header } from 'semantic-ui-react';
import Input from '../Input';
import Check from '../Check';
import validate from './validate';
import { accountModules, timezones } from '../../common/options';
import Select from '../Select';

type Props = {
  handleSubmit: Function,
  isPosting: boolean,
  isUpdating: boolean,
  pristine: boolean,
};

function AddAccountForm({
  handleSubmit,
  isPosting,
  isUpdating,
  pristine,
}: Props) {
  const modulesCB = [];
  accountModules.forEach((module) =>
    modulesCB.push(
      <Field
        key={module.name}
        component={Check}
        label={module.label}
        name={module.name}
        className="field"
      />,
    ),
  );

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Form.Group widths="equal">
        <Field
          name="name"
          label="Council Name*"
          component={Input}
          type="text"
        />
        <Field name="lga" label="LGA" component={Input} type="text" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="lat" label="Latitude*" component={Input} type="number" />
        <Field name="lng" label="Longitude*" component={Input} type="number" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          name="territoryID"
          label="Territory ID*"
          component={Input}
          type="text"
          className="half-flex"
        />
        <Field
          name="timezone"
          label="Time Zone"
          component={Select}
          options={timezones}
          icon="angle down"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          name="range"
          label="Search Radius (in meters)"
          component={Input}
          type="number"
          className="half-flex"
        />
      </Form.Group>
      <Header as="h3" style={{ marginBottom: '40px' }}>
        Account Module Access
      </Header>
      <Form.Group inline>
        <FormSection name="modules">{modulesCB}</FormSection>
      </Form.Group>
      <Divider style={{ margin: '50px 0' }} />
      <div className="form-footer clearfix">
        <Button
          type="submit"
          size="massive"
          color="green"
          loading={isPosting || isUpdating}
          floated="right"
          disabled={pristine || isPosting || isUpdating}
        >
          {isPosting !== undefined ? 'Add Account' : 'Update'}
        </Button>
        <Link to="/account-management" className="cancel">
          Cancel
        </Link>
      </div>
    </Form>
  );
}

// decorate
AddAccountForm = reduxForm({
  // eslint-disable-line no-func-assign
  form: 'addAccount',
  validate,
})(AddAccountForm);

export default AddAccountForm;
