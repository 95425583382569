
// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import diff from 'object-diff';
import { Header, Container, Segment } from 'semantic-ui-react';
import * as accountActionCreators from '../../redux/modules/account';
import AccountForm from '../../components/AccountForm';

class AccountContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  props: {
    isUpdating: boolean,
    account: Object,
    initialValues: Object,
    updateAccount: Function,
  };

  handleUpdate(values) {
    const { id, role, email, firstName, lastName, suspended } = values;
    const { confirmPassword, ...delta } = diff(this.props.initialValues, values);
    const data = {
      role,
      email,
      firstName,
      lastName,
      suspended,
      ...delta,
    };
    this.props.updateAccount(id, data);
  }

  render() {
    const { account } = this.props;

    return (
      <div className="comp">
        <Helmet
          title="Account"
          meta={[
            { name: 'Account', content: 'Customer Portal' },
          ]}
        />
        <Container text>
          <Header as="h1" className="form-header">Account</Header>
          <Segment secondary padded="very" style={{ marginBottom: '80px' }} className="gray-seg">
            <h3>Company Name</h3>
            <p>{account.name}</p>
          </Segment>
          <Header as="h3">Profile</Header>
          <AccountForm
            initialValues={this.props.initialValues}
            onSubmit={this.handleUpdate}
            isUpdating={this.props.isUpdating}
          />
        </Container>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isUpdating: state.account.isUpdating,
    account: state.account.info.account,
    initialValues: {
      ...state.account.info.user,
      password: 'password',
      confirmPassword: 'password',
    },
  }),
  (dispatch) => bindActionCreators(accountActionCreators, dispatch)
)(AccountContainer);
