// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';
import {
  Table,
  Icon,
  Menu,
  Select,
  Image,
  Form,
  Search,
  Accordion,
  Loader,
  Segment,
  Divider,
  Button,
} from 'semantic-ui-react';
import RaiseTicket from '../../containers/RaiseTicket';
import Ticket from '../../components/Ticket';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';
import Close from '../../common/images/general/close_round.svg';
import * as searchActionCreators from '../../redux/modules/search';
import * as ticketsActionCreators from '../../redux/modules/tickets';
import { ticketTypes, ticketStatuses } from '../../common/options';
import { reportedMinutes } from '../../common/helpers';
import { SAddressResult } from '../../components/SearchBar/index.sc';
import { IconMap } from '../../components/Icons/Map';

class TicketsContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.view = this.view.bind(this);
    this.filterByStatus = this.filterByStatus.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
  }

  componentDidMount() {
    const { location, tickets, search, territoryId } = this.props;
    const fromMap =
      location.state !== undefined &&
      location.state.fromMap &&
      search.result.id !== null;

    // if we haven't already fetched
    if (!tickets.length && !fromMap) {
      this.props.requestTickets(
        territoryId,
        this.getStatusQuery(),
        tickets.selectedType,
        tickets.from,
        tickets.limit,
      );
    }

    // came from map sidebar
    if (fromMap) {
      this.props.setSearchValue(search.result);
      this.props.clearTickets();
      this.props.requestTickets(
        territoryId,
        this.getStatusQuery(),
        tickets.selectedType,
        tickets.from,
        tickets.limit,
        search.result.id,
      );
    }
  }

  /**
   * Return the selected statuses in a string readable by the API
   * @param   {*=} selectedStatuses
   *
   * @returns {string}
   */
  getStatusQuery(selectedStatuses) {
    let statuses = selectedStatuses;
    if (!statuses) {
      statuses = this.props.tickets.selectedStatuses;
    }
    const statusesRequired = [];
    Object.keys(statuses).forEach((key) => {
      if (statuses[key]) {
        statusesRequired.push(key);
      }
    });
    return statusesRequired.join(',');
  }

  loadMoreTickets() {
    const { tickets, territoryId } = this.props;

    this.props.requestMoreTickets(
      territoryId,
      this.getStatusQuery(tickets.selectedStatuses),
      tickets.selectedType,
      tickets.from + tickets.limit,
      tickets.limit,
      tickets.addressId,
    );
  }

  filterByType(event, data) {
    const { tickets, territoryId } = this.props;

    // Reset from new list
    this.props.tickets.from = 0;

    this.props.selectType(data.value);
    this.props.requestTickets(
      territoryId,
      this.getStatusQuery(),
      data.value,
      tickets.from,
      tickets.limit,
      tickets.addressId,
    );
  }

  filterByStatus(event, data) {
    const { tickets, territoryId } = this.props;

    // Reset from new list
    this.props.tickets.from = 0;

    tickets.selectedStatuses[data.name] = data.checked;
    this.props.selectStatuses(tickets.selectedStatuses);

    this.props.requestTickets(
      territoryId,
      this.getStatusQuery(tickets.selectedStatuses),
      tickets.selectedType,
      tickets.from,
      tickets.limit,
      tickets.addressId,
    );
  }

  handleResultSelect(e, { result }) {
    const { tickets, territoryId } = this.props;

    const address = JSON.parse(result.description);

    // Reset from new list (should be done in clear tickets?)
    this.props.tickets.from = 0;

    this.props.setSearchValue(result);
    this.props.clearTickets();
    this.props.requestTickets(
      territoryId,
      this.getStatusQuery(),
      tickets.selectedType,
      tickets.from,
      tickets.limit,
      address.id,
    );
  }

  /**
   *  Hits the address endpoint as the user types in the search input. Handle
   *  the case when the user clears the search input completely.
   */
  handleSearchChange(e, { value }) {
    this.props.searchValueChanged(value, this.props.territoryId);
    if (!value) {
      this.handleClearSearch();
    }
  }

  /**
   *  Clear search results on the state tree and hit the endpoint to refresh the
   *  tickets list.
   */
  handleClearSearch() {
    const { tickets, territoryId } = this.props;

    this.props.resetSearch();
    this.props.clearTickets();
    this.props.requestTickets(
      territoryId,
      this.getStatusQuery(),
      tickets.selectedType,
      tickets.from,
      tickets.limit,
      null,
    );
  }

  view(ticket) {
    this.props.history.push(`/tickets/${ticket.id}/view`, {
      ticket,
      result: {},
    });
  }

  props: {
    requestTickets: Function,
    requestMoreTickets: Function,
    searchValueChanged: Function,
    selectStatuses: Function,
    resetSearch: Function,
    setSearchValue: Function,
    clearTickets: Function,
    selectType: Function,
    match: Object,
    history: Object,
    tickets: Array,
    location: Object,
    search: Object,
    territoryId: String,
  };

  /* Returns a search result line */
  renderResult = ({ description }) => {
    const address = JSON.parse(description);

    return (
      <SAddressResult>
        <div className="map-icon">
          <IconMap />
        </div>
        <div className="inner">
          <span className="street">
            {address.streetNumber} {address.streetName},
          </span>{' '}
          <span className="suburb">{address.suburb.toLowerCase()}</span>
        </div>
      </SAddressResult>
    );
  };

  render() {
    const { search, tickets, match } = this.props;

    const ticketItems = tickets.items.map((ticket) => (
      <Table.Row key={ticket.id} onClick={() => this.view(ticket)}>
        <Table.Cell className="ticket-id">#{ticket.id}</Table.Cell>
        <Table.Cell className="address">
          {ticket.address.streetNumber} {ticket.address.streetName}{' '}
          {ticket.address.suburb}
        </Table.Cell>
        <Table.Cell className="reason">{ticket.workOrderType}</Table.Cell>
        <Table.Cell className="created">
          {reportedMinutes(ticket.created)}
        </Table.Cell>
        <Table.Cell className="status">{ticket.status}</Table.Cell>
        <Table.Cell className="crn">{ticket.councilReferenceNumber}</Table.Cell>
        <Table.Cell className="lastUpdated">
          {reportedMinutes(ticket.modified)}
        </Table.Cell>
      </Table.Row>
    ));

    const loadButton = (
      <div>
        <Divider />
        <Segment basic textAlign="center">
          <Button
            basic
            color="blue"
            size="massive"
            className="load-more-btn"
            onClick={() => this.loadMoreTickets()}
            loading={tickets.isFetchingMoreTickets}>
            Load More
          </Button>
        </Segment>
      </div>
    );

    const table = (
      <div>
        <Table
          basic
          padded
          celled
          size="large"
          selectable
          className="standard tickets">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Reported</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>CRN</Table.HeaderCell>
              <Table.HeaderCell>Last Updated</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{ticketItems}</Table.Body>
        </Table>

        {/* load more */}
        {tickets && tickets.items.length && tickets.showLoadMore
          ? loadButton
          : null}
      </div>
    );

    // Transform search items to match the format Semantic UI React is expecting.
    const searchAddresses = search.items.addresses.slice(0, 8).map((s) => ({
      description: JSON.stringify(s),
      key: s.id,
      title: 'address',
    }));

    return (
      <div className="comp secondary-comp">
        <Route path={`${match.url}/raise-ticket`} component={RaiseTicket} />
        <Route path={`${match.url}/:id/view`} component={Ticket} />
        <Route
          exact
          path={match.url}
          render={() => (
            <div>
              <Helmet
                title="Tickets"
                meta={[{ name: 'Tickets', content: 'Customer Portal' }]}
              />
              <Menu secondary stackable>
                <Search
                  className={`item search-input ${
                    search.value ? 'shown' : 'hidden'
                  }`}
                  onResultSelect={this.handleResultSelect}
                  showNoResults={false}
                  onSearchChange={this.handleSearchChange}
                  noResultsMessage="No address found"
                  noResultsDescription="Are you sure this is correct?"
                  results={searchAddresses}
                  value={search.value}
                  resultRenderer={this.renderResult}
                  input={{
                    icon: 'search',
                    label: (
                      <Button onClick={this.handleClearSearch}>
                        <Image src={Close} alt="close" />
                      </Button>
                    ),
                    labelPosition: 'right',
                    iconPosition: 'left',
                    placeholder: 'Search Address...',
                    loading: search.isFetching,
                  }}
                />
                <Accordion className="menu-accordion menu right">
                  <Accordion.Title>
                    <Icon className="icon-mobile-toggle" />
                  </Accordion.Title>
                  <Accordion.Content>
                    <Menu.Menu position="right">
                      <Menu.Item className="status-container">
                        <Form>
                          <Form.Group inline>
                            <label htmlFor="status">Status</label>
                            {ticketStatuses.map((ticketStatus) => (
                              <Form.Checkbox
                                key={ticketStatus.value}
                                name={ticketStatus.value}
                                label={ticketStatus.label}
                                checked={
                                  tickets.selectedStatuses[ticketStatus.value]
                                }
                                onChange={this.filterByStatus}
                              />
                            ))}
                          </Form.Group>
                        </Form>
                      </Menu.Item>
                      <Select
                        placeholder="All Tickets"
                        value={tickets.selectedType}
                        options={ticketTypes}
                        icon="angle down"
                        className="user-dropdown"
                        onChange={this.filterByType}
                      />
                    </Menu.Menu>
                  </Accordion.Content>
                </Accordion>
              </Menu>

              <FluidContainer fluid>
                <PageHeader title="Tickets" />

                {/* show table or loader */}
                {tickets.items.length ? (
                  table
                ) : (
                  <Loader
                    active={tickets.isFetching}
                    size="massive"
                    inline="centered"
                  />
                )}

                {/* no results */}
                {!tickets.items.length && !tickets.isFetching ? (
                  <p
                    style={{
                      color: '#424852',
                      textAlign: 'center',
                      fontSize: '16px',
                    }}>
                    No results
                  </p>
                ) : null}
              </FluidContainer>
            </div>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    tickets: state.tickets,
    search: state.search,
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...searchActionCreators,
        ...ticketsActionCreators,
      },
      dispatch,
    ),
)(TicketsContainer);