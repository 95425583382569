import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import account, { accountEpics } from './account';
import users, { usersEpics } from './users';
import councils from './councils';
import search, { searchEpics } from './search';
import map, { mapEpics } from './map';
import liveJob, { liveJobEpic } from './liveJob';
import tickets, { ticketsEpics } from './tickets';
import alerts, { alertsEpics } from './alerts';
import accounts, { accountsEpics } from './accounts';
import reports, { reportsEpics } from './reports';
import messages from './messages';
import invoices, { invoiceEpics } from './invoices';
import { wrapEpics } from '../../common/epicHelpers';
import jobs, { jobEpics } from './jobs';

// wrap epics for error handling, see epicHelpers
export const rootEpic = wrapEpics({
  accountEpics,
  usersEpics,
  searchEpics,
  ticketsEpics,
  mapEpics,
  liveJobEpic,
  alertsEpics,
  accountsEpics,
  reportsEpics,
  invoiceEpics,
  jobEpics
});

// combine all module reducers
export const rootReducer = combineReducers({
  form,
  account,
  councils,
  users,
  search,
  map,
  liveJob,
  tickets,
  jobs,
  alerts,
  accounts,
  invoices,
  reports,
  messages,
});
