
// @flow

import styled from 'styled-components';

const Inner = styled.span`
  display: block;
  margin-top: -2px;
  width: 20px;
  height: 2px;
  background-color: #FFFFFF;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  top: 2px;

  &:before, &:after {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background-color: #FFFFFF;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &:before {
    top: 7px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }

  &:after {
    top: 14px;
  }

  &.is-active {
    transform: translate3d(0, 10px, 0) rotate(45deg);

    &:before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }

    &:after {
      transform: translate3d(0, -14px, 0) rotate(-90deg);
    }
  }
`;

export default Inner;

