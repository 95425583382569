import { getWasteStream } from './options';

const truckColors = {
  other: '#0095e3',
  general: '#DC0028',
  green: '#53c72c',
  recycle: '#E8AA0C',
  hard: '#FF5C00',
  glass: '#9541fe',
  organics: '#42C332',
};


const dotColors = {
  other: '#535353',
  general: '#DC0028',
  refuse: '#DC0028',
  green: '#53c72c',
  greenwaste: '#42C332',
  recycle: '#E8AA0C',
  hard: '#FF5C00',
  hardwaste: '#FF5C00',
  glass: '#9541fe',
  organics: '#42C332',
};


const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16),
  } : null;
};

export const getDotColor = (key: string): string =>
  dotColors[key.toLowerCase()] || dotColors.other;


export const getTruckColor = (key: string): string =>
  truckColors[key.toLowerCase()] || truckColors.other;

export const getEventColourFromWasteStream = (wasteStream: string): string =>
  getTruckColor(getWasteStream(wasteStream).key);

const truckSVGColors = {
  other: '#535353',
  general: '#DC0028',
  refuse: '#DC0028',
  bin: '#DC0028',
  greenwaste: '#42C332',
  green: '#42C332',
  recycle: '#FFEE3F',
  hard: '#FF5C00',
  hardwaste: '#ffa500',
};

export const getTruckSVGColor = (key: string): string =>
  truckSVGColors[key.toLowerCase()] || truckSVGColors.other;

export const getTruckSVGColorRgb = (key: string): string =>
  hexToRgb(truckSVGColors[key.toLowerCase()] || truckSVGColors.other);

const alertsSVGColors = {
  contaminated: '#B0D600',
  'not presented': '#F52A2A',
  'bin damaged': '#000',
  'bin not emptied': '#8D43FF',
  other: '#0095E3',
};

export const getAlertsSVGColor = (key: string): string =>
  alertsSVGColors[key.toLowerCase()] || truckSVGColors.other;
