
// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Form,
  Button,
} from 'semantic-ui-react';

import Input from '../Input';
import validate from './validate';

type Props = {
  handleSubmit: Function,
  isPosting: boolean,
  pristine: boolean,
};

function RaiseTicketForm({ handleSubmit, isPosting, pristine }: Props) {
  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Form.Group widths="equal">
        <Field name="firstname" label="First Name" component={Input} type="text" />
        <Field name="lastname" label="Last Name" component={Input} type="text" />
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="email" label="Email" component={Input} type="email" className="half-flex" />
      </Form.Group>
      <div className="form-footer clearfix">
        <Button
          type="submit"
          size="massive"
          color="green"
          loading={isPosting}
          floated="right"
          disabled={pristine || isPosting}
        >
          Create Ticket
        </Button>
        <Link to="/tickets">Cancel</Link>
      </div>
    </Form>
  );
}

// decorate
RaiseTicketForm = reduxForm({ // eslint-disable-line no-func-assign
  form: 'raiseTicket',
  validate,
})(RaiseTicketForm);

export default RaiseTicketForm;
