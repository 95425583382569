// @flow

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import Logo from '../../common/images/general/cleanaway.svg';
import Badge from './Badge';
import Hamburger from '../Hamburger';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  props: {
    handleLogout: Function,
    handleLogoClear: Function,
    handleCouncilChange: Function,
    account: Object,
    user: Object,
    location: Object,
    userAccess: Array,
  };

  toggle() {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }

  userHasAccessTo(section) {
    for (let i = 0; i < this.props.userAccess.length; i += 1) {
      if (this.props.userAccess[i] === section) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      handleLogout,
      handleCouncilChange,
      handleLogoClear,
      account: { lga, lgaImage, councilName },
      user: { firstName, lastName },
      location,
    } = this.props;

    const initial = (
      <div className="user-badge-wrapper">
        <Badge
          circular
          color="teal"
          content={firstName[0] + lastName[0]}
          size="big"
          className="user-badge"
        />
        <span style={{ marginLeft: '20px' }}>{`${firstName} ${lastName}`}</span>
      </div>
    );

    const icon = (
      <Icon
        name="angle right"
        style={{ float: 'right', marginRight: '0' }}
        fitted
      />
    );

    const userLinks = ['account', 'user-management'];

    let muni = null;
    if (lga) {
      if (location.pathname === '/live-collections') {
        muni = (
          <Menu.Item header className="muni">
            <span role="presentation" onClick={this.props.handleLogoClear}>
              {lgaImage ? (
                <img
                  src={`https://cleanaviewstaticprd.blob.core.windows.net/logos/${councilName}.png`}
                  alt={lga}
                  height={40}
                />
              ) : (
                lga
              )}
            </span>
          </Menu.Item>
        );
      } else {
        muni = (
          <Menu.Item header className="muni">
            <Link to="/live-collections">
              {lgaImage ? (
                <img
                  src={`https://cleanaviewstaticprd.blob.core.windows.net/logos/${councilName}.png`}
                  alt={lga}
                  height={40}
                />
              ) : (
                lga
              )}
            </Link>
          </Menu.Item>
        );
      }
    }

    return (
      <div className="header-bar">
        <Menu style={{ minHeight: '74px' }}>
          {muni}
          <Menu.Item header className="logo">
            {location.pathname === '/live-collections' ? (
              <span role="presentation" onClick={handleLogoClear}>
                <Image src={Logo} alt="Cleanaway" />
              </span>
            ) : (
              <Link to="/live-collections">
                <Image src={Logo} alt="Cleanaway" />
              </Link>
            )}
          </Menu.Item>
          <Menu.Item className="mobile-menu right">
            <Dropdown
              trigger={<Hamburger isActive={this.state.toggle} />}
              basic
              onClick={this.toggle}
              open={this.state.toggle}
              className="user-dropdown">
              <Dropdown.Menu className="scale">
                {this.userHasAccessTo('LIVE_SERVICE') && (
                  <NavLink to="/live-collections" className="item">
                    Live Collections
                  </NavLink>
                )}
                {this.userHasAccessTo('LIVE_SERVICE') && (
                  <NavLink to="/live-jobs" className="item">
                    Live Jobs
                  </NavLink>
                )}
                {this.userHasAccessTo('ALERTS') && (
                  <NavLink to="/alerts" className="item">
                    Alerts
                  </NavLink>
                )}
                {this.userHasAccessTo('REPORTS') && (
                  <NavLink to="/reports" className="item">
                    Reporting
                  </NavLink>
                )}
                {this.userHasAccessTo('TICKETS') && (
                  <NavLink to="/tickets" className="item">
                    Tickets
                  </NavLink>
                )}
                {this.userHasAccessTo('JOBS_LIST') && (
                  <NavLink to="/jobs" className="item">
                    Jobs
                  </NavLink>
                )}
                <NavLink to="/account" className="item">
                  My Account
                </NavLink>
                {/* {this.userHasAccessTo('INVOICE') && (
                  <NavLink to="/invoices" className="item">
                    Documents
                  </NavLink>
                )} */}
                {this.userHasAccessTo('USER_MANAGEMENT') && (
                  <NavLink to="/user-management" className="item">
                    User Management
                  </NavLink>
                )}
                <a
                  onClick={handleCouncilChange}
                  role="menuitem"
                  tabIndex="-1"
                  className="item">
                  Change Council {icon}
                </a>
                <a
                  onClick={handleLogout}
                  role="menuitem"
                  tabIndex="-1"
                  className="item">
                  Logout
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
          <Menu.Menu position="right" className="main-menu">
            {this.userHasAccessTo('LIVE_SERVICE') && (
              <NavLink to="/live-collections" className="item">
                Live Collections
              </NavLink>
            )}
             {this.userHasAccessTo('LIVE_JOBS') && (
              <NavLink to="/live-jobs" className="item">
                Live Jobs
              </NavLink>
            )}
            {this.userHasAccessTo('ALERTS') && (
              <NavLink to="/alerts" className="item">
                Alerts
              </NavLink>
            )}
            {this.userHasAccessTo('REPORTS') && (
              <NavLink to="/reports" className="item">
                Reporting
              </NavLink>
            )}
            {this.userHasAccessTo('TICKETS') && (
              <NavLink to="/tickets" className="item">
                Tickets
              </NavLink>
            )}
            {this.userHasAccessTo('JOBS_LIST') && (
                <NavLink to="/jobs" className="item">
                  Jobs
                </NavLink>
              )}
            {/* {this.userHasAccessTo('INVOICE') && (
              <NavLink to="/invoices" className="item">
                Documents
              </NavLink>
            )} */}
            <Dropdown
              item
              trigger={initial}
              basic
              className={`user-dropdown ${
                userLinks.includes(location.pathname.split('/')[1])
                  ? 'active'
                  : null
              }`}>
              <Dropdown.Menu>
                <NavLink to="/account" className="item">
                  My Account {icon}
                </NavLink>
                {this.userHasAccessTo('USER_MANAGEMENT') && (
                  <NavLink to="/user-management" className="item">
                    User Management {icon}
                  </NavLink>
                )}
                {this.userHasAccessTo('ACCOUNT_MANAGEMENT') && (
                  <NavLink to="/account-management" className="item">
                    Account Management {icon}
                  </NavLink>
                )}
                {/* <NavLink to="/council-select" className="item">
                    Change Council {icon}
                  </NavLink> */}
                {handleCouncilChange && (
                  <a
                    onClick={handleCouncilChange}
                    role="menuitem"
                    tabIndex="-1"
                    className="item">
                    Change Council {icon}
                  </a>
                )}
                <a
                  onClick={handleLogout}
                  role="menuitem"
                  tabIndex="-1"
                  className="item">
                  Logout {icon}
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}
