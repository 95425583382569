
// @flow

import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import ErrorMsg from './ErrorMsg';

type Props = {
  icon?: string,
  className?: string,
  placeholder?: string,
  input: Object,
  type: string,
  label?: string,
  name: string,
  width: number,
  meta: Object,
};

CustomInput.defaultProps = {
  icon: null,
  className: null,
  placeholder: null,
  label: null,
  width: null,
};

export default function CustomInput({ icon, className, placeholder, input, type, label, name, width, meta: { touched, error, warning } }: Props) {
  const dirty = touched && error ? true : false; // eslint-disable-line no-unneeded-ternary
  return (
    <Form.Field error={dirty} width={width} className={className}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      {touched && ((error && <ErrorMsg>{error}</ErrorMsg>) || (warning && <span>{warning}</span>))}
      <Input
        placeholder={placeholder}
        value={input.value}
        onChange={input.onChange}
        type={type}
        name={name}
        icon={icon}
      />
    </Form.Field>
  );
}
